import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setConfigDataAction} from '@/reducer/video_action';

export default connect(
    state => {
        return {
            configData: state.configData
        };
    },
    {
        setConfigDataAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeSrc: ''
        };
    }

    componentDidMount() {
        const configData = JSON.parse(localStorage.getItem('configData'));
        console.log(configData);
        configData.map(item => {
            if (item.menuType === 'menu-file') {
                this.setState({
                    iframeSrc: item.menuServer
                });

            }
            // if(item.menuType == 'menu-alarm'){
            //     this.setState({
            //         iframeSrc: item.menuServer
            //     })
            // }
        });
    }

    render() {
        const StyleIframe = {
            wrap: {
                width: '100%',
                height: '100%'
            }
        };

        return (
            <div className="limit-wrap" style={StyleIframe.wrap}>
                <iframe
                    title="file-recode"
                    name="file-recode"
                    style={{width: '100%', height: '100%'}}
                    src={`${this.state.iframeSrc}?token=${sessionStorage.getItem('token')}`}
                    frameBorder="0">
                </iframe>
            </div>

        );
    }
});