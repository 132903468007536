/*
 * @Author: yanghui 
 * @Date: 2019-09-23 14:56:49 
 */

import React from 'react';
import NowDateTime from '../view/components/date';
import PieDeviceOne from '../view/components/PieDeviceOne';
import PieDeviceTwo from '../view/components/PieDeviceTwo';
import {Alert, Button, Form, Input, message, Modal} from 'antd';
import {gathCount_api, log_api, record_api, scooper_poc_log, scooper_poc_server, sys_api} from '@/rest-api/restapi';
import AddModal from '@/view/components/Modal';
import {connect} from 'react-redux';
import {setConfigDataAction, setPoliceListAction} from '@/reducer/video_action';
import {sha256} from 'js-sha256';
import Subscribe from '@/subscribe';
import {initDispatch, destroy} from '@/view/components/dispatch-manage';
import sosMusic from '@/image/sos.mp3';
import QrCodeLogin from '@/component/qr-code-login';
import {getFile, getVideo} from '@/component/HelpDocument';
import {getGisTitle, getHomeTitle} from '@/api/SysConfig';
import '@/less/home/home.less';
import LockScreen from '@/containers/LockScreen/index';
import {setMenu} from "../view/pages/gis/gis-header"

const list1 = [
    {class: 'item1', name: 'GIS实时指挥', key: 'gis-conduct', type: 'gis'},
    {class: 'item2', name: '监控管理', key: 'minitor-manager', type: 'minitor/terminal'},
    {class: 'item3', name: '文件管理', key: 'doc-manager', type: 'document'},
    {class: 'item4', name: '告警记录', key: 'police-record', type: 'police'},
    {class: 'item5', name: '统计分析', key: 'statistics-analyze', type: 'statistics'}
];

const menuArr = [1, 2, 3, 4, 5];
const Home = connect(
    state => {
        return {
            policeList1: state.policeList1
        };
    },
    {
        setConfigDataAction,
        setPoliceListAction
    }
)(class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            removeAble: 'none',
            menuConfigData: [],
            visible: false,
            addAble1: 0,   //第一个模块可添加
            addAble2: 0,
            addAble3: 0,
            addAble4: 0,
            addAble5: 0,  //第五个模块可添加
            day: 0,
            hour: 0,
            minute: 0,
            imgAvatar: '',
            show: false,
            isShow: true,
            pageAuthority: '',  //模块权限
            eventType: 0,  //默认事件类型为sos
            warningShow: false,  //告警弹框默认不显示
            warningMessage: '',  //告警弹框默认提示内容
            accountConfigFlag: false // 是否获取扣件员配置项
        };
    }

    amendPwd = () => {
        this.setState({
            show: true
        });
    };

    onCancel = () => {
        this.setState({
            visible: false,
            show: false
        });
        this.props.form.resetFields();
    };

    modalShow = (menuIndex) => {
        this.setState({
            visible: true,
            menuIndex
        });
    };

    updateShow = (index) => {
        let a = `addAble${index}`;
        this.setState({
            [a]: !index
        });
    };

    /**
     * 功能模块路由跳转
     */
    jumpPage = (item) => {
        const {pageAuthority, accountConfigFlag} = this.state;
        console.log("yuany=",pageAuthority, accountConfigFlag,item);
        if (!accountConfigFlag) {
            return message.info('请稍后尝试...');
        }
        let _key = '';
        if (item.key === 'doc-manager') _key = 'file';
        if (item.key === 'gis-conduct') _key = 'gis';
        if (item.key === 'minitor-manager') _key = 'video';
        if (item.key === 'police-record') _key = 'alarm';
        if (item.key === 'statistics-analyze') _key = 'statistics';
        if (pageAuthority.indexOf(_key) === -1) {
            return message.info('该模块正在开发中...');
        }
        const menuConfigData = JSON.parse(localStorage.getItem('configData'));
        const firstDeptId = sessionStorage.getItem('firstDeptId');
        const {nativeBridge, top} = window;
        const globalNativeBridge = nativeBridge || top.nativeBridge;
        if (globalNativeBridge) {
            if (item.type !== 'minitor/terminal') {
                this.props.history.push(`main/${item.type}`);
            }
            if (this.props.policeList1 !== []) {
                this.props.setPoliceListAction({policeList1: {}});
            }
            if (item.type === 'minitor/terminal') {
                this.openWindow(firstDeptId);
            }
        } else {
            if (item.key === 'doc-manager') {
                menuConfigData.map(item_1 => {
                    if (item_1.menuType === 'menu-file') {
                        if (item_1.menuServer.indexOf('http') > -1) {
                            item.type = 'iframe';
                        } else {
                            item.type = 'document';
                        }
                    }
                });
                this.props.history.push(`main/${item.type}`);
            } else if (item.key === 'gis-conduct') {
                this.props.history.push(`main/${item.type}`);
            } else {
                this.props.history.push(`main/${item.type}`);
            }
        }
    };


    /**
     * 配置管理路由跳转
     */
    jumpPageMenuConfig = (item) => {
        const {pageAuthority, accountConfigFlag} = this.state;
        if (!accountConfigFlag) {
            return message.info('请稍后尝试...');
        }
        let _key = '';
        if (item.menuType === 'menu-dept') _key = 'dept';
        if (item.menuType === 'menu-das') _key = 'das';
        if (item.menuType === 'menu-dev') _key = 'dev';
        if (item.menuType === 'menu-group') _key = 'group';
        if (item.menuType === 'menu-oplog') _key = 'oplog';
        if (pageAuthority.indexOf(_key) === -1) {
            return message.info('该模块正在开发中...');
        }
        this.props.history.push(`${item.menuServer}`);    //路由跳转
    };

    removeAble = () => {
        if (this.state.removeAble === 'none') {
            this.setState({
                removeAble: 'block'
            });
        } else if (this.state.removeAble === 'block') {
            this.setState({
                removeAble: 'none'
            });
        }
    };

    /**
     * 获取页面标题
     */
    getTitle = async () => {
        // const param = {
        //     cfgKey: 'scooper.poc.home.title'
        // };
        // const data = await sys_api.getSysConfig(param);
        // if (!data.code) {
        //     this.setState({
        //         title: data.data.cfgValue
        //     });
        // }
    };

    componentWillMount() {
        this.getAccountConfig();
        this.avatarJudge();

        const token = sessionStorage.getItem('token') || localStorage.getItem('token');
        const accId = sessionStorage.getItem('accId') || localStorage.getItem('accId');
        // 通过token和accId获取对应的配置信息
        if (token && accId) {
            this.getPageConfig();
        } else {
            this.getDefaultSysConfig();
        }
    }

    componentDidMount() {
        this.loadConfig();
        this.timer = setInterval(() => this.timeChange(), 1000);  //设置定时器每隔一分钟变化一次时间
        if (!sessionStorage.getItem('token')) {
            setTimeout(() => this.props.history.push(`/`), 1000);
        }
        this.getHeart().then(resolve => {
            Subscribe.initSubscribe();
            this.getTitle();
            this.getGisConfig();

            const file = getFile();
            const video = getVideo();
            this.setState({
                file,
                video
            });

            this.getStatistic();
            this.getDiskUsageWarning();

            Subscribe.register('LIMIT_LOGIN', this.registerFn = ({data}) => {
                if (typeof (data) === 'string') {
                    data = JSON.parse(data);
                }
                if (data.loginTime !== sessionStorage.getItem('loginTime') && data.username === sessionStorage.getItem('username')) {
                    message.error('该用户已在别处登录！');
                    setTimeout(() => {
                        this.props.history.push(`/`);
                        localStorage.removeItem('flag');
                    }, 1000);
                }
            });

            let _this = this;
            // if (typeof scooper.poc === 'undefined') {
            initDispatch(this.props)
                .finally(() => {
                    _this.getSosList();
                });
        });

        // }
    }

    /**
     * 调度获取/监听sos告警
     */
    getSosList = () => {
        let _this = this;
        //初始化加载页面获取告警列表
        scooper.poc.groupPoc.listAllMemSos(function (listData) {
            if (!listData.code) {
                _this.setState({
                    sosList: listData.data
                });

                //检测初始化页面时是否有当前告警，如果有则加载告警音
                let _status = [];
                if (listData.data.length > 0) {
                    listData.data.map(function (val) {
                        _status.push(val.sosStatus);
                        if (val.sosStatus == 1) {
                            $('#sos-music').attr('src', sosMusic);
                        }
                    });
                }
                let status = _status.join(',');
                if (status.indexOf(1) == -1) {
                    $('#sos-music').attr('src', '');
                }
            }
        });

        //监听告警列表
        scooper.poc.listen(scooper.poc.event_const.POC_SOS_CHN, this.callback = function (data) {
            scooper.poc.groupPoc.listAllMemSos(function (listData) {
                var _status = [];
                if (!listData.code) {
                    _this.setState({
                        sosList: listData.data
                    });
                    if (listData.data) {
                        listData.data.map(function (item) {
                            _status.push(item.sosStatus);
                            if (item.sosStatus == 1) {
                                $('#sos-music').attr('src', sosMusic);
                            }
                        });
                    }
                    var status = _status.join(',');
                    if (status.indexOf(1) == -1) {
                        $('#sos-music').attr('src', '');
                    }
                }
            });
        });
    };

    /**
     * 获取磁盘信息
     */
    getDiskUsageWarning = () => {
        //监听磁盘信息
        Subscribe.register('DISK_USAGE_WARN', this.diskFn = ({data}) => {
            this.setState({
                warningMessage: data,
                warningShow: true
            });
        });
    };

    /**
     * 获取操作员配置
     */
    getAccountConfig = async () => {
        const param = {
            accId: sessionStorage.getItem('accId')
        };
        const data = await scooper_poc_server.getAccountConfig(param);
        if (!data.code && data.data && data.data.pageAuthority) {
            banList=data.data.pageAuthority;
            this.setState({
                pageAuthority: data.data.pageAuthority,
                accountConfigFlag: true
            });
        }
    };

    /**
     * 壳中打开新窗口
     */
    openWindow = (firstDeptId) => {
        var globalNativeBridge = window.nativeBridge || window.top.nativeBridge;
        var windowProxy;
        if (globalNativeBridge) {
            if (globalNativeBridge.screenOp.getAllScreenInfo().length < 2) {
                return;
            } else {
                var winProxy = globalNativeBridge.windowOp.findWindowByName('video-minitor');
                if (!winProxy) {
                    var url = `/scooper-poc-web/#/main/minitor?token=${sessionStorage.getItem('token')}&firstDeptId=${firstDeptId}`;
                    var windowPro = globalNativeBridge.windowOp.openAt(url, {screenIndex: 1, name: 'video-minitor'});
                    windowProxy = windowPro;
                }
            }
        } else {
            console.log('不是壳');
        }
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        Subscribe.unregister('LIMIT_LOGIN', this.registerFn);
        Subscribe.unregister('DISK_USAGE_WARN', this.diskFn);
        if (scooper.poc) {
            scooper.poc.unlisten(scooper.poc.event_const.POC_SOS_CHN, this.callback);
        }
    }


    /**
     * 判断当前用户有没有头像
     */
    avatarJudge = () => {
        if (sessionStorage.getItem('avatar')) {
            this.setState({
                noneAva: `/scooper-core-rest/data/contacts/orgMemberManage/getImage?token=${sessionStorage.getItem('token')}&fileName=${sessionStorage.getItem('avatar')}`
            });
        }
    };

    /**
     * 页面加载模块
     */
    loadConfig = async () => {
        this.setState({
            addAble1: 1,
            addAble2: 2,
            addAble3: 3,
            addAble4: 4,
            addAble5: 5
        });
        const param = {
            accId: sessionStorage.getItem('accId') || localStorage.getItem('accId')
        };
        const data = await scooper_poc_server.getMenuConfig(param);
        setMenu(data.data.list);
        if (!data.code) {
            const newData = [...data.data.list
            //     , {
            //     ...data.data.list[0],
            //     id: 21,
            //     menuIndex: 11,
            //     menuKey: 'menu-pocAnalysis',
            //     menuName: '考勤数据',
            //     menuServer: '/main/data_statistics',
            //     menuType: 'menu-poc-analysis',
            //     showType: 2
            // }
        ];
            this.setState({
                menuConfigData: newData,
                addAble1: 0,
                addAble2: 0,
                addAble3: 0,
                addAble4: 0,
                addAble5: 0
            });
            this.props.setConfigDataAction({configData: newData});
            localStorage.setItem('configData', JSON.stringify(newData));
        }

        const index = [];
        this.state.menuConfigData && this.state.menuConfigData.map(item => {
            if (item.menuIndex !== 10) {
                index.push(item.menuIndex);
            }
        });
        const _index = index.join(',');
        menuArr.map(item => {
            let menuShow = `addAble${item}`;
            if (_index.indexOf(item) === -1) {
                this.setState({
                    [menuShow]: item
                });
            }
        });
    };

    /**
     * 删除配置块
     */
    deleteConfig = async (menuIndex, e, menuName) => {
        e.stopPropagation();
        const param = {
            accId: sessionStorage.getItem('accId'),
            menuIndex: menuIndex,
            menuName: menuName
        };
        const data = await scooper_poc_server.deleteConfig(param);
        if (!data.code) {
            const data = await scooper_poc_server.getMenuConfig({accId: sessionStorage.getItem('accId')});
            let a = `addAble${menuIndex}`;
            this.setState({
                menuConfigData: data.data.list,
                [a]: menuIndex,
                addAble: 'block'
            });
        }
    };

    /**
     * 退出登录
     */
    doLogout = async () => {
        const data = await scooper_poc_log.doLogout({username: sessionStorage.getItem('username')});
        if (!data.code) {
            await window.auth.logout();
            await sessionStorage.removeItem('token');
            await localStorage.removeItem('token');
            await localStorage.removeItem('flag');
            await sessionStorage.removeItem('mapZoom');
            await sessionStorage.removeItem('mapType');
            this.props.history.push(`/`);
            scooper.poc.logout();
        }
        // Subscribe.unregister('LIMIT_LOGIN', this.registerFn);
        Subscribe.unregister('LIMIT_LOGIN', this.registerFn = ({data}) => {
            if (typeof (data) === 'string') {
                data = JSON.parse(data);
            }
            const _this = this;
            if (data.loginTime !== sessionStorage.getItem('loginTime') && data.username === sessionStorage.getItem('username')) {
                message.error('该用户已在别处登录！');
                setTimeout(async function () {
                    await window.auth.logout();
                    _this.props.history.push(`/`);
                    Subscribe.unregister('LIMIT_LOGIN', _this.registerFn);
                    localStorage.removeItem('flag');
                }, 1000);
            }
        });
        const globalNativeBridge = window.nativeBridge || window.top.nativeBridge;
        if (globalNativeBridge) {
            const windowArr = globalNativeBridge.windowOp.getOpenedWindows();
            windowArr && windowArr.map(item => {
                if (item.windowId !== globalNativeBridge.windowOp.currentWindowId) {
                    item.close();
                }
            });
        }
    };

    /**
     * 时间转换
     */
    timeChange() {
        let _diffDate;
        let _oldDate;
        const oldDate = sessionStorage.getItem('accessTime') || localStorage.getItem('accessTime');
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            _oldDate = oldDate.replace(/-/g, '/');
        } else {
            _oldDate = oldDate;
        }
        const diffDate = new Date().getTime() - new Date(_oldDate).getTime();

        if (diffDate < 0) {
            _diffDate = new Date().getTime() - (new Date(_oldDate).getTime() + diffDate);
        } else {
            _diffDate = new Date().getTime() - new Date(_oldDate).getTime();
        }
        const days = Math.floor(_diffDate / (24 * 3600 * 1000));
        const leave1 = _diffDate % (24 * 3600 * 1000);
        const hours = Math.floor(leave1 / (3600 * 1000));
        const leave2 = leave1 % (3600 * 1000);
        const minutes = Math.floor(leave2 / (60 * 1000));
        this.setState({
            day: days,
            hour: hours,
            minute: minutes
        });
    }

    /**
     * 获取首页采集站/执法记录仪的统计
     */
    getStatistic = async () => {
        const param = {
            token: sessionStorage.getItem('token') || localStorage.getItem('token'),
            corpId: sessionStorage.getItem('corpId') || localStorage.getItem('corpId')
        };
        const data = await Promise.all([
            await gathCount_api.getGatherCount(param),
            await record_api.getRecordCount(param)
        ]);
        this.setState({
            gatherCount: data[0].data,
            recordCount: data[1].data
        });
    };

    /**
     * 修改密码
     */
    resetPwd = async () => {
        const {
            form: {validateFields}
        } = this.props;
        const _this = this;
        validateFields(async (err, values) => {
            const param = {
                username: sessionStorage.getItem('username'),
                origPwd: sha256(values.origPwd),
                newPwd: sha256(values.newPwd)
            };
            if (values.origPwd === values.newPwd) {
                return message.error('新密码不能与旧密码相同！');
            }
            if (values.newPwd !== values.newPwd_2) {
                return message.error('两次输入密码不一致！请重新输入！');
            }
            if (values.newPwd.length < 6) {
                return message.error('请输入至少六位以上的新密码!');
            }
            let isnum = /^\d+$/.test(values.newPwd);
            let isstr = /^[a-zA-Z]+$/.test(values.newPwd);
            if (isnum) return message.error('密码不能全部为数字!');
            if (isstr) return message.error('密码不能全部为字母!');
            const data = await scooper_poc_log.resetPwd(param);
            if (!data.code) {
                message.success('密码修改成功！');
                this.onCancel();
                setTimeout(function () {
                    message.error('登录验证已过期，请重新登录！');
                    _this.props.history.push('/');
                }, 2000);
                await sessionStorage.removeItem('token');
                await localStorage.removeItem('flag');
            }
            if (data.code === 1000) {
                message.error('旧密码输入错误！');
            }
        });
    };

    /**
     * 心跳保活
     */
    getHeart = () => {
        return new Promise(async (resolve, reject) => {
            await window.auth.initialize();
            const {authed, token, authInfo, expired} = window.auth;
            if (expired) { // token 和 r-token都已经过期
                message.error('token过期，请重新登录。');
                this.props.history.push(`/login`);
            }
            // 若已登錄則輸出相關信息
            if (authed) {
                const {username, accountId, memberId} = authInfo;
                console.log('已登录账号：', username);
                console.log('Token：', token);
                console.log('账号ID：', accountId);
                console.log('关联的成员ID：', memberId);
                // 登录、退出、token刷新回调
                window.auth.onAuthChanged = (type) => {
                    if (type === 'refresh') {   // 当token更新时，需要同步更新缓存中的token
                        sessionStorage.setItem('token', window.auth.token);
                        localStorage.setItem('token', window.auth.token);
                    } else if (type === 'baned') {
                        this.props.history.push(`/login`);
                    }
                };
                resolve();
            }
        });
    };

    /**
     * 使用视频
     */
    getVideo = async () => {
        const data = await sys_api.getSysConfig({cfgKey: 'scooper.poc.file.video.address'});
        if (!data.code) {
            if (data.data.cfgValue || data.data.defaultValue) {
                this.setState({
                    video: true
                });
            } else if (data.data.cfgValue === '' && data.data.defaultValue === '') {
                this.setState({
                    video: false
                });
            }
        }
    };

    /**
     * 获取页面配置信息
     */
    getPageConfig = async () => {
        const param = {
            token: localStorage.getItem('token') || sessionStorage.getItem('token'),
            accId: localStorage.getItem('accId') || sessionStorage.getItem('accId')
        };
        const homeTitleData = await log_api.getPageConfig(param);
        if (homeTitleData.data) {
            const {pageName, pageTitle, pageGisName, pageImg} = homeTitleData.data;
            // console.log(pageName, pageTitle, pageGisName, pageImg, '==========');
            pageTitle && pageTitle.length && sessionStorage.setItem('pageTitle', pageTitle);
            pageName && pageName.length && sessionStorage.setItem('pageName', pageName);
            pageGisName && pageGisName.length && sessionStorage.setItem('pageGisName', pageGisName);
            document.title = sessionStorage.getItem('pageTitle');
            this.setState({
                pageName: sessionStorage.getItem('pageName')
            });
            this.setTitleImage(pageImg);
        }
    };

    getDefaultSysConfig = async () => {
        const pageTitle = sessionStorage.getItem('pageTitle') || '';
        const pageName = sessionStorage.getItem('pageName') || '';
        const pageGisName = sessionStorage.getItem('pageGisName') || '';
        // 如果未获得相应的配置信息则获取默认的配置信息
        if (pageTitle.length === 0) {
            document.title = document.getElementsByTagName('title')[0];
        }
        if (pageName.length === 0) {
            const homeTitleData = await getHomeTitle();
            const {cfgValue: pageName} = homeTitleData.data;
            sessionStorage.setItem('pageName', pageName);
            this.setState({
                pageName
            });
        }
        if (pageGisName.length === 0) {
            const gisTitleData = await getGisTitle();   // 获取默认登录gis标题数据
            const {cfgValue: pageGisName} = gisTitleData.data;
            sessionStorage.setItem('pageGisName', pageGisName);
            this.setState({
                pageGisName
            });
        }
    };

    /**
     * 获取地图配置信息
     */
    getGisConfig = async () => {
        const param = {
            token: sessionStorage.getItem('token'),
            accId: sessionStorage.getItem('accId')
        };
        const data = await scooper_poc_server.getGisConfig(param);
        if (!data.code && data.data.mapType && data.data.mapZoom && data.data.center) {
            sessionStorage.setItem('mapType', data.data.mapType);
            sessionStorage.setItem('mapZoom', data.data.mapZoom);
            sessionStorage.setItem('center', JSON.stringify(data.data.center));
            this.setState({
                eventType: data.data.eventType
            });
        }
    };

    /**
     * 设置网页Title的图标，favicon.icon
     * @param url
     */
    setTitleImage = (url) => {
        const token = sessionStorage.getItem('token') || localStorage.getItem('token');
        const {sysUrl} = window.config;
        //修改favicon
        const oLink = document.getElementById('linkId');
        if (oLink) {
            oLink.parentNode.removeChild(oLink);
        }
        const link = document.createElement('link');
        link.rel = 'shortcut icon';
        if (typeof url !== 'undefined' && url !== '') {
            link.href = `${sysUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${token}&filePath=${url}`;
        } else {
            link.href = '../../favicon.ico';
        }
        link.id = 'linkId';
        document.getElementsByTagName('head')[0].appendChild(link);
    };

    getCurrentId = (item) => {
        this.setState({
            currentId: item.sosKeyid
        });
    };

    resetCurrentId = () => {
        this.setState({
            currentId: ''
        });
    };

    /**
     * 告警列表显隐
     */
    getListBox = (e) => {
        e.stopPropagation();
        this.setState({
            isShow: !this.state.isShow
        });
    };

    /**
     * 忽略告警
     */
    ignoreSos = (data, e) => {
        e.stopPropagation();
        const {eventType} = this.state;
        const {sysUrl} = window.config;
        const params = {
            ids: data.id,
            token: sessionStorage.getItem('token')
        };
        let self = this;
        //当前告警
        if (data.sosStatus == 1) {
            scooper.poc.groupPoc.ignoreMemSos(data.memTel, function (resp) {
                self.addOplogRecord('op-sos-ignore', sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '的sos请求' : '的上呼请求'), '', sessionStorage.getItem('accId'), sessionStorage.getItem('corpId'));
            });
            self.addSosRecord(4, data.memTel, sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '发起的sos请求' : '发起的上呼请求'), data.sosKeyid, '');   //告警记录

            //忽略历史告警
        } else if (data.sosStatus == 0) {
            $.post(sysUrl + '/poc-web/data/alarmRecord/ignoreAlarmRecord', params, function (res) {
                if (!res.code) {
                    self.addOplogRecord('op-sos-ignore', sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '的sos请求' : '的上呼请求'), '', sessionStorage.getItem('accId'), sessionStorage.getItem('corpId'));
                    self.addSosRecord(4, data.memTel, sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '发起的sos请求' : '发起的上呼请求'), data.sosKeyid, '');   //告警记录
                    scooper.poc.groupPoc.listAllMemSos(function (listData) {
                        if (!listData.code) {
                            self.setState({
                                sosList: listData.data
                            });
                        }
                    });
                }
            });
        }

    };

    /**
     * 生成操作记录
     */
    addOplogRecord = (typeKey, content, logExt, accId, corpId) => {
        var param = {
            token: sessionStorage.getItem('token'),
            typeKey: typeKey,
            content: content,
            logExt: logExt,
            accId: accId,
            corpId: corpId
        };
        $.post(`${window.config.modulesUrl}/scooper-poc-server/data/manaOplog/saveManaOplog`, param, function (res) {
            if (!res.data) {
                console.log('新增操作日志');
            }
        });
    };

    /**
     * 生成告警记录
     */
    addSosRecord = (sosType, tel, content, sosKeyid) => {
        var param = {
            token: sessionStorage.getItem('token'),
            type: 3,
            content: content,
            memTel: tel,
            sosType: sosType,
            sosKeyid: sosKeyid
        };
        $.post(`${window.config.modulesUrl}/poc-web/data/alarmRecord/addAlarmRecord`, param, function (resp) {
            if (resp.code === 0) {
                console.log('新增告警记录');
            }
        });
    };


    /**
     * 处理sos告警并跳转至gis界面
     */
    dealSos = (data) => {
        var sosData = {
            memTel: data.memTel || data.mem.mem_tel,
            name: data.memName,
            status: data.sosStatus
            // id: data.sosKeyid
        };
        if (data.sosStatus == 1) {
            sosData.id = data.sosKeyid;
        } else if (data.sosStatus == 0) {
            sosData.id = data.id;
        }
        this.props.history.push(`main/gis?memTel=${sosData.memTel}&name=${sosData.name}&status=${sosData.status}&getType=sosJump&id=${sosData.id}`);
        sessionStorage.setItem('memTel', sosData.memTel);
        sessionStorage.setItem('name', sosData.name);
        sessionStorage.setItem('status', sosData.status);
        sessionStorage.setItem('getType', 'sosJump');
        sessionStorage.setItem('id', sosData.id);
    };

    /**
     * 磁盘告警弹框关闭之后的回调
     */
    handleClose = () => {
        this.setState({
            warningShow: false,
            warningMessage: ''
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {
            addAble1,
            addAble2,
            addAble3,
            addAble4,
            addAble5,
            file,
            video,
            sosList,
            currentId,
            isShow,
            pageAuthority,
            eventType,
            pageName,
            warningMessage,
            warningShow
        } = this.state;

        return (
            <div className='home-page'>
                <LockScreen/>
                <QrCodeLogin/>
                <div className='login-box'>
                    <div className='top-header'>
                        <div className='sosMusic'>
                            <audio id='sos-music' src='' autoPlay='autoplay' loop/>
                        </div>
                        <div className='top-left'>
                            <span
                                className='top-title'>{pageName}</span>
                            {warningShow && <Alert type='warning' message={warningMessage} closable showIcon
                                                   afterClose={this.handleClose}/>}
                        </div>
                        <div className='top-right'>
                            {/* {(file == true || video == true) && <div className="help">
                                <i className="help-icon"></i>
                                <span className="help-text">帮助</span>
                                {file == true && <a className="doc-icon" title="使用文档"  href={`${window.config.sysUrl}/scooper-poc-server/api/sysConfig/downloadFile?fileType=document`}></a>}
                                {video == true && <a className="video-icon" title="使用视频" href={`${window.config.sysUrl}/scooper-poc-server/api/sysConfig/downloadFile?fileType=video`}></a>}
                            </div>} */}

                            <div className='sos-box'>
                                <i className='police-icon' onClick={(e) => this.getListBox(e)}/>
                                {sosList && <span className='police-count'>{sosList.length}</span>}
                                {sosList && sosList.length > 0 && <div className={`sos-wrap ${isShow ? 'isShow' : ''}`}>
                                    <ul className='sos-ul'>
                                        {
                                            sosList && sosList.length > 0 && sosList.map(item => {
                                                return (
                                                    <li className='sos-list' key={item.sosKeyid}
                                                        onMouseEnter={() => this.getCurrentId(item)}
                                                        onMouseLeave={this.resetCurrentId}
                                                        onClick={() => this.dealSos(item)}>
                                                        <i/>
                                                        <span className='sos-content'
                                                              title={`${item.gmtCreate}  ${item.memName || item.memName}${eventType == 0 ? 'sos告警' : '上呼请求'}`}>{item.gmtCreate}&nbsp;&nbsp; {item.memName || item.memName}{eventType == 0 ? 'sos告警' : '上呼请求'}</span>
                                                        <span
                                                            className={`ignore-btn ${item.sosKeyid == currentId ? 'isShow' : ''}`}
                                                            onClick={(e) => this.ignoreSos(item, e)}>忽略</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>}
                            </div>
                            <NowDateTime/>
                        </div>
                    </div>
                    <div className='login-content'>
                        <div className='login-form'>
                            <div className='user-message'>
                                <div className='user-avatar'>
                                    <img src={this.state.noneAva} className='verify-img' style={{border: 'none'}}/>
                                </div>
                                <div className='login-title'>
                                    {sessionStorage.getItem('username') || localStorage.getItem('username')}
                                </div>
                            </div>
                            <div className='longtime'>
                                您本次已登录<span className='changeable'>{this.state.day}</span>天<span
                                className='changeable'>{this.state.hour}</span>小时<span
                                className='changeable'>{this.state.minute}</span>分
                            </div>
                            <div className='login-time'>
                                登录时间：<span>{sessionStorage.getItem('accessTime')}</span>
                            </div>
                            <div className='device-data'>
                                <span className='device-title'>设备在线情况</span>
                                {
                                    pageAuthority.indexOf('das') !== '-1' && <div className='device-pit'>
                                        <PieDeviceOne gatherCount={this.state.gatherCount}/>
                                        <div className='divider'/>
                                        <PieDeviceTwo recordCount={this.state.recordCount}/>
                                        <div className='divider'/>
                                    </div>
                                }

                                {pageAuthority.indexOf('das') == '-1' && <div className='device-pit1'>
                                    <div className='divider'></div>
                                    <PieDeviceTwo recordCount={this.state.recordCount}/>
                                </div>}
                                <div className='logout-btn'>
                                    <span className='amend-btn' onClick={this.amendPwd}>修改密码</span>
                                    <span className='exit-btn' onClick={this.doLogout}>退出登录</span>
                                </div>
                            </div>

                        </div>
                        <div className='nav-list'>
                            <p className='list1-title'>功能模块</p>
                            <span className='list1'>
                        {
                            list1.map((item) => {
                                if (item.key === 'police-record') {
                                    return (
                                        <div className={item.class} key={item.key} onClick={() => this.jumpPage(item)}>
                                            <i/>
                                            <div className='title'>{eventType == 0 ? item.name : '上呼记录'}</div>
                                        </div>
                                    );
                                }
                                return (
                                    <div className={item.class} key={item.key} onClick={() => this.jumpPage(item)}>
                                        <i/>
                                        <div className='title'>{item.name}</div>
                                    </div>
                                );

                            })
                        }
                        </span>
                            <p className='list2-title'>配置管理</p>
                            <div className='module-manager' onClick={this.removeAble}>
                                <i className='module-icon'/>
                                <span className='module-text'>模块管理</span>
                            </div>
                            <span className='list2'>
                        {
                            menuArr.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {eval(`addAble${item}`) === item && <div className={`part${item}`}>
                                            <i onClick={() => this.modalShow(item)}/>
                                        </div>}
                                    </React.Fragment>
                                );
                            })
                        }
                                {
                                    this.state.menuConfigData && this.state.menuConfigData.map((item) => {
                                        return (
                                            <div className={`item${item.menuIndex}`} key={item.menuIndex}
                                                 onClick={() => this.jumpPageMenuConfig(item)}>
                                                {item.menuType !== 'memu-gis' && item.menuType !== 'menu-video' && item.menuType !== 'menu-file' && item.menuType !== 'menu-alarm' && item.menuType !== 'menu-statistics' &&
                                                <i className={item.menuType}/>}
                                                {item.menuType !== 'menu-gis' && item.menuType !== 'menu-video' && item.menuType !== 'menu-file' && item.menuType !== 'menu-alarm' && item.menuType !== 'menu-statistics' &&
                                                <div className='title'>{item.menuName}</div>}
                                                <span className='remove-icon' style={{display: this.state.removeAble}}
                                                      onClick={(e) => this.deleteConfig(item.menuIndex, e, item.menuName)}/>
                                            </div>
                                        );
                                    })
                                }
                        </span>
                        </div>
                    </div>
                    <AddModal visible={this.state.visible} onCancel={this.onCancel} modalShow={this.modalShow}
                              menuConfigData={this.state.menuConfigData} menuIndex={this.state.menuIndex}
                              loadConfig={this.loadConfig} updateShow={this.updateShow}/>

                    <Modal
                        title='修改密码'
                        className='reset-pass'
                        visible={this.state.show}
                        cancelText='取消'
                        onCancel={this.onCancel}
                        okText='确认'
                        footer={[
                            <Button key='1' onClick={this.onCancel}>取消</Button>,
                            <Button key='2' onClick={this.resetPwd}>完成修改</Button>
                        ]}
                    >

                        <Form className='form'>
                            <Form.Item label='旧密码'>
                                {getFieldDecorator('origPwd', {
                                    initialValue: ''
                                    // rules: [{ required: true, message: '请输入旧密码!' }], 
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                            <Form.Item label='新密码'>
                                {getFieldDecorator('newPwd', {
                                    initialValue: ''
                                    // rules: [{ required: true, message: '请输入新密码!' }],   
                                })(
                                    <Input type='password'/>
                                )}
                            </Form.Item>
                            <Form.Item label='确认新密码'>
                                {getFieldDecorator('newPwd_2', {
                                    initialValue: ''
                                    // rules: [{ required: true, message: '请确认新密码!' }],   
                                })(
                                    <Input type='password'/>
                                )}
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </div>
        );
    }
});
let banList="";
const getConfig = async () => {
    const param = {
        accId: sessionStorage.getItem('accId')
    };
    const data = await scooper_poc_server.getAccountConfig(param);
    if (!data.code && data.data && data.data.pageAuthority) {
        banList=data.data.pageAuthority;
        return banList;
    }
};
export const getBanList=async()=>{
    if(banList===""){
        banList= await getConfig();
    }
        return banList; 
}

export default Form.create()(Home);
