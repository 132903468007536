import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import {Button, Input, message} from 'antd';
import {connect} from 'react-redux';
import {sha256} from 'js-sha256';
import {setLockScreenAction} from '@/reducer/video_action';
import styles from './LockScreen.module.css';

@connect(
    state => {
        return {
            lockTimer: state.lockTimer
        };
    }, {
        setLockScreenAction
    }
)
export default class LockScreen extends Component {
    constructor(props) {
        super(props);
        if (!this.node) {
            this.node = document.createElement('div');
            this.node.className = styles['portal-container'];
            document.body.insertBefore(this.node, document.getElementById('root'));
            this.props.setLockScreenAction({lockTimer: new Date().getTime()});
        }
    }

    componentDidMount() {
        window.addEventListener('mousemove', () => {
            this.props.setLockScreenAction({lockTimer: new Date().getTime()});
        });
        window.addEventListener('click', () => {
            this.props.setLockScreenAction({lockTimer: new Date().getTime()});
        });
        window.addEventListener('message', (event) => {
            if (event.data.type === 'mousemove' || event.data.type === 'mouseclick') {
                this.props.setLockScreenAction({lockTimer: new Date().getTime()});
            }
        });
        if (sessionStorage.getItem('isLockScreen') === 'true') {
            this.node.style.display = 'block';
        } else {
            this.lockMethod();
        }
    }

    //组件即将卸载时候删除dom节点
    componentWillUnmount() {
        this.node && this.node.remove();
        this.clear();
    }

    lockMethod = () => {
        this.clockInterval = setInterval(() => {
            const {lockTimer} = this.props;
            let newDate = new Date().getTime();
            const seconds = (newDate - lockTimer) / 1000;
            if (seconds > window.config.lockTime * 60) {
                this.node.style.display = 'block';
                sessionStorage.setItem('isLockScreen', 'true');
                document.onkeydown=(ev)=>{
                    ev = document.all ? window.event : ev;
                   if(ev.keyCode===13){
                   this.confirmHandler()
               }
               }
                this.clear();
            }
        }, 1000);
    };

    confirmHandler = () => {
        // this.node && this.node
        const value = this.inputElement.state.value;
        if(value===undefined) {
           return message.warning('请输入密码！');
        }
        if (sha256(value) === sessionStorage.getItem('password')) {
            this.inputElement.state.value = '';
            this.node.style.display = 'none';
            sessionStorage.setItem('isLockScreen', 'false');
            this.props.setLockScreenAction({lockTimer: new Date().getTime()});
            document.onkeydown=null;
            this.lockMethod();
        } else {
            this.inputElement.state.value = '';
            message.warning('密码输入错误，请重新输入。');
        }
    };

    clear = () => {
        clearInterval(this.clockInterval);
        // sessionStorage.removeItem('isLockScreen');
    };

    renderContent() {
        return (
            <div className={styles['portal-center']}>
                <div className={styles['portal-center_wrapper']}>
                    <h1 className={styles['portal-center_lock-tab']}>已锁定</h1>
                    <h4 className={styles['portal-center_lock-tip']}>请重新输入密码进行解锁</h4>
                    <Input ref={el => this.inputElement = el} type={'password'}
                           className={styles['portal-center-input']}
                           placeholder={'请输入密码...'}/>
                </div>
                <Button className={styles['portal-center_confirm']} onClick={this.confirmHandler}>确定</Button>
            </div>
        );
    }

    render() {
        return (
            this.node && createPortal(this.renderContent(), this.node)
        );
    }
}