/*
 * @Author: your name
 * @Date: 2021-03-11 20:03:18
 * @LastEditTime: 2021-03-19 14:52:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scooper-poc-web\src\view\pages\document\file-description.js
 */
import React, { PureComponent } from "react";
import { Form, Input, Select, Button, Divider, Icon, message } from "antd";
import { scooper_poc_server } from "../../../rest-api/restapi";

const { Option } = Select;

class FileDescription extends PureComponent {
    state ={
        showChoose: true,
        showCustom: false
    }
    
    componentDidMount() {
        this.props.onRef(this);
        this.listFileLawType();  //获取执法类型列表
    }

     /**
     * @description 添加自定义销售类型
     */
    addItem = () => {
        this.setState({
            showChoose: false,  //选项下拉框
            showCustom: true,  //自定义输入框
        })
        this.props.form.setFieldsValue({
            lawTypeName: ''
        });
    }

    /**
     * @description 弹框关闭的回调
     */
    restoreInitVal = () => {
        this.setState({
            showChoose: true,  //选项下拉框
            showCustom: false,  //自定义输入框
        })
        this.props.form.resetFields(); 
    }

    /**
     * @description 查询执法类型列表
     */
    listFileLawType = async() => {
        scooper_poc_server.listFileLawType().then(res => {
            this.setState({
                lawTypeList: res.data
            })
        })
    }

    /**
     * @description 更新文件信息
     */
    updateFileInfo = () => {
        const { fileDetail, form, showView } = this.props;

        form.validateFields((err, values) => {
            const params = {
                id: fileDetail.id,
                fileType: fileDetail.fileType
            }
            const targetParams = Object.assign(values, params);
            scooper_poc_server.updateFileInfo(targetParams).then(res => {
                if(!res.code) {
                    message.success(res.message || "修改成功！");
                    showView == 1 && this.props.loadTable(10);
                    showView == 2 && this.props.loadTable(15);
                    this.listFileLawType();
                    this.props.listFileLawType();
                }
            })
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { showChoose, showCustom, lawTypeList } = this.state;
        const { fileDetail } = this.props;
        const lawTypeOptions = lawTypeList && lawTypeList.map(item => (<Option key={item.id} value={item.typeName}>{item.typeName}</Option>))
        return (
            <div className="file-description">
                <Form.Item label="文件描述">
                {getFieldDecorator("description", {
                    initialValue: fileDetail.description,
                })(
                    <Input style={{ width: "9rem" }} />
                )}
                </Form.Item>
                <Form.Item label="手动标记">
                    {getFieldDecorator("fileLevel", {
                        initialValue: fileDetail ? fileDetail.fileLevel : '',
                    })(
                        <Select style={{ width: "9rem" }}>
                            <Option key="0" value={0}>普通</Option>
                            <Option key="1" value={1}>重要</Option>
                            <Option key="3" value={3}>其他</Option>
                        </Select>
                    )} 
                </Form.Item>

                {showChoose && <Form.Item label="执法类型">
                    {getFieldDecorator("lawTypeName", {
                        initialValue: fileDetail ? fileDetail.lawTypeName : '',
                    })(
                        <Select style={{ width: "9rem" }}
                        dropdownRender={menu => (
                            <div style={{ position: 'relative' }}>
                                {menu} 
                                <Divider style={{ margin: '4px 0' }} />
                                <div
                                style={{ padding: '4px 8px', cursor: 'pointer', color: "#00c1de" }}
                                onMouseDown={e => e.preventDefault()}
                                onClick={this.addItem}
                                >
                                <Icon type="plus" style={{position:'relative', marginLeft: '5px', top: '0', left: '0', color: "#00c1de"}} /> 自定义执法类型
                                </div>
                            </div>
                            )}
                        >
                        {lawTypeOptions}
                    </Select>
                    )} 
                </Form.Item>}
                {showCustom && <Form.Item label="执法类型">
                    {getFieldDecorator("lawTypeName", {
                        initialValue: '',
                    })(
                        <Input placeholder="请输入自定义类型" style={{ width: "9rem" }} /> 
                    )}
                    <p className="choose-lawtype" onClick={this.restoreInitVal}>点击选择已有类型</p>
                </Form.Item>}
                <Button className="file-ok" onClick={this.updateFileInfo}>确定</Button>
            </div>
        )
    }
}

export default Form.create()(FileDescription)
