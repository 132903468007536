const functionModuleList = [
    {class: 'item1', name: 'GIS实时指挥', key: 'gis-conduct', type: 'gis', cfgKey: 'scooper.poc.gis.title'},
    {class: 'item2', name: '监控管理', key: 'minitor-manager', type: 'minitor', cfgKey: 'scooper.poc.video.title'},
    {class: 'item3', name: '文件管理', key: 'doc-manager', type: 'document', cfgKey: 'scooper.poc.file.title'},
    {class: 'item4', name: '告警记录', key: 'police-record', type: 'police', cfgKey: 'scooper.poc.alarm.title'},
    {
        class: 'item5',
        name: '统计分析',
        key: 'statistics-analyze',
        type: 'statistics',
        cfgKey: 'scooper.poc.statistics.title'
    }
];

const settingManagementList = [
    {class: 'item1', name: '部门管理', key: 'department-manager', type: 'department', cfgKey: 'scooper.poc.dept.title'},
    {class: 'item2', name: '采集站管理', key: 'member-manager', type: 'member', cfgKey: 'scooper.poc.das.title'},
    {class: 'item3', name: '记录仪管理', key: 'terminal-manager', type: 'terminal', cfgKey: 'scooper.poc.dev.title'},
    {class: 'item4', name: '通话组管理', key: 'call-manager', type: 'call', cfgKey: 'scooper.poc.group.title'},
    {class: 'item5', name: '', key: 'add-more', type: 'add', cfgKey: ''}
];

const moduleManagementList = [];

export {functionModuleList, settingManagementList, moduleManagementList};