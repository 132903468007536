/*
 * @Description: 数据类型统计图
 * @Author: liulian
 * @Date: 2019-10-08 14:12:30
 * @LastEditTime: 2021-02-25 19:55:59
 * @LastEditors: yanghui
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {debounce, isEqual} from 'lodash';
import echarts from 'echarts';
import $ from '../../../lib/jquery-vendor';
import {setDataTypeAction, setDateStoreAction} from '@/reducer/video_action';
import {statistics} from '@/rest-api/restapi-video';
import {commonGet, commonPost} from '@/request/common-axios';


import resizeFootSize from './ResizeFontSize';


export default connect(
    state => {
        return {
            dataType: state.dataType,
            dateStore: state.dateStore

        };
    },
    {
        setDataTypeAction,
        setDateStoreAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.setFileOption = this.setFileOption.bind(this);
        this.initFile = this.initFile.bind(this);

        this.state = {};
    }


    componentDidMount() {
        this.initFile();

    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.dataType, nextProps.dataType)) {

            this.initFile(nextProps, 1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFn);
        this.myChart = null;
    }

    initFile = (nextProps, type) => {
        if (!this.fileChart) return;
        //外部传入的data数据
        const {data} = nextProps || this.props;


        //初始化echarts
        this.myChart = echarts.init(this.fileChart, 'customed');

        let options = null;
        options = this.setFileOption(data);

        this.myChart.clear();
        //设置options
        this.myChart.setOption(options);

        this.myChart.resize();

        window.addEventListener('resize', this.resizeFn = debounce(() => {
            this.myChart && this.myChart.resize();
        }, 200));
    };

    /**
     * echarts图表配置
     */
    setFileOption = (data) => {
        const fontSize = resizeFootSize();
        return {
            tooltip: {
                formatter: '{b} : {c} ({d}%)'
            },
            toolbox: {},  //工具栏，导出图片还原之类的
            calculable: true,
            series: [
                {
                    type: 'pie',
                    radius: ['22%', '58%'],
                    center: ['50%', '50%'],
                    legendHoverLink: false,
                    hoverOffset: 0,
                    roseType: 'radius',
                    color: ['#00C362', '#C49F22', '#0FACD6'],
                    label: {
                        fontSize: '90%',
                        show: true,
                        formatter: '{b} {d}%',
                        position: 'outside'
                    },
                    labelLine: {
                        normal: {
                            length: 3
                        }
                    },
                    itemStyle: {
                        normal: {
                            labelLine: {
                                length: 3
                            }
                        }
                    },
                    data: [
                        {value: data.audio, name: '音频'},
                        {value: data.video, name: '视频'},
                        {value: data.image, name: '图片'}
                    ]
                }
            ]
        };
    };

    render() {
        return (

            <div id="pitPie" style={{width: '100%', height: '100%'}} ref={fileChart => {
                this.fileChart = fileChart;
            }} className="data-type"></div>


        );
    }
});