/*
 * @Descripttion: 
 * @version: V1.0
 * @Author: yanghui
 * @Date: 2020-11-11 15:19:23
 * @LastEditors: yanghui
 * @LastEditTime: 2020-12-30 10:21:38
 */
/**
 * 存放消息接收通道地址
 * @author MASu 
 * @date 2018-12-13
 * @Last Modified by:   MASu
 * @Last Modified time: 2018-12-07 15:36:13 
 */
const hti = '/hti';
const manager = '/scooper-app-manager'

export const URLS = {
    DEV_ALARM: hti + '/alarm/dev',  //设备告警
    CLOSE_CLOUD_EYE: '/poc/cloudEyeClose',   //打开云眼
    LIMIT_LOGIN: '/scooper-poc-server/login',   //登录
    DISK_USAGE_WARN: manager + "/notify/space/diskUsageWarn",  //磁盘空间告警
};
