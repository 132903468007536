/*
 * File Created: 2019-09-24
 * Author:Chenhuan
 * Desc: （企业，通讯录成员、部门）添加/编辑
 * -----
 */
import React from 'react';
import {Input, Modal, Form, message, Select, TreeSelect, Icon} from 'antd';
import {connect} from 'react-redux';
import {scooper_app_api, scooper_core_api} from '@/util/api';

const Option = Select.Option;
const {TreeNode} = TreeSelect;
const {confirm} = Modal;

const CompanyEditModal = connect(
    state => {
        return {
            deptTreeData: state.deptTreeData
        };
    },
    {}
)(
    class CompanyEditModal extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                isAdd: false,
                type: '',
                visible: false,
                //选中列表信息
                record: undefined,
                centerList: [],
                modalInput: [],
                //加载
                confirmLoading: false,
                //部门id
                deptId: '',
                //公司信息
                recordInfo: '',
                deptValue: undefined,
                //树节点
                treeData: [],
                // 加载过的树节点
                loadedKeys: [],
                //默认选中的树节点
                treeSelectedKeys: [],
                title: ''
            };
        }

        componentDidMount() {
            this.props.showModal(this.showModal);
            this.loadCenter();
        }

        // 暴露接口给父组件来打开弹窗
        showModal = async data => {
            this.onInitTree(data.deptId);
            this.setState({
                isAdd: data.type === 'add' ? true : false,
                modalInput: data.dataConfig,
                title: data.title,
                type: data.type,
                record: data.record || '',
                visible: true,
                netPort: data.scooper_api,
                recordInfo: data.recordInfo,
                deptId: data.deptId || ''
            });
        };

        // 取消添加
        handleCancel = () => {
            this.props.form.resetFields();
            this.setState({
                confirmLoading: false,
                visible: false,
                isAdd: false
            });
        };

        // 添加
        addSubmit() {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                } else {
                    this.setState({
                        confirmLoading: true
                    });
                    const port = this.state.netPort;
                    switch (port) {
                        case 'saveOrgMember':
                            let params = {
                                corpId: this.state.recordInfo.corpId,
                                memName: values.memName,
                                memCode: values.memCode,
                                memMobile: values.memMobile,
                                deptId: String(values.deptId),
                                isCreateLog: 1
                            };
                            scooper_app_api
                                .saveOrgMember(params)
                                .then(() => this.addSuccess())
                                .then(res => {
                                    this.props.form.resetFields();
                                    this.setState({
                                        confirmLoading: false,
                                        visible: false,
                                        isAdd: false
                                    });
                                })
                                .catch(error => {
                                    this.setState({
                                        confirmLoading: false
                                    });
                                });
                            break;
                        case 'saveOrgDept':
                            let param2 = {
                                parentId: values.deptId ? String(values.deptId) : 0, //上级部门id
                                deptName: values.deptName,
                                shortName: values.shortName,
                                deptDesc: values.deptDesc,
                                corpTel: values.corpTel,
                                corpId: this.state.recordInfo
                                    ? this.state.recordInfo.id
                                    : values.corpId,
                                orgCode: values.orgCode,
                                accUsername: values.accUsername,
                                isCreateLog: 1
                            };
                            scooper_app_api
                                .saveOrgDept(param2)
                                .then(() => this.addSuccess())
                                .then(() => {
                                    this.props.form.resetFields();
                                    this.setState({
                                        confirmLoading: false,
                                        visible: false,
                                        isAdd: false
                                    });
                                    this.props.onInitTree();
                                })
                                .catch(error => {
                                    this.setState({
                                        confirmLoading: false
                                    });
                                });
                            break;
                        default:
                    }
                }
            });
        }

        // 提醒用户是否添加或编辑成功
        addSuccess() {
            if (this.state.isAdd) {
                this.props.loadTable();
                message.success('新增成功!');
            } else {
                this.props.loadTable();
                message.success('编辑成功!');
            }
        }

        //提交编辑的用户信息
        editSubmit() {
            const {record, type} = this.state;
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                } else {
                    confirm({
                        title: '确定编辑？',
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            this.setState({
                                confirmLoading: true
                            });
                            const port = this.state.netPort;
                            switch (port) {
                                case 'updateOrgCorp':
                                    let params = {
                                        parentId: values.deptId ? String(values.deptId) : 0, //上级部门id
                                        deptName: values.deptName,
                                        shortName: values.shortName,
                                        deptDesc: values.deptDesc,
                                        corpAddr: values.orgCode,
                                        corpTel: values.corpTel,
                                        id: record.id,
                                        isCreateLog: 1
                                    };
                                    scooper_app_api
                                        .updateOrgDept(params)
                                        .then(() => this.addSuccess())
                                        .then(() => {
                                            this.props.form.resetFields();
                                            this.setState({
                                                confirmLoading: false,
                                                visible: false,
                                                isAdd: false
                                            });
                                        })
                                        .catch(error => {
                                            this.setState({
                                                confirmLoading: false
                                            });
                                        });
                                    break;
                                case 'updateOrgMemberInfo':
                                    let checkedeNum;
                                    if (type === 'change') {
                                        checkedeNum = record;
                                    } else {
                                        checkedeNum = record.id;
                                    }
                                    var memLevel;
                                    if (values.memLevel && values.memLevel.toString().indexOf('(') > -1) {
                                        memLevel = values.memLevel.split('(')[0];
                                    } else {
                                        memLevel = values.memLevel;
                                    }
                                    let param = {
                                        deptId: String(values.deptId),
                                        memName: values.memName,
                                        memMobile: values.memMobile,
                                        memCode: values.memCode,
                                        ids: checkedeNum,
                                        memLevel: memLevel,
                                        isCreateLog: 1
                                    };
                                    scooper_app_api
                                        .updateOrgMemberInfo(param)
                                        .then(() => this.addSuccess())
                                        .then(() => {
                                            this.props.form.resetFields();
                                            this.setState({
                                                confirmLoading: false,
                                                visible: false,
                                                isAdd: false
                                            });
                                        })
                                        .catch(error => {
                                            this.setState({
                                                confirmLoading: false
                                            });
                                        });
                                    break;
                                default:
                            }
                        }
                    });
                }
            });
        }

        onValuesChange() {
            this.setState({
                confirmLoading: true
            });
        }

        // 获取区域
        loadCenter() {
            scooper_core_api.listAdCode().then(res => {
                this.setState({
                    centerList: res
                });
            });
        }

        onChange = value => {
            // console.log(value);
        };

        // 初始化树节点
        onInitTree(deptId) {
            this.setState({
                treeData: this.props.deptTreeData,
                treeSelectedKeys: [deptId + '']
            });
        }

        /**
         * @desc 加载树节点
         * */
        onLoadTree = treeNode =>
            new Promise(resolve => {
                let {treeData} = this.state;
                if (treeNode.props.children && treeNode.props.children.length) {
                    resolve();
                    return;
                }
                const id = treeNode.props.eventKey.toString();
                scooper_app_api
                    .listOrgDept({
                        parentId: id
                        // deptId: this.state.addbulk
                    })
                    .then(res => {
                        treeNode.props.dataRef.children = res.list;
                        this.setState({
                            treeData: [...treeData]
                        });
                        resolve();
                    });
            });

        //加载树节点
        renderTreeNodes = data =>
            data.map(item => {
                if (item.children) {
                    return (
                        <TreeNode
                            value={item.id}
                            title={item.deptName}
                            key={item.id}
                            dataRef={item}
                        >
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                }
                return (
                    <TreeNode
                        value={item.id}
                        title={item.deptName}
                        key={item.id}
                        dataRef={item}
                    />
                );
            });

        render() {
            const {
                isAdd,
                visible,
                record,
                centerList,
                modalInput,
                treeData,
                confirmLoading,
                treeSelectedKeys,
                title
            } = this.state;
            const {getFieldDecorator} = this.props.form;
            const formItemLayout = {
                labelCol: {span: 8},
                wrapperCol: {span: 15},
                labelAlign: 'center'
            };
            //区域下拉
            const options = centerList.map(list => (
                <Option key={list.areaCode}>{list.areaName}</Option>
            ));

            const levelArr = [{id: 0, value: '0(最低)'}, {id: 1, value: '1(较低)'}, {id: 2, value: '2(较低)'}, {
                id: 3,
                value: '3(中等)'
            }, {id: 4, value: '4(中等)'}, {id: 5, value: '5(中等)'}, {id: 6, value: '6(中等)'},
                {id: 7, value: '7(较高)'}, {id: 8, value: '8(较高)'}, {id: 9, value: '9(较高)'}, {id: 10, value: '10(最高)'}];
            const memLevelOptions = levelArr.map((list, index) => <Option key={list.id}
                                                                          value={list.id}>{list.value}</Option>);
            return (
                <React.Fragment>
                    {visible && (
                        <Modal
                            title={isAdd ? `添加${title}` : `编辑${title}`}
                            maskClosable={false}
                            confirmLoading={confirmLoading}
                            visible={visible}
                            onOk={isAdd ? () => this.addSubmit() : () => this.editSubmit()}
                            onCancel={this.handleCancel}
                            className="edit-gather"
                            width="440px"
                        >
                            <Form
                                {...formItemLayout}
                                onValuesChange={changedValues =>
                                    this.onValuesChange(changedValues)
                                }
                            >
                                {modalInput.map(item => {
                                    if (item.key === 'orgCode') {
                                        return (
                                            <Form.Item label={item.title}>
                                                {getFieldDecorator(item.key, {
                                                    initialValue: isAdd ? '' : record[item.key],
                                                    rules: [
                                                        {
                                                            required: item.required,
                                                            message: `请选择${item.title}`
                                                        }
                                                    ]
                                                })(
                                                    <Select
                                                        placeholder="请选择区域"
                                                        clearIcon={
                                                            <span>
                                <i className="cancel"></i>
                              </span>
                                                        }
                                                        allowClear
                                                    >
                                                        {options}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        );
                                    } else if (item.key === 'deptId') {
                                        return (
                                            <Form.Item label={item.title}>
                                                {getFieldDecorator(item.key, {
                                                    initialValue: treeSelectedKeys,
                                                    rules: [
                                                        {
                                                            required: item.required,
                                                            message: `请选择${item.title}`
                                                        }
                                                    ]
                                                })(
                                                    <TreeSelect
                                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                                        allowClear
                                                        onChange={this.onChange}
                                                        loadData={this.onLoadTree}
                                                    >
                                                        {this.renderTreeNodes(treeData)}
                                                    </TreeSelect>
                                                )}
                                            </Form.Item>
                                        );
                                    } else if (item.key === 'memLevel') {
                                        return (
                                            <Form.Item label={item.title}>
                                                {getFieldDecorator(item.key, {
                                                    initialValue: isAdd ? '' : levelArr[record.memLevel].value,
                                                    rules: item.rules
                                                })(<Select>
                                                    {memLevelOptions}
                                                </Select>)}
                                            </Form.Item>
                                        );
                                    } else {
                                        return (
                                            <Form.Item label={item.title}> key={item.key}
                                                {getFieldDecorator(item.key, {
                                                    initialValue: isAdd ? '' : record[item.key],
                                                    rules: item.rules
                                                })(<Input maxLength={32}/>)}
                                            </Form.Item>
                                        );
                                    }
                                })}
                            </Form>
                        </Modal>
                    )}
                </React.Fragment>
            );
        }
    }
);

export default Form.create({mapProps: props => props})(CompanyEditModal);
