/*
 * @Author: yanghui 
 * @Date: 2019-09-30 10:33
 */

import React from 'react';
import {Input, Form, Select, Button, Modal, message, Layout} from 'antd';
import {connect} from 'react-redux';
import {gather_api} from '@/rest-api/restapi';
import UserSelect from './select-user/UserPicker';
import {
    setDeptMemAction,
    setAddUserAction,
    setShowSelectAction
} from '@/reducer/video_action';

const {Content} = Layout;
const FormItem = Form.Item;
const {TextArea} = Input;
const coreIp = sessionStorage.getItem('corpId');
const token = sessionStorage.getItem('token');

const AddModal = connect(
    state => {
        return {
            deptMem: state.deptMem,
            addUser: state.addUser,
            showSelect: state.showSelect
        };
    },
    {
        setDeptMemAction,
        setAddUserAction,
        setShowSelectAction
    }
)(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                visible: true
            };
        }

        onCancel = () => {
            this.props.onCancel();
            this.props.form.resetFields();
        };

        //判断token可用
        tokenJudge() {
            let params = {
                token: token
            };

            let postData = new URLSearchParams();
            for (let key in params) {
                postData.append(key, params[key]);
            }
            axios.post(coreIp + '/system/authManage/tokenVerify', postData)
                .then((response) => {
                    if (response.data.code === 0) {
                        //console.log(response.data);
                    } else {
                        message.error(response.data.message);
                    }
                })
                .catch(function (error) {
                        console.log(error);
                    }
                );
        }

        /**
         * 人员选择
         */
        showSelector = () => {
            let {singleData} = this.state;
            this.setState({
                modalVisible: true,
                mode: 0,
                defaultMemData: '',
                showInpVal: 1
            });
        };

        //多人添加按钮
        addMultiPerson() {
            let {multiData} = this.state;
            this.setState({
                modalVisible: true,
                mode: 1,
                defaultMemData: multiData
            });
        }

        //获取人员选择器 人员
        getMemData = (memData) => {
            let {mode} = this.state;
            if (mode === 1) {
                this.setState({
                    multiData: memData
                });
            } else {
                this.setState({
                    singleData: memData
                });
            }
            this.setState({
                modalVisible: false
            });
        };

        /**
         * 更新采集站人员绑定信息
         */
        update_Gather = async () => {
            const {
                form: {validateFields}
            } = this.props;
            validateFields(async (err, values) => {
                console.log(values);
                var param = {};
                $.each(values, (key, value) => {
                    if (key !== 'memId') {
                        param[key] = value;
                    }
                    param.memId = this.props.addUser.id;
                    param.id = this.props.id;
                    param.gzzXh = this.props.gzzXh;
                });
                const data = await gather_api.updateGather(param);
                if (!data.code) {
                    message.success(data.message);
                    this.props.onCancel();
                    var param1 = {
                        token: sessionStorage.getItem('token'),
                        corpId: sessionStorage.getItem('corpId'),
                        pageNum: 1,
                        pageSize: 10
                        // orgCode: sessionStorage.getItem('orgCode')
                    };
                    const res = await gather_api.getDataGather(param1);
                    if (!res.code) {
                        this.props.setGatherListAction({gatherList: res.data.list});
                        this.props.setGatherListAllAction({gatherListAll: res.data});
                        this.onCancel();
                        this.props.form.resetFields();
                    }
                }
            });
        };


        render() {
            const {getFieldDecorator} = this.props.form;
            let {modalVisible, mode, singleData, multiData, defaultMemData} = this.state;

            let multiMem = [];
            if (multiData) {
                multiData.map((item) => {
                    multiMem.push(item.memName);
                });
            }

            //向选择器组件传参
            let userPickerParams = {
                visible: modalVisible,
                mode: mode,
                ip: '',
                token: token,
                deptType: 'all',
                limit: 5,
                defaultMemData: defaultMemData
            };

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 6}
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16}
                }
            };

            const userPickerForm = () => {
                return (
                    <React.Fragment>
                        {this.props.showSelect == 1 && this.props.addUser && <Form.Item className="left">
                            <span className="key-words">部门</span>
                            {getFieldDecorator('deptId', {
                                initialValue: this.props.addUser.deptId
                            })(
                                <Select style={{width: '17.5rem'}} dropdownClassName="add-xiala">
                                    {
                                        this.props.deptMem && this.props.deptMem.map(item => {
                                            return (
                                                <Select.Option value={item.deptId} key={item.deptId}>
                                                    {item.deptName}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>}
                        {this.props.showSelect == 0 &&
                        <Form.Item className="left">
                            <span className="key-words">部门</span>
                            {getFieldDecorator('deptId', {
                                initialValue: this.props.deptId
                            })(
                                <Select style={{width: '17.5rem'}} dropdownClassName="add-xiala">
                                    {
                                        this.props.deptMem && this.props.deptMem.map(item => {
                                            return (
                                                <Select.Option value={item.deptId} key={item.deptId}>
                                                    {item.deptName}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        }
                    </React.Fragment>
                );
            };
            return (
                <Modal
                    title="修改人员信息"
                    visible={this.props.showAmend}
                    onOk={this.props.handleOk}
                    onCancel={this.props.onCancel}
                    className="amend-gather"
                    okText="确认"
                    cancelText="取消"
                    footer={[
                        <Button key="back" onClick={this.onCancel} className="cancel">
                            取消
                        </Button>,
                        <Button key="submit" className="confirm" onClick={this.update_Gather}>
                            更新
                        </Button>
                    ]
                    }
                >
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Content
                            style={{background: '#fff', padding: 24, margin: 0, minHeight: 280, position: 'relative'}}>
                            {userPickerForm()}
                        </Content>
                        <UserSelect {...userPickerParams} memData={memData => this.getMemData(memData)}/>
                    </Layout>
                    {this.props.showSelect == 1 && this.props.addUser && <Form.Item className="left">
                        <span className="key-words">人员</span>
                        {getFieldDecorator('memId', {
                            initialValue: ''
                        })(
                            <React.Fragment>
                                <Input maxLength={32} value={this.props.addUser.memName}/>
                                <i className="amend-btn" onClick={this.showSelector}></i>
                            </React.Fragment>
                        )}
                    </Form.Item>}

                    {this.props.showSelect == 0 && <Form.Item className="left">
                        <span className="key-words">人员</span>
                        {getFieldDecorator('memId', {
                            initialValue: ''
                        })(
                            <React.Fragment>
                                <Input maxLength={32} value={this.props.memName}/>
                                <i className="amend-btn" onClick={this.showSelector}></i>
                            </React.Fragment>
                        )}
                    </Form.Item>
                    }

                </Modal>
            );

        }
    }
);

export default Form.create()(AddModal);