import React, {Component} from 'react';
import {Checkbox, Popconfirm} from 'antd';
import {setSelectedValueAction} from '@/reducer/video_action';
import musicImg from '@/image/music.png';
import videoImg from '@/image/video.png';
import {connect} from 'react-redux';

@connect(state => {
    return {
        selectedValue: state.selectedValue
    };
}, {
    setSelectedValueAction
})
export default class Detail extends Component {

    constructor(props) {
        super(props);
        this.arr = [];
        // this.state = {
        //     checked: false
        // };
    }

    onChangeHandler = (e) => {
        const {selectedValue} = this.props;
        /*if (this.props.selectedValue.length === 0) {
            this.arr = [];
        }*/
        if (e.target.checked) {
            const newData = [this.props, ...selectedValue];
            this.props.setSelectedValueAction({selectedValue: newData});
            /*this.arr.push(e.target.value);
            const _arr = [];    //数组去重
            for (let i = 0; i < this.arr.length; i++) {
                if (_arr.indexOf(this.arr[i]) === -1) {
                    _arr.push(this.arr[i]);
                }
            }
            this.props.setSelectedValueAction({selectedValue: _arr});*/
        } else {
            //取消选中
            const selectedData = selectedValue.filter(item => {
                return item.id !== this.props.id;
            });
            this.props.setSelectedValueAction({selectedValue: selectedData});
            /*for (let len = this.arr.length, i = len - 1; i >= 0; i--) {
                if (this.arr[i].id.indexOf(e.target.value.id) !== -1) {
                    this.arr.splice(i, 1);
                }
            }
            this.props.setSelectedValueAction({selectedValue: this.arr});*/
        }
    };

    render() {
        const {
            id,
            fileType,
            filePath,
            fileName,
            fileSize,
            memName,
            fileMoveStatus,
            cloudFilePath,
            devNo,
            gmtCreate,
            flowStatus,
            preView,
            onDelete,
            noFile,
            thumbPath
        } = this.props;
        // const {checked} = this.state;
        // console.log(checked, '========checked', this.props);
        const thumbUrl = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&filePath=${thumbPath}`;
        return (
            <>
                {fileType == 0 &&
                <img
                    onClick={() => preView(fileType, filePath, fileName, fileSize, memName, this.props)}
                    src={fileMoveStatus == 2 ? `${cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}` : `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&fileId=${id}`}
                    alt={'图片类型'}/>
                }
                {fileType == 2 &&
                <img src={musicImg} className="musicImg" onClick={
                    () => preView(fileType, filePath, fileName, fileSize, memName, this.props)}
                     alt={'音频类型'}/>
                }
                {fileType == 1 &&
                <>
                    <img src={thumbUrl} alt={'视频缩略图'}/>
                    <img src={videoImg} className="videoImg"
                         onClick={() => preView(fileType, filePath, fileName, fileSize, memName, this.props)}
                         alt={'视频播放图片'}/>
                </>
                }
                {fileType == -2 &&
                <>
                    <img src={thumbUrl} alt={'视频缩略图'}/>
                    <img src={videoImg} className="videoImg"
                         onClick={() => preView(fileType, filePath, fileName, fileSize, memName, this.props)}
                         alt={'云眼视频'}/>
                </>
                }
                <div className="doc-detail"/>
                <div className="ant-checkbox-group-custom">
                    <Checkbox onChange={this.onChangeHandler}/>
                </div>
                {/*<Checkbox.Group>
                    <Checkbox value={this.props} onChange={this.onChangeHandler}/>
                </Checkbox.Group>*/}
                <div className="operate">
                    {((fileMoveStatus == 2 && cloudFilePath != '') || (fileMoveStatus != 2 && filePath != '')) &&
                    <a className="edit" target="_blank"
                       href={fileMoveStatus == 2 ? `${this.changeProto(cloudFilePath)}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}` : `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`}
                       onClick={(e) => {
                           noFile(this.props, e);
                           flowStatus ? null : e.preventDefault();
                       }}><i className="download"/></a>}
                    {((fileMoveStatus == 2 && cloudFilePath == '') || (fileMoveStatus != 2 && filePath == '')) &&
                    <a className="edit" target="_blank"
                       href={fileMoveStatus == 2 ? `${this.changeProto(cloudFilePath)}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}` : `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`}
                       onClick={(e) => {
                           noFile(this.props, e);
                           e.preventDefault();
                       }}><i className="download"/></a>}
                    <Popconfirm title="确认要删除吗？" okText="确认" cancelText="取消" className="popConfirm"
                                onConfirm={() => onDelete(id, fileName)}>
                        <i className="delete"/>
                    </Popconfirm>
                </div>
                <span className="dev-nem">设备编号： {devNo}</span>
                <span className="username">姓名： {memName}</span>
                <span className="pitTime">拍摄时间： {gmtCreate}</span>
            </>
        );
    }
}