import React from 'react';
import {Table, Divider, Icon, Popconfirm, message, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {
    setSortListAction,
    setSortListAllAction,
    setSelectedKeyLogAction
} from '@/reducer/video_action';
import {log_api} from '@/rest-api/restapi';


const LogTable = connect(
    state => {
        return {
            sortList: state.sortList,
            sortListAll: state.sortListAll
        };
    },
    {
        setSortListAction,
        setSortListAllAction,
        setSelectedKeyLogAction
    }
)
(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     *checkbox状态
     */
    onSelectChange = (selectedRowKeys) => {
        console.log('选中的行为: ', selectedRowKeys);
        this.setState({
            selectedRowKeys
        });
        this.props.setSelectedkeyLogAction({selectedKey: selectedRowKeys});
    };

    /**
     * 分页请求
     */
    onChangePage = async (page, pageSize) => {
        const param = {
            token: sessionStorage.token,
            pageNum: page,
            pageSize: pageSize,
            corpId: sessionStorage.getItem('corpId'),
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            categoryId: this.props.categoryId,
            typeId: this.props.typeId,
            accShowName: this.props.accShowName,
            accUserName: this.props.accUserName
            // orgCode: sessionStorage.getItem('orgCode')
        };
        const data = await log_api.getOplogList(param);
        if (!data.code) {
            this.props.setSortListAction({sortList: data.data.list});
            this.props.setSortListAllAction({sortListAll: data.data});
        }
    };


    render() {
        const columns = [{
            title: '序号',
            render: (text, record, index) => {
                return <span>{(pagination.current - 1) * 10 + index + 1}</span>;
            }
        }, {
            title: '日志类型',
            dataIndex: 'type'
        }, {
            title: '日志时间',
            dataIndex: 'gmtCreate'
        },
            {
                title: '操作员',
                dataIndex: 'accShowName'

            },
            {
                title: '操作员账号',
                dataIndex: 'accUserName'
            },
            {
                title: '日志内容',
                dataIndex: 'content',
                ellipsis: true,
                render: (text, record) => {
                    if (record.respResult == 0) {
                        return <Tooltip placement="topLeft" title={text + '成功'}><span>{text}成功</span></Tooltip>;
                    } else if (record.respResult == 1) {
                        return <Tooltip placement="topLeft" title={text + '失败'}><span>{text}失败</span></Tooltip>;
                    }
                },
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 250,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    };
                }
                // render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
            }
        ];


        const {selectedRowKeys} = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows) => {
                this.props.setSelectedLogAction({selectedRowsLog: selectedRows});
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.props.setSelectedLogAction({selectedRowsLog: selectedRows});
            }
        };

        let pagination = {
            current: this.props.sortListAll.pageNum,
            total: this.props.sortListAll.total,
            pageSize: 10,
            showSizeChanger: true,
            itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            },
            onChange: this.onChangePage
        };

        return (
            <div className="content">
                <Table rowSelection={rowSelection} columns={columns} rowKey={record => record.id}
                       pagination={pagination} dataSource={this.props.sortList}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   this.setState({
                                       rowId: record.id
                                   });
                               }
                           };
                       }}
                       rowClassName={(record, index) => {
                           let className = 'even-bg';
                           if (index % 2 === 0) {
                               className = 'odd-bg';
                           }
                           if (index === selectedRowKeys) {
                               className = 'light';
                           }
                           return className;
                       }}
                />
            </div>
        );
    }
});

export default LogTable;