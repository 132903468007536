/**
 * 该模块是用于定义action对象中type类型的常量值
 */

export const SET_CLOUDEYECLOSE_ACTION = 'setCloudEyeCloseAction';     //关闭云眼通知
export const SET_ISDOWNLOAD_ACTION = 'setIsDownloadAction';

export const SET_COLLECTNAME_ACTION = 'setCollectNameAction';

export const SET_SEARCH_ACTION = 'setSearchAction';   //搜索词
//===============videoStart==============================
export const SET_DEPT_ACTION = 'setDeptAction'; //设置通讯录数据
export const SET_VIDEOCOR_ACTION = 'settervideocorAction'; //设置视频数据
export const SET_PLAYRECORDLIST_ACTION = 'setPlayRecordList'; //设置播放历史数据
export const SET_COLLECT_ACTION = 'setCollectAction'; //设置收藏夹数据
export const SET_COLLECTPARENT_ACTION = 'setCollectParentAction'; //设置收藏夹树的父节点数据
export const SET_COLLECTED_DIC_ACTION = 'setCollectedDicAction';    // 设置已收藏设备的数据
export const SET_COLLECTITEM_ACTION = 'setCollectItemAction';
export const SET_PLAYLIST_ACTION = 'setPlayListAction'; //设置播列表
export const SET_BOOKNAME_ACTION = 'setBookNameAction';
export const SET_ISFLOW_ACTION = 'setIsFlowAction';   //是否开启流量控制
//===============videoEnd==============================

//===============countStart==============================
export const SET_DATESTORE_ACTION = 'setDateStoreAction'; //设置日期数据
export const SET_DATATYPE_ACTION = 'setDataTypeAction'; //设置数据类型统计的数据
export const SET_FILERECORD_ACTION = 'setFileRecordAction'; //设置数据上传趋势的数据
export const SET_FILETIME_ACTION = 'setFileTimeAction'; //设置文件创建时间的数据
export const SET_FILELENGTH_ACTION = 'setFileLengthAction'; //设置文件时长统计的数据
export const SET_FILELEVEL_ACTION = 'setFileLevelAction'; //设置重要文件占比的数据
export const SET_DASDATA_ACTION = 'setDasDataAction'; //设置采集站数据
export const SET_DEVNUM_ACTION = 'setDevNumAction'; //设置执法记录仪数据
export const SET_SYSDATA_ACTION = 'setSysDataAction';
export const SET_STORERANGE_ACTION = 'setStoreRangeAction';  //存储使用情况

//===============countEnd==============================

//===============deptStart==============================
export const SET_DEPT_TREEDATA = 'setDeptTreeData'; //部门树列表

export const SET_DISPGROUP_ACTION = 'setDispGroupAction'; //设置通话组数据
export const SET_ORGDEPT_ACTION = 'setOrgDeptAction'; //设置通讯录数据
export const SET_CORPMEMBER_ACTION = 'setCorpMemberAction'; //设置公司成员列表数据
export const SET_CORPMEMBERALL_ACTION = 'setCorpMemberAllAction';
export const SET_CORPNODE_ACTION = 'setCorpNodeAction';
export const SET_CORPNODEID_ACTION = 'setCorpNodeIdAction';

export const SET_GROUPMEMBER_ACTION = 'setGroupMemberAction'; //设置对讲组成员列表
export const SET_GROUPMEMBERALL_ACTION = 'setGroupMemberAllAction';
export const SET_DEVMEMBER_ACTION = 'setDevMemberAction'; //设置执法记录仪列表
export const SET_DEVMEMBERALL_ACTION = 'setDevMemberAllAction';
export const SET_DEVINFO_ACTION = 'setDevInfoAction';

//===============deptEnd==============================

export const SET_FILEDATA_ACTION = 'setFileDataAction'; //设置文件查询
export const SET_FILEDATALL_ACTION = 'setFileDataAllAction';
export const SET_TIMERANGE_ACTION = 'setTimeRangeAction';
export const SET_SELECT_ACTION = 'setSelectAction';
export const SET_SELECTEDKEY_ACTION = 'setSelectKeyAction';
export const SET_PAGE_ACTION = 'setPageAction';
export const SET_DEPT_MEMBER_ACTION = 'setDeptMemAction';
export const SET_SELECTEDVALUE_ACTION = 'setSelectedValueAction';
export const SET_ROWSELECTED_ACTION = 'setRowsSelectedAction';
export const SET_KEYSELECTED_ACTION = 'setKeySelectedAction';
export const SET_GATHERDETAIL_ACTION = 'setGatherDetailAction';
export const SET_GATHERLIST_ACTION = 'setGatherListAction';
export const SET_GATHERLISTALL_ACTION = 'setGatherListAllAction';
export const SET_SHOWSELECT_ACTION = 'setShowSelectAction';
export const SET_POCFILE_ACTION = 'setPocFileAction';    // 对讲记录数据
export const SET_POCFILEALL_ACTION = 'setPocFileAllAction';  //包含总页数的数据
export const SET_SELECTPOC_ACTION = 'setSelectPocAction';
export const SET_SELECTPOCKEY_ACTION = 'setSelectPocKeyAction';

export const SETSORTLIST_ACTION = 'setSortListAction'; //操作日志数据
export const SETSORTLISTALL_ACTION = 'setSortListAllAction';
export const SETSELECTEDLOG_ACTION = 'setSelectedLogAction';
export const SET_SELECTEDlLOG_ACTION = 'setSelectedkeyLogAction';

export const SET_POLICELIST_ACTION = 'setPoliceListAction'; //告警记录
export const SET_POLICELISTALL_ACTION = 'setPoliceListAllAction';

export const SET_ADDUSER_ACTION = 'SetAddUserAction'; //人员选择器

export const SET_GATHERCOUNT_ACTION = 'SetGatherCountAction';
export const SET_RECORDCOUNT_ACTION = 'setRecordCountAction';

export const SET_CHECKED_ACTION = 'setCheckedAction';
export const SET_CONFIGDATA_ACTION = 'setConfigDataAction';

export const SET_LISTSTATUS_ACTION = 'setListStatusAction';  //人员数组

export const SET_LOCK_SCREEN_ACTION = 'setLockScreenAction'; // 设置锁屏的action

export const SET_RECORD_DATA_ACTION = 'setRecordDataAction';

export const SET_PATROL_ACTION = 'setPatrolAction'; // 轮巡
