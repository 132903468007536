/*
 * @Description: 系统资源
 * @Author: liulian
 * @Date: 2019-10-08 15:27:16
 * @LastEditTime: 2021-03-04 15:04:52
 * @LastEditors: Please set LastEditors
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PieChart from './PieChart';
import {debounce, isEqual} from 'lodash';
import {setStoreRangeAction, setDasDataAction, setDevNumAction, setRecordCountAction} from '@/reducer/video_action';

export default connect(
    state => {
        return {
            devNum: state.devNum,
            dasData: state.dasData,
            recordCount: state.recordCount,
            storeData: state.storeData
        };
    },
    {
        setDevNumAction,
        setDasDataAction,
        setRecordCountAction,
        setStoreRangeAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devNumAll: 0,
            onlineDevNum: 0,
            total: 0,
            use: 0,
            num: 0
        };
    }


    numberShow = (title, number) => {
        return (
            <div className="number-show">
                <header>{title}</header>
                <p>{number}</p>
            </div>
        );
    };

    // loadData = (nextProps, type) => {
    //     const { dasData } = nextProps || this.props;
    //     const { storeData } = nextProps || this.props;
    //     if(dasData) {
    //         this.setState({
    //             total:dasData.totalDisk,
    //             use:dasData.usedDisk,
    //             num:dasData.dasCount
    //         })
    //     }
    //     if(storeData) {
    //         this.setState({
    //             totalStorage: storeData.totalStorage,
    //             usedStorage: storeData.usedStorage,
    //             storeData
    //         })
    //     }
    // }

    loadDevNum = (nextProps, type) => {
        const {devNum} = nextProps || this.props;
        const {storeData} = nextProps || this.props;
        if (devNum) {
            this.setState({
                devNumAll: devNum.devNum,
                onlineDevNum: devNum.onlineDevNum
            });
        }
        if (storeData) {
            this.setState({
                totalStorage: storeData.totalStorage,
                usedStorage: storeData.usedStorage,
                storeData
            });
        }

    };

    componentWillMount() {
        // this.loadData();
        this.loadDevNum();
    }

    componentWillReceiveProps(nextProps) {
        // if (!isEqual(this.props.dasData, nextProps.dasData)) {

        //     this.loadData(nextProps, 1)
        // }

        if (!isEqual(this.props.devNum, nextProps.devNum)) {

            this.loadDevNum(nextProps, 1);
        }
        if (!isEqual(this.props.storeData, nextProps.storeData)) {

            this.loadDevNum(nextProps, 1);
        }
    }


    render() {
        const {storeData: {deploymentType, totalStorage, usedStorage, used, total}} = this.state;
        const storeTitle = deploymentType === 'local' ? '存储使用情况' : '流量套餐使用情况';
        const storeUsed = deploymentType === 'local' ? '已用存储' : '已用流量';
        const storeTotal = deploymentType === 'local' ? '存储总量' : '总流量';

        return (
            <div className="pie-wrap">

                <div className="record">
                    <div className="record-title">记录仪</div>
                    <PieChart
                        data={{
                            normalTotal: this.state.onlineDevNum,
                            minTotal: this.state.devNumAll - this.state.onlineDevNum
                        }}
                        title={'在线率'}
                        color={'#12EBCA'}
                        color2={'#00BAFF'}
                        // newArr={this.props.devNum}
                    />
                    <div className="number">
                        {this.numberShow('记录仪在线个数', this.state.onlineDevNum)}
                        {this.numberShow('记录仪总数', this.state.devNumAll)}
                    </div>
                </div>

                <div className="record">
                    <div className="record-title">{storeTitle}</div>
                    {(totalStorage || totalStorage === 0) && <PieChart
                        data={{
                            normalTotal: (totalStorage >= usedStorage) ? usedStorage : 1,
                            minTotal: totalStorage === 0 ? 1 : (totalStorage > usedStorage) ? (totalStorage - usedStorage) : 0
                        }}
                        title={'使用率'}
                        color={'#E5394d'}
                        color2={'#E5394d'}
                    />}
                    <div className="number">
                        {this.numberShow(`${storeUsed}`, used)}
                        {this.numberShow(`${storeTotal}`, total)}
                    </div>
                </div>
            </div>

        );
    }
});
