/*
 * File Created: 2019-10-15
 * Author:Chenhuan
 * Desc: 数据配置主页表格
 * -----
 */
import React, {Component} from 'react';
import {Button, Col, Divider, Icon, Input, message, Modal, Row, Select, Table} from 'antd';
import CreateGroupModal from './modal/create-group';
import CompanyEditModal from './modal/companyEditModal';
import UserPicker from '@/view/components/UserPick/UserPicker';
import {getToken} from '@/config/constans';
import {scooper_app_api} from '@/util/api';
import {
    BOOK_COLUMNS,
    CHENGEDEPART_COLUMNS,
    DEPART_COLUMNS,
    EDITADD_DEPT_MEMBER,
    GROUP_COLUMNS,
    INSTRUMENT_COLUMNS
} from '@/util/table-config';
import '@/less/dept/dept.less';

const {confirm} = Modal;
const {Search} = Input;
const {Option} = Select;

class UnitTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 1,
            pageNum: 1,
            pageSize: 10,
            tableData: [],
            //搜索姓名
            memName: '',
            mac: '',
            macValue: '',
            pitchOn: '',
            checked: false,
            //批量操作选中
            selectedRowKeys: [],
            selectedRows: [],
            //人员选择器是否可见
            memVisible: false,
            mode: 0,
            restrict: false,
            memBindList: [],   //已绑定设备的成员列表
            devTypeVal: undefined   //默认设备类型
        };
    }

    componentDidMount() {
        this.props.onRef(this);
        this.setState({
            pitchOn: this.props.pitchOn
        });
    }

    /**
     *
     * @param preprocess
     */
    componentDidUpdate(preprocess) {
        //判断点击切换
        if (this.props.checkedSubKey !== preprocess.checkedSubKey) {
            this.setState(
                {
                    pitchOn: this.props.pitchOn,
                    checkedSubKey: this.props.checkedSubKey,
                    mac: '',
                    macValue: '',
                    memName: '',
                    checked: false,
                    pageNum: 1
                },
                () => {
                    this.loadTable();
                }
            );
        }
    }

    /**
     * 加载通讯录-部门列表
     * @param { object } res
     */
    getTable(res) {
        if (res) {
            if (res.list.length === 0 && this.state.pageNum > 1) {
                this.setState({
                    pageNum: this.state.pageNum - 1
                });
                this.loadTable();
            }
            this.setState({
                tableData: res.list,
                total: res.total
            });
        }
    }

    /**
     * @desc 加载列表
     * @param { String } openKeys 一级菜单key值
     * @param { String } devType 部门id
     */
    loadTable(openKeys, devType) {
        const parentKey = openKeys || this.props.firstLevelKey;
        const {pageNum, pageSize, checkedSubKey, memName, mac} = this.state;
        switch (parentKey) {
            case 'sub1':
                let param1 = {
                    currentPage: pageNum,
                    pageSize: pageSize,
                    deptId: checkedSubKey,
                    corpId: this.props.recordInfo.corpId,
                    memName: memName
                };
                scooper_app_api.queryCorpMemberInfo(param1).then(res => {
                    this.getTable(res);
                });
                break;

            case 'sub2':
                let param2 = {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    groupId: checkedSubKey,
                    memName: memName
                };
                scooper_app_api.queryGroupMemberInfo(param2).then(res => {
                    this.getTable(res);
                });
                break;

            case 'sub3':
                let param = {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    // firstDeptId: this.props.recordInfo.id,
                    corpId: this.props.recordInfo.corpId,
                    memName: memName,
                    mac: mac,
                    devType
                };
                scooper_app_api.queryCorpDevMemberInfo(param).then(res => {
                    this.getTable(res);
                });
                break;
            default:
        }

        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            checked: false
        });
    }

    //列表翻页
    pageChange(pageNum, pageSize) {
        this.setState({pageNum, pageSize}, () => {
            if (this.props.firstLevelKey.toString() === 'sub1' && this.state.memName.length > 0) {
                this.searchAllNum();
            } else {
                this.loadTable();
            }
        });
    }

    // 清空筛选
    clearAll() {
        this.setState({memName: '', mac: '', pageNum: 1, devTypeVal: undefined}, () => {
            this.loadTable();
        });
    }

    // 根据条件搜索执法记录仪表格
    searchNum = () => {
        this.setState({pageNum: 1}, () => {
            this.loadTable();
        });
    };

    //根据姓名搜索表格
    searchAllNum = pageNum => {
        if (pageNum) {
            this.setState({
                pageNum: pageNum
            });
        }
        let param = {
            currentPage: pageNum ? pageNum : this.state.pageNum,
            pageSize: this.state.pageSize,
            deptId: this.state.checkedSubKey,
            corpId: this.props.recordInfo.corpId,
            memName: this.state.memName
        };
        // scooper_app_api.queryMemberInfoInFirstDept(param).then(res => {
        // 接口修改
        scooper_app_api.queryCorpMemberInfo(param).then(res => {
            this.setState({
                tableData: res.list,
                total: res.total
            });
        });
    };

    // 删除表格数据
    handleDelete(record) {
        const {checkedSubKey} = this.props;
        confirm({
            title: '确定删除数据?',
            okText: '确认',
            cancelText: '取消',
            content: '',
            onOk: () => {
                switch (this.props.firstLevelKey) {
                    case 'sub1':
                        let orgParams = {
                            ids: record.id,
                            macs: record.mac,
                            corpId: this.props.recordInfo.corpId,
                            memNames: record.memName,
                            isCreateLog: 1
                        };
                        scooper_app_api.deleteOrgMemberList(orgParams).then(res => {
                            message.success('刪除成功');
                            this.loadTable();
                        });
                        break;
                    case 'sub2':
                        let dispParams = {
                            id: checkedSubKey,
                            memIds: record.id,
                            isCreateLog: 1
                        };
                        scooper_app_api.deleteDispMemberList(dispParams).then(res => {
                            message.success('刪除成功');
                            this.loadTable();
                        });
                        break;
                    default:
                }
            }
        });
    }

    //批量调整部门
    changeDepart() {
        const {selectedRowKeys} = this.state;
        if (selectedRowKeys.length < 1) {
            message.warning('请选择需要调整部门的人员');
        } else {
            this.showAddModal('companyEdit', {
                recordInfo: this.props.recordInfo,
                dataConfig: CHENGEDEPART_COLUMNS,
                scooper_api: 'updateOrgMemberInfo',
                record: selectedRowKeys.toString(),
                title: '成员',
                type: 'change',
                deptId: this.props.checkedSubKey
            });
        }
    }

    // 批量删除表格数据
    handleGroupDelete() {
        const {selectedRowKeys, selectedRows} = this.state;
        const {checkedSubKey} = this.props;
        if (!selectedRowKeys) {
            message.warning('请选择要删除的人员');
        } else {
            let arr = [];
            let mem = [];
            selectedRows.forEach((item, i) => {
                arr.push(item.mac);
                mem.push(item.memName);
            });
            confirm({
                title: '确定删除数据?',
                okText: '确认',
                cancelText: '取消',
                content: '',
                onOk: () => {
                    switch (this.props.firstLevelKey) {
                        case 'sub1':
                            let orgParams = {
                                ids: selectedRowKeys.toString(),
                                macs: arr.toString(),
                                corpId: this.props.recordInfo.corpId,
                                memNames: mem.toString(),
                                isCreateLog: 1
                            };
                            scooper_app_api.deleteOrgMemberList(orgParams).then(res => {
                                message.success('刪除成功');
                                this.loadTable();
                            });
                            break;
                        case 'sub2':
                            let dispParams = {
                                id: checkedSubKey,
                                memIds: selectedRowKeys.toString(),
                                isCreateLog: 1
                            };
                            scooper_app_api.deleteDispMemberList(dispParams).then(res => {
                                message.success('刪除成功');
                                this.loadTable();
                            });
                            break;
                        default:
                    }
                }
            });
        }
    }

    //多项选择
    rowSelect() {
        if (this.state.checked) {
            return {
                fixed: 'left',
                onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({
                        selectedRowKeys,
                        selectedRows
                    });
                }
            };
        } else {
            return null;
        }
    }

    //进行批量操作
    groupHandle() {
        this.setState({
            checked: true
        });
    }

    //取消批量操作
    groupCancel() {
        this.setState({
            checked: false,
            selectedRowKeys: [],
            selectedRows: []
        });
    }

    // 弹窗类型加载
    showAddModal(type, record, cancel) {
        this[type](record);
        if (cancel.toString() === 'cancel') {
            this.setState({
                checked: false,
                selectedRowKeys: [],
                selectedRows: []
            });
        }
    }

    //表头选择
    getColumns() {
        let arr = [];
        switch (this.props.firstLevelKey) {
            case 'sub1':
                arr = JSON.parse(JSON.stringify(BOOK_COLUMNS));
                arr.push({
                    title: '操作',
                    dataIndex: 'operate',
                    key: 'operate',
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <span>
                                <a
                                    className="edit"
                                    onClick={() =>
                                        this.showAddModal('companyEdit', {
                                            recordInfo: this.props.recordInfo,
                                            dataConfig: EDITADD_DEPT_MEMBER,
                                            scooper_api: 'updateOrgMemberInfo',
                                            record: record,
                                            type: 'edit',
                                            title: '成员',
                                            deptId: this.props.checkedSubKey
                                        })
                                    }
                                >
                                    <i/>
                                </a>
                                <Divider type="vertical"/>
                                <a onClick={() => this.handleDelete(record)} className="delete">
                                    <i/>
                                </a>
                            </span>
                        );
                    }
                });
                return arr;
            case 'sub2':
                arr = JSON.parse(JSON.stringify(GROUP_COLUMNS));
                arr.push({
                    title: '操作',
                    dataIndex: 'operate',
                    key: 'operate',
                    align: 'center',
                    render: (text, record) => {
                        return (
                            <span>
                                <a onClick={() => this.handleDelete(record)} className="delete">
                                    <i/>
                                </a>
                            </span>
                        );
                    }
                });
                return arr;
            case 'sub3':
                arr = [...INSTRUMENT_COLUMNS];
                const memNameItem = arr.find(item => item.key === 'memName');
                memNameItem.render = (text, record) => {
                    return (
                        <React.Fragment>
                            <span>{text}</span>
                            <Icon
                                type="align-center"
                                onClick={() => this.selectMember(record)}
                                style={{color: 'red'}}
                            />
                        </React.Fragment>
                    );
                };
                //在线离线转化
                const status = arr.find(item => item.key.toString() === 'devStatus');
                status.render = (text, record) => {
                    if (record.devStatus.toString() === '0') {
                        return <span>离线</span>;
                    } else if (record.devStatus.toString() === '1') {
                        return <span>在线</span>;
                    }
                };
                return arr;
            default:
        }
    }

    // 选择使用人员
    selectMember = record => {
        this.setState({
            memVisible: true,
            mode: 0,
            macValue: record.mac,
            restrict: true,
            currentDevInfo: record
        });
        this.queryMemNameListBind(record.corpId);
    };


    //获取已绑定设备的成员列表
    queryMemNameListBind = (corpId) => {
        scooper_app_api.queryMemNameListBind({corpId}).then(res => {
            this.setState({
                memBindList: res
            });
        });
    };

    //选项按钮
    buttonClick() {
        const {recordInfo, firstLevelKey, checkedSubKey} = this.props;
        const {memName, mac, selectedRowKeys, devTypeVal} = this.state;
        const devTypeList = [
            {id: '', value: '全部'},
            {id: 0, value: '捷控'},
            // {id: 1, value: "简讯"},
            {id: 2, value: '记录仪'},
            {id: 3, value: 'uchat助手'}
        ];
        const devTypeOptions = devTypeList.map(item => (<Option key={item.id} value={item.id}>{item.value}</Option>));
        switch (firstLevelKey) {
            case 'sub1':
                return (
                    <Row>
                        <Col span={4}>
                            <Input
                                onPressEnter={() => this.searchAllNum(1)}
                                maxLength={12}
                                value={memName}
                                placeholder="请输入姓名"
                                style={{width: '14.2rem'}}
                                onChange={e =>
                                    this.setState({
                                        memName: e.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span={4}>
                            <Button onClick={() => this.searchAllNum(1)}>查询</Button>
                            <Button onClick={() => this.clearAll()}>重置</Button>
                        </Col>
                        <Col span={9}>
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.showAddModal('createGroup', {
                                        type: 'dept',
                                        recordInfo,
                                        rowKey: selectedRowKeys,
                                        deptId: checkedSubKey
                                    })
                                }
                            >
                                生成通话组
                            </Button>

                            <Button
                                type="primary"
                                onClick={() =>
                                    this.showAddModal('companyEdit', {
                                        recordInfo,
                                        dataConfig: DEPART_COLUMNS,
                                        title: '部门',
                                        type: 'add',
                                        scooper_api: 'saveOrgDept',
                                        deptId: checkedSubKey
                                    })
                                }
                            >
                                添加子部门
                            </Button>

                            <Button
                                type="primary"
                                onClick={() =>
                                    this.showAddModal('companyEdit', {
                                        recordInfo,
                                        dataConfig: EDITADD_DEPT_MEMBER,
                                        title: '部门成员',
                                        type: 'add',
                                        scooper_api: 'saveOrgMember',
                                        deptId: checkedSubKey
                                    })
                                }
                            >
                                添加部门成员
                            </Button>
                        </Col>
                        {this.state.checked ? (
                            <Col span={7}>
                                <Button type="primary" onClick={() => this.changeDepart()} className="choice">
                                    调整部门
                                </Button>
                                <Button type="primary" onClick={() => this.handleGroupDelete()} className="choice">
                                    批量删除
                                </Button>
                                <Button type="primary" onClick={() => this.groupCancel()} className="choice">
                                    取消批量
                                </Button>
                            </Col>
                        ) : (
                            <Button type="primary" onClick={() => this.groupHandle()}>
                                批量操作
                            </Button>
                        )}
                    </Row>
                );
            case 'sub2':
                return (
                    <Row>
                        <Col span={4}>
                            <Input
                                maxLength={12}
                                onPressEnter={() => this.searchNum()}
                                value={memName}
                                placeholder="请输入姓名"
                                style={{width: '14.1rem'}}
                                onChange={e =>
                                    this.setState({
                                        memName: e.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => this.searchNum()}>查询</Button>
                            <Button onClick={() => this.clearAll()}>重置</Button>
                        </Col>
                        <Col span={9}>
                            <Button
                                type="primary"
                                className="btn-place"
                                onClick={() =>
                                    this.showAddModal('createGroup', {
                                        type: 'group',
                                        recordInfo
                                    })
                                }
                            >
                                添加通话组
                            </Button>

                            <Button
                                type="primary"
                                className="btn-place"
                                onClick={() => {
                                    this.setState({
                                        memVisible: true,
                                        mode: 1,
                                        restrict: false
                                    });
                                }}
                            >
                                添加通话组成员
                            </Button>
                        </Col>
                        {this.state.checked ? (
                            <Col span={5}>
                                <Button type="primary" onClick={() => this.handleGroupDelete()} className="choice">
                                    批量删除
                                </Button>
                                <Button type="primary" onClick={() => this.groupCancel()} className="choice">
                                    取消批量
                                </Button>
                            </Col>
                        ) : (
                            <Button type="primary" onClick={() => this.groupHandle()}>
                                批量操作
                            </Button>
                        )}
                    </Row>
                );
            case 'sub3':
                return (
                    <Row className="table-filter">
                        <Col span={4}>
                            <Input
                                maxLength={32}
                                value={mac}
                                onPressEnter={() => this.searchNum()}
                                placeholder="请输入设备编号"
                                onChange={e =>
                                    this.setState({
                                        mac: e.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span={4}>
                            <Input
                                onPressEnter={() => this.searchNum()}
                                maxLength={12}
                                value={memName}
                                placeholder="请输入使用人员"
                                onChange={e =>
                                    this.setState({
                                        memName: e.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span={4}>
                            <Select style={{width: '10rem'}} value={devTypeVal} onChange={(value) => {
                                this.loadTable('', value);
                                this.setState({
                                    devTypeVal: value
                                });
                            }} placeholder="请选择设备类型">
                                {devTypeOptions}
                            </Select>
                        </Col>

                        <Col span={10}>
                            <Button onClick={() => this.searchNum()}>查询</Button>
                            <Button onClick={() => this.clearAll()}>重置</Button>
                        </Col>
                    </Row>
                );
            default:
        }
    }

    //人员选择回调
    updateMember = (...rest) => {
        const {currentDevInfo, checkedSubKey} = this.state;
        if (rest[0] !== 'unBind' && rest[0].length !== 0) {
            const memberList = rest[0];
            if (this.state.mode) {
                //批量新增通话组成员
                let cogMemList = [];
                memberList.forEach(item => {
                    cogMemList.push(item.id);
                });
                let param = {
                    id: checkedSubKey,
                    memIds: String(cogMemList),
                    isCreateLog: 1
                };
                scooper_app_api.saveDispMemberList(param).then(() => {
                    message.success('添加成功!');
                    this.setState({memVisible: false});
                    this.loadTable();
                });
            } else {
                //执法仪设备绑定成员
                let params = {
                    mac: this.state.macValue,
                    memName: memberList[0].memName,
                    memId: memberList[0].id,
                    devType: currentDevInfo.devType,
                    isCreateLog: 1
                };
                scooper_app_api.setUserCloude(params).then(() => {
                    message.success('修改成功!');
                    this.setState({memVisible: false});
                    this.loadTable();
                });
            }
        } else if (rest[0] === 'unBind') {
            let params = {
                mac: this.state.macValue,
                token: getToken(),
                devType: currentDevInfo.devType
            };
            scooper_app_api.unBindUserCloude(params).then((res) => {
                if (!res.code) {
                    message.success('解绑成功!');
                    this.setState({memVisible: false});
                    this.loadTable();
                } else {
                    message.error(res.message);
                }
            });
        }
    };

    cancel = () => {
        this.setState({memVisible: false});
    };

    render() {
        const {
            tableData,
            pageNum,
            total,
            memVisible,
            mode,
            restrict,
            selectedRowKeys,
            memBindList
        } = this.state;
        const {recordInfo} = this.props;
        return (
            <div className="dept-table">
                {this.buttonClick()}
                <div className="content">
                    <Table
                        rowSelection={this.rowSelect()}
                        columns={this.getColumns()}
                        rowKey="id"
                        dataSource={tableData}
                        pagination={{
                            showQuickJumper: true,
                            current: pageNum,
                            pageSize: 10,
                            total: total,
                            onChange: (page, pageSize) => {
                                this.pageChange(page, pageSize);
                            },
                            itemRender: (page, type, originalElement) => {
                                if (type === 'prev') {
                                    return <a>上一页</a>;
                                }
                                if (type === 'next') {
                                    return <a>下一页</a>;
                                }
                                return originalElement;
                            }
                        }}
                        rowClassName={(record, index) => {
                            let className = 'even-bg';
                            if (index % 2 === 0) {
                                className = 'odd-bg';
                            }
                            if (index === selectedRowKeys) {
                                className = 'light';
                            }
                            return className;
                        }}
                    />
                </div>
                <CompanyEditModal
                    showModal={showModal => (this.companyEdit = showModal)}
                    loadTable={(...rest) => this.loadTable(...rest)}
                    onInitTree={(...rest) => this.props.onInitTree(...rest)}
                />

                <CreateGroupModal
                    selectedName={this.props.selectedName}
                    groupCancel={() => this.groupCancel()}
                    showModal={showModal => (this.createGroup = showModal)}
                    loadMemberListTree={this.props.loadMemberListTree}
                />

                <UserPicker
                    visible={memVisible}
                    mode={mode}
                    ip=""
                    restrict={restrict}
                    token={getToken()}
                    record={recordInfo}
                    memData={this.updateMember}
                    cancel={this.cancel}
                    memBindList={memBindList}
                />
            </div>
        );
    }
}

export default UnitTable;
