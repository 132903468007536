import React from 'react';
import {Input,Modal,message,Form} from 'antd';
import { connect } from 'react-redux';
import Pie_one from '../components/pie-disk';
import Pie_two from '../components/pie-cpu';
import Pie_three from '../components/pie-memory';
import { Button } from 'antd/lib/radio';

export default connect(

)(
    class extends React.Component{
        constructor(props){
            super(props);
            this.state = {
                visible: true,
            }
        }     

        render(){
            return(
                <Modal
                title="设备运行情况"
                visible={this.props.show}
                onCancel={this.props.onCancel} 
                className="show-gather"
                footer={
                    <Button className="close-btn" onClick={this.props.onCancel}>关闭</Button>
                }
                >
                <div className="dev-detail">
                    <p>设备编号：{this.props.detailMan.gzzXh}</p>
                    <p>所属单位：{this.props.detailMan.deptName}</p>
                    <p>运行时长：{this.props.detailMan.time}</p>
                    <p>软件版本：{this.props.detailMan.versionCode}</p>
                    <p>已用/总储存：{this.props.detailMan.diskUsedRTotal}</p>
                </div>
                <div className="dev-use">
                    <div className="disk-use pit-pie">
                        <p className="title">预计可用天数</p>
                        <Pie_one  />
                        <div className="decription">
                            <p className="text-des">已用天数：{this.props.detailMan.usedDay}</p>
                            <p className="text-des-two">可用天数：{this.props.detailMan.day}</p>
                        </div>
                    </div>
                    <div className="CPU-use pit-pie">
                        <p className="title">CPU使用情况</p>
                        <Pie_two />
                        <div className="decription">
                            <p className="text-des">CPU温度：{this.props.detailMan.cpuTemp}</p>
                        </div>
                    </div>
                    <div className="store-use pit-pie">
                        <p className="title">内存使用情况</p>
                        <Pie_three detailMan={this.props.detailMan}/>
                        <div  className="decription">
                            <p className="text-des">已使用：{this.props.detailMan.usedMemory}</p>
                            <p className="text-des-two">总量：{this.props.detailMan.totalMemory}</p>
                        </div>
                    </div>
                    
                </div> 
                
            </Modal>
            )
        }
    }
)