/*
 * @Description: 文件管理表格
 * @Author: yangh
 * @Date: 2019-10.13 15:09:45
 * @LastEditTime: 2022-03-15 20:56:28
 * @LastEditors: Please set LastEditors
 */

import React from 'react';
import {
    Table,
    Divider,
    Icon,
    Popconfirm,
    message,
    Upload,
    Modal,
    ConfigProvider,
    Form,
    Input,
    Select,
    Button
} from 'antd';
import {connect} from 'react-redux';
import {doc_api, queryflow_api} from '@/rest-api/restapi';
import {
    setFileDataAction,
    setFileDataAllAction,
    setSelectAction,
    setSelectKeyAction
} from '@/reducer/video_action';
import FileDescription from './file-description';

const {Option} = Select;

@connect(
    state => {
        return {
            fileData: state.fileData,
            fileDataAll: state.fileDataAll,
            selectedRowKeys: state.selectedRowKeys
        };
    },
    {
        setFileDataAction,
        setFileDataAllAction,
        setSelectKeyAction,
        setSelectAction
    }
)
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            previewSrc: '',
            visible: false,
            previewEnv: '',
            pageNum: 1,
            enlargeVisible: false,
            modalWidth: 700,
            isShowReduce: 'none',
            isShowEnlarge: 'block',
            flowStatus: true,
            bodyHeight: '270px'

        };
    }

    /**
     *checkbox状态
     */
    onSelectChange = (selectedRowKeys) => {
        console.log('选中的行为: ', selectedRowKeys);
        this.props.setSelectKeyAction({selectedRowKeys: selectedRowKeys});
    };

    /**
     * 分页请求
     */
    onChangePage = async (page, pageSize) => {
        const param = {
            token: sessionStorage.token,
            pageNum: page,
            pageSize,
            fileType: this.props.fileType,
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            memName: this.props.memName,
            deptId: this.props.deptId,
            devNo: this.props.devNo,
            gzzXh: this.props.gzzXh,
            fileLevel: this.props.fileLevel,
            corpId: sessionStorage.getItem('corpId')
            // orgCode: sessionStorage.getItem('orgCode')
        };
        this.setState({
            pageNum: page
        });
        const data = await doc_api.queryFile(param);
        this.props.setFileDataAction({fileData: data.data.list});
        this.props.setFileDataAllAction({fileDataAll: data.data});
    };

    /**
     * 文件删除
     */
    onDelete = async (id, fileName) => {
        var param = {
            token: sessionStorage.getItem('token'),
            id: id,
            fileName: fileName
        };
        const data = await doc_api.fileDelete(param);
        message.success(data.message);
        if (!data.code) {
            var param1 = {
                token: sessionStorage.token,
                fileType: this.props.fileType,
                startTime: this.props.startTime,
                endTime: this.props.endTime,
                memName: this.props.memName,
                deptId: this.props.deptId,
                devNo: this.props.devNo,
                fileLevel: this.props.fileLevel,
                // orgCode: sessionStorage.getItem('orgCode'),
                corpId: sessionStorage.getItem('corpId')
            };
            param1.pageNum = this.props.fileDataAll.pageNum;
            const data = await doc_api.queryFile(param1);
            if (!data.code) {
                if (data.data.list.length === 0) {
                    param1.pageNum = data.data.lastPage;
                    const resp = await doc_api.queryFile(param1);
                    this.props.setFileDataAction({fileData: resp.data.list});
                    this.props.setFileDataAllAction({fileDataAll: resp.data});
                } else {
                    this.props.setFileDataAction({fileData: data.data.list});
                    this.props.setFileDataAllAction({fileDataAll: data.data});
                }
            }
        }
    };

    handleCancel = () => {
        this.setState({
            previewVisible: false,
            modalWidth: 700,
            bodyHeight: '270px',
            showChoose: true,
            showCustom: false
        });
        this.child.restoreInitVal();
        var audio = document.getElementsByClassName('prev-audio')[0];
        var audio2 = document.getElementsByClassName('prev-audio2')[0];
        var videoDiv = document.getElementsByClassName('prev-video-div')[0];
        var imgDiv = document.getElementsByClassName('prev-img-div')[0];
        var videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
        var audioDiv = document.getElementsByClassName('pit-music')[0];
        var audioDiv2 = document.getElementsByClassName('pit-music-ie')[0];

        audio && audio.pause();
        audio2 && audio2.pause();

        if (videoDiv && videoDiv.children) {
            var video = videoDiv.children[0];
            if (video) {
                videoDiv.removeChild(video);
            }
        }

        if (imgDiv && imgDiv.children) {
            var img = imgDiv.children[0];
            if (img) {
                imgDiv.removeChild(img);
            }
        }
        if (videoDivY && videoDivY.children) {
            var video = videoDivY.children[0];
            if (video) {
                videoDivY.removeChild(video);
            }
        }
        if (audioDiv && audioDiv.children) {
            var audio = audioDiv.children[0];
            if (audio) {
                audioDiv.removeChild(audio);
            }
        }

        if (audioDiv2 && audioDiv2.children) {
            var audio = audioDiv2.children[0];
            if (audio) {
                audioDiv2.removeChild(audio);
            }
        }
    };

    /**
     * 切换当前访问协议
     */
    changeProto = (target) => {
        const targetProto = target.split(':')[0];
        return target.replace(targetProto, window.location.protocol.split(':')[0]);
    };


    /**
     * 文件预览
     */
    preView = async (record) => {
        const {isFlow} = this.props;
        const {fileMoveStatus, cloudFilePath, fileType, filePath, fileName, fileSize, memName, id} = record;
        if (isFlow) {
            const resp = await queryflow_api.queryCorpFlowState();
            if (!resp.data.obj) {
                message.error(resp.message || '流量已达上限');
                return;
            } else {
                if (resp.message) {
                    message.warning(resp.message || '流量即将达到上限');
                }
            }
        }
        this.setState({
            previewVisible: true,
            fileName: fileName,
            filePath: filePath,
            fileType: fileType,
            fileSize: fileSize,
            memName: memName,
            fileDetail: record
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            this.setState({
                previewEnv: 'ie'
            });
        } else {
            this.setState({
                previewEnv: 'noie'
            });
        }
        const self = this;
        window.setTimeout(function () {
            let url;
            if (fileType === 1) {
                const videoDiv = document.getElementsByClassName('prev-video-div')[0];
                const videoElement = document.createElement('video');
                videoElement.className = 'prev-video';
                url = '';
                // if (record.fileMoveStatus === 2 && record.cloudFilePath === '') {
                //     message.error('文件不存在！');
                //     return;
                // }
                if (fileMoveStatus !== 2 && filePath === '') {
                    message.error('文件不存在！');
                    return;
                }
                if (fileMoveStatus === 2) {
                    url = self.changeProto(cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}`;
                } else {
                    url = `/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`;
                }
                videoElement.src = url;
                videoElement.controls = true;
                videoElement.controlsList = 'nodownload';
                videoElement['disablePictureInPicture'] = true;
                videoDiv.appendChild(videoElement);
            }
            if (fileType === 0) {
                const imgDiv = document.getElementsByClassName('prev-img-div')[0];
                const imgElement = document.createElement('img');
                imgElement.className = 'prev-img enlarge';
                url = '';
                // if (record.fileMoveStatus === 2 && record.cloudFilePath === '') {
                //     message.error('该文件不存在！');
                //     return;
                // }
                if (fileMoveStatus !== 2 && filePath === '') {
                    message.error('该文件不存在！');
                    return;
                }
                if (fileMoveStatus === 2) {
                    url = self.changeProto(cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}`;
                } else {
                    url = `/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`;
                }
                imgElement.src = url;
                imgDiv.appendChild(imgElement);
            }
            if (fileType === -2) {
                const videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
                const videoElementY = document.createElement('video');
                videoElementY.className = 'prev-video';
                url = '';
                // if (record.fileMoveStatus === 2 && record.cloudFilePath === '') {
                //     message.error('该文件不存在！');
                //     return;
                // }
                if (fileMoveStatus !== 2 && filePath === '') {
                    message.error('该文件不存在！');
                    return;
                }
                if (fileMoveStatus === 2) {
                    url = self.changeProto(cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}`;
                } else {
                    url = `/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`;
                }
                videoElementY.src = url;
                videoElementY.controls = true;
                videoElementY.controlsList = 'nodownload';
                videoElementY['disablePictureInPicture'] = true;
                videoDivY.appendChild(videoElementY);
            }
            if (fileType === 2) {
                const audioDiv = document.getElementsByClassName('pit-music')[0];
                const audioElement = document.createElement('audio');
                audioElement.className = 'prev-audio2';
                url = '';
                // if (record.fileMoveStatus === 2 && record.cloudFilePath === '') {
                //     message.error('该文件不存在！');
                //     return;
                // }
                if (fileMoveStatus !== 2 && filePath === '') {
                    message.error('该文件不存在！');
                    return;
                }
                if (fileMoveStatus === 2) {
                    url = self.changeProto(cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}`;
                } else {
                    url = `/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`;
                }
                audioElement.src = url;
                audioElement.controls = true;
                audioElement.type = 'application/json';
                audioElement.controlsList = 'nodownload';
                audioElement['disablePictureInPicture'] = true;
                audioDiv.appendChild(audioElement);
            }
        }, 100);

    };

    componentDidMount() {
        this.getInitStatus();
    }

    /**
     * @description 获取子组件的方法
     */
    onRef = ref => {
        this.child = ref;
    };


    /**
     * @description 初始获取流量是否够用
     */
    getInitStatus = () => {
        queryflow_api.queryCorpFlowState().then(resp => {
            if (!resp.data.obj) {
                this.setState({
                    flowStatus: false
                });
            } else {
                if (resp.message) {
                    this.setState({
                        flowStatus: true
                    });
                } else {
                    this.setState({
                        flowStatus: true
                    });
                }
            }
        });
    };
    //点击下载文件前的判断
    noFile = (record, e) => {
        const {isFlow} = this.props;
        if (isFlow) {
            queryflow_api.queryCorpFlowState().then(resp => {
                if (!resp.data.obj) {
                    message.error(resp.message || '流量已达上限');
                    this.setState({
                        flowStatus: false
                    });
                } else {
                    if (resp.message) {
                        message.warning(resp.message || '流量即将达到上限');
                        this.setState({
                            flowStatus: true
                        });
                    } else {
                        this.setState({
                            flowStatus: true
                        });
                    }
                    // window.setTimeout(function() {
                    // if (record.fileMoveStatus == 2 && record.cloudFilePath === '') {
                    //     message.error('该文件不存在！');
                    //     return false;
                    // }
                    if (record.fileMoveStatus !== 2 && record.filePath === '') {
                        message.error('该文件不存在！');
                        return false;
                    }
                    // },300)
                }
            });
        } else {
            if (record.fileMoveStatus == 2 && record.cloudFilePath === '') {
                message.error('该文件不存在！');
                return false;
            }
            if (record.fileMoveStatus !== 2 && record.filePath === '') {
                message.error('该文件不存在！');
                return false;
            }
        }
    };


    /**
     * 图片放大功能
     */
    enlargePit = () => {
        this.setState({
            modalWidth: 1200,
            bodyHeight: '1000px',
            isShowEnlarge: 'none',
            isShowReduce: 'block'
        });
    };

    /**
     * 图片缩小功能
     */
    reducePit = () => {
        this.setState({
            modalWidth: 700,
            bodyHeight: '270px',
            isShowEnlarge: 'block',
            isShowReduce: 'none'
        });
    };

    /**
     * 改变图片大小
     */
    changePit = () => {
        if ($('.prev-img').hasClass('enlarge')) {
            $('.prev-img').removeClass('enlarge').addClass('reduce');
            this.setState({
                modalWidth: 1200,
                bodyHeight: '650px'
            });
        } else if ($('.prev-img').hasClass('reduce')) {
            $('.prev-img').removeClass('reduce').addClass('enlarge');
            this.setState({
                modalWidth: 700,
                bodyHeight: '270px'
            });
        }
    };

    /**
     * @description 动态渲染列表项
     */
    getColumns = () => {
        const {fileOptions} = this.props;
        const _fileOptions = fileOptions && fileOptions.split(',');
        const files = {
            'fileName': {label: '文件名', value: 'fileName'},
            'fileType': {label: '文件类型', value: 'fileType'},
            'fileLevel': {label: '文件等级', value: 'fileLevel'},
            'memName': {label: '成员名', value: 'memName',width:200},
            'deptName': {label: '部门名', value: 'deptName',width:120},
            'devNo': {label: '设备编码', value: 'devNo'},
            'fileSize': {label: '文件大小', value: 'fileSize'},
            'gmtCreate': {label: '生成时间', value: 'gmtCreate'},
            'uploadTime': {label: '上传时间', value: 'uploadTime'},
            'expireTime': {label: '过期时间', value: 'expireTime'},
            'fileTime': {label: '文件时长', value: 'fileTime'},
            'gzzXh': {label: '采集站编码', value: 'gzzXh'},
            'memCode': {label: '工号', value: 'memCode'},
            'lawType': {label: '执法类型', value: 'lawTypeName'}
        };
        // const a = ["fileName", "fileType", "fileLevel", "fileSize", "expireTime", "fileTime", "memName", "memCode"];
        const columns = _fileOptions && _fileOptions.map(item => {
            const fileTypeArr = {
                '-2': '云眼视频',
                '-1': '其他',
                '0': '图片',
                '1': '视频',
                '2': '音频',
                '3': 'log',
                '10': '所有'
            };
            const fileLevelArr = {
                '0': '普通',
                '1': '重要',
                '3': '其他',
                '10': '所有'
            };
            if (files[item] && files[item].value === 'fileType') {
                return {
                    title: files[item] && files[item].label,
                    dataIndex: files[item] && files[item].value,
                    render: (text, record) => {
                        return <span>{fileTypeArr[record.fileType]}</span>;
                    }
                };
            } else if (files[item] && files[item].value === 'fileLevel') {
                return {
                    title: files[item] && files[item].label,
                    dataIndex: files[item] && files[item].value,
                    render: (text, record) => {
                        return <span>{fileLevelArr[record.fileLevel]}</span>;
                    }
                };
            } else if (files[item] && (files[item].value === 'gmtCreate' || files[item].value === 'uploadTime' || files[item].value === 'expireTime')) {
                return {
                    title: files[item] && files[item].label,
                    dataIndex: files[item] && files[item].value,
                    render: (text, record) => {
                        if(typeof text === 'undefined'){
                            return <div>{''}</div>;
                        }
                        let contentStr;
                        let br = <pre><br/></pre>;
                        let result = null;
                        contentStr = text.split(' ');
                        if (contentStr.length < 2) {
                            return <span>{text}</span>;
                        }
                        for (let i = 0; i < contentStr.length; i++) {
                            if (i === 0) {
                                result = contentStr[i];
                            } else {
                                result = <span>{result}{br}{br}{contentStr[i]}</span>;
                            }
                        }
                        return <div>{result}</div>;
                    }
                };
            }else if(files[item] && (files[item].value === 'memName' || files[item].value === 'deptName')){
                return {
                    title: files[item] && files[item].label,
                    dataIndex: files[item] && files[item].value,
                    width:files[item]&&files[item].width,
                    render:(text, record)=>{
                        return <span className="deptname-span">{text}</span>
                    }
                };
            } else {
                return {
                    title: files[item] && files[item].label,
                    dataIndex: files[item] && files[item].value,
                };
            }
        });
        const operColumn = {
            title: '操作',
            dataIndex: 'operate',
            render: (text, record) => {
                const {fileMoveStatus, cloudFilePath, filePath, fileType, fileName, fileSize, memName, id} = record;
                return (
                    <span>
                        <a className='preview'
                           onClick={() => this.preView(record)}><i/></a>
                        <Divider type='vertical'/>
                        {/* <a  className="edit" target="_blank" onClick={(e)=>{this.noFile(record, e)}} href={record.fileMoveStatus === 2 ? `${record.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}` : `/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${record.fileName}&filePath=${record.filePath}`} ><i></i></a> */}
                        {
                            (fileMoveStatus === 2 && cloudFilePath !== '') &&
                            <a className='edit' target='_blank'
                               href={this.changeProto(cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${id}`}
                               onClick={(e) => {
                                   this.noFile(record, e);
                               }}><i/></a>
                        }
                        {
                            (cloudFilePath === '' && filePath !== '') &&
                            <a className='edit' target='_blank'
                               href={`/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${id}`}
                               onClick={(e) => {
                                   this.noFile(record, e);
                               }}><i/></a>
                        }
                        <Divider type='vertical'/>
                        <Popconfirm title='确认要删除吗？' okText='确认' cancelText='取消' className='popConfirm'
                                    onConfirm={() => this.onDelete(record.id, record.fileName)}>
                            <a href='#' className='delete'><i/></a>
                        </Popconfirm>
                    </span>
                );
            }
        };
        return [...columns, operColumn];
    };


    render() {
        const {pageAuthority, showView} = this.props;

        if (pageAuthority && pageAuthority.indexOf('das') === -1) {
            columns.splice(6, 2);
        }

        const {selectedRowKeys} = this.state;

        const rowSelection = {
            selectedRowKeys: this.props.selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows) => {
                this.props.setSelectAction({selectedRows: selectedRows});

            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.props.setSelectAction({selectedRows: selectedRows});
            }
        };

        let pagination = {
            current: this.props.fileDataAll.pageNum,
            total: this.props.fileDataAll.total,
            pageSize: 10,
            defaultCurrent: this.props.current,
            hideOnSinglePage: true,
            showSizeChanger: true,
            showTotal: ((total) => {
                return `共 ${total} 条`;
            }),
            itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            },

            onChange: this.onChangePage
        };

        const {modalWidth, isShowReduce, isShowEnlarge, bodyHeight, fileDetail} = this.state;
        return (
            <div className='content'>
                <Modal title={`${this.state.fileName}`} visible={this.state.previewVisible} onCancel={this.handleCancel}
                       className='prev-modal'
                       maskClosable={false} width={modalWidth} bodyStyle={{height: bodyHeight}}
                       footer={
                           <>
                               <span className='msg-size footer-msg'
                                     title={`文件大小：${this.state.fileSize}`}>文件大小：{this.state.fileSize}</span>
                               <span className='msg-mem footer-msg'
                                     title={`上传人员： ${this.state.memName}`}>上传人员： {this.state.memName}</span>
                           </>
                       }
                >
                    {this.state.fileType == '0' && <div className='prev-img-div' onClick={this.changePit}></div>}
                    {this.state.fileType == '2' && this.state.previewEnv == 'noie' && <div className='pit-music'></div>}
                    {this.state.fileType == '2' && this.state.previewEnv == 'ie' && <div className='pit-music'></div>}
                    {this.state.fileType == '1' && <div className='prev-video-div'></div>}
                    {this.state.fileType == '-2' && <div className='prev-video-div-yy'></div>}
                    <FileDescription {...{fileDetail, showView}} onRef={this.onRef} loadTable={this.props.loadTable}
                                     listFileLawType={this.props.listFileLawType}/>
                </Modal>
                <Table rowSelection={rowSelection} columns={this.getColumns()} rowKey={record => record.id}
                       pagination={pagination} dataSource={this.props.fileData}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   this.setState({
                                       rowId: record.id
                                   });
                               }
                           };
                       }}
                       rowClassName={(record, index) => {
                           let className = 'even-bg';
                           if (index % 2 === 0) {
                               className = 'odd-bg';
                           }
                           if (index === selectedRowKeys) {
                               className = 'light';
                           }
                           return className;
                       }}
                />
            </div>
        );
    }
};