/*
 * @Description: 环形图
 * @Author: liulian
 * @Date: 2019-10-10 20:36:11
 * @LastEditTime: 2021-03-04 14:29:02
 * @LastEditors: Please set LastEditors
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {debounce, isEqual} from 'lodash';
import echarts from 'echarts';
import resizeFootSize from './ResizeFontSize';
import {setDasDataAction, setDevNumAction} from '@/reducer/video_action';

export default connect(
    state => {
        return {
            devNum: state.devNum,
            dasData: state.dasData
        };
    },
    {
        setDevNumAction,
        setDasDataAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.setPieOption = this.setPieOption.bind(this);
        this.initPie = this.initPie.bind(this);
    }

    componentDidMount() {
        this.initPie();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.dasData, nextProps.dasData)) {
            this.initPie(nextProps, 1);
        }
        if (!isEqual(this.props.devNum, nextProps.devNum)) {
            this.initPie(nextProps, 1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFn);
        this.myChart = null;
    }

    initPie(nextProps, type) {
        if (!this.pieChart) return;
        const {data, title, color, color2} = nextProps || this.props; //外部传入的data数据
        this.myChart = echarts.init(this.pieChart, 'customed'); //初始化echarts
        let options = null;
        options = this.setPieOption(data, title, color, color2);
        //设置options
        this.myChart.clear();
        this.myChart.setOption(options);
        this.myChart.resize();
        window.addEventListener('resize', this.resizeFn = debounce(() => {
            this.myChart && this.myChart.resize();
        }, 200));

    }

    render() {
        return (
            <div ref={pieChart => {
                this.pieChart = pieChart;
            }} style={{width: '50%', height: '60%'}}>
            </div>
        );
    }

    /**
     * 一个基本的echarts图表配置函数
     * @param {*} data
     * @param {*} title
     * @param {*} color   渐变色0%处的颜色
     * @param {*} color2  渐变色100%处的颜色
     * 没有渐变色 color = color2
     */

    setPieOption(data, title, color, color2) {
        const normalTotal = data.normalTotal;
        const minTotal = data.minTotal;
        // 重设fontSize
        const fontSize = resizeFootSize();
        return {
            color: ['#142440', color],
            graphic: {
                type: 'text',
                left: 'center',
                bottom: '35%',
                style: {
                    text: title,
                    textAlign: 'center',
                    fill: '#7998B7',
                    width: 0.4 * fontSize,
                    height: 0.4 * fontSize,
                    font: '16px "Microsoft YaHei", sans-serif'
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: ['70%', '80%'],
                    legendHoverLink: false,
                    hoverOffset: 0,
                    cursor: 'default',
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: minTotal},
                        {
                            value: normalTotal,
                            label: {
                                show: true,
                                align: 'left',
                                fontSize: 0.16 * fontSize,
                                position: 'center',
                                formatter: '{d}%',
                                padding: [0, 0, 0.15 * fontSize, 0],
                                textStyle: {
                                    fontSize: 0.25 * fontSize,
                                    color: '#fff'
                                }
                            },
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    colorStops: [
                                        {
                                            offset: 0, color: color // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: color2 // 100% 处的颜色
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                }
            ]
        };
    }
});


