/**
 * @author chenlin
 * @date 2018/12/10
 * @version:v1.1.0
 */

import {message} from 'antd';
import {getToken} from '@/config/constans';

/**
 * 调度 电话
 */
export function initDispatch(props) {
    // const globalNativeBridge = window.nativeBridge || window.top.nativeBridge;
    let token = getToken();
    /*if (globalNativeBridge) {
        token = localStorage.getItem('token');
    } else {
        token = sessionStorage.getItem('token');
    }*/
    if (typeof scooper.poc !== 'undefined') {
        return new Promise((resolve, reject) => {
            scooper.poc.loginByToken(token).then(() => {
                resolve();
            });
        });
    }
    let navParam = props.location.search;
    if (navParam) {
        let param1 = navParam.split('?')[1];
        let param2 = param1.split('&');
        param2.forEach(item => {
            if (item.indexOf('token') > -1) {
                let token = item.split('=')[1];
                sessionStorage.setItem('token', token);
            }
            if (item.indexOf('firstDeptId') > -1) {
                sessionStorage.setItem('firstDeptId', item.split('=')[1]);
            }
            if (item.indexOf('loginTime') > -1) {
                sessionStorage.setItem('loginTime', item.split('=')[1]);
            }
        });
    }

    //  let token = localStorage.getItem('token') || sessionStorage.getItem('token');
    //let token = "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6InhqamYiLCJ1c2VySWQiOjExNjIsIkludmFsaWRUaW1lIjoiRnJpIFNlcCAxMyAxNzoxODo0NyBDU1QgMjAxOSJ9.VwEj0fATdajwaxGygCXIzDcFQ_DK-rgdx7JESJ_aKKs_1162"
    return new Promise((resolve, reject) => {
        window.requirejs(window.home,
            ['scooper.poc', 'scooper.sse'],
            (poc, sse) => {
                poc.setRemoteBaseUrl(`/poc-web/`);
                (async () => {
                    if (window.auth.authed) {
                        poc.initialize('main', {scAuth: window.auth});
                    } else {
                        await window.auth.initialize();
                        poc.initialize('main', {scAuth: window.auth});
                    }
                    if (token !== null) {
                        await poc.loginByToken(token);
                        // console.log('==============dispatch-manage poc', poc === window.scooper.poc, window.scooper.poc, '============sse', sse === window.scooper.sse, window.scooper.sse);
                        resolve();
                    } else {
                        message.error('请先登录！');
                        setTimeout(() => props.history.push(`/`), 1000);
                    }
                })();
            }
        );
    });
}

/**
 * 销毁
 */
export function destroy() {
    try {
        scooper.poc.destroy();
    } catch (e) {
        console.log(e.message);
    }
}






