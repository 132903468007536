import React, {Component} from 'react';
import QRCode from 'qrcode.react';
import {or_info_api} from '@/rest-api/restapi';
import '../less/qr-code-login.less';

class QrCodeCodeLogin extends Component {
    state = {
        data: ''
    };

    componentDidMount() {
        or_info_api.getQrInfo().then(res => {
            if (res.code.toString() === '0') {
                this.setState({data: JSON.stringify(res.data)});
            }
        });
    }

    render() {
        const {data} = this.state;
        return (
            <div className={'qr-code-login-container'}>
                <QRCode value={data} size={218}/>
                <div className={'qr-code-login-label-wrapper'}>
                    <span className={'qr-code-login_login-label'}>扫我登录</span>
                </div>
            </div>
        );
    }
}

export default QrCodeCodeLogin;