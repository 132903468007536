import React, { PureComponent } from "react";
import { message } from 'antd';
import moment from "moment";
import { Alert } from "antd";
import sosMusic from '../../image/sos.mp3';
import Subscribe from "../../subscribe";
import { getMenu } from "../pages/gis/gis-header";
import { getBanList } from "../../home/index"
import "../../less/gisheader.less";
let banList=[];
class ManageHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      /* 现在时间 */
      nowTime: "",
      currentId: "",
      isShow: true,
      warningMessage: "",
      warningShow: false,
      selectValue:"",
      visible:false,
      title:"",
      headNavVisible: false,
      path:getMenu((data)=>{this.setState({path:data})})||[],
    };
  }

  getParam = () => {
    let navParam = this.props.this.location.search;
    if(navParam) {
      this.setState({
        isBack: "hidden"
      })
    }
  }

  getSosList = () => {
    //初始化加载页面获取告警列表
    let _this = this;
    scooper.poc.groupPoc.listAllMemSos(function (listData) {
      if(!listData.code) {
        _this.setState({
          sosList: listData.data
        });

        //检测初始化页面时是否有当前告警，如果有则加载告警音
        let _status = [];
        if(listData.data.length > 0) {
          listData.data.map(function(val) {
            _status.push(val.sosStatus);
            if(val.sosStatus == 1) {
              $("#sos-music").attr("src", sosMusic);
            }
          })
        }
        let status = _status.join(',');
        if(status.indexOf(1) == -1) {
          $("#sos-music").attr("src",'');
        }
      }
    })
  }

  listenSosList = () => {
    let _this = this;
    //监听告警列表
    scooper.poc.listen(scooper.poc.event_const.POC_SOS_CHN, this.callback = function (data) {  
      scooper.poc.groupPoc.listAllMemSos(function (listData) {
        var _status = [];
        if(!listData.code) {
          _this.setState({
            sosList: listData.data
          });
          if(listData.data) {
            listData.data.map(function(item) {
              _status.push(item.sosStatus);
              if(item.sosStatus == 1) {
                $("#sos-music").attr("src", sosMusic);
              }
            })
          }
          var status = _status.join(',');
          if(status.indexOf(1) == -1) {
            $("#sos-music").attr("src",'');
          }
        }
      })
    })
  }

  //监听磁盘告警
  listenWarning = () => {
    Subscribe.register('DISK_USAGE_WARN', this.diskFn = ({data}) => {
      this.setState({
        warningMessage: data,
        warningShow: true
      })
    })
  }

 async componentDidMount() {
    this.getParam();
    this.getNowTime();
    this.getSosList();  //初始化获取告警列表
    this.listenSosList();  //监听告警列表
    this.listenWarning();
    banList =await getBanList();
    console.log("banList",banList);
    if(this.state.selectValue===""){
this.judgeLink();
    }
    this.timer = setInterval(() => {
      this.getNowTime();
    }, 1000);
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
    scooper.poc.unlisten(scooper.poc.event_const.POC_SOS_CHN, this.callback);
    Subscribe.unregister('DISK_USAGE_WARN', this.diskFn);
  }

  /* 得到现在时间 */
  getNowTime = () => {
    let nowTime = moment();
    let date = nowTime.format("YYYY/MM/DD");
    let time = nowTime.format("HH:mm:ss");
    let dateTime = date + " " + time;
    this.setState({
      nowTime: dateTime
    });
  };

  getCurrentId = (item) => {
    this.setState({
      currentId: item.sosKeyid
    })
  }

  resetCurrentId = () => {
    this.setState({
      currentId: ""
    })
  }

  /**
   * 处理sos告警并跳转至gis界面
   */
  dealSos = (data) => {
    var sosData = {
      memTel: data.memTel || data.mem.mem_tel,
      name: data.memName,
      status: data.sosStatus,
      id: data.sosKeyid
    }

    if(this.props.this.match.path !== "/main/minitor") {
      this.props.this.history.push(`gis?memTel=${sosData.memTel}&name=${sosData.name}&status=${sosData.status}&getType=sosJump&id=${sosData.id}`);
    } else {
      this.props.this.history.push(`/main/gis?memTel=${sosData.memTel}&name=${sosData.name}&status=${sosData.status}&getType=sosJump&id=${sosData.id}`)
    }
  
    sessionStorage.setItem('memTel', sosData.memTel);
    sessionStorage.setItem('name', sosData.name);
    sessionStorage.setItem('status', sosData.status);
    sessionStorage.setItem('getType', 'sosJump');
    sessionStorage.setItem('id', sosData.id)
  }

  /**
   * 忽略历史告警
   */
  ignoreSos = (data, e) => {
    e.stopPropagation();
    const { eventType } = this.props;
    const sysUrl = window.config.sysUrl;
    const params = {
      ids: data.id,
      token: sessionStorage.getItem('token')
    }
    let self = this;
    //当前告警
    if(data.sosStatus == 1) {
      scooper.poc.groupPoc.ignoreMemSos(data.memTel, function (resp) {
				self.addOplogRecord('op-sos-ignore', sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '的sos请求' : '的上呼请求'), '', sessionStorage.getItem('accId'), sessionStorage.getItem('corpId'))
			})
      self.addSosRecord(4, data.memTel, sessionStorage.getItem('memName') + '忽略了'  + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ? '发起的sos请求' : '发起的上呼请求'), data.sosKeyid, "");   //告警记录
      
      //忽略历史告警
    } else if (data.sosStatus == 0) {
      $.post(sysUrl + '/poc-web/data/alarmRecord/ignoreAlarmRecord', params, function(res) {
        if(!res.code) {
          self.addOplogRecord('op-sos-ignore', sessionStorage.getItem('memName') + '忽略了' + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ?'的sos请求':'的上呼请求'), '', sessionStorage.getItem('accId'), sessionStorage.getItem('corpId'))
          self.addSosRecord(4, data.memTel, sessionStorage.getItem('memName') + '忽略了'  + (data.memName) + '【' + data.memTel + '】' + (eventType == 0 ?'发起的sos请求':'发起的上呼请求'), data.sosKeyid, "");   //告警记录
          scooper.poc.groupPoc.listAllMemSos(function (listData) {
            if(!listData.code) {
              self.setState({
                sosList: listData.data
              })
            }
          })
        }
      })
    }
  }

   /**
   * 生成操作记录
   */
  addOplogRecord = (typeKey, content, logExt, accId, corpId) => {
    var param = {
        token: sessionStorage.getItem('token'),
        typeKey: typeKey,
        content: content,
        logExt: logExt,
        accId: accId,
        corpId: corpId
    }
    $.post(`${window.config.modulesUrl}/scooper-poc-server/data/manaOplog/saveManaOplog`, param, function(res) {
        if(!res.data) {
            console.log('新增操作日志')
        }
    });
  }

  /**
   * 生成告警记录
   */
  addSosRecord = (sosType, tel, content,sosKeyid) => {
    var param = {
        token: sessionStorage.getItem('token'),
        type: 3,
        content: content,
        memTel: tel,
        sosType: sosType,
        sosKeyid:sosKeyid
    }
    $.post(`${window.config.modulesUrl}/poc-web/data/alarmRecord/addAlarmRecord`, param, function(resp) {
        if (resp.code === 0) {
            console.log('新增告警记录')
        }
    });
  }

  /**
   * 告警列表显隐
   */
  getListBox = () => {
   this.setState({
     isShow: !this.state.isShow
   })
  }

  /**
   * 磁盘空间提示框关闭之后的回调
   */
  handleClose = () => {
    this.setState({
      warningShow: false,
      warningMessage: ""
    })
  }

   selectChange = (value) => {
    if (banList.indexOf(value.split(",")[1]) > -1) {
      this.props.this.history.push({ pathname: value.split(",")[0], state: value.split(",")[0] });
      this.setState({selectValue: value});
    } else {
      return message.info('该模块正在开发中...');
    }
  }

  judgeLink = (item) => {
    this.state.path.map(it=>{
      if(window.location.hash.indexOf(it.path)>-1){
        this.setState({headNavVisible: true});
        this.setState({selectValue:`${it.path},${it._key}`});
        this.setState({title:it.name});
      }
    })
    // let res = window.location.hash.indexOf(item.path);
    // if (res > -1) {
    //   return true;
    // } else return false;
  };
  isVisible = () => {
    this.setState({visible:!this.state.visible});
  }

  render() {
    const { nowTime, sosList, currentId, isShow, isBack, warningMessage, warningShow } = this.state;
    const { eventType, alarm } = this.props;
    return (
      <div className="header-wrap">
        <div className="sosMusic">
          <audio id="sos-music" src="" autoPlay="autoplay" loop></audio>
        </div>
        <div className="header-left" onClick={this.props.goHome}  style={{visibility: isBack}}>
          首页
        </div>
        {warningShow && <Alert type="warning" message={warningMessage} closable showIcon afterClose={this.handleClose} />}
        <div className="header-main">
          {/*{console.log("this.props",this.props,this.state.headNavVisible)}*/}
          {/* {(alarm && eventType == 1) ? "上呼记录" : this.props.title} */}
          {(alarm && eventType == 1) ? "上呼记录" :!this.state.headNavVisible?this.props.title:
          // <select name="pathSelect" onChange={this.selectChange} value={this.state.selectValue}>
          //     {this.state.path.map((item, index) => {
          //       return (
          //         <option
          //           // selected={judgeLink(item)}
          //           key={item.key}
          //           value={`${item.path},${item._key}`}
          //         >
          //           {item.name}
          //         </option>
          //       );
          //     })}
          //   </select>
          <div className="select-value" onClick={this.isVisible}>{this.state.title} <span className="select-tri"></span>
          {this.state.visible && <div className="select-box">
            {this.state.path.map((item, index) => {
              return (
                <div className="select-item"
                  // selected={judgeLink(item)}
                  // key={item.key}
                  onClick={this.selectChange.bind(this, `${item.path},${item._key}`)}
                  value={`${item.path},${item._key}`}
                >
                  {item.name}
                </div>
              );
            })}
          </div>}
        </div>
            }
          
        </div>
        <div className="header-right">
          <div className="date-time">
            <i className="police-icon" onClick={this.getListBox}></i>
            {sosList && <span className="police-count">{sosList.length}</span>}
            {nowTime}
            {/* 2018年9月20日 周四 10：00 农历八月十一 */}
          </div>
          {sosList && sosList.length > 0 &&<div className={`sos-wrap ${isShow ? "isShow" : ""}`}>
            <ul className="sos-ul">
              {
                sosList && sosList.length > 0 && sosList.map(item => {
                  return (
                    <li className="sos-list" key={item.sosKeyid} onMouseEnter={()=>this.getCurrentId(item)} onMouseLeave={this.resetCurrentId} onClick={(a)=>this.dealSos(item, a)}>
                      <i></i>
                      <span  className="sos-content" title={`${item.gmtCreate}  ${item.memName || item.memName}${eventType == 0 ? "sos告警" : "上呼请求"}`}>{item.gmtCreate}&nbsp;&nbsp; {item.memName || item.memName}{eventType == 0 ? "sos告警" : "上呼请求"}</span>
                      <span className={`ignore-btn ${item.sosKeyid == currentId ? "isShow" : ""}`} onClick={(e)=>this.ignoreSos(item, e)}>忽略</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>}
        </div>
      </div>
    );
  }
}

export default ManageHeader;
