import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import {connect} from 'react-redux';

@connect(state => {
    return {};
}, {})
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps) {
        var myChart = echarts.init(document.getElementById('pitPie2'));
        myChart.setOption({
            coordinateSystem: 'cartesian2d',
            tooltip: {},
            formatter: '{b}\n{c}%',
            xAxis: {
                show: false
            },
            yAxis: {
                show: false
            },
            series: [{
                type: 'pie',
                radius: ['60%', '80%'],
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                        textStyle: {
                            fontSize: 12

                        },
                        formatter: '{b}\n{c}%'
                    }
                },
                data: [{
                    value: nextProps.recordCount.devNum !== 0 ? ((nextProps.recordCount.onlineDevNum / nextProps.recordCount.devNum) * 100).toFixed(0) : 0,
                    name: `终端在线${nextProps.recordCount.onlineDevNum}台`,
                    label: {
                        normal: {
                            show: true,
                            color: '#EDF8FF'
                        }
                    },
                    itemStyle: {
                        color: {
                            type: 'linear',
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(0,252,255,1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1, color: 'rgba(0,162,237,1)' // 100% 处的颜色
                                }]
                        }
                    }
                },
                    {
                        value: 100 - (nextProps.recordCount.devNum !== 0 ? ((nextProps.recordCount.onlineDevNum / nextProps.recordCount.devNum) * 100).toFixed(0) : 0),
                        name: '',
                        itemStyle: {
                            color: '#003273'
                        }
                    }
                ],
                x: 'center',
                y: 'center',
                labelLine: {
                    normal: {
                        show: false
                    }
                }
            }]
        });

    }

    render() {
        return (
            <div id="pitPie2" style={{width: 180, height: 180}}/>
        );
    }
}