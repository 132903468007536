/*
 * @Author: liulian
 * @Date: 2019/9/12 10:50:06 
 */

//  video API
const urlPrefix = `${window.config.sysUrl}/scooper-video`;

export const videoPart = {
    // listByParent:urlPrefix + '/data/video/load/route/scooper-core-rest/data/contacts/orgDeptManage/listDeptByParent',
    // savePlayRecord: '/scooper-video/data/play/savePlayRecord',
    // loadCollectList:urlPrefix + '/data/bookmark/loadZtreeBookmarkCatalog',   // 1.4.4收藏夹接口
    // queryPlayRecord:urlPrefix + '/data/play/queryPlayRecordList',
    // loadBookmarkCatalogInfo: urlPrefix + '/data/bookmark/loadBookmarkCatalogInfo',
    // updateBookmarkCatalog: '/scooper-video/data/bookmark/updateBookmarkCatalog',
    // testLoadBook:urlPrefix + '/data/bookmark/loadZtreeBookmarkCatalog',
    // --------------------------------1.4.5 视频接口-------------------
    // queryFacilityList: '/scooper-video/data/facility/queryFacilityList', // 1.4.5 视频树接口

    queryFacilityList: '/scooper-video/data/baseRes/queryBaseResList', // 1.5.0 视频树接口
    addPlayHistory: '/scooper-video/data/playHistory/addPlayHistory',
    clearAllPlayHistories: '/scooper-video/data/playHistory/clearAllPlayHistories',
    loadCollectList: '/scooper-video/data/bookmark/queryBookmarkTree',  // 1.4.5收藏夹接口
    saveBookmarkCatalog: '/scooper-video/data/bookmark/saveBookmarkCatalog',    // 1.4.5新增收藏夹 子节点
    deleteBookmarkCatalog: '/scooper-video/data/bookmark/deleteBookmarkCatalog',    // 1.4.5删除收藏夹 子节点

    queryPlayHistoryList: '/scooper-video/data/playHistory/pagePlayHistoryList',
    saveBookmark: '/scooper-video/data/bookmark/saveBookmark',
    deleteBookmark: '/scooper-video/data/bookmark/deleteBookmark',
    updateBook: '/scooper-video/data/bookmark/updateBookmark'
};

// count API

const countPrefix = '/scooper-poc-server/data/statisticsFile/';

export const statistics = {
    getFileType: countPrefix + 'getFileTypeStatistics',
    getFileUpload: countPrefix + 'getFileUploadStatistics',
    getFileCreate: countPrefix + 'getFileCreateTimeStatistics',
    getFileLength: countPrefix + 'getFileTimeStatistics',
    getFileLevel: countPrefix + 'getFileLevelStatistics',
    getDASData: countPrefix + 'getDASStatistics',
    GetDevNum: '/scooper-poc-manager/data/dev/queryDevNumByCorpId',
    getStoreRange: countPrefix + 'getUsedStorage' //存储使用情况
};

// dept API 
const deptPrefix = '/scooper-core-rest';
export const dept = {
    listOrgDept: deptPrefix + '/data/contacts/orgDeptManage/listOrgDept',
    listDispGroup: deptPrefix + '/data/dispatch/dispGroupManage/listDispGroup',
    saveOrgMember: deptPrefix + '/data/contacts/orgMemberManage/saveOrgMember',  //新增部门成员
    updateOrgMember: deptPrefix + '/data/contacts/orgMemberManage/updateOrgMember',  //更新部门成员
    addOrgDept: deptPrefix + '/data/contacts/orgDeptManage/saveOrgDept',   //新增部门
    delOrgDept: deptPrefix + '/data/contacts/orgDeptManage/deleteOrgDept',  //删除部门
    reOrgDept: deptPrefix + '/data/contacts/orgDeptManage/updateOrgDept', //更新部门
    saveTempMember: deptPrefix + '/data/dispatch/dispMemberManage/saveDispMember', //新增通话组成员
    getOrgDeptDetail: deptPrefix + '/data/contacts/orgDeptManage/getOrgDeptDetail',   //查询部门详情
    findOrgByTel: deptPrefix + '/data/contacts/orgMemberManage/findOrgMemberByTel' //按号码搜索成员
};
// weishuai API
const memberPrefix = '/scooper-poc-manager/data/contacts';
export const member = {
    queryCoreMember: memberPrefix + '/queryCorpMemberInfo',   //查询公司成员列表
    queryGroupMember: memberPrefix + '/queryGroupMemberInfo',  //查询通话组成员列表
    deleteOrgMember: memberPrefix + '/deleteOrgMemberList',    //删除成员列表
    queryCorpDevMember: memberPrefix + '/queryCorpDevMemberInfo', //查询执法记录仪列表
    exportData: '/scooper-poc-manager/data/dev/pocExportCloude', //导出通讯录
    setUser: '/scooper-poc-manager/data/dev/setUserCloude', //执法仪绑定成员
    deleteDispMember: memberPrefix + '/deleteDispMemberList' //删除通话组成员
};
// 调度API
const dispatchPrefix = '/poc-web/api/';
export const dispatch = {
    login: dispatchPrefix + 'conn/connectionByToken',  //登录
    heartKeep: dispatchPrefix + 'conn/heartKeep',   //心跳
    openCloudEye: dispatchPrefix + 'groupPoc/openCloudEye',   //打开云眼
    closeCloudEye: dispatchPrefix + 'groupPoc/closeCloudEye',  //关闭云眼
    getListStatus: dispatchPrefix + 'call/listStatus' //拿到成员状态
};


