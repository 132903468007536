//数据配置
export const BOOK_COLUMNS = [
  {
    title: "姓名",
    dataIndex: "memName",
    key: "memName",
    align: "center",
    required: true
  },
  {
    title: "工号",
    dataIndex: "memCode",
    key: "memCode",
    align: "center"
  },
  {
    title: "电话",
    dataIndex: "memTel2",
    key: "memTel2",
    align: "center"
  },
  {
    title: "手机",
    dataIndex: "memMobile",
    key: "memMobile",
    align: "center"
  },
  {
    title: "终端",
    dataIndex: "mac",
    key: "mac",
    align: "center"
  }
];

export const GROUP_COLUMNS = [
  {
    title: "姓名",
    dataIndex: "memName",
    key: "memName",
    align: "center"
  },
  {
    title: "工号",
    dataIndex: "memCode",
    key: "memCode",
    align: "center"
  },
  {
    title: "电话",
    dataIndex: "memTel",
    key: "memTel",
    align: "center"
  },
  {
    title: "手机",
    dataIndex: "memMobile",
    key: "memMobile",
    align: "center"
  },
  {
    title: "终端",
    dataIndex: "mac",
    key: "mac",
    align: "center"
  }
];
//执法记录仪
export const INSTRUMENT_COLUMNS = [
  {
    title: "设备编号",
    key: "mac",
    dataIndex: "mac",
    align: "center"
  },
  {
    title: "设备状态",
    key: "devStatus",
    dataIndex: "devStatus",
    align: "center"
  },
  {
    title: "使用人员",
    key: "memName",
    dataIndex: "memName",
    align: "center"
  },
  {
    title: "工号",
    key: "memCode",
    dataIndex: "memCode",
    align: "center"
  },
  {
    title: "部门",
    key: "deptName",
    dataIndex: "deptName",
    align: "center"
  },
  {
    title: "手机",
    key: "memMobile",
    dataIndex: "memMobile",
    align: "center"
  },
  {
    title: "物联网卡号",
    key: "lotTel",
    dataIndex: "lotTel",
    align: "center"
  }
];


//通讯录-添加/编辑成员
export const EDITADD_DEPT_MEMBER = [
  {
    title: "姓名",
    dataIndex: "memName",
    key: "memName",
    align: "center",
    rules: [
      {
        required: true,
        message: `请输入姓名`
      }
    ]
  },
  {
    title: "成员等级",
    dataIndex: "memLevel",
    key: "memLevel",
    align: "center",
  },
  {
    title: "所属部门",
    dataIndex: "deptId",
    key: "deptId",
    align: "center",
    required: true
  },
  {
    title: "工号",
    dataIndex: "memCode",
    key: "memCode",
    align: "center",
    rules: [
      {
        validator: (rule, value = "", callback) => {
          const reg = new RegExp("^[0-9]*$");
          try {
            if (value !== "") {
              if (!reg.test(value)) {
                callback("请输入数字");
              }
            }
            callback();
          } catch (err) {
            callback(err);
          }
        }
      }
    ]
  },
  {
    title: "手机",
    dataIndex: "memMobile",
    key: "memMobile",
    align: "center",
    rules: [
      {
        validator: (rule, value = "", callback) => {
          const reg = new RegExp("^[0-9]*$");
          try {
            if (value !== "") {
              if (value.length !== 11 || !reg.test(value)) {
                callback("请输入11位数字");
              }
            }
            callback();
          } catch (err) {
            callback(err);
          }
        }
      }
    ]
  }
];

//新增/编辑部门
export const DEPART_COLUMNS = [
  {
    title: "部门名称",
    key: "deptName",
    rules: [
      {
        required: true,
        message: `请输入部门名称`
      }
    ]
  },
  {
    title: "上级部门",
    key: "deptId",
    required: true
  }
];

//成员调整部门
export const CHENGEDEPART_COLUMNS = [
  {
    title: "调整部门至",
    key: "deptId",
    required: true
  }
];
