/*
 * @Description: Gis
 * @Author: liulian
 * @Date: 2019-10-18 11:20:59
 * @Last Modified by: yanghui
 * @Last Modified time: 2019-12-05 15:14
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import GisHeader from './gis-header';
import {scooper_poc_log} from '@/rest-api/restapi';

export default connect()(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        async componentWillMount() {
            if (
                document.location.href.indexOf('token') > -1 &&
                document.location.href.indexOf('jumpType') === -1
            ) {
                var arr = document.location.href.split('?');
                var _arr = arr[1].split('&');
                var token = '';
                _arr.map((item) => {
                    if (item.indexOf('token') > -1) {
                        token = item.split('=')[1];
                        sessionStorage.setItem('token', token);
                        // localStorage.setItem('token', token);
                    }
                });
                var _loginTime = new Date().getTime();
                var param = {
                    token: token,
                    username:
                        localStorage.getItem('username') ||
                        sessionStorage.getItem('username'),
                    loginTime: _loginTime
                };
                await scooper_poc_log.doLoginByToken(param);
            }
        }

        componentWillUnmount() {
            const iframe = document.getElementById('calculation');
            //把iframe指向空白页面，这样可以释放大部分内存。
            iframe.src = 'about:blank';
            try {
                iframe.contentWindow.document.write('');
                iframe.contentWindow.document.clear();
            } catch (e) {
            }
            //把iframe从页面移除
            iframe.parentNode.removeChild(iframe);
        }

        render() {
            const StyleIframe = {
                wrap: {
                    width: '100%',
                    height: '100%'
                }
            };
            let srcGis = '';
            if (
                sessionStorage.getItem('memTel') &&
                sessionStorage.getItem('sosType')
            ) {
                srcGis = `${window.config.sysUrl}/scooper-poc-map/html/index.html?token=${sessionStorage.token}&memTel=${sessionStorage.memTel}&type=${sessionStorage.sosType}&loginType=jump`;
            } else if (
                sessionStorage.getItem('memTel') &&
                sessionStorage.getItem('memName') &&
                sessionStorage.getItem('status') &&
                sessionStorage.getItem('getType')
            ) {
                srcGis = `${
                    window.config.sysUrl
                }/scooper-poc-map/html/index.html?token=${
                    sessionStorage.token
                }&memTel=${sessionStorage.memTel}&memName=${
                    sessionStorage.memName
                }&status=${sessionStorage.getItem(
                    'status'
                )}&getType=${sessionStorage.getItem(
                    'getType'
                )}&id=${sessionStorage.getItem('id')}`;
            } else {
                srcGis = `${window.config.sysUrl}/scooper-poc-map/html/index.html?token=${sessionStorage.token}&loginType=jump`;
            }
            return (
                <div className='limit-wrap' style={StyleIframe.wrap}>
                    <GisHeader props={this.props}/>
                    <iframe
                        id={'calculation'}
                        title='file-recode'
                        name='file-recode'
                        style={{width: '100%', height: '100%'}}
                        allowFullScreen={true}
                        src={srcGis + '&count=' + Math.random() * 10}
                        frameBorder='0'
                        onLoad={() => {
                            const {contentWindow} = document.getElementById('calculation');
                            contentWindow.postMessage(
                                {
                                    type: 'iframeMessage',
                                    href: window.document.location.href,
                                    nativeBridge: window.top.nativeBridge
                                },
                                '*'
                            );
                        }}
                    />
                </div>
            );
        }
    }
);
