import React, { PureComponent } from 'react';
import moment from 'moment';

class NowDateTime extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            /* 现在时间 */
            nowTime: '',
        }
    }

    componentDidMount(){
        this.getNowTime();

        this.timer = setInterval(() => {
            this.getNowTime();
        }, 1000)
    }

    componentWillUnmount(){
        this.timer && clearInterval(this.timer);
        this.timer = null;
    }

    /* 得到现在时间 */
    getNowTime = () => {
        let nowTime = moment();
        let date = nowTime.format("YYYY/MM/DD");
        let time = nowTime.format("HH:mm:ss")
        let dateTime = date + " " + time;
        this.setState({
            nowTime: dateTime
        })
    }

    render(){
        const { nowTime } = this.state;
        return(
            <div className="date-time">
                { nowTime }
                {/* 2018年9月20日 周四 10：00 农历八月十一 */}
            </div>
        )
    }
}

export default NowDateTime;
