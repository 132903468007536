/*
 * @Author: liulian
 * @Date: 2019/9/19 10:50:06 
 * @Last Modified by: 
 * @Last Modified time: 
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Menu} from 'antd';

import {isEmpty} from 'lodash';

export default connect(
    state => {
        return {
            playList: state.playList
        };
    },
    {}
)(class extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='list-wrap'>
                <div className='play-list'>
                    <Menu>
                        {!isEmpty(this.props.playList) && this.props.playList.map((item) => {
                            return (
                                item && <Menu.Item key={`list-${item.id}`}>
                                    <span className='monitor-name'>{item.name}</span>
                                    <span className='department'>{item.parentName}</span>
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                </div>
            </div>
        );

    }
});