import React, { Component } from 'react'
import { connect } from 'react-redux'
import NowDateTime from '../../components/date'

export default connect(

)(class extends Component {
    constructor(props) {
        super(props)
    }

  /**
   * 返回首页
   */
    // goHome = () => {
    //     this.props.history.push('/home');
    // }
    render() {
        return (
            <div className='header-wrap'>
                <div className="header-left" >首页</div>
                <div className="header-main">视频监控</div>
                <div className="header-right">
                    <NowDateTime />
                </div>
                
            </div>
        )
        
       
    }
})