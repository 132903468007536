import {sys_api} from '@/rest-api/restapi';

export const getTitleByCfgKey = (key) => {
    return sys_api.getSysConfig({cfgKey: key});
};

export const getWebTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.web.title'});     // 网页标签数据获取
};

export const getLoginTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.login.title'});   // 登录页面标题获取
};

export const getHomeTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.home.title'});    // 首页页面标题获取
};

export const getGisTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.gis.title'});    // gis页面标题
};

export const getVideoTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.video.title'});    // 监控管理页面标题
};

export const getAlarmTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.alarm.title'});    // 告警记录页面标题
};

export const getFileTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.file.title'});    // 文件管理页面标题
};

export const getStatisticsTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.statistics.title'});    // 统计分析页面标题
};

export const getDasTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.das.title'});    // 采集站管理页面标题
};

export const getDeptTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.dept.title'});    // 部门管理页面标题
};

export const getDevTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.dev.title'});    // 记录仪管理页面标题
};

export const getGroupTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.group.title'});    // 通话组管理页面标题
};

export const getOplogTitle = () => {
    return sys_api.getSysConfig({cfgKey: 'scooper.poc.oplog.title'});    // 操作日志页面标题
};