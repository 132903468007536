/**
 * @author chenlin
 * @date 2018/11/9
 * @version:v1.1.0
 */
import axios from 'axios';
import {message} from 'antd';
import 'url-search-params-polyfill';
import {getToken} from '@/config/constans';

const CancelToken = axios.CancelToken;

let cancel;

let service = axios.create({
    baseURL: '',
    timeout: 10000
});

//添加请求拦截器
service.interceptors.request.use(function (config) {
    let token = getToken();
    if (token) {
        config.headers['token'] = token;
    } else if (window.location.hash !== '#/login' && !token) {
        window.location.hash = '#/login';
        return;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

//添加响应拦截器
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        console.log('err' + error);
        // message.error(error.message)
        return Promise.reject(error);
    }
);

export function commonGet(url, param, tip, deleteToken) {
    let token = getToken();

    //url加时间戳 防止缓存
    let getTimestamp = (new Date()).getTime();
    let tempUrl = '';
    if (url.indexOf('?') > -1) {
        tempUrl = url + '&v=' + getTimestamp;
    } else {
        tempUrl = url + '?v=' + getTimestamp;
    }


    if (!deleteToken) {
        param.token = token;
    }

    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: tempUrl,
            params: param,
            cancelToken: new CancelToken(c => {
                cancel = c;
            })
        }).then(res => {  //axios返回的是一个promise对象

            resolve(res);  //resolve在promise执行器内部
            if (res.data.code === 0) {

                if (tip) {
                    message.success(tip);
                }
            } else {
                if (res.data.message && res.data.message.indexOf('token验证无效') > -1) {
                    res.data.message = '请先登录';

                }
                if (res.config.url.indexOf('/poc-web/api/groupPoc/openCloudEye') > -1 && res.data.code === 1100 && res.data.message === null) {
                    res.data.message = '该成员未绑定设备！';
                }
                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err, '异常');
        });

    });
}

//post请求
export function commonPost(url, param, tip, isFile, clearToken) {
    let token = getToken();
    let params;

    if (isFile) {
        params = new FormData();
    } else {
        params = new URLSearchParams();
    }
    for (let i in param) {
        if (param[i] || param[i] == 0) {
            params.append(i, param[i]);
        }
    }
    !clearToken && params.append('token', token);
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url,
            data: params
        }).then(res => {
            resolve(res);
            if (res.data.code === 0) {
                if (tip) {
                    message.success(tip);
                }
            } else {
                if (res.data.message && res.data.message.indexOf('token验证无效') > -1) {
                    res.data.message = '请先登录';

                }
                if (res.config.url.indexOf('/poc-web/api/groupPoc/openCloudEye') > -1 && res.data.code === 1100 && res.data.message === null) {
                    res.data.message = '该成员未绑定设备！';
                }

                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err, '异常');
        });
    });
}

export function commonPostJson(url, param, tip, isFile, clearToken) {
    let token = getToken();
    let params;

    if (isFile) {
        params = new FormData();
    } else {
        params = new URLSearchParams();
    }
    for (let i in param) {
        if (param[i] || param[i] == 0) {
            params.append(i, param[i]);
        }
    }
    !clearToken && params.append('token', token);
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: `${url}?token=${token}`,
            data: param
            // headers: {
            //     'Content-Type': 'application/json'  //如果写成contentType会报错
            // }
        }).then(res => {
            resolve(res);
            if (res.data.code === 0) {
                if (tip) {
                    message.success(tip);
                }
            } else {
                if (res.data.message && res.data.message.indexOf('token验证无效') > -1) {
                    res.data.message = '请先登录';

                }
                if (res.config.url.indexOf('/poc-web/api/groupPoc/openCloudEye') > -1 && res.data.code === 1100 && res.data.message === null) {
                    res.data.message = '该成员未绑定设备！';
                }

                message.error(res.data.message);
            }
        }).catch(err => {
            console.log(err, '异常');
        });
    });
}