import React from 'react';
import {Input,Modal,message, Radio,Button,Select,Form} from 'antd';

import {scooper_poc_server} from '../../rest-api/restapi';

const { Option } = Select;

class AddModal extends  React.Component{
    constructor(props){
        super(props);
        this.state = {
            visible: true,
            menuList:[],
            chooseWhich: '1',
        }
    }

    onChange = (e) => {
        console.log('radio checked:' + e.target.value,e);
    }
    getMenuInfo=async()=>{
        let data=await scooper_poc_server.listHomeMenuType({showType:2});
        if(data.code===0){
            this.setState({menuList:data.data.list});
        }
    }
    componentDidMount(){
        this.getMenuInfo();
    }
    /**
     * 添加配置块
     */
    addConfig = async() => {
        const {
            form: { validateFields },
        } = this.props;
        validateFields(async (err,values) => {
            console.log("values",values,this.props)
            let index=parseInt(values.menuIndex);
            let obj=this.state.menuList[index];
                values.menuIndex =this.props.menuIndex
                values.menuType = obj.menuKey
                values.accId = sessionStorage.getItem('accId')
                values.menuName = obj.menuName

            // if(values.menuIndex === '1'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-dept'
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '部门管理'
            // } 
            // if(values.menuIndex === '2'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-das';
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '采集站管理'
            // } 
            // if(values.menuIndex === '3'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-group'
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '通话组管理'
            // } 
            // if(values.menuIndex === '4'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-dev'
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '终端管理'
            // } 
            // if(values.menuIndex === '5'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-oplog'
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '操作日志管理'
            // } 
            // if(values.menuIndex === '6'){
            //     values.menuIndex = this.props.menuIndex
            //     values.menuType = 'menu-data-statistics'
            //     values.accId = sessionStorage.getItem('accId')
            //     values.menuName = '数据统计'
            // }
             
            const data = await scooper_poc_server.addConfig(values);
            if(!data.code){
                message.success("添加成功！");
                await scooper_poc_server.getMenuConfig({accId: sessionStorage.getItem('accId')});
                this.props.loadConfig();
                this.props.onCancel();
            }
            if(data.code == 1121){
               return message.error("该配置块已存在，请勿重复添加！");
            }
            this.props.updateShow(this.props.menuIndex)
        })
    
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
          };
        return (
            <div>
                
                <Modal
                    title="添加配置块"
                    className="add-config"
                    visible={this.props.visible}
                    onCancel={this.props.onCancel}
                    cancelText ="取消"
                    okText = "添加"
                    footer={[
                        <Button onClick={this.props.onCancel} key="1">取消</Button>,
                        <Button onClick={this.addConfig} key="2">添加</Button>
                    ]}
                    >
                    <Form onSubmit={this.handleSubmit} className="form"> 
                        <Form.Item label="选择配置块">
                            {getFieldDecorator('menuIndex',{
                                initialValue: '1'  
                            })(
                                <Radio.Group onChange={this.onChange}>
                                    {this.state.menuList.map((item,index)=>{
                                        return <Radio value={index} style={radioStyle}>{item.menuName}</Radio>
                                    })}
                                    {/* <Radio value="1" style={radioStyle}>部门管理</Radio>
                                    <Radio value="2" style={radioStyle}>采集站管理</Radio>
                                    <Radio value="3" style={radioStyle}>通话组管理</Radio>
                                    <Radio value="4" style={radioStyle}>终端管理</Radio>
                                    <Radio value="5" style={radioStyle}>操作日志管理</Radio>
                                    <Radio value="6" style={radioStyle}>数据统计</Radio> */}
                                </Radio.Group>   
                            )}
                        </Form.Item>
                     </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(AddModal)