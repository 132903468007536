import {SET_FILEDATALL_ACTION, SET_LOCK_SCREEN_ACTION} from '@/reducer/constant';

const initState = {
    search: '',
    //页面初始数据对象
    //===============videoStart===============================
    deptTree: [], //通讯录树
    videoController: '', //视频
    playRecordList: [], //播放历史
    collectTree: [], //收藏夹树
    collectTreeParent: [], //收藏夹树的父节点
    collectItem: [],
    bookName: '',
    playList: [], //播放列表
    isFlow: false,  //是否开启流量控制功能
    collectedDic: {},  // 已收藏的设备列表
    //===============videoEnd==============================

    //===============countStart===============================
    DateStore: [],
    dataType: [], //数据类型统计
    fileRecord: [],
    fileTime: [],
    fileLength: [],
    fileLevel: [],
    dasData: [],
    devNum: [],
    sysData: [],
    storeData: [], //存储使用情况

    //===============countEnd==============================

    //===============deptStart===============================
    // dispGroup:[],
    // orgDept:[],
    // corpMember:[],   //公司成员
    // corpMemberAll:{},
    // corpNode:{},
    // corpNodeId:[],

    // groupMember:[],  //对讲组成员
    // groupMemberAll:{},

    // devMember:[], //执法记录仪数据
    // devMemberAll:{},
    // devInfo:{}, //当前选中的执法记录仪信息

    deptTreeData: [], //部门树列表

    //===============deptEnd==============================

    //============文件管理================
    fileData: [],
    fileDataAll: {},
    timeRange: [],
    selectedRows: [],
    selectedRowKeys: [],
    deptMem: [],
    selectedValue: [],
    pagination: '',
    pocFile: [],
    pocFileAll: {},
    selectedPocRows: [],
    selectedPocRowKeys: [],
    //============采集站管理================
    rowsSelected: [],
    keySelected: [],
    gatherDetail: [],
    gatherList: [],
    gatherListAll: [],
    showSelect: '',
    //============操作日志管理================
    sortList: [],
    sortListAll: {},
    selectedRowsLog: [],
    selectedKey: [],
    //============告警记录================
    policeList: [],
    policeList1: [],
    policeListAll: [],
    addUser: {},
    //============首页================
    gatherCount: {},
    recordCount: {},
    checked: '',
    configData: [],

    cloudEyeId: '',    //关闭云眼通知

    collectName: '',
    isDownload: false,

    listStatus: {}, // 人员状态对象
    lockTimer: 0,
    patrolList: [] // 轮巡列表
};

export default function videoReducer(previousState = initState, action) {
    const {type, payload} = action;
    /*switch (type) {
        case SET_LOCK_SCREEN_ACTION:
            return {...payload};
        default:
            return {...payload};
    }*/
    return {
        ...previousState,
        ...payload
    };
}