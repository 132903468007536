import 'react-app-polyfill/ie11';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import AppRouter from './router/index';
import {Provider} from 'react-redux';
import {store} from './reducer/store';
import LockScreen from './containers/LockScreen';
import * as serviceWorker from './serviceWorker';
import './css/normalize.css';
import './less/video/header.less';
import './less/video/index.less';


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <AppRouter/>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
