/*
 * @Author: songxiao 
 * @Date: 2018-11-28 10:24:20 
 * @Last Modified by: songxiao
 * @Last Modified time: 2019-04-12 11:11:50
 */

import React, { Component } from 'react';

import '../../../less/progress.less';

class Progress extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const ratio = Number.isNaN(this.props.ratio)? 0: this.props.ratio;
        return(
        <div className="progress-show">
            <span className="title">{this.props.title}</span>
            <div className="progress-bar-bg">
                <div className="progress-bar" style={{width: ratio * 100 + '%', backgroundSize: (1 / ratio)*100 + '%'}}>
                </div>
            </div>
            <span className="num">{this.props.num}M</span>
        </div>)
    }
}

export default Progress;