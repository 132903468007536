/*
 * @Author: xx
 * @Date: 2019-09-19 16:51
 */

import React from 'react';
import {
    DatePicker,
    Input,
    Button,
    Select,
    Menu,
    Form,
    message,
    Row,
    Col,
    Empty,
    ConfigProvider,
    Modal,
    InputNumber
} from 'antd';
import $ from 'jquery';

import zhCN from 'antd/es/locale/zh_CN';

import MyTable from '../../components/document/table';
import PitView from '../../components/document/pitView';
import TalkbackTable from '../../components/document/talkback-table';

import '../../../less/doc-manager.less';
import MenuItem from 'antd/lib/menu/MenuItem';

import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import {connect} from 'react-redux';
import {doc_api, dept_api, sys_api, queryflow_api, scooper_poc_server} from '@/rest-api/restapi';
import {
    setFileDataAction,
    setFileDataAllAction,
    setSelectedValueAction,
    setDeptMemAction,
    setPageAction,
    setSelectAction,
    setSelectKeyAction,
    setPocFileAction,
    setPocFileAllAction,
    setSelectPocAction,
    setSelectPocKeyAction
} from '@/reducer/video_action';

import ManageHeader from '../../components/manage-header';
import {bindActionCreators} from 'redux';

const {RangePicker} = DatePicker;
const Option = Select.Option;
const {confirm} = Modal;

function mapStateToProps(state) {
    return {
        fileData: state.fileData,
        fileDataAll: state.fileDataAll,
        selectedRows: state.selectedRows,
        selectedRowKeys: state.selectedRowKeys,
        deptMem: state.deptMem,
        selectedValue: state.selectedValue,
        pagination: state.pagination,
        pocFile: state.pocFile,
        pocFileAll: state.pocFileAll,
        selectedPocRows: state.selectedPocRows,
        selectedPocRowKeys: state.selectedPocRowKeys    //将state映射到当前组件上
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setFileDataAction,
        setFileDataAllAction,
        setSelectAction,
        setSelectKeyAction,
        setDeptMemAction,
        setSelectedValueAction,
        setPageAction,
        setPocFileAction,
        setPocFileAllAction,
        setSelectPocAction,
        setSelectPocKeyAction
    }, dispatch);
}

const DocManager = connect(
    mapStateToProps, mapDispatchToProps
)(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showView: '1',
            downLoadList: [],
            fileType: 10,
            timeRange: ['', ''],
            timeRangePoc: ['', ''],
            currentIndex: 1,
            pagination: this.props.pagination,
            checked: false,
            curIndex: 1,
            isFlow: false,  //是否开启流量控制功能
            fileOptions: ''  //文件管理模块列表选项
        };
    }

    showView = (item, key) => {
        this.setState({
            showView: item.key
        });
    };

    dateOnChange = (date, dateString) => {
        this.setState({
            timeRange: dateString
        });

    };

    dateOnChangePoc = (date, dateString) => {
        this.setState({
            timeRangePoc: dateString
        });

    };


    CascaderClear = () => {
        let closeBtn = document.getElementsByClassName('ant-calendar-picker-clear')[0];
        closeBtn && closeBtn.click();
    };

    /**
     * 获取页面标题
     */
    getTitle = async () => {
        const param = {
            cfgKey: 'scooper.poc.file.title'
        };
        const data = await sys_api.getSysConfig(param);
        if (!data.code) {
            this.setState({
                title: data.data.cfgValue || '文件管理'
            });
        }
    };

    componentDidMount() {
        this.getAccountConfig();
        this.getConfig();
        this.loadTable(10);
        this.getDeptData();
        if (!sessionStorage.getItem('token')) {
            message.error('请先登录！');
            setTimeout(() => this.props.history.push(`/`), 1000);
        }
        this.getTitle();
        this.loadTalkBackTable();
        this.listFileLawType();  //获取执法类型下拉框
    }

    componentWillMount() {
        this.updateSelectedKeys(this.props.selectedRows);
        $('.ant-empty-description').text('暂无数据');
    }

    /**
     * 加载列表(回传记录)
     */
    loadTable = async (pageSize) => {
        const {fileType} = this.state;
        const param = {
            token: sessionStorage.token,
            fileType,
            pageNum: 1,
            pageSize: pageSize || 10,
            corpId: sessionStorage.getItem('corpId')
            // orgCode: sessionStorage.getItem('orgCode')
            // startTime: this.state.startTime,
            // endTime: this.state.endTime,
            // devNo: this.state.devNo,
            // gzzXh: this.state.gzzXh,
            // memName: this.state.memName
        };

        const data = await doc_api.queryFile(param);
        if (!data.code) {
            this.props.setFileDataAction({fileData: data.data.list});
            this.props.setFileDataAllAction({fileDataAll: data.data});
        }
    };

    /**
     * 加载对讲记录列表
     */
    loadTalkBackTable = async () => {
        const param = {
            token: sessionStorage.getItem('token'),
            corpId: sessionStorage.getItem('corpId'),
            // orgCode: sessionStorage.getItem('orgCode'),
            pageNum: 1,
            pageSize: 10
        };
        const pocData = await doc_api.getPocFile(param);
        if (!pocData.code) {
            this.props.setPocFileAction({pocFile: pocData.data.list});
            this.props.setPocFileAllAction({pocFileAll: pocData.data});
        }
    };

    /**
     * 查询文件
     */
    searchFile = async (num) => {
        const {
            form: {validateFields}
        } = this.props;
        validateFields(async (err, values) => {
            const param = {};
            $.each(values, (key, value) => {
                if (key !== 'time') {
                    param[key] = value;
                }
                param.startTime = this.state.timeRange[0];
                param.endTime = this.state.timeRange[1];
                param.pageNum = 1;
                param.fileType = this.state.fileType;
                param.corpId = sessionStorage.getItem('corpId');
                // param.orgCode = sessionStorage.getItem('orgCode')
            });
            if (num === '2') {
                param.pageSize = 15;
            }
            if (num === '1') {
                param.pageSize = 10;
            }

            const data = await doc_api.queryFile(param);
            if (!data.code) {
                this.props.setFileDataAction({fileData: data.data.list});
                this.props.setFileDataAllAction({fileDataAll: data.data});
                this.setState({
                    current: 1,
                    startTime: param.startTime,
                    endTime: param.endTime,
                    memName: values.memName,
                    deptId: values.deptId,
                    devNo: values.devNo,
                    fileLevel: values.fileLevel,
                    gzzXh: values.gzzXh
                });
                // $('.ant-empty-description').text('暂无数据')
            }

        });
    };

    /**
     * 获取操作员配置
     */
    getAccountConfig = () => {
        const param = {
            accId: sessionStorage.getItem('accId')
        };
        scooper_poc_server.getAccountConfig(param).then(res => {
            if (!res.code && res.data) {
                this.setState({
                    pageAuthority: res.data.pageAuthority,
                    fileOptions: res.data.fileOptions
                });
            }
        });

    };

    /**
     * 获取是否开启流量控制功能
     */
    getConfig = async () => {
        const res = await sys_api.getSysConfig({cfgKey: 'scooper.poc.flow'});
        if (!res.code && res.data.cfgValue === '1') {
            this.setState({
                isFlow: true
            });
        }
    };

    downLoad = () => {
        const selectedRows = this.props.selectedRows;
        let _url = '';
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            //ie下
            const iframeArr = [];

            selectedRows.length === 1 && selectedRows.forEach((item, index) => {
                selectedRows && selectedRows.forEach((item, index) => {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                    }
                    var url = _url;
                    var iframe = $('<iframe>');
                    iframe.hide();
                    iframe.css('height', '0');
                    iframe.attr('src', url);
                    iframe.append('</iframe>');
                    $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
                });
            });
            selectedRows.length > 1 && selectedRows.forEach((item, index) => {
                setTimeout(function () {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                    }
                    const url = _url;
                    iframeArr[index] = $('<iframe>');
                    iframeArr[index].attr('id', 'selectedFrame' + index).attr('name', 'selectedFrameName' + index);
                    iframeArr[index].hide();
                    iframeArr[index].css('height', '0');
                    iframeArr[index].attr('src', url);
                    iframeArr[index].append('</iframe>');
                    $('body').append(iframeArr[index]); // 这一行必须，iframe挂在到dom树上才会发请求
                }, index * `${window.config.time}`);
            });
        } else {
            selectedRows && selectedRows.map((item, index) => {
                //chrome  
                if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                    message.error('文件不存在！');
                    return;
                }
                if (item.fileMoveStatus === 2) {
                    _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                } else {
                    _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                }
                const url = _url;
                const iframe = $('<iframe>');
                iframe.hide();
                iframe.css('height', '0');
                iframe.attr('src', url);
                iframe.append('</iframe>');
                $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            });
        }

        this.props.setSelectAction({selectedRows: []});
        this.props.setSelectKeyAction({selectedRowKeys: []});
    };

    /**
     * 批量下载文件(列表)
     */
    fileDownloadBatch = async () => {
        const {isFlow} = this.state;
        const selectedRows = this.props.selectedRows;
        if (selectedRows.length === 0) {
            message.error('请勾选要下载的文件！');
            return;
        }
        if (isFlow) {
            const resp = await queryflow_api.queryCorpFlowState();
            if (!resp.data.obj) {
                message.error(resp.message || '流量已达上限');
                return;
            } else {
                if (resp.message) {
                    message.warning(resp.message || '流量即将达到上限');
                }
            }
            this.downLoad();
        } else {
            this.downLoad();
        }
    };

    /**
     * 批量下载文件(图片)
     */
    fileDownloadBatchPit = async () => {
        const {isFlow} = this.state;
        this.setState({
            isClick: true
        });
        const {selectedValue} = this.props;

        if (selectedValue.indexOf(undefined) > -1) {
            return;
        }
        if (selectedValue.length === 0) {
            message.error('请勾选要下载的文件！');
            return;
        }
        if (isFlow) {
            const resp = await queryflow_api.queryCorpFlowState();

            if (!resp.data.obj) {
                message.error(resp.message || '流量已达上限');
                return;
            } else {
                if (resp.message) {
                    message.warning(resp.message || '流量即将达到上限');
                }
            }
        }

        let _url = '';
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            //ie下
            const iframeArr = [];
            selectedValue.length = 1 && selectedValue.forEach((item, index) => {
                selectedRows && selectedRows.forEach((item, index) => {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                    }
                    var url = _url;
                    var iframe = $('<iframe>');
                    iframe.hide();
                    iframe.css('height', '0');
                    iframe.attr('src', url);
                    iframe.append('</iframe>');
                    $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
                });
            });
            selectedValue.length > 1 && selectedValue.forEach((item, index) => {
                setTimeout(function () {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                    }
                    var url = _url;
                    iframeArr[index] = $('<iframe>');
                    iframeArr[index].attr('id', 'selectedFrame' + index).attr('name', 'selectedFrameName' + index);
                    iframeArr[index].hide();
                    iframeArr[index].css('height', '0');
                    iframeArr[index].attr('src', url);
                    iframeArr[index].append('</iframe>');
                    $('body').append(iframeArr[index]); // 这一行必须，iframe挂在到dom树上才会发请求
                }, index * `${window.config.time}`);

            });
        } else {
            selectedValue && selectedValue.forEach((item, index) => {
                //chrome  
                if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                    message.error('文件不存在！');
                    return;
                }
                if (item.fileMoveStatus === 2) {
                    _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${item.id}`;
                } else {
                    _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}&id=${item.id}`;
                }
                var url = _url;
                var iframe = $('<iframe>');
                iframe.hide();
                iframe.css('height', '0');
                iframe.attr('src', url);
                iframe.append('</iframe>');
                $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            });
        }
        // this.props.selectedValue = [];  
        this.queryFileAll(this.state.fileType, this.state.currentIndex);
        const arr = [];
        this.props.setSelectedValueAction({selectedValue: arr});

    };

    /**
     * 批量删除文件(列表)
     */
    fileDeleteBatch = async () => {
        const selectedRows = this.props.selectedRows;
        if (selectedRows.length === 0) {
            message.error('请勾选要删除的文件！');
            return;
        }
        confirm({
            title: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                const id = [];
                selectedRows && selectedRows.map((item) => {
                    id.push(item.id);
                });

                const _id = id.join(',');
                const data = await doc_api.fileDeleteBatch({ids: _id});
                if (!data.code) {
                    message.success('删除成功');
                    this.updateSelectedKeys(this.props.selectedRows);
                    this.props.setSelectAction({selectedRows: []});
                    const param = {
                        token: sessionStorage.token,
                        // pageNum: this.props.fileDataAll.pageNum,
                        pageSize: 10,
                        fileType: this.state.fileType,
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        memName: this.state.memName,
                        deptId: this.state.deptId,
                        devNo: this.state.devNo,
                        fileLevel: this.state.fileLevel,
                        // orgCode: sessionStorage.getItem('orgCode'),
                        corpId: sessionStorage.getItem('corpId')
                    };
                    param.pageNum = this.props.fileDataAll.pageNum;
                    const data = await doc_api.queryFile(param);
                    if (!data.code) {
                        if (data.data.list.length === 0) {
                            param.pageNum = data.data.lastPage;
                            const resp = await doc_api.queryFile(param);
                            this.props.setFileDataAction({fileData: resp.data.list});
                            this.props.setFileDataAllAction({fileDataAll: resp.data});
                        } else {
                            this.props.setFileDataAction({fileData: data.data.list});
                            this.props.setFileDataAllAction({fileDataAll: data.data});
                        }
                    }
                }else{
                    message.warning(data.message);
                }
            }
        });

    };

    /**
     * 批量删除文件（图片）
     */
    fileDeleteBatchPit = async () => {
        const selectedValue = this.props.selectedValue;
        if (selectedValue.length === 0) {
            message.error('请勾选要删除的文件！');
            return;
        }
        confirm({
            title: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                const id = [];
                selectedValue && selectedValue.map((item) => {
                    id.push(item.id);
                });
                const _id = id.join(',');
                const data = await doc_api.fileDeleteBatch({ids: _id});
                if (!data.code) {
                    message.success('删除成功');
                    this.updateSelectedKeys(this.props.selectedValue);
                    this.props.setSelectedValueAction({selectedValue: []});
                    const param = {
                        token: sessionStorage.token,
                        pageNum: 1,
                        pageSize: 15,
                        fileType: this.state.fileType,
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        memName: this.state.memName,
                        deptId: this.state.deptId,
                        devNo: this.state.devNo,
                        fileLevel: this.state.fileLevel,
                        // orgCode: sessionStorage.getItem('orgCode'),
                        corpId: sessionStorage.getItem('corpId')
                    };
                    param.pageNum = this.props.fileDataAll.pageNum;
                    const data = await doc_api.queryFile(param);
                    if (!data.code) {
                        if (data.data.list.length == 0) {
                            param.pageNum = data.data.lastPage;
                            const resp = await log_api.getOplogList(param);
                            this.props.setFileDataAction({fileData: resp.data.list});
                            this.props.setFileDataAllAction({fileDataAll: resp.data});
                        } else {
                            this.props.setFileDataAction({fileData: data.data.list});
                            this.props.setFileDataAllAction({fileDataAll: data.data});
                        }
                    }
                }
            }
        });


    };

    /**
     * 批量下载对讲记录
     */
    pocFileDownload = async () => {
        const selectedPocRows = this.props.selectedPocRows;
        if (selectedPocRows.length === 0) {
            message.error('请勾选要下载的文件！');
            return;
        }
        let _url = '';
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            //ie下
            const iframeArr = [];
            selectedPocRows.length === 1 && selectedPocRows.forEach((item, index) => {
                selectedPocRows && selectedPocRows.forEach((item, index) => {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}`;
                    }
                    var url = _url;
                    var iframe = $('<iframe>');
                    iframe.hide();
                    iframe.css('height', '0');
                    iframe.attr('src', url);
                    iframe.append('</iframe>');
                    $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
                });
            });
            selectedPocRows.length > 1 && selectedPocRows.forEach((item, index) => {
                setTimeout(function () {
                    if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                        message.error('文件不存在！');
                        return;
                    }
                    if (item.fileMoveStatus === 2) {
                        _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}`;
                    } else {
                        _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}`;
                    }
                    var url = _url;
                    iframeArr[index] = $('<iframe>');
                    iframeArr[index].attr('id', 'selectedFrame' + index).attr('name', 'selectedFrameName' + index);
                    iframeArr[index].hide();
                    iframeArr[index].css('height', '0');
                    iframeArr[index].attr('src', url);
                    iframeArr[index].append('</iframe>');
                    $('body').append(iframeArr[index]); // 这一行必须，iframe挂在到dom树上才会发请求
                }, index * `${window.config.time}`);

            });
        } else {
            selectedPocRows && selectedPocRows.map((item, index) => {
                //chrome  
                if (item.fileMoveStatus === 2 && item.cloudFilePath === '') {
                    message.error('文件不存在！');
                    return;
                }
                if (item.fileMoveStatus === 2) {
                    _url = `${item.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}`;
                } else {
                    _url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${item.fileName}&filePath=${item.filePath}`;
                }
                var url = _url;
                var iframe = $('<iframe>');
                iframe.hide();
                iframe.css('height', '0');
                iframe.attr('src', url);
                iframe.append('</iframe>');
                $('body').append(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            });
        }
        this.props.setSelectPocAction({selectedPocRows: []});
        this.props.setSelectPocKeyAction({selectedPocRowKeys: []});
    };

    /**
     * 批量删除对讲记录
     */
    pocFileDelete = async () => {
        let _this = this;
        const selectedPocRows = this.props.selectedPocRows;
        if (selectedPocRows.length === 0) {
            message.error('请勾选要删除的文件！');
            return;
        }
        confirm({
            title: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                var id = [];
                selectedPocRows && selectedPocRows.map((item) => {
                    id.push(item.id);
                });
                const _id = id.join(',');
                const data = await doc_api.deleteBatchPocFile({ids: _id});
                if (!data.code) {
                    message.success('删除成功');
                    _this.updateSelectedKeysPoc(_this.props.selectedPocRows);
                    this.props.setSelectAction({selectedPocRows: []});
                    var param = {};
                    if (_this.state.pocParam) {
                        param = _this.state.pocParam;
                    } else {
                        param.token = sessionStorage.getItem('token'),
                            param.corpId = sessionStorage.getItem('corpId'),
                            param.pageSize = 10;
                    }
                    param.pageNum = _this.props.pocFileAll.pageNum;
                    const data = await doc_api.getPocFile(param);
                    if (!data.code) {
                        if (data.data.list.length == 0) {
                            param.pageNum = data.data.lastPage;
                            const resp = await doc_api.getPocFile(param);
                            this.props.setPocFileAction({pocFile: resp.data.list});
                            this.props.setPocFileAllAction({pocFileAll: resp.data});
                        } else {
                            this.props.setPocFileAction({pocFile: data.data.list});
                            this.props.setPocFileAllAction({pocFileAll: data.data});
                        }
                    }
                }
            }
        });
    };

    /**
     * 清空残留复选框
     */
    updateSelectedKeys = (rows) => {
        var selectKeys = this.props.selectedRowKeys;
        for (let i = 0; i < rows.length; i++) {
            selectKeys.splice(selectKeys.indexOf(rows.sequence), 1);
        }
    };

    updateSelectedKeysPoc = (rows) => {
        var selectKeys = this.props.selectedPocRowKeys;
        for (let i = 0; i < rows.length; i++) {
            selectKeys.splice(selectKeys.indexOf(rows.sequence), 1);
        }
    };

    /**
     * 查询文件
     */
    queryFileAll = async (fileType, index) => {
        this.setState({
            fileType: fileType,
            currentIndex: index
        });
        let pageSize = '';
        if (this.state.showView === '1') {
            pageSize = 10;
        } else if (this.state.showView === '2') {
            pageSize = 15;
        }
        const param = {
            token: sessionStorage.token,
            fileType: fileType,
            pageNum: 1,
            pageSize: pageSize,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            memName: this.state.memName,
            deptId: this.state.deptId,
            devNo: this.state.devNo,
            gzzXh: this.state.gzzXh,
            fileLevel: this.state.fileLevel,
            corpId: sessionStorage.getItem('corpId')
            // orgCode: sessionStorage.getItem('orgCode')
        };
        const data = await doc_api.queryFile(param);
        if (!data.code) {
            this.props.setFileDataAction({fileData: data.data.list});
            this.props.setFileDataAllAction({fileDataAll: data.data});
            this.props.setSelectedValueAction({selectedValue: []});
        }
        const arr = [];
        this.props.setSelectedValueAction({selectedValue: arr});
    };


    /**
     * 加载部门数据
     */
    getDeptData = async () => {
        const that = this;
        const param = {
            // orgCode: sessionStorage.getItem('orgCode'),
            token: sessionStorage.getItem('token')
        };
        $.ajax({
            url: `${window.config.modulesUrl}/scooper-poc-server/api/login/listDeptByOrgCode`,
            data: param,
            success: function (data) {

                if (!data.code) {
                    that.props.setDeptMemAction({deptMem: data.data.list});
                }
            }
        });

    };

    /**
     * 搜索对讲记录
     */
    searchPoc = async () => {
        const {
            form: {validateFields}
        } = this.props;
        validateFields(async (err, values) => {
            const param = {};
            $.each(values, (key, value) => {
                if (key !== 'time' && key !== 'mainName') {
                    param[key] = value;
                }
                param.startTime = this.state.timeRangePoc[0];
                param.endTime = this.state.timeRangePoc[1];
                param.lengthType = values.lengthType;
                param.corpId = sessionStorage.getItem('corpId');
                param.memName = values.mainName;
                // param.orgCode = sessionStorage.getItem('orgCode')
            });
            const pocData = await doc_api.getPocFile(param);
            if (!pocData.code) {
                this.props.setPocFileAction({pocFile: pocData.data.list});
                this.props.setPocFileAllAction({pocFileAll: pocData.data});
                this.setState({
                    pocParam: param
                });
            }
        });

    };

    /**
     * 重置回传记录
     */
    reset = () => {
        this.props.form.resetFields();
        this.setState({
            timeRange: ['', ''],
            timeRangePoc: ['', ''],
            currentIndex: 1,
            fileType: 10,
            memName: '',
            deptId: '',
            devNo: '',
            gzzXh: '',
            startTime: '',
            endTime: ''
        }, () => {
            this.CascaderClear();
        });
        if (this.state.showView == 1) {
            this.loadTable(10);
        } else if (this.state.showView == 2) {
            this.loadTable(15);
        }
    };

    /**
     * 重置对讲记录
     */
    resetPoc = () => {
        this.props.form.resetFields();
        const param = {
            groupName: '',
            memName: '',
            lengthType: '',
            tmLength: '',
            corpId: sessionStorage.getItem('corpId'),
            orgCode: sessionStorage.getItem('orgCode'),
            token: sessionStorage.getItem('token')
        };
        this.setState({
            timeRange: ['', ''],
            timeRangePoc: ['', ''],
            currentIndex: 1,
            pocParam: param,
            numVal: ''
        }, () => {
            this.CascaderClear();
        });
        this.loadTalkBackTable();
    };

    /**
     * 返回首页
     */
    goHome = () => {
        // window.history.go(-1);
        this.props.history.push('/home');
    };

    onPgeChange = (pagination) => {
    };

    changeTable = (index, type) => {
        this.setState({
            curIndex: index
        });
    };

    /**
     * 对讲记录时长
     */
    changeNumVal = value => {
        this.setState({
            numVal: value
        });
    };

    /**
     * 回车事件
     */
    keypressFile = e => {
        if (e.keyCode == 13) {
            this.searchFile(this.state.showView);
        }
    };

    /**
     * @description 查询执法类型列表
     */
    listFileLawType = async () => {
        scooper_poc_server.listFileLawType().then(res => {
            this.setState({
                lawTypeList: res.data
            });
        });
    };

    /**
     * 设置未来时间不可选
     */
    disabledDate = current => current && current > moment().endOf('day');

    render() {
        const {getFieldDecorator} = this.props.form;
        const {curIndex, title, pageAuthority, isFlow, fileOptions, lawTypeList, showView} = this.state;
        const lawTypeOptions = lawTypeList && lawTypeList.map(item => (
            <Option key={item.id} value={item.id} title={item.typeName}>{item.typeName}</Option>));
        return (
            <div className="box">
                <ManageHeader goHome={this.goHome} title={title} this={this.props}/>
                <div className="content-main1">
                    <React.Fragment>
                        <div className="record-type">
                            <Button className={`return-record ${1 === curIndex ? 'checked' : null}`}
                                    onClick={() => this.changeTable(1, 'return')}>回传记录</Button>
                            <Button className={`talkback-record ${2 === curIndex ? 'checked' : null}`}
                                    onClick={() => this.changeTable(2, 'talkback')}>对讲记录</Button>
                        </div>

                        {curIndex === 1 && <div className="content-top">
                            <Row>
                                <Col span={19}>
                                    <Form onSubmit={this.handleSubmit} className="form">
                                        <Form.Item>
                                            <span className="time-ranger  key-words">时间</span>
                                            {getFieldDecorator('time', {
                                                initialValue: ''
                                            })(
                                                <ConfigProvider locale={locale}>
                                                    <RangePicker
                                                        locale={locale}
                                                        showTime={{format: 'HH:mm:ss'}}
                                                        format="YYYY-MM-DD HH:mm:ss"
                                                        placeholder={['开始时间', '结束时间']}
                                                        onChange={this.dateOnChange}
                                                        disabledDate={this.disabledDate}

                                                    />
                                                </ConfigProvider>
                                            )}

                                        </Form.Item>

                                        <Form.Item>
                                            <span className="key-words first-word"> 终端编号 </span>
                                            {getFieldDecorator('devNo', {
                                                initialValue: ''
                                            })(
                                                <Input placeholder="请输入终端编号" style={{width: '8rem'}}
                                                       onChange={this.callerChange}
                                                       onPressEnter={() => this.searchFile(this.state.showView)}/>
                                            )}

                                        </Form.Item>
                                        {/* <Form.Item>
                            <span className="key-words gzzXh-member"> 采集站编号 </span>
                            {getFieldDecorator('gzzXh',{
                                    initialValue:''
                                })(
                                    <Input placeholder="请输入采集站编号" style={{ width: '8rem' }} onChange={this.callerChange} onPressEnter={()=>this.searchFile(this.state.showView)} />
                                )}
                           
                        </Form.Item> */}
                                        <Form.Item>
                                            <span className="key-words name"> 姓名 </span>
                                            {getFieldDecorator('memName', {
                                                initialValue: ''
                                            })(
                                                <Input placeholder="请输入姓名" style={{width: '8rem'}}
                                                       onChange={this.calledChange}
                                                       onPressEnter={() => this.searchFile(this.state.showView)}/>
                                            )}

                                        </Form.Item>

                                        <Form.Item>
                                            <span className="key-words dept"> 部门 </span>
                                            {getFieldDecorator('deptId', {
                                                initialValue: ''
                                            })(
                                                <Select dropdownClassName="dept-xiala" style={{width: '8rem'}}>
                                                    {
                                                        this.props.deptMem && this.props.deptMem.map(item => {
                                                            return (
                                                                <Option value={item.deptId} key={item.deptId}
                                                                        title={item.deptName}>
                                                                    {item.deptName}
                                                                </Option>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item>
                                            <span className="key-words last-word"> 等级 </span>
                                            {getFieldDecorator('fileLevel', {
                                                initialValue: '10'
                                            })(
                                                <Select onChange={this.handleChange} dropdownClassName="xiala"
                                                        style={{width: '8rem'}}>
                                                    <Option value="10">所有</Option>
                                                    <Option value="0">普通</Option>
                                                    <Option value="1">重要</Option>
                                                    <Option value="3">其他</Option>
                                                </Select>
                                            )}

                                        </Form.Item>
                                        <Form.Item>
                                            <span className="key-words law-type"> 执法类型 </span>
                                            {getFieldDecorator('lawType', {
                                                initialValue: ''
                                            })(
                                                <Select onChange={this.handleChange} dropdownClassName="xiala"
                                                        style={{width: '8rem'}}>
                                                    {lawTypeOptions}
                                                </Select>
                                            )}

                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={5}>
                                    <div className="btn-group">
                                        <button onClick={() => this.searchFile(this.state.showView)}
                                                className="ant-btn">搜索
                                        </button>
                                        <button onClick={this.reset} className="ant-btn">重置</button>
                                    </div>
                                    <Menu onClick={this.showView} defaultSelectedKeys={['1']}>
                                        <MenuItem key="1" onClick={() => this.searchFile(1)}>
                                            列表
                                        </MenuItem>
                                        <MenuItem key="2" onClick={() => this.searchFile(2)}>
                                            平铺
                                        </MenuItem>
                                    </Menu>
                                </Col>
                            </Row>
                        </div>}

                        {curIndex === 2 && <div className="content-top-talkback">
                            <Form onSubmit={this.handleSubmit} className="form">
                                <Form.Item>
                                    <span className="time-ranger  key-words">时间</span>
                                    {getFieldDecorator('timerange', {
                                        initialValue: ''
                                    })(
                                        <ConfigProvider locale={locale}>
                                            <RangePicker
                                                locale={locale}
                                                showTime={{format: 'HH:mm:ss'}}
                                                format="YYYY-MM-DD HH:mm:ss"
                                                placeholder={['开始时间', '结束时间']}
                                                onChange={this.dateOnChangePoc}
                                                disabledDate={this.disabledDate}
                                            />
                                        </ConfigProvider>
                                    )}

                                </Form.Item>
                                <Form.Item>
                                    <span className="key-words first-word"> 通话组 </span>
                                    {getFieldDecorator('groupName', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder="请输入通话组" style={{width: '8rem'}} onChange={this.callerChange}
                                               onPressEnter={this.searchPoc}/>
                                    )}

                                </Form.Item>
                                <Form.Item>
                                    <span className="key-words gzzXh-member"> 话权人 </span>
                                    {getFieldDecorator('mainName', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder="请输入话权人" style={{width: '8rem'}} onChange={this.callerChange}
                                               onPressEnter={this.searchPoc}/>
                                    )}

                                </Form.Item>
                                <Form.Item>
                                    <span className="key-words last-word"> 时长 </span>
                                    {getFieldDecorator('lengthType', {
                                        initialValue: '1'
                                    })(
                                        <Select onChange={this.handleChange} dropdownClassName="xiala"
                                                style={{width: '6rem'}}>
                                            <Option value="1">大于</Option>
                                            <Option value="2">小于</Option>
                                            <Option value="3">等于</Option>
                                        </Select>
                                    )}

                                </Form.Item>
                                <Form.Item className="time-length">
                                    {getFieldDecorator('tmLength', {
                                        initialValue: this.state.numVal
                                    })(
                                        <React.Fragment>
                                            <InputNumber className="input-val" min={0} value={this.state.numVal}
                                                         onChange={this.changeNumVal} onPressEnter={this.searchPoc}/>
                                            <span className="danwei">秒</span>
                                        </React.Fragment>
                                    )}

                                </Form.Item>
                            </Form>
                            <div className="btn-group">
                                <Button onClick={this.searchPoc}>搜索</Button>
                                <Button onClick={this.resetPoc}>重置</Button>
                            </div>
                        </div>}

                        <div className="divider"/>
                        <div className="btn-group-table">
                            {curIndex === 1 &&
                            <div className="btn-left">
                                <a onClick={() => this.queryFileAll(10, 1)}
                                   className={`all ${1 === this.state.currentIndex ? 'active' : null}`}>全部</a>
                                <a onClick={() => this.queryFileAll(1, 2)}
                                   className={`${2 === this.state.currentIndex ? 'active' : null}`}>视频</a>
                                <a onClick={() => this.queryFileAll(2, 3)}
                                   className={`${3 === this.state.currentIndex ? 'active' : null}`}>音频</a>
                                <a onClick={() => this.queryFileAll(0, 4)}
                                   className={`${4 === this.state.currentIndex ? 'active' : null}`}>图片</a>
                                <a className={`yysp ${5 === this.state.currentIndex ? 'active' : null}`}
                                   onClick={() => this.queryFileAll(-2, 5)}>云眼录像</a>
                            </div>}
                            {showView === '1' && <div className="btn-right">
                                <a className="download-btn" onClick={this.fileDownloadBatch}
                                   download={this.state.fileName}>
                                    下载
                                </a>
                                <a className="delete-btn" onClick={this.fileDeleteBatch}>删除</a>
                            </div>}
                            {showView === '2' && <div className="btn-right">
                                <a className="download-btn" onClick={this.fileDownloadBatchPit}
                                   download={this.state.fileName}>
                                    下载
                                </a>
                                <a className="delete-btn"
                                   onClick={() => this.fileDeleteBatchPit(this.state.fileType)}>删除</a>
                            </div>}
                            {curIndex === 2 && <div className="btn-right">
                                <a className="download-btn" onClick={() => this.pocFileDownload()}>下载</a>
                                <a className="delete-btn" onClick={() => this.pocFileDelete()}>删除</a>
                            </div>}
                        </div>
                        <ConfigProvider locale={zhCN}>
                            {curIndex === 1 && <div className="content">
                                {showView === '1' && <MyTable loadTable={this.loadTable} fileType={this.state.fileType}
                                                              startTime={this.state.startTime}
                                                              endTime={this.state.endTime} memName={this.state.memName}
                                                              deptId={this.state.deptId} devNo={this.state.devNo}
                                                              fileLevel={this.state.fileLevel}
                                                              current={this.state.current} pageNum={this.state.pageNum}
                                                              onChange={this.onPgeChange} gzzXh={this.state.gzzXh}
                                                              pageAuthority={pageAuthority} isFlow={isFlow}
                                                              fileOptions={fileOptions}
                                                              listFileLawType={this.listFileLawType} showView={showView}
                                />}
                                {showView === '1' && this.props.fileData.length === 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="pit-empty-two"
                                       description="暂无数据"/>
                                }
                                {showView === '2' && this.props.fileData.length === 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="pit-empty" description="暂无数据"/>
                                }
                                {showView === '2' && <PitView loadTable={this.loadTable} fileType={this.state.fileType}
                                                              startTime={this.state.startTime}
                                                              endTime={this.state.endTime} memName={this.state.memName}
                                                              deptId={this.state.deptId}
                                                              devNo={this.state.devNo} fileLevel={this.state.fileLevel}
                                                              isClick={this.state.isClick} selList={this.state.selList}
                                                              gzzXh={this.state.gzzXh} isFlow={isFlow}
                                                              fileOptions={fileOptions}
                                                              listFileLawType={this.listFileLawType} showView={showView}
                                />}
                            </div>}
                        </ConfigProvider>
                        {curIndex === 2 &&
                        <ConfigProvider locale={zhCN}>
                            <div className="content">
                                <TalkbackTable pocParam={this.state.pocParam}/>
                                {this.props.pocFile.length === 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="pit-empty-two"
                                       description="暂无数据"/>
                                }
                            </div>
                        </ConfigProvider>
                        }
                    </React.Fragment>
                </div>
            </div>
        );

    }
});
export default Form.create()(DocManager);