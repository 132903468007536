/*
 * @Description: 操作日志
 * @Author: yanghui
 * @Date: 2019-10-16 10:19:19
 * @LastEditTime: 2020-12-23 10:25:31
 * @LastEditors: yanghui
 */

import React from 'react';
import {
    Input,
    Form,
    Modal,
    message,
    DatePicker,
    Cascader,
    LocaleProvider
} from 'antd';
import {connect} from 'react-redux';
import ManageHeader from '../../components/manage-header';
import LogTable from '../../components/log-table';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {log_api, sys_api} from '@/rest-api/restapi';
import {
    setSortListAction,
    setSortListAllAction,
    setSelectedLogAction,
    setSelectedKeyLogAction
} from '@/reducer/video_action';
import '../../../less/operate-log.less';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';

const {RangePicker} = DatePicker;
const {confirm} = Modal;

const Log = connect(
    state => {
        return {
            selectedRowsLog: state.selectedRowsLog,
            selectedKey: state.selectedKey,
            sortListAll: state.sortListAll
        };
    },
    {
        setSortListAction,
        setSelectedLogAction,
        setSelectedKeyLogAction,
        setSortListAllAction
    }
)(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                sortList: [],
                sortListAll: {},
                logTypeList: [],
                logSortList: [],
                timeRange: ['', '']
            };
        }

        dateOnChange = (date, dateString) => {
            this.setState({
                timeRange: dateString
            });
        };

        CascaderClear = () => {
            let closeBtn = document.getElementsByClassName(
                'ant-cascader-picker-clear'
            )[0];
            closeBtn && closeBtn.click();
            let closeBtn2 = document.getElementsByClassName(
                'ant-calendar-picker-clear'
            )[0];
            closeBtn2 && closeBtn2.click();
        };


        componentWillMount() {
            if (document.location.href.indexOf('token') > -1) {
                var arr = document.location.href.split('?');
                var _arr = arr[1].split('&');
                var token = '';
                _arr.map(item => {
                    if (item.indexOf('token') > -1) {
                        token = item.split('=')[1];
                        sessionStorage.setItem('token', token);
                        localStorage.setItem('token', token);
                    }
                });
            }
        }

        /**
         * 重置
         */
        reset = () => {
            this.props.form.resetFields();
            this.setState(
                {
                    timeRange: ['', ''],
                    typeId: '',
                    categoryId: '',
                    accUserName: ''
                },
                () => {
                    this.CascaderClear();
                }
            );
            this.getOplogList();
        };

        /**
         * 获取页面标题
         */
        getTitle = async () => {
            var param = {
                cfgKey: 'scooper.poc.oplog.title'
            };
            const data = await sys_api.getSysConfig(param);
            if (!data.code) {
                this.setState({
                    title: data.data.cfgValue
                });
            }
        };

        componentDidMount() {
            this.getOplogSort();
            this.getOplogList();
            this.getTitle();
            if (!sessionStorage.getItem('token')) {
                message.error('请先登录！');
                setTimeout(() => this.props.history.push(`/`), 1000);
            }
            $('.ant-empty-description').text('暂无数据');
            // document.addEventListener("keydown",this.keypressLog);
        }

        /**
         * 获取操作类别
         */
        getOplogSort = async () => {
            const data = await log_api.getOplogSort();
            if (!data.code) {
                this.setState(
                    {
                        logSortList: data.data.list
                    },
                    () => {
                        this.state.logSortList.map(item => {
                            item.oplogTypePos.map(item2 => {
                                item2.categoryName = item2.typeName;
                                item2.categoryName = item2.typeName;
                            });
                        });
                    }
                );
            }
        };

        /**
         * 获取日志列表
         */
        getOplogList = async () => {
            var param = {
                token: sessionStorage.getItem('token'),
                corpId: sessionStorage.getItem('corpId'),
                // orgCode: sessionStorage.getItem('orgCode'),
                pageNum: 1,
                pageSize: 10
            };
            const data = await log_api.getOplogList(param);
            if (!data.code) {
                this.props.setSortListAction({sortList: data.data.list});
                this.props.setSortListAllAction({sortListAll: data.data});
            }
        };

        /**
         * 搜索操作日志
         */
        searchLog = async () => {
            const {
                form: {validateFields}
            } = this.props;
            validateFields(async (err, values) => {
                var param = {};
                $.each(values, (key, value) => {
                    if (key !== 'time' && key !== 'categoryId') {
                        param[key] = value;
                    }
                    param.startTime = this.state.timeRange[0];
                    param.endTime = this.state.timeRange[1];
                    param.typeId = this.state.typeId;
                    param.categoryId = this.state.categoryId;
                    param.corpId = sessionStorage.getItem('corpId');
                    // param.orgCode = sessionStorage.getItem('orgCode');
                });
                const data = await log_api.getOplogList(param);
                if (!data.code) {
                    this.props.setSortListAction({sortList: data.data.list});
                    this.props.setSortListAllAction({sortListAll: data.data});
                    this.setState(
                        {
                            startTime: param.startTime,
                            endTime: param.endTime,
                            typeId: param.typeId,
                            categoryId: param.categoryId,
                            accShowName: values.accShowName,
                            accUserName: values.accUserName
                        },
                        () => {
                            console.log(this.state.startTime);
                        }
                    );
                    $('.ant-empty-description').text('暂无数据');
                }
            });
        };

        /**
         * 返回首页
         */
        goHome = () => {
            this.props.history.push('/home');
        };

        /**
         * 数据处理
         */
        getLogData = (value, option) => {
            if (option.length == 0) {
                this.setState({
                    categoryId: '',
                    typeId: ''
                });
            }
            if (option && option.length == 1) {
                this.setState({
                    categoryId: option[0].id,
                    typeId: ''
                });
            }
            if (option && option.length == 2) {
                this.setState({
                    typeId: option[1].id
                });
            }
        };

        /**
         * 批量删除操作日志
         */
        deleteBatchLog = async () => {
            const _this = this;
            const selectedRowsLog = this.props.selectedRowsLog;
            if (selectedRowsLog.length === 0) {
                message.error('请勾选要删除的文件！');
                return;
            }

            confirm({
                title: '确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: async () => {
                    var id = [];
                    selectedRowsLog &&
                    selectedRowsLog.map(item => {
                        id.push(item.id);
                    });

                    const _id = id.join(',');
                    const data = await log_api.deleteLog({ids: _id});
                    if (!data.code) {
                        message.success('批量删除成功！');
                        _this.props.setSelectedLogAction({selectedRowsLog: []});
                        this.updateSelectedKeys(selectedRowsLog);
                        var param = {
                            token: sessionStorage.getItem('token'),
                            corpId: sessionStorage.getItem('corpId'),
                            // orgCode: sessionStorage.getItem('orgCode'),
                            startTime: this.state.startTime,
                            endTime: this.state.endTime,
                            categoryId: this.state.categoryId,
                            typeId: this.state.typeId,
                            accShowName: this.state.accShowName,
                            accUserName: this.state.accUserName
                        };
                        param.pageNum = this.props.sortListAll.pageNum;
                        const res = await log_api.getOplogList(param);
                        if (!res.code) {
                            if (res.data.list.length === 0) {
                                param.pageNum = res.data.lastPage;
                                const resp = await log_api.getOplogList(param);
                                _this.props.setSortListAction({sortList: resp.data.list});
                                _this.props.setSortListAllAction({sortListAll: resp.data});
                            } else {
                                _this.props.setSortListAction({sortList: res.data.list});
                                _this.props.setSortListAllAction({sortListAll: res.data});
                            }
                        }
                    }
                }
            });
        };

        /**
         * 清空残留复选框
         */
        updateSelectedKeys = rows => {
            var selectKeys = this.props.selectedKey;
            for (let i = 0; i < rows.length; i++) {
                selectKeys.splice(selectKeys.indexOf(rows.sequence), 1);
            }
        };

        /**
         * 回车事件
         */
        keypressLog = e => {
            if (e.keyCode == 13) {
                this.searchLog();
            }
        };

        /**
         * 设置未来时间不可选
         */
        disabledDate = current => current && current > moment().endOf('day');

        render() {
            const {getFieldDecorator} = this.props.form;
            return (
                <div className="box">
                    <ManageHeader goHome={this.goHome} title={this.state.title} this={this.props}></ManageHeader>
                    <div className="main-content">
                        <div className="top-content">
                            <Form onSubmit={this.handleSubmit} className="form">
                                <Form.Item>
                                    <span className="time-ranger"> 时间 </span>
                                    {getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <LocaleProvider locale={locale}>
                                            <RangePicker
                                                locale={locale}
                                                showTime={{format: 'HH:mm:ss'}}
                                                format="YYYY-MM-DD HH:mm:ss"
                                                placeholder={['开始时间', '结束时间']}
                                                onChange={this.dateOnChange}
                                                disabledDate={this.disabledDate}
                                            />
                                        </LocaleProvider>
                                    )}
                                </Form.Item>
                                <Form.Item className="aa">
                                    <span className="key-words log"> 日志类型 </span>
                                    {getFieldDecorator('categoryId', {
                                        initialValue: []
                                    })(
                                        <React.Fragment>
                                            <Cascader
                                                onChange={this.getLogData}
                                                options={this.state.logSortList}
                                                fieldNames={{
                                                    label: 'categoryName',
                                                    value: 'categoryName',
                                                    children: 'oplogTypePos'
                                                }}
                                                placeholder=""
                                                changeOnSelect={true}
                                            />
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                                {/* <Form.Item className="search">
                  <span className="key-words name"> 操作员姓名 </span>
                  {getFieldDecorator("accShowName", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item> */}
                                <Form.Item className="search op-number">
                                    <span className="key-words member"> 操作员账号 </span>
                                    {getFieldDecorator('accUserName', {
                                        initialValue: ''
                                    })(<Input onPressEnter={this.searchLog}/>)}
                                </Form.Item>
                            </Form>
                            <div className="btn-group">
                                <button className="search-btn ant-btn" onClick={this.searchLog}>
                                    搜索
                                </button>
                                <button className="reset-btn ant-btn" onClick={this.reset}>
                                    重置
                                </button>
                                <button className="delete-btn ant-btn" onClick={this.deleteBatchLog}>
                                    删除
                                </button>
                            </div>
                        </div>
                        <ConfigProvider locale={zhCN}>
                            <div className="log-table">
                                <LogTable
                                    opLogData={this.state.opLogData}
                                    sortListAll={this.state.sortListAll}
                                    transferVal={this.transferVal}
                                    startTime={this.state.startTime}
                                    endTime={this.state.endTime}
                                    typeId={this.state.typeId}
                                    categoryId={this.state.categoryId}
                                    accShowName={this.state.accShowName}
                                    accUserName={this.state.accUserName}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </div>
            );
        }
    }
);

export default Form.create()(Log);
