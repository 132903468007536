/*
 * @Author: liulian
 * @Date: 2019/9/18 10:50:06 
 * @Last Modified by: 
 * @Last Modified time: 
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from '../../../lib/jquery-vendor';
import {
    setCollectAction,
    setBookNameAction,
    setCollectParentAction,
    setVideoCorAction,
    setIsFlowAction,
    setPlayListAction
} from '@/reducer/video_action';
import {videoPart, dept} from '@/rest-api/restapi-video';
import {queryflow_api} from '@/rest-api/restapi';
import zTree from 'ztree';
import 'ztree/js/jquery.ztree.exhide';
import {Input, Icon, Modal, message, Popconfirm, Form} from 'antd';
import {commonGet, commonPost} from '@/request/common-axios';
import {openCloud} from '../../components/dispatch-cloud';
import Frame from 'react-frame-component';
import 'ztree/css/zTreeStyle/zTreeStyle.css';
import '../../../less/video/ztree-cover.less';
import collecta from '../../../image/video-img/collect.png';
import yuyuan1 from '../../../image/video-img/yunyan.png';

import Pinyin from 'pinyin';

const {Search} = Input;
const {confirm} = Modal;

let list_status = [];
let list_statusPoc = [];

const Collect = connect(
    state => {
        return {
            collectItem: state.collectItem,
            collectTree: state.collectTree,
            collectTreeParent: state.collectTreeParent,
            videoController: state.videoController,
            bookName: state.bookName,
            playList: state.playList,
            isFlow: state.isFlow
        };
    },
    {
        setCollectAction,
        setCollectParentAction,
        setVideoCorAction,
        setBookNameAction,
        setPlayListAction,
        setIsFlowAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookName: '',
            cancelCollect: false,
            editCollect: false,
            delCollectId: -1000,
            editCollectId: -1000,
            deptName: '',
            searchVal: '',
            testNodes: [],
            collectVisible: false,
            collectName: '',
            collectConfirm: false,
            cancelCollectId: -1000 //取消收藏接口需要的ID
        };
    }

    /**
     * 加载收藏夹树数据
     */
    loadCollectTree = async () => {
        const _this = this;
        const isFirst = true;
        let param = {};
        let response = await commonPost(videoPart.loadCollectList, param);
        let data = response.data;
        if (data.code === 0) {
            data.data.list.map((item) => {
                item.isParent = true;
                item.name = item.bookmarkName;
            });
        }
        const setting = {
            view: {
                showTitle: true,
                showLine: false,
                showIcon: false,
                // dblClickExpand: false,
                fontCss: {color: '#666'},
                addHoverDom: addHoverDomHandler,
                removeHoverDom: removeHoverDomHandler
            },
            data: {
                parent: true,
                key: {
                    name: 'name',
                    title: 'name',
                    children: 'bookmarkCatalogs'
                },
                simpleData: {
                    enable: true,
                    idKey: 'id',
                    pIdKey: 'bookmarkId'
                }
            },
            callback: {
                //    onClick:onclick,
                // onAsyncSuccess: zTreeOnAsyncSuccess
                // beforeAsync:beforeAsync,
                // onAsyncSuccess: onAsyncSuccess
                onNodeCreated: getStatus
            }
        };

        function getStatus(event, treeId, treeNode) {
            if (!treeNode.isParent) {
                if (treeNode.status) {
                    const tIdNode = `#${treeNode.tId}_a`;
                    switch (treeNode.status) {
                        case 0: // 离线
                        case 'offline': // 离线
                            $(tIdNode).addClass('offline');
                            break;
                        case 1: // 在线
                        case 'idle': // 在线
                        case 'working': // 在线
                            $(tIdNode).addClass('idle');
                            //初始化树时检测到播放列表，给正在播放的视频添加图标常亮按钮
                            _this.props.playList && _this.props.playList.length > 0 && _this.props.playList.map(val => {
                                if (val && val.video === treeNode.devId) {
                                    const aObj = $('.level' + treeNode.level).find(tIdNode);
                                    const _nodeView = '<i class="bofang" id="bofang"><i/>';
                                    aObj.append(_nodeView);
                                }
                            });
                            break;
                        case 2: // 未知
                            break;
                        default:
                            break;
                    }
                }
                /*if (treeNode.status && treeNode.status === 0) { // 离线
                    $('#' + treeNode.tId + '_a').addClass('offline');
                } else {
                    let memTel = treeNode.devId;
                    if (list_statusPoc[memTel] && (list_statusPoc[memTel].status == 'idle' || list_statusPoc[memTel].status == 'working')) {
                        $('#' + treeNode.tId + '_a').addClass('idle');
                    }
                    if (list_statusPoc[memTel] && list_statusPoc[memTel].status == 'offline') {
                        $('#' + treeNode.tId + '_a').addClass('offline');
                    }
                    //初始化树时检测到播放列表，给正在播放的视频添加图标常亮按钮
                    _this.props.playList && _this.props.playList.length > 0 && _this.props.playList.map(val => {
                        if (val.video == memTel) {
                            var aObj = $('.level' + treeNode.level).find('#' + treeNode.tId + '_a');
                            var _nodeView = '<i class="bofang" id="bofang"><i/>';
                            aObj.append(_nodeView);
                        }
                    });
                }*/
            } else {
                $('#' + treeNode.tId + '_a').addClass('dept-name');
            }
        }

        function dataReturn(treeId, parentNode, data) {
            data.list.map((item) => {
                if (item.parentId === 0) {
                    item.isParent = true;
                }
            });
            _this.props.setCollectAction({collectTree: data.list});
            return data.list;
        }

        var selectId = -1;

        // 移到树节点
        function addHoverDomHandler(treeId, treeNode) {
            let html;
            if (selectId === treeNode.idKey) return;
            selectId = treeNode.idKey;
            const delCollectClass = '.del-collect';
            const editCollectClass = '.edit-collect';
            const collectPlayClass = '.collect-play';
            const collectOperateClass = '.collect-operate';
            const treeNodeTID = `#${treeNode.tId}_a`;
            // 移到根节点
            if (treeNode.level === 0) {
                html = '<button class="del-collect" title="删除收藏夹"  id="delete-' + treeNode.id + '"></button>' +
                    '<button class="edit-collect" title="编辑收藏夹"  id="edit-' + treeNode.id + '"></button>';
                if ($(delCollectClass).length !== 0 || $(editCollectClass).length !== 0) {
                    $(delCollectClass).remove();
                    $(editCollectClass).remove();
                }
                if ($(treeNodeTID).find('button').length === 0) {
                    $(treeNodeTID).append(html);
                    operateLevel(treeNode);
                }
            }
            // 移到叶子结点
            if (!treeNode.isParent) {
                _this.loadDeptName(treeNode.propKey);
                html = '<button class="collect-play call-video plays-' + treeNode.idKey + '" title="视频播放"  id="collect-play' + treeNode.id + '" video="' + treeNode.id + '"></button>'
                    + '<button class="collect-operate allCollect" id="collect-operate' + treeNode.id + '" title="已收藏" propKey="' + treeNode.id + '"></button>';
                if ($(collectPlayClass).length !== 0 || $(collectOperateClass).length !== 0) {
                    $(collectPlayClass).remove();
                    $(collectOperateClass).remove();
                }
                if ($(treeNodeTID).find('button').length === 0) {
                    $(treeNodeTID).append(html);
                }
                // 已收藏
                if ($('[id=\'collect-operate' + treeNode.id + '\']').hasClass('allCollect')) {
                    // 换背景+删除
                    $('[id=\'collect-operate' + treeNode.id + '\']').css('background-image', `url(${collecta})`);
                    $('[id=\'collect-operate' + treeNode.id + '\']').attr('title', '已收藏');
                }
                if (_this.props.videoController.isPlaying(treeNode.idKey) !== -1) {
                    $('[id=\'collect-play' + treeNode.id + '\']').css('background-image', `url(${yuyuan1})`);
                }
                // 主要操作
                operateNode(treeNode);
            }
        }

        //离开根节点
        function removeHoverDomHandler(treeId, treeNode) {
            selectId = -1;
            if (!treeNode.isParent) {
                if ($('.collect-play').length !== 0 || $('.collect-operate').length !== 0) {
                    $('.collect-play').remove();
                    $('.collect-operate').remove();
                }

            } else {
                if ($('.del-collect').length !== 0 || $('.edit-collect').length !== 0) {
                    $('.del-collect').remove();
                    $('.edit-collect').remove();
                }

            }

        }

        // 主要操作
        function operateNode(treeNode) {
            // 点击播放按钮
            const {isFlow} = _this.props;
            var isClick = true;
            $('[id=\'collect-play' + treeNode.id + '\']').click(function () {
                if (isClick) {
                    isClick = false;
                    if (_this.props.videoController.isPlaying(treeNode.propKey) != -1) {
                        // 已播放
                        message.error('该视频正在播放');
                    } else {
                        // 调云眼接口
                        var param = {
                            sipTel: treeNode.idKey,
                            type: 'video'
                        };
                        if (isFlow) {
                            queryflow_api.queryDevFlowState(param).then(resp => {
                                if (!resp.data.obj) {
                                    message.error(resp.message || '流量达到上限！');
                                    return;
                                } else {
                                    if (resp.message) {
                                        message.warning(resp.message);
                                    }
                                    openVideo(treeNode);
                                }
                            });
                        } else {
                            openVideo(treeNode);
                        }

                    }
                    setTimeout(function () {
                        isClick = true;
                    }, 500);
                }

            });


            // 点击关闭播放按钮
            $('[id=\'stop-monitor' + treeNode.id + '\']').unbind('click').bind('click', function (e) {
                $(this).remove();
                _this.props.videoController.close(_this.props.videoController.isPlaying(4));
                //e.cancelBubble = true;
            });


            // 点击收藏按钮的操作
            $('#collect-operate' + treeNode.id).click(function () {
                _this.setState({
                    cancelCollect: true,
                    cancelCollectId: treeNode.id
                });

            });


        }


        function openVideo(treeNode) {
            scooper.poc.groupPoc.openCloudEye(treeNode.catalogId, function (data) {
                if (data.data.result !== 'fail' && data.code == 0) {
                    _this.props.videoController.playByOrderExpandWindow(treeNode.catalogId, treeNode.catalogId, {
                        name: treeNode.name,
                        parentName: _this.state.deptName
                    });
                    var aObj = $('.level' + treeNode.level).find('#' + treeNode.tId + '_a');
                    var _nodeView = '<i class="bofang" id="bofang"><i/>';
                    aObj.append(_nodeView);
                } else {
                    var reason;
                    if (data.data.reason) {
                        reason = data.data.reason;
                        if (reason.indexOf('非预呼叫对讲成员') > -1) {
                            reason = '设备离线!';
                        } else if (reason.indexOf('无此对讲成员') > -1) {
                            reason = '该成员未绑定设备！';
                        }
                    } else {
                        reason = '';
                    }
                    message.error(reason);
                }
            });
        }

        // 根节点按钮
        function operateLevel(treeNode) {
            // 删除收藏夹
            $('[id=\'delete-' + treeNode.id + '\']').click(function () {
                _this.setState({
                    delCollectId: treeNode.id,
                    collectConfirm: true
                });
                // showDeleteCollectConfirm();

            });
            // 编辑收藏夹
            $('[id=\'edit-' + treeNode.id + '\']').click(function () {
                _this.setState({
                    editCollectId: treeNode.id,
                    editCollect: true
                });
                _this.props.setBookNameAction({bookName: treeNode.bookmarkName});
            });
        }


        // 初始化树
        $.fn.zTree.init($('#treeCollect'), setting, data.data.list);
        var zTree = $.fn.zTree.getZTreeObj('treeCollect');

        // 默认展开第一级
        if (zTree != null) {
            const nodeList = zTree.getNodes();
            zTree.expandNode(nodeList[0], true, false, false);
            // for (var i = 0; i < nodeList.length; i++) {
            //     zTree.expandNode(nodeList[i], true, false, false);
            // }
        }
    };

    componentDidMount() {
        this.loadCollectTree();
        let self = this;

        //监听消息改变树节点成员状态
        scooper.poc.listen(scooper.poc.event_const.POC_MEM_CHN, function (data) {
            let pocMsg = data.msg;
            let changeNode = [];
            self.props.collectTree && self.props.collectTree.map(item => {
                if (item.idKey == pocMsg.pocNo) {
                    changeNode.push(item);
                }
            });
            changeNode && changeNode.map(val => {
                $('[title=\'' + val.name + '\']').removeClass('idle').removeClass('offline');
                $('[title=\'' + val.name + '\']').addClass(pocMsg.status);
                if (pocMsg.status == 'working') {
                    $('[title=\'' + val.name + '\']').addClass('idle');
                }
            });

        });
        // scooper.poc.calls.listStatus(function(data){ 
        //     data && data.list.map(item => {
        //         list_status[item.tel] = item
        //     });
        //     // self.loadCollectTree();
        // });  
        scooper.poc.groupPoc.listMemStatus(function (data) {
            data.data && data.data.map(item => {
                list_statusPoc[item.pocNo] = item;
            });
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const StyleIframe = {
            wrap: {
                position: 'absolute',
                visibility: 'inherit',
                top: '-6px',
                left: '-5px',
                width: '372px',
                height: '210px',
                zIndex: '-1',
                border: 'medium',
                filter: 'alpha(opacity=0)'

            }
        };
        return (
            <div className="collect-wrap">
                <div className="top">
                    <span className="collect-name left">收藏夹</span>
                    <button className="add-collect right" onClick={this.addCollect}>添加组</button>
                </div>
                <Search
                    className="input-search"
                    id="search-collect"
                    prefix={<Icon type="search" style={{color: '#057797'}}/>}
                    placeholder="搜索"
                    onChange={this.searchCollect}
                />
                <div className="zTreeCollect">
                    <ul id="treeCollect" className="ztree"/>
                </div>

                <Modal
                    title="添加收藏夹组"
                    visible={this.state.collectVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                    className="add-collect"
                >
                    {getFieldDecorator('bookmarkName', {
                        initialValue: ''
                    })(
                        <Input
                            placeholder="请输入收藏夹名称"
                            id="collectName"

                        />
                    )}
                    {/* <Frame style={StyleIframe.wrap}></Frame> */}
                </Modal>
                <Modal
                    title="修改收藏夹组"
                    visible={this.state.editCollect}
                    onOk={this.handleEditOk}
                    onCancel={this.handleEditCancel}
                    okText="确定"
                    cancelText="取消"
                    className="add-collect"
                >
                    <Input value={this.props.bookName} onChange={(e) => {
                        this.bookNameChange(e);
                    }} placeholder="请输入收藏夹名称"
                           id="editCollectName"/>
                    <Frame style={StyleIframe.wrap}/>
                </Modal>

                {/* <Popconfirm
                    visible={this.state.collectConfirm}
                    title="确定要取消收藏吗？"
                    onConfirm={this.confirm}
                    onCancel={this.cancel}
                    okText="确定"
                    cancelText="取消"
                >

                </Popconfirm> */}
                {/* confirm({
                title: '确定要取消收藏吗?',
                okText: '确定',
                okType: 'danger',
                className: 'delConfirm',
                cancelText: '取消',
                onOk() {
                    _this.confirm();
                },
                onCancel() {
                    _this.cancel();
                },
            }); */}

                <Modal
                    title="确定要删除收藏夹吗?"
                    visible={this.state.collectConfirm}
                    onOk={this.delCollectconfirm}
                    onCancel={this.delCollectcancel}
                    okText="确定"
                    cancelText="取消"
                    className="add-config delConfirm"
                >
                    <Frame style={StyleIframe.wrap}/>
                </Modal>

                {/* cancelCollect */}
                <Modal
                    title="确定要取消收藏吗?"
                    visible={this.state.cancelCollect}
                    onOk={this.confirm}
                    onCancel={this.cancel}
                    okText="确定"
                    cancelText="取消"
                    className="add-config delConfirm"
                >
                    <Frame style={StyleIframe.wrap}/>
                </Modal>
            </div>
        );

    }

    bookNameChange = (e) => {
        this.props.setBookNameAction({bookName: e.target.value});
    };
    /**
     * 取消收藏的确定
     */
    confirm = async () => {
        this.setState({
            cancelCollect: false
        });
        let cancelCollectParam = {
            ids: this.state.cancelCollectId
        };
        let cancelData = await commonPost(videoPart.deleteBookmarkCatalog, cancelCollectParam);

        var _this = this;
        if (cancelData.data.code == 0) {
            // 保存成功重新加载收藏夹树
            this.loadBookmarkCatalog();
            this.loadCollectTree();
            message.success('取消收藏成功');
        }
    };
    /**
     * 取消收藏的取消
     */
    cancel = () => {
        this.setState({
            cancelCollect: false
        });
    };

    /**
     * 删除收藏夹的确定
     */
    delCollectconfirm = async () => {
        let param = {
            id: this.state.delCollectId
        };
        commonPost(videoPart.deleteBookmark, param)
            .then(({data}) => {
                if (data.code == 0) {
                    this.loadBookmarkCatalog();
                    message.success('删除成功');
                    this.loadCollectTree();
                    this.setState({
                        collectConfirm: false
                    });

                }
            });
    };
    /**
     * 删除收藏夹的取消
     */
    delCollectcancel = () => {
        this.setState({
            delCollectId: -1000,
            collectConfirm: false
        });
    };


    /**
     * 收藏夹树的搜索
     */
    searchCollect = (e) => { //输入框的值
        this.setState({
            searchVal: e.target.value
        }, () => {
            var treeObj = $.fn.zTree.getZTreeObj('treeCollect');
            if (this.state.value === '') {
                var nodes = treeObj.getNodesByParam('isHidden', true);
                treeObj.showNodes(nodes);
            } else {
                var nodes = treeObj.transformToArray(treeObj.getNodes());
                treeObj.hideNodes(nodes);
                var nodeArray;
                nodeArray = treeObj.getNodesByFilter(this.treeFilter);
                console.log('filter node array length=' + nodeArray.length);
                for (var i = 0; i < nodeArray.length; i++) {
                    var node = nodeArray[i];
                    var pathNodes = node.getPath(node);
                    treeObj.showNodes(pathNodes);
                    var childrenNodes = node.children;
                    if (childrenNodes) {
                        for (var j = 0; j < childrenNodes.length; j++) {
                            var pathNodes = childrenNodes[j].getPath(childrenNodes[j]);
                            treeObj.showNodes(pathNodes);
                        }
                    }
                }
                treeObj.expandAll(true);
            }
            ;
            if (!this.state.searchVal) {
                this.loadCollectTree();
            }
        });
    };
    treeFilter = (node) => {
        var getPinYin = Pinyin(node.name, {
            style: Pinyin.STYLE_NORMAL
        });

        //获取全拼
        var result1 = getPinYin.join('');
        node['fullPinyin'] = result1;
        //获取首字母
        var list = [];
        getPinYin && getPinYin.length > 0 && getPinYin.map(item => {
            list.push(item[0].substr(0, 1));
        });
        var result2 = list.join('');
        node['initialPinyin'] = result2;
        // var flag = node.level == 1 || node.level == 2 || node.level == 3;
        return node.name.indexOf(this.state.searchVal) >= 0 ||
            node.fullPinyin.indexOf(this.state.searchVal) >= 0 ||
            node.initialPinyin.indexOf(this.state.searchVal) >= 0;
    };

    /**
     * 添加收藏夹组
     */
    addCollect = () => {
        this.setState({
            collectVisible: true
        });
    };

    /**
     * 添加收藏夹组确定
     */
    handleOk = async () => {
        // this.setState({
        //     collectVisible:false
        // })
        const {
            form: {validateFields}
        } = this.props;
        validateFields(async (err, values) => {
            // $.each(values, (key, value) => {

            // })
            if (values.bookmarkName.length <= 0) {
                message.error('收藏夹名称不能为空！');
                return;
            }
            if (values.bookmarkName.length > 32) {
                message.error('收藏夹名称不能超过32个字符！');
                return;
            }
            var collectNameStr = values.bookmarkName.trim();
            var reg = /^[\s]*$/;
            var flag = reg.test(collectNameStr);
            if (flag) {
                message.error('收藏夹名称不能全为空格！');
                return;
            }
            let param = {
                bookmarkName: values.bookmarkName
            };
            let response = await commonPost(videoPart.saveBookmark, param);
            let data = response.data;
            if (data.code === 0) {
                message.success('添加成功');
                this.setState({
                    collectVisible: false
                });
                this.loadBookmarkCatalog();
                this.loadCollectTree();
                this.props.form.resetFields();
            }
        });


    };
    /**
     * 加载收藏夹树
     */
    loadBookmarkCatalog = async () => {
        let param = {};
        let data = await commonPost(videoPart.loadCollectList, param);
        if (data.data.code === 0) {
            data.data.data.list.map((item) => {
                if (item.parentId === 0) {
                    item.isParent = true;
                }
            });
            this.props.setCollectAction({collectTree: data.data.data.list});
            this.props.setCollectParentAction({collectTreeParent: data.data.data.list});
        }
        // if (data.data.code === 0) {
        //     store.dispatch(setCollectAction({ collectTree: data.data.list }));
        // }
    };
    /**
     * 添加收藏夹组取消
     */
    handleCancel = () => {
        this.setState({
            collectVisible: false
        });
        this.props.form.resetFields();
    };

    /**
     * 编辑收藏夹组确定
     */
    handleEditOk = async () => {
        let collectName = $('#editCollectName').val();
        if (collectName.length <= 0) {
            message.error('收藏夹名称不能为空！');
            return;
        }
        if (collectName.length > 32) {
            message.error('收藏夹名称不能超过32个字符！');
            return;
        }
        let params = {
            id: this.state.editCollectId,
            bookmarkName: collectName
        };
        let data = await commonPost(videoPart.updateBook, params);

        console.log(data, '=========handleEditOk====');
        if (data.data.code == 0) {
            message.success('修改成功');
            this.setState({
                editCollect: false,
                bookName: ' '
            });
            this.loadBookmarkCatalog();
            this.loadCollectTree();

        }

    };
    /**
     * 编辑收藏夹取消
     */
    handleEditCancel = () => {
        this.setState({
            editCollect: false,
            bookName: ' '
        });
    };

    /**
     * 根据memTel查部门名称
     */
    loadDeptName = (memTel) => {
        let params = {
            tel: memTel,
            all: false
        };
        commonPost(dept.findOrgByTel, params)
            .then(({data}) => {
                if (data.code == 0 && data.data.length !== 0) {
                    this.setState({
                        deptName: data.data[0].deptName
                    });
                }
            });

    };
});

export default Form.create()(Collect);