import React from 'react';
import {Table, Divider, Icon, Popconfirm, message} from 'antd';

import DevDetail from './Devdetail';
import AmendMsg from './amend-msg';


import {gather_api} from '@/rest-api/restapi';
import {
    setGatherListAction,
    setGatherListAllAction,
    setRowsSelectedAction,
    setKeySelectedAction,
    setGatherDetailAction,
    setShowSelectAction
} from '@/reducer/video_action';
import {connect} from 'react-redux';

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1179465_pauxgceq3j.js'
});


const GatherTable = connect(
    state => {
        return {
            gatherList: state.gatherList,
            gatherListAll: state.gatherListAll
        };
    },
    {
        setGatherListAction,
        setGatherListAllAction,
        setKeySelectedAction,
        setGatherDetailAction,
        setShowSelectAction,
        setRowsSelectedAction
    }
)
(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showAmend: false,
            detailMan: {}
        };
    }

    /**
     *checkbox状态
     */
    onSelectChange = (selectedRowKeys) => {
        console.log('选中的行为: ', selectedRowKeys);
        this.setState({selectedRowKeys: selectedRowKeys});
        this.props.setKeySelectedAction({keySelected: selectedRowKeys});
    };

    /**
     * 隐藏弹框
     */
    onCancel = () => {
        this.setState({
            show: false,
            showAmend: false
        });
    };

    /**
     *  展示采集站详情
     */
    showDetail = async (id) => {
        var param = {
            token: sessionStorage.getItem('token'),
            id
        };
        const data = await gather_api.getManDetail(param);
        this.props.setGatherDetailAction({gatherDetail: data.data});
        this.setState({
            show: true,
            detailMan: data.data,
            aa: 1
        });
    };

    /**
     * 分页请求
     */
    onChangePage = async (page, pageSize) => {
        const param = {
            token: sessionStorage.token,
            pageNum: page,
            pageSize,
            corpId: sessionStorage.getItem('corpId'),
            deptId: this.props.deptId,
            gzzXh: this.props.gzzXh,
            isOnline: this.props.isOnline
            // orgCode: sessionStorage.getItem('orgCode')      
        };
        const data = await gather_api.getDataGather(param);

        if (!data.code) {
            this.props.setGatherListAction({gatherList: data.data.list});
            this.props.setGatherListAllAction({gatherListAll: data.data});
        }
    };

    /**
     * 采集站删除
     */
    onDelete = async (id, gzzXh) => {
        var param = {
            token: sessionStorage.getItem('token'),
            id: id,
            gzzXh: gzzXh
        };
        const data = await gather_api.deleteGather(param);
        if (!data.code) {
            message.success(data.message);
            var param2 = {
                token: sessionStorage.getItem('token'),
                corpId: sessionStorage.getItem('corpId'),
                // orgCode: sessionStorage.getItem('orgCode'),
                pageNum: 1,
                pageSize: 10,
                deptId: this.props.deptId,
                gzzXh: this.props.gzzXh,
                isOnline: this.props.isOnline
            };
            param2.pageNum = this.props.gatherListAll.pageNum;
            const res = await gather_api.getDataGather(param2);
            this.setState((prevState, props) => {

            });
            if (!res.code) {
                if (res.data.list.length === 0) {
                    param2.pageNum = res.data.lastPage;
                    const resp = await gather_api.getDataGather(param2);
                    this.props.setGatherListAction({gatherList: resp.data.list});
                    this.props.setGatherListAllAction({gatherListAll: resp.data});
                } else {
                    this.props.setGatherListAction({gatherList: res.data.list});
                    this.props.setGatherListAllAction({gatherListAll: res.data});
                }
            }
        }
    };

    /**
     * 修改基本信息
     */
    amendMsg = async (id, deptId, memName, gzzXh) => {
        this.setState({
            showAmend: true,
            id: id,
            deptId: deptId,
            memName: memName,
            gzzXh: gzzXh
        });
        this.props.setShowSelectAction({showSelect: 0});
    };

    render() {
        const columns = [{
            title: '设备编码',
            dataIndex: 'gzzXh'
        }, {
            title: '所属单位',
            dataIndex: 'deptName'
        }, {
            title: '运行时长',
            dataIndex: 'time'
        }, {
            title: '状态',
            dataIndex: 'isOnline',
            render: (text, record) => {
                if (record.isOnline == 1) {
                    return <span>在线</span>;
                }
                if (record.isOnline == 0) {
                    return <span>离线</span>;
                }
            }
        },
            {
                title: '已用/总储存',
                dataIndex: 'diskUsedRTotal'
            },
            {
                title: '主机地址',
                dataIndex: 'gzzIpdz'
            },
            {
                title: '管理员',
                dataIndex: 'memName'
            },
            {
                title: '联系方式',
                dataIndex: 'memTel'
            },
            {
                title: '操作',
                dataIndex: 'operate',
                render: (text, record) => (
                    <span>
                        <a className="edit" onClick={() => this.showDetail(record.id)}><i></i></a>
                        <Divider type="vertical"/>
                        <Popconfirm title="确认要删除吗？" okText="确认" cancelText="取消" className="popConfirm"
                                    onConfirm={() => this.onDelete(record.id, record.gzzXh)}>
                            <a className="delete"><i></i></a>
                        </Popconfirm>
                        <Divider type="vertical"/>
                        <a className="amend"
                           onClick={() => this.amendMsg(record.id, record.deptId, record.memName, record.gzzXh)}><i></i></a>
                    </span>
                )
            }];

        const {selectedRowKeys} = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows) => {
                this.props.setRowsSelectedAction({rowsSelected: selectedRows});
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.props.setRowsSelectedAction({rowsSelected: selectedRows});
            }
        };

        let pagination = {
            current: this.props.gatherListAll.pageNum,
            total: this.props.gatherListAll.total,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total, range) => {
                return '共' + total + '条';
            },
            itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            },
            showQuickJumper: {
                goButton: <button>确定</button>
            },
            onChange: this.onChangePage
        };

        return (
            <div className="content">
                <DevDetail show={this.state.show} onCancel={this.onCancel} detailMan={this.state.detailMan}/>
                <AmendMsg showAmend={this.state.showAmend} onCancel={this.onCancel} id={this.state.id}
                          deptId={this.state.deptId} memName={this.state.memName} gzzXh={this.state.gzzXh}
                          showSel={this.state.showSel}/>
                <Table rowSelection={rowSelection} columns={columns} rowKey={record => record.id}
                       pagination={pagination} dataSource={this.props.gatherList}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   this.setState({
                                       rowId: record.id
                                   });
                               }
                           };
                       }}
                       rowClassName={(record, index) => {
                           let className = 'even-bg';
                           if (index % 2 === 0) {
                               className = 'odd-bg';
                           }
                           if (index === selectedRowKeys) {
                               className = 'light';
                           }
                           return className;
                       }}
                />

            </div>
        );
    }
});
export default GatherTable;