/**
 * @Author : JiGuangJie
 * @Date : 2021/11/19
 * @Time : 11:53
 * @Version : 1.0.0
 * @descr : 执法记录仪统计分析模块
 */

import React, { Component } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  ConfigProvider,
  TreeSelect,
  message,
  Modal,
} from "antd";
import moment from "moment";
import zh_CN from "antd/es/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import ManageHeader from "@/view/components/manage-header";
import StatisticList from "@/view/pages/dept-analysis/statistic-list/index";
import { scooper_app_api } from "@/util/api";
import { statistics_api } from "@/rest-api/restapi";

import "@/less/deptAnalysis/deptAnalysis.less";
import axios from "axios";
import { mount } from "require/server";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

class DeptAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "数据统计",
      treeData: [],
      treeNodeList: [],
      statisticalData: [],
      backList: [],
      isDepartment: true,
      selctValue: "",
      backSelectValue: ""
    };
    this.expandedKeyDic = {};
    this.isContainDepts = 0; // 是否包含下级部门 0 - 不包含， 1- 包含
    this.startTimeValue = ""; // 开始时间
    this.endTimeValue = ""; // 结束时间
    this.deptId = ""; // 部门id
  }

  componentDidMount() {
    // todo 获取模块title，通过接口获取

    // 通过通讯录获取部门树
    scooper_app_api.listOrgDept({ parentId: 0 }).then((res) => {
      const { list } = res;
      const treeData = list.map((item) => {
        return {
          id: item.id,
          parentId: item.parentId,
          title: item.deptName,
          value: item.id,
          key: item.id,
          children: [],
          isLeaf: false,
          ...item,
        };
      });
      this.setState({
        treeData,
        treeNodeList: [...treeData],
      });
    });
    this.startTimeValue =
      new Date().toLocaleDateString().replace(/\//g, "-") + " 00:00:00";
    this.endTimeValue = new Date()
      .toLocaleString("chinese", { hour12: false })
      .replace(/\//g, "-");
    this.getDataMethod(this.startTimeValue, this.endTimeValue);
  }

  getDataMethod = (start, end) => {
    if (start === "" || end === "") {
      message.warning("请选择正确的时间段查询");
      return;
    }

    const params = {
      start,
      end,
    };
    // 当deptId不为空的时候，是否包含下级部门才有效
    if (this.deptId !== "") {
      params["deptId"] = this.deptId;
      params["isContainDepts"] = this.isContainDepts;
    }
    statistics_api.getDeptStatInfoSorted(params).then((res) => {
      if (res.code === 0 && res.data) {
        this.setState({
          statisticalData: [...res.data],
        });
      } else {
        this.setState({
          statisticalData: [],
        });
      }
    });
  };

  getListOrgDeptMethod = (parentId) => {
    // 通过通讯录获取部门树
    scooper_app_api.listOrgDept({ parentId }).then((res) => {
      const { list } = res;
      const childrenNode = list.map((item) => {
        return {
          title: item.deptName,
          value: item.id,
          key: item.id,
          parentId: item.parentId,
          id: item.id,
          children: [],
          isLeaf: false,
          ...item,
        };
      });
      const preTreeNodeList = this.state.treeNodeList;
      const allTreeNodeList = [...preTreeNodeList, ...childrenNode];
      const treeData = this.formatTreeNodeMethod([...allTreeNodeList]);
      this.expandedKeyDic[parentId] = treeData;
      this.setState({
        treeData,
        treeNodeList: allTreeNodeList,
      });
    });
  };

  formatTreeNodeMethod = (list) => {
    const res = [];
    const map = list.reduce((res, node) => {
      res[node.id] = node;
      node.children = [];
      return res;
    }, {});
    for (const item of list) {
      if (item.parentId === 0) {
        res.push(item);
        continue;
      }
      if (item.parentId in map) {
        const parent = map[item.parentId];
        parent.children = parent.children || [];
        parent.children.push(item);
      }
    }
    return res;
  };

  goHome = () => {
    this.props.history.push("/home");
  };

  /**
   * 日期选择变化事件触发
   * @param value
   * @param dateString
   */
  onChangeHandle = (value, dateString) => {
    const [start, end] = dateString;
    this.startTimeValue = start;
    this.endTimeValue = end;
  };

  /**
   * 包含子单位选择
   * @param evt
   */
  onChangeSubunitsHandle = (evt) => {
    this.isContainDepts = evt.target.checked ? 1 : 0;
  };

  onTreeExpandHandle = (expandedKeys) => {
    expandedKeys.forEach((key) => {
      if (!this.expandedKeyDic.hasOwnProperty(key)) {
        this.getListOrgDeptMethod(key);
      }
    });
  };

  onSelectHandle = (value, node) => {
    this.deptId = node.props.id;
    this.setState({ selectValue: node.props.title })
  };
  /**
   * 搜索
   */
  onSearchHandle = () => {
    if (this.deptId === "") {
      return message.info('搜索关键词为空');
    }
    const diffDay = moment(this.endTimeValue).diff(
      moment(this.startTimeValue),
      "day"
    );
    if (diffDay > 7) {
      message.error("选择天数范围不能超过7天,请重新选择");
      return;
    } if (this.state.isDepartment) {
      this.getDataMethod(this.startTimeValue, this.endTimeValue);
      this.state.backSelectValue = this.state.selectValue;
    } else {
      this.getDataMethod(this.startTimeValue, this.endTimeValue);
      this.rowClickFn({ deptId: this.deptId })
    }
  };
  /**
   * 导出
   */
  onExportHandle = () => {
    if (
      this.startTimeValue === "" ||
      this.endTimeValue === "" ||
      this.state.statisticalData.length === 0
    ) {
      message.warning("当前无数据可以导出");
      return;
    }

    confirm({
      title: "确定导出？",
      okText: "确定",
      cancelText: "取消",
      content: "",
      onOk: () => {
        const token = window.sessionStorage.getItem("token");
        // let params = `token=${token}&start=${this.startTimeValue}&end=${this.endTimeValue}`;
        // 当deptId不为空的时候，是否包含下级部门才有效
        let postData = new URLSearchParams();
        if (this.deptId !== "") {
          // params = `${params}&deptId=${this.deptId}&isContainDepts=${this.isContainDepts}`;
          const params = {
            token: token, start: this.startTimeValue, end: this.endTimeValue, deptId: this.deptId, isContainDepts: this.isContainDepts
          }
          for (let key in params) {
            postData.append(key, params[key]);
          }
        }

        axios
          .post(
            this.state.isDepartment ? `/scooper-poc-manager/data/stat/exportDeptStatFormwork` : `/scooper-poc-manager/data/stat/exportDevStatFormwork`, postData,
            { responseType: "blob" }
          )
          .then((res) => {
            if (res && res.status === 200) {
              const blob = new Blob([res.data]);
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.download = `考勤数据-${this.startTimeValue} -- ${this.endTimeValue}.xls`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              message.success("导出成功");
            } else {
              message.error("导出失败！");
            }
          });
      },
    });
  };

  /**
   * 限制选择日期
   * @param dateTime
   */
  disabledDateHandle = (dateTime) => {
    return dateTime > moment().endOf("day");
  };

  /**
   * 限制选择时间
   * @param dateTime
   */
  disabledTimeHandle = (dateTime) => {
    if (moment(dateTime).isBefore(moment(), "day")) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }

    if (moment(dateTime).isSame(moment(), "day")) {
      return {
        disabledHours: () => this.disabledHours(dateTime),
        disabledMinutes: () => this.disabledMinutes(dateTime),
        disabledSeconds: () => this.disabledSeconds(dateTime),
      };
    }
  };

  /**
   * 限制选择小时
   * @return {*[]}
   */
  disabledHours = () => {
    const hours = [];
    for (let i = 23; i > moment().hour(); i--) {
      hours.push(i);
    }
    return hours;
  };

  /**
   * 限制选择分钟
   * @param currentDate
   * @return {*[]}
   */
  disabledMinutes = (currentDate) => {
    const currentHour = moment(currentDate).hour();
    const currentMinute = moment().minute();
    const minutes = [];
    if (currentHour === moment().hour()) {
      for (let i = 59; i > currentMinute; i--) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  /**
   * 限制选择秒
   * @param currentDate
   * @return {*[]}
   */
  disabledSeconds = (currentDate) => {
    const currentHour = moment(currentDate).hour();
    const currentMinute = moment(currentDate).minute();
    const currentSeconds = moment().seconds();
    const seconds = [];
    if (
      currentHour === moment().hour() &&
      currentMinute === moment().minute()
    ) {
      for (let i = 59; i > currentSeconds; i--) {
        seconds.push(i);
      }
    }
    return seconds;
  };
  rowClickFn = async (info) => {
    if (this.state.isDepartment) {
      this.state.backList = this.state.statisticalData;
    }
    if (info.deptName) {
      this.setState({ selectValue: info.deptName })
    }
    if (info.deptId) {
      this.deptId = info.deptId;
    }
    const params = {
      start: this.startTimeValue,
      end: this.endTimeValue,
    };
    params["deptId"] = info.deptId;
    params["isContainDepts"] = this.isContainDepts;
    let res = await statistics_api.getDevInfoStatistical(params);
    if (res.code === 0 && res.data) {
      this.setState({
        statisticalData: [...res.data],
      });
    } else {
      this.setState({
        statisticalData: [],
      });
    }
    this.setState({isDepartment: false});
    console.log("statisticalData", this.state.statisticalData);
  };
  onBackHandle = () => {
    if (this.state.isDepartment === true) {
      return message.info("没有上级，请使用搜索");
    }
    this.setState({ isDepartment: true });
    this.setState({
      statisticalData: this.state.backList,
    })
    this.setState({
      selectValue: this.state.backSelectValue
    })
  };
  onOpenChangeHandle = (open) => {
    console.log(open, "=========================");
  };

  render() {
    const { title, treeData, statisticalData } = this.state;
    const dateFormat = "YYYY-MM-DD HH:mm:ss";

    return (
      <div className={"dept-analysis-wrap"}>
        <ManageHeader goHome={this.goHome} title={title} this={this.props} />
        <div className={"analysis-content"}>
          <div className={"top-btn-menu-wrap"}>
            <div className={"right-wrap"}>
              <span className={"title"}>部门</span>
              {console.log("selctValue", this.state.selctValue)}
              <TreeSelect
                placeholder="请选择单位"
                className={"select-company"}
                treeData={treeData}
                onTreeExpand={this.onTreeExpandHandle}
                onSelect={this.onSelectHandle}
                value={this.state.selectValue}
              />
              <Checkbox
                className={"subunits-checkbox"}
                defaultChecked={false}
                onChange={this.onChangeSubunitsHandle}
              >
                包含子部门
              </Checkbox>
            </div>
            <div className={"left-wrap"}>
              <ConfigProvider locale={zh_CN}>
                <RangePicker
                  className={"range-picker"}
                  defaultValue={[
                    moment(new Date().toLocaleDateString(), dateFormat),
                    moment(
                      new Date().toLocaleString("chinese", { hour12: false }),
                      dateFormat
                    ),
                  ]}
                  showTime={{ format: "HH:mm:ss" }}
                  format={"YYYY-MM-DD HH:mm:ss"}
                  placeholder={["开始时间", "结束时间"]}
                  disabledDate={this.disabledDateHandle}
                  disabledTime={this.disabledTimeHandle}
                  onChange={this.onChangeHandle}
                  onOpenChange={this.onOpenChangeHandle}
                />
              </ConfigProvider>
              <Button onClick={this.onSearchHandle}>搜索</Button>
              <Button onClick={this.onExportHandle}>导出</Button>
              {!this.state.isDepartment && <Button onClick={this.onBackHandle}>返回上级</Button>}
            </div>
          </div>
          <div className="divider" />
          <div className={"statistic-list-wrap"}>
            <StatisticList
              rowClickFn={this.rowClickFn}
              data={statisticalData}
              isDepartment={this.state.isDepartment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DeptAnalysis;
