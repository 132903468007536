/*
 * @Author: liulian
 * @Date: 2019/9/19 10:50:06 
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {videoPart} from '@/rest-api/restapi-video';
import {Menu, SubMenu, Popconfirm, message, Button, Modal} from 'antd';
import $ from '../../../lib/jquery-vendor';
import {commonGet, commonPost} from '@/request/common-axios';
import {
    setVideoCorAction,
    setPlayListAction,
    setPatrolAction
} from '@/reducer/video_action';
import {closeCloudEye} from '../../components/dispatch-cloud';
import zhuatu from '../../../image/video-img/zhuatu1.png';
import jingyin from '../../../image/video-img/shengyin1.png';
import gaunbi from '../../../image/video-img/guanbi.png';
import allScreen from '../../../image/video-img/quanp1.png';
import yuyuan1 from '../../../image/video-img/yunyan.png';
import noyunyan from '../../../image/video-img/yunyan1.png';
import config from '../../../lib/scooper.config';

const {confirm} = Modal;

const List = [
    {
        key: '1',
        num: '1',
        type: 'model-1'
    },
    {
        key: '2',
        num: '4',
        type: 'model-2'
    },
    {key: '4', num: '9', type: 'model-4'},
    {key: '5', num: '16', type: 'model-5'}
];


export default connect(
    state => {
        return {
            playRecordList: state.playRecordList,
            videoController: state.videoController,
            patrolList: state.patrolList,
            playList: state.playList
        };
    },
    {
        setVideoCorAction,
        setPlayListAction,
        setPatrolAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selContent: 'model-2',
            i: 0,
            zhutuVisible: false
        };
    }

    /**
     * 视频初始化
     */
    videoInit = () => {
        let videoConf;
        let videoController;
        const _this = this;
        config.initialize(function (conf) {
            $(function () {
                videoConf = conf;
                // 初始化视频播放方式
                _this.initplaVideoType();

                let janusUrl = conf['video.janus.url'];
                // janusUrl = 'wss:192.168.106.246:10002/janus/';
                console.log(janusUrl);
                if (!_this.hasOcx()) {
                    return;
                }
                const videoOpts = {
                    //初始化时的界面显示的分屏树
                    windows: 4,
                    //共有哪几种分屏
                    windowsArr: [1, 4, 6, 9, 16],
                    //总的窗口数
                    windowsNum: 16,
                    conf: {
                        user: conf['video.username'],
                        passwd: conf['video.password'],
                        ip: conf['video.ip'],
                        port: conf['video.port'],
                        janusUrl: conf['video.janus.url'],
                        token: sessionStorage.getItem('token')
                    },
                    extra: false,
                    streamType: conf['video.stream'],
                    openChangeWindowStrategy: conf['video.openChangeWindowStrategy'] === 'true',
                    capImage: conf['video.cap.image'] === 'true',
                    videoCapImagePath: conf['video.cap.image.path'],
                    videoInfoInBottom: false,
                    businessType: 1,
                    waitPlayQueueSwitch: conf['video.play.queue.switch'] === 'true'
                };

                conf['video.poll.time'] && (videoOpts.pollInterval = conf['video.poll.time']);

                const videoArea = _this.isIE() ? '.video-area' : '.web-rtc-camera-content';

                videoController = new window.scooper.video($(videoArea), videoOpts);

                _this.props.setVideoCorAction({videoController});

                if (_this.props.videoController) {
                    //播放成功
                    _this.props.videoController.addListener('playsuccess', function (e) {
                        // $("[title='" + e.opts.name + "']").addClass('bofang');
                        // var aObj = $(".level" + treeNode.level).find("#" + treeNode.tId + "_a")
                        // var _nodeView = '<i class="bofang"><i/>';
                        // aObj.append(_nodeView);  
                        $('#video-' + (e.index + 1)).unbind('playing');    //每次点击时先解除绑定事件
                        $('#video-' + (e.index + 1)).bind('playing', function () {
                            if (!_this.props.videoController.isPlayingByIndex(e.index)) return false;
                            if ((e.opts.isSos && (e.opts.isSos === true || e.opts.isSos === 'true')) || (e.opts.isVideoPoc && (e.opts.isVideoPoc === true || e.opts.isVideoPoc === 'true'))) {
                                //sos模拟触发点对点
                                setTimeout(function () {
                                    $('#video-' + (e.index + 1)).siblings('.operate-btn').children('.unsend-audio-btn').click(function (e) {
                                        e.stopPropagation();
                                    });
                                    $('#video-' + (e.index + 1)).siblings('.operate-btn').children('.unsend-audio-btn').trigger('click');
                                }, 1000);
                            } else {
                                //正常情况下模拟点击喇叭
                                setTimeout(function () {
                                    $('#video-' + (e.index + 1)).siblings('.operate-btn').children('.unrecv-audio-btn').click(function (e) {
                                        e.stopPropagation();
                                    });
                                    $('#video-' + (e.index + 1)).siblings('.operate-btn').children('.unrecv-audio-btn').trigger('click');
                                }, 1000);
                            }
                        });
                        $('.plays-' + e.id).css('background-image', `url(${yuyuan1})`);
                        // 视频拓展，加关闭按钮
                        // $('.info').css('display', 'none');
                        let temp = '<div class="video-operation operation-' + (e.index + 1) + '">' +
                            // '<span class="devName">' + e.opts.name + '</span>' +
                            // '<span class="btn-stop btn-stop' + (e.index + 1) + '"></span>' +
                            '<div class="video-quality video-quality-' + e.id + '">' +
                            '<input readOnly="readonly" id="input_' + e.id + '" type="text"></input>' +
                            '<ul class="video-ul video-ul-' + e.id + '" style="display:none">' +
                            '<li id="CIF">标清</li>' +
                            '<li id="480P">高清</li>' +
                            '<li id="720P">超清</li>' +
                            '<li id="1080P">蓝光</li>' +
                            '</ul>' +
                            '</div>' +
                            '</div>';
                        if (e.index < 16) {
                            $('.screen-' + (e.index + 1)).append(temp);
                        }
                        // 切换清晰度
                        $('.video-quality-' + e.id + ' li').hover(function (event) {
                            $(this).toggleClass('on');
                            event.stopPropagation();
                        });
                        $('.video-quality-' + e.id + ' li').click(function (event) {
                            // var val = $(this).text();
                            // $(this).parents('.video-quality-' + e.id).find('input').val(val);
                            $('.video-quality-' + e.id + ' ul').hide();

                            var resolution = $(this).attr('id');
                            _this.props.videoController.changeResolution(e.index, resolution);

                            event.stopPropagation();
                        });
                        $(document).click(function () {
                            $('.video-quality-' + e.id + ' ul').hide();
                        });

                        // 关闭视频
                        $('.btn-stop' + (e.index + 1)).on('click', function () {
                            let pocNo = e.id;
                            closeCloudEye(pocNo, function (data) {
                                _this.props.videoController.close(e.index);
                                if (data.result == 'success') {
                                } else {
                                    var reason;
                                    if (data.reason) {
                                        reason = data.reason;
                                    } else {
                                        reason = '';
                                    }
                                    message.error('打开失败' + reason);
                                }
                            });

                        });

                        // 保存播放列表
                        var playList = _this.props.playList || [];
                        var i = _this.state.i;
                        playList[i] = {id: e.id, video: e.video, name: e.opts.name, parentName: e.opts.parentName};
                        _this.props.setPlayListAction({playList: [...playList]});

                        // 保存播放记录，去播放历史
                        _this.addPlayHistory(e.video, e.opts.name, e.opts.parentName);

                        i = i + 1;
                        _this.setState({
                            i: i
                        });
                    });
                    // 关闭后
                    _this.props.videoController.addListener('afterclose', function (e) {
                        scooper.poc.groupPoc.closeCloudEye(e.id, function (res) {

                        });
                        $('.operation-' + (e.index + 1)).remove();
                        if (e.index != '16') {
                            $('.plays-' + e.id).css('background-image', `url(${noyunyan})`);
                            $('[id=\'play-monitor' + e.id + '\']').css('background-image', `url(${noyunyan})`);
                            let playList = _this.props.playList;
                            var list = [];

                            //播放列表数组去空值
                            playList && playList.map(item => {
                                if (item != undefined && item !== '') {
                                    list.push(item);
                                }
                            });
                            var newList = [];
                            for (var i = 0; i < list.length; i++) {
                                if (list[i] && list[i].id != e.id) {
                                    newList.push(list[i]);
                                } else {
                                    $('[title=\'' + list[i].name + '\']').children('#bofang').remove();
                                    $('[class=\'' + list[i].id + '\']').children('.bofang-btn').remove();
                                }
                            }
                            _this.props.setPlayListAction({playList: newList});
                            $('.operation-' + (e.index + 1)).remove();
                        }

                    });
                    // 调整分辨率
                    _this.props.videoController.addListener('notifyresolution', function (e) {
                        if (!e.resolution) {
                            return;

                        }
                        // 切换清晰度
                        $('.video-quality-' + e.id).on('click', function (event) {
                            $('.video-quality-' + e.id).find('ul').hide();
                            $(this).find('ul').show();
                            event.stopPropagation();
                        });

                        var val = e.resolution;
                        var realVal = '';
                        if (val == 'CIF') {
                            realVal = '标清';
                        } else if (val == '480P') {
                            realVal = '高清';
                        } else if (val == '720P') {
                            realVal = '超清';
                        } else if (val == '1080P') {
                            realVal = '蓝光';
                        }
                        $('#input_' + e.id).val(realVal);
                        message.success('分辨率已调整为' + e.resolution);
                    });

                    // 监听消息
                    _this.props.videoController.addListener('msginfo', function (e) {
                        if (e.msg) {
                            message.error(e.msg);
                        }
                    });

                    //监听窗口(分屏数)变化
                    _this.props.videoController.addListener('screenchange', function (e) {
                        $('.screen-control .windows-num').removeClass('sel');
                        $('.model-' + e.windowNums).addClass('sel');

                    });

                    //监听videoServer Down
                    // _this.props.videoController.addListener('videoServerClosed', function (e) {
                    //     _this.props.videoController.destoryJanus();
                    //     _this.videoInit();
                    // })
                }


            });

        }, undefined, {'type': 'main', 'token': sessionStorage.getItem('token')});
    };

    /**
     * 保存播放记录
     */
    addPlayHistory = (recordKey, recordName, recordDeptName, recordType) => {
        let params = {
            'recordKey': recordKey,
            'recordName': recordName,
            'recordDepartment': recordDeptName,
            'recordType': recordType ? recordType : 1
        };
        commonPost(videoPart.addPlayHistory, params)
            .then(({data}) => {
                if (data.code === 0) {
                    console.log('播放记录保存成功');
                } else {
                    console.log('播放记录保存失败');
                }
            });
    };


    /**
     * 初始化视频播放的方式
     */
    initplaVideoType = () => {
        if (this.isIE()) {
            console.log('IE');
            $('.ocx-video').removeClass('hide');
            return;
        }
        $('.web-rtc-video').removeClass('hide');
    };
    /**
     * 是否安装ocx控件
     */
    hasOcx = () => {
        if (!(!!window.ActiveXObject || 'ActiveXObject' in window)) {
            return true;
        }
        try {
            var ax = new ActiveXObject('SCOOPERWEBVIDEO.ScooperWebVideoCtrl.1');
        } catch (e) {
            if (confirm('浏览器未安装ocx控件，是否安装')) {
                alert('去安装');
            } else {
                return false;
            }
        }
        return true;
    };
    /**
     * 判断是否是IE
     */
    isIE = () => {
        return !!window.ActiveXObject || 'ActiveXObject' in window;
    };


    componentWillMount() {
        // if(!this.props.videoController) {
        this.videoInit();
        // }
    }

    componentWillUnmount() {
        if (this.props.videoController) {
            this.props.videoController.closeAll();
        }
    }

    render() {
        const {selContent} = this.state;
        return (
            <div className='video-wrap'>
                <div className='screen-control'>
                    {
                        List.map((item, index) => {
                            return (
                                <button key={item.key}
                                        className={`windows-num model-${item.num} ${item.type === selContent ? 'sel' : ''}`}
                                        num={`${item.num}`} onClick={() => this.changeTab(item)}/>
                            );
                        })

                    }
                </div>
                <div className='btn-list right'>
                    <button className='btn-item' onClick={this.capturePic}><img src={zhuatu}></img>获取抓图</button>
                    {/* <button className='btn-item' onClick={this.mute}><img src={jingyin}></img>全部静音</button> */}
                    <button className='btn-item' onClick={this.closeAll}><img src={gaunbi}></img>关闭全部</button>
                    <button className='all-screen' onClick={this.fullScreen}><img src={allScreen}></img></button>
                </div>

                <div className='ocx-video hide'>
                    <div className='camera-content video-area'>

                    </div>
                </div>

                <div className='web-rtc-video hide'>
                    <div className='web-rtc-camera-content'>

                    </div>
                </div>
                <Modal
                    visible={this.state.zhutuVisible}
                    title='确定要保存图片吗？'
                    onOk={this.confirm}
                    onCancel={this.cancel}
                    okText='确定'
                    cancelText='取消'
                    className='add-config zhua-config'
                >

                </Modal>

            </div>
        );

    }

    /**
     * model change
     */
    changeTab = (item) => {
        const {videoController, patrolList} = this.props;
        if(+item.num===+videoController.getWindowsNum())
        return;
        const num = item.num;
        if (patrolList.length > 0) {
            confirm({
                title: '当前视频正在轮巡，是否要中断？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    this.props.videoController.stopPoll();
                    this.props.setPatrolAction({patrolList: []});
                    this.props.setPlayListAction({playList: []});
                    this.setState({selContent: item.type});
                    const num = item.num;

                    if (num === '6' && this.isIE()) {
                        videoController.setWindowsNum(66, 66);
                    } else if (num === '2') {
                        videoController.setWindowsNum(2, 2);
                    } else {
                        videoController.setWindowsNum(num);
                    }
                }
            });
        } else {
            this.setState({selContent: item.type});
            if (num === '6' && this.isIE()) {
                videoController.setWindowsNum(66, 66);
            } else if (num === '2') {
                videoController.setWindowsNum(2, 2);
            } else {
                videoController.setWindowsNum(num);
            }
        }

    };

    /**
     * 抓图
     */
    capturePic = () => {
        // IE 
        if (this.isIE()) {
            let videoController = this.props.videoController;
            let windows = videoController.getWindowsPlay();
            if (windows == '') {
                alert('请选择可用视频源');
                return;
            }
            $.each(windows.split(','), function (i, index) {
                var playId = videoController.getPlayIdBywindow(index);
            });
            var obj = videoController.getInChoiceVideo();
            if (!obj) {
                infoMsg('请选择可用的视频源');
                return;
            }
            var respResult = videoController.capturePicture(obj.playId);
            if (respResult) {
                console.log(respResult);
                var opts = videoController.getVideoControllerOpts();
                var videoCapImagePath = '文件存放路径为:' + ((respResult != 0 && respResult != 1) ? respResult : (opts.videoCapImagePath || ''));
                alert('截图成功！' + videoCapImagePath);
            } else {
                alert('截图失败！');
            }
        } else {
            // google
            this.setState({
                zhutuVisible: true
            });
        }

    };
    confirm = () => {
        let videoController = this.props.videoController;
        let windows = videoController._getChoiceWindow();
        if (videoController.getInChoiceVideo() == undefined) {
            message.error('请选择可用的播放源');
        }
        if (!videoController.getScreenCaptureData) {
            message.error('请检查当前环境的video库');
            return;
        }
        videoController.getScreenCaptureData(windows, function (dataUrl) {
            let getTimestamp = (new Date()).getTime();
            var a = document.createElement('a');
            // 将a的download属性设置为我们想要下载的图片名称
            a.download = getTimestamp;
            // 将生成的URL设置为a.href属性
            a.href = dataUrl;
            // 触发a的单击事件
            a.click();
        });
        this.setState({
            zhutuVisible: false
        });


    };
    cancel = () => {
        this.setState({
            zhutuVisible: false
        });
    };
    /**
     * 静音
     */
    mute = () => {

    };
    /**
     * 关闭全部
     */
    closeAll = () => {
        this.props.videoController.closeAll();
        this.props.setPlayListAction({playList: []});
    };
    /**
     * 全屏
     */
    fullScreen = () => {
        this.props.videoController.setFullScreen();
    };
});