/**
 * @author: MASu
 * @date: 2018-12-13
 * @Last Modified by MASu
 * @Last Modified time 2018-12-13
 */
import EventSubscribe from '@/lib/event-subscribe';
import {URLS} from './constants';

/* 
* 消息监听
* 
*/
export default new class extends EventSubscribe {
    constructor() {
        super();
        this.subscribeIsInit = false;
        // this.initSubscribe();
        console.log('===========EventSubscribe constructor 事件初始化');
    }

    initSubscribe() {
        if (!this.subscribeIsInit) {
            console.log('===========Cometd 事件初始化');
            this.subscribeIsInit = true;
            this.setCometdUrl('/scooper-msg-queue/cometd');
            this.init();
            this.registerSubscribe();
        }
    }

    registerSubscribe() {
        this.register('sse_connect_finish', () => {
            Object.keys(URLS).forEach(key_1 => {
                this.subscribe(URLS[key_1], data => {
                    this.fire(`${key_1}`, data.data);
                });
            });
        });
    }
}();
