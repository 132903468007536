/*
 * File Created: 2019-12-21
 * Author:Chenhuan
 * Desc: 通讯录导入
 * -----
 */
import React from "react";
import reqwest from "reqwest";
import { Modal, Form, message, Icon, Upload, Button } from "antd";
import { scooper_app_api } from "@/util/api";
import { getToken } from "@/config/constans";

const { Dragger } = Upload;
class BulkImportModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editCheck: {},
            fileList: [],
            recordInfo: [],
            //加载
            confirmLoading: false
        };
    }

    componentDidMount() {
        this.props.showModal(this.showModal);
    }

    //暴露接口给父组件来打开弹窗
    showModal = async data => {
        this.setState({
            visible: true,
            recordInfo: data.recordInfo,
            confirmLoading: false
        });
    };


    // 取消添加
    handleCancel = () => {
        this.props.form.resetFields();
        this.setState({
            visible: false,
            fileList: []
        });
    };

    // 提交上传
    Submit() {
        const { fileList, recordInfo } = this.state;
        this.setState({
            confirmLoading: true
        });
        if (fileList.length > 0) {
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append("file", file);
            });
            reqwest({
                url:
                    "/scooper-poc-manager/data/dev/memberPocImportCloude?token=" +
                    getToken() +
                    "&firstDeptId=" +
                    recordInfo.id +
                    "&corpId=" +
                    recordInfo.corpId + 
                    "&isCreateLog=1",
                method: "post",
                processData: false,
                data: formData,
                success: resp => {
                    this.setState({
                        fileList: [],
                        visible: false,
                        createMember: 0
                    });
                    this.props.onInitTree();
                    if (resp.code === 0) {
                        this.props.loadTable();
                        message.success(resp.message);
                    } else {
                        message.warning(resp.message);
                    }
                },
                error: () => {
                    this.setState({
                        confirmLoading: false
                    });
                    message.error("上传失败.");
                }
            });
        } else {
            this.setState({
                confirmLoading: false
            });
            message.warning("请选择文件");
        }
    }

    //下载模板
    download() {
        const token = getToken();
        let fileSrc = `/scooper-poc-manager/data/dev/getOrgFormwork?token=${token}&corpId=${this.state.recordInfo.corpId}`;
        if (!document.getElementById("downloadFrame")) {
            let downFile = document.createElement("iframe");
            downFile.id = "downloadFrame";
            downFile.style.display = "none";
            document.body.appendChild(downFile);
        }
        document.getElementById("downloadFrame").src = fileSrc;
    }

    //获取客户名称
    loadCenter() {
        scooper_app_api.listAdCode().then(res => {
            this.setState({
                companyList: res
            });
        });
    }

    render() {
        const { visible, fileList, confirmLoading } = this.state;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 }
        };
        const props = {
            name: "file",
            multiple: false,
            accept: ".xls,.xlsx",
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));
                return false;
            },
            fileList
        };

        return (
            <React.Fragment>
                <Modal
                    maskClosable={false}
                    title={"通讯录导入"}
                    visible={visible}
                    onOk={() => this.Submit()}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                    okText="提交"
                    cancelText="取消"
                    className="edit-gather"
                >
                    <Form {...formItemLayout}>
                        <Form.Item label="模板下载">
                            <Button type="primary" onClick={() => this.download()}>
                                下载模板
                            </Button>
                        </Form.Item>

                        <Form.Item label="请上传文件">
                            <Dragger {...props} fileList={this.state.fileList}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                            </Dragger>
                        </Form.Item>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Form.create()(BulkImportModal);
