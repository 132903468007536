import React, {Component} from 'react';
import FormItem from 'antd/es/form/FormItem';
import {Button, Form, Input, message} from 'antd';
import {sha256} from 'js-sha256';
import {scooper_poc_log, scooper_poc_server_login} from '@/rest-api/restapi';

class LoginPanel extends Component {
    state = {
        disabled: false,    // 是否允许登录，false允许。true不允许
        checked: false, // 是否勾选下次自动登录，false不勾选，true勾选
        imageSrc: '' // 验证码请求路径
    };

    componentDidMount() {
        const agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf('chrome') === -1) {
            message.error('不允许登录和操作！');
            this.setState({
                disabled: true
            });
        }

        this.changeImage();
        this.doLoginByToken().then(() => {
        });
    }

    /**
     * 清空输入框的值(用户名)
     */
    clearValueUser = () => {
        this.props.form.setFieldsValue({
            username: ''
        });
    };

    /**
     * 清空输入框的值(密码)
     */
    clearValuePass = () => {
        this.props.form.setFieldsValue({
            password: ''
        });
    };

    /**
     * 登录验证码图片
     */
    changeImage = () => {
        const t = new Date().getTime();
        const {modulesUrl} = window.config;
        this.setState({
            imageSrc: `/scooper-poc-server/api/login/getCaptcha?t=${t}`
        });
    };

    getAccountInfoMethod = async (params) => {
        const {token, refreshToken, username, password, systemTime} = params;
        const accountInfoResponse = await scooper_poc_server_login.getAccountInfoByToken({token});
        try {
            if (accountInfoResponse.code === 0) {
                const {
                    orgCode,
                    firstDeptId,
                    accountVo,
                    orgMemberVo,
                    orgResId
                } = accountInfoResponse.data;
                const {centerId, id, corpId, accUsername} = accountVo;
                const {memName, memPicture} = orgMemberVo;
                window.auth.fillAuthInfo({
                    token,
                    refreshToken
                });
                sessionStorage.setItem('orgCode', orgCode);
                sessionStorage.setItem('orgResId', orgResId);
                sessionStorage.setItem('firstDeptId', firstDeptId);
                localStorage.setItem('firstDeptId', firstDeptId);
                sessionStorage.setItem('centerId', centerId);
                sessionStorage.setItem('memName', memName);
                sessionStorage.setItem('accId', id);
                localStorage.setItem('accId', id);
                sessionStorage.setItem('corpId', corpId);
                sessionStorage.setItem('username', username);
                sessionStorage.setItem('accUsername', accUsername);
                sessionStorage.setItem('avatar', memPicture);
                sessionStorage.setItem('password', password);
                sessionStorage.setItem('avatar', memPicture);
                localStorage.setItem('corpId', corpId);
                if (this.state.checked) {
                    localStorage.setItem('username', username);
                    localStorage.setItem('password', password);
                    localStorage.setItem('token', token);
                    localStorage.setItem('accId', id);
                    localStorage.setItem('flag', 'true');
                    // localStorage.setItem('memName', memName)
                }
                this.props.loginSuccess();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * 点击登录按钮，登录操作
     */
    handleSubmit = async (e) => {
        const _this = this;
        e.preventDefault();
        const {
            form: {validateFields}
        } = this.props;
        const loginTime = new Date().getTime();
        sessionStorage.setItem('loginTime', loginTime.toString());
        validateFields(async (err, values) => {     //校验并获取一组输入域的值
            if (!err) {
                const {username, password, verify} = values;

                const loginParams = {
                    username,
                    password: sha256(password),
                    captcha: verify,
                    loginTime
                };
                const loginResponse = await scooper_poc_server_login.login(loginParams);
                if (!loginResponse.code) {
                    const {systemTime} = loginResponse;
                    const {token, refreshToken, needChangePwd} = loginResponse.data;
                    const params = {token, refreshToken, ...loginParams};
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('accessTime', systemTime);
                    if (typeof needChangePwd !== 'undefined') {
                        switch (needChangePwd) {
                            case 0:
                                await _this.getAccountInfoMethod(params);
                                break;
                            case 1:
                            case 2:
                                _this.props.resetPassWord(needChangePwd);
                                break;
                            default:
                                break;
                        }
                    } else {
                        await _this.getAccountInfoMethod(params);
                        // 测试代码
                        // _this.props.resetPassWord(1);
                    }
                    // _this.props.history.push(`/home`);
                } else {
                    message.error(loginResponse.message || '登录失败');
                    _this.changeImage();
                    _this.props.form.setFieldsValue({
                        verify: ''
                    });
                }
            }
        });
    };

    /**
     * 自动登录功能，使用Token登录
     */
    doLoginByToken = async () => {
        const _this = this;
        if (localStorage.getItem('flag') === 'true') {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            this.props.form.setFieldsValue({'username': username});
            this.props.form.setFieldsValue({'password': password});
            if (username) {
                this.setState({
                    checked: true
                });
            }
            const token = localStorage.getItem('token');
            const _loginTime = new Date().getTime();
            const param = {
                token,
                username: localStorage.getItem('username'),
                loginTime: _loginTime
            };
            const data = await scooper_poc_log.doLoginByToken(param);
            if (!data.code) {
                const {orgCode, accountVo, orgMemberVo, firstDeptId, validateVo} = data.data;
                const {validToken, centerId, corpId, accUsername, accessTime} = accountVo;
                const {memName, memPicture} = orgMemberVo;
                window.auth.fillAuthInfo({
                    token,
                    refreshToken: ''
                });
                // 使用延时处理自动登录
                setTimeout(function () {
                    sessionStorage.setItem('orgCode', orgCode);
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('memName', memName);
                    sessionStorage.setItem('firstDeptId', firstDeptId);
                    localStorage.setItem('firstDeptId', firstDeptId);
                    sessionStorage.setItem('centerId', centerId);
                    // localStorage.setItem('token', data.data.accountVo.validToken);
                    localStorage.setItem('corpId', corpId);
                    sessionStorage.setItem('username', accUsername);
                    sessionStorage.setItem('username', localStorage.getItem('username'));
                    localStorage.setItem('accId', localStorage.getItem('accId'));
                    sessionStorage.setItem('accId', localStorage.getItem('accId'));
                    localStorage.setItem('avatar', memPicture);
                    // sessionStorage.setItem('password',values.password);
                    sessionStorage.setItem('accessTime', accessTime);
                    localStorage.setItem('avatar', memPicture);
                    sessionStorage.setItem('corpId', corpId);
                    // _this.props.history.push(`/home`);
                    _this.props.loginSuccess();
                    // sessionStorage.setItem('corpId', data.data.accountVo.corpId);
                }, 1500);
            }
        }
    };

    toggleChecked = async () => {
        this.setState({
            checked: !this.state.checked
        });
        if (!this.state.checked) {
            localStorage.clear();
            localStorage.setItem('username', this.state.username);
            localStorage.setItem('password', this.state.password);
            await localStorage.removeItem('flag');
        }
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {disabled, imageSrc, checked} = this.state;
        return (
            <div className="login-form">
                <div className="user-message">
                    <div className="user-avatar">
                        <i/>
                    </div>
                    <div className="login-title">
                        用户登录
                    </div>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                        <i className="user-left icon-left"/>
                        {getFieldDecorator('username', {
                            rules: [{required: true, message: '请输入账号!'}]
                        })(
                            <Input placeholder="用户名" className="username" autoComplete="off"
                                   disabled={disabled}/>
                        )}
                        <i className="user-right icon-right" onClick={this.clearValueUser}/>
                    </Form.Item>
                    <FormItem>
                        <i className="pass-left icon-left"/>
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: '请输入密码!'}]
                        })(
                            <Input type="password" placeholder="密码" className="password"
                                   disabled={disabled}/>
                        )}
                        <i className="pass-right icon-right" onClick={this.clearValuePass}/>
                    </FormItem>
                    <FormItem>
                        <i className="verify-left icon-left"/>
                        {getFieldDecorator('verify', {
                            rules: [{required: true, message: '请输入验证码!'}]
                        })(
                            <Input type="text" placeholder="验证码" className="verify-input" autoComplete="off"
                                   disabled={disabled}/>
                        )}
                        <img onClick={this.changeImage} src={imageSrc} className="verify-img"
                             alt={'验证码'}/>

                    </FormItem>
                    <FormItem>
                        <Input type="checkbox" className="auto-login" checked={checked}
                               onClick={this.toggleChecked}/><span className="auto-text">下次自动登录</span>
                    </FormItem>
                    <FormItem>
                        <Button htmlType="submit" className="submit-btn">
                            登 录
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default Form.create()(LoginPanel);