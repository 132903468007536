/*
 * @Description: 历史记录
 * @Author: liulian
 * @Date: 2019-09-30 15:09:45
 * @LastEditTime: 2022-03-22 19:39:02
 * @LastEditors: Please set LastEditors
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {videoPart} from '@/rest-api/restapi-video';
import {commonGet, commonPost} from '@/request/common-axios';
import {
    setPlayRecordListAction,
    setCollectAction,
    setCollectItemAction,
    setSearchAction,
    setCollectedDicAction,
    setCollectParentAction
} from '@/reducer/video_action';

import {Menu, Input, Icon, Alert, message, Modal, Select, Popconfirm, Form} from 'antd';
import {openCloud} from '../../components/dispatch-cloud';
import Frame from 'react-frame-component';
import playImg from '../../../image/video-img/yunyan1.png';
import collectImg from '../../../image/video-img/collect.png';
import collectNoImg from '../../../image/video-img/collecta.png';
import yuyuan1 from '../../../image/video-img/yunyan.png';
import {isEmpty} from 'lodash';
import video from '../video/video';
import Pinyin from 'pinyin';
import { queryflow_api} from '@/rest-api/restapi';
import { commonPostJson } from '../../../request/common-axios';


const {Search} = Input;
const {Option} = Select;


const PlayHistory = connect(
    state => {
        return {
            playRecordList: state.playRecordList,
            videoController: state.videoController,
            collectTreeParent: state.collectTreeParent,
            collectItem: state.collectItem,
            collectTree: state.collectTree,
            search: state.search,
            isFlow: state.isFlow,
            collectedDic: state.collectedDic
        };
    },
    {
        setPlayRecordListAction,
        setCollectAction,
        setCollectItemAction,
        setSearchAction,
        setCollectedDicAction,
        setCollectParentAction

    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hisPageNum: 1,
            total: 0,
            recordKey: '',
            visible: false,
            bookmark_id: -1,
            confirmVisible: false,
            cancelId: -1000
        };
    }

    /**
     * 获取视频播放历史
     */
    loadPlayHistoryList = async (pageNumber) => {
        const _this = this;
        // console.log(_this)
        let params = {
            pageNum: pageNumber,
            pageSize: 15
        };
        let response = await commonPost(videoPart.queryPlayHistoryList, params);
        const {data} = response;
        if (data.code === 0) {
            _this.props.setPlayRecordListAction({playRecordList: data.data.list});
            _this.setState({total: data.data.total});
            if (data.data.list.length > 0) {
                _this.state.hisPageNum++;
            }
        } else {
            console.log('播放历史记录获取失败');
        }
    };

    /**
     * 清空收藏夹记录
     */
    clearList = async () => {
        const token = sessionStorage.getItem('token');
        let data = await commonPost(videoPart.clearAllPlayHistories, {token});
        if (data.data.code === 0) {
            // this.searchList(this.props.search);
            message.success('清空记录成功！');
            this.props.setPlayRecordListAction({playRecordList: []});
            this.setState({total:0})
        }
    };

    /**
     * 滚动加载
     */
    // scrollEvent = () => {
    //     // $(".his")
    //     var _this = this;
    //     $(".ant-menu").scroll(function(){

    //         var viewH =$(this)[0].clientHeight;//可见高度
    //         var contentH =$(this).get(0).scrollHeight;//内容高度
    //         var scrollTop =$(this).scrollTop();//滚动高度
    //         var flag = true;
    //         if(contentH - viewH - scrollTop <=0 && flag){
    //             _this.loadPlayHistoryList(_this.state.hisPageNum);
    //         }
    //     })
    // }


    componentDidMount() {
        this.loadPlayHistoryList(this.state.hisPageNum);
        if (this.props.videoController) {
            this.props.videoController.addListener('playsuccess', function (e) {
                $('.video-' + e.id).css('background-image', `url(${playImg})`);
            });
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {collectedDic} = this.props
        const StyleIframe = {
            wrap: {
                position: 'absolute',
                visibility: 'inherit',
                top: '-6px',
                left: '-5px',
                width: '372px',
                height: '210px',
                zIndex: '-1',
                border: 'medium',
                filter: 'alpha(opacity=0)'

            }
        };
        return (
            <div className="history-wrap">
                <div className="history-list">
                    <span className="total">共{this.state.total}条播放记录</span>
                    <button className="remove-list" onClick={this.clearList}>清空记录</button>
                    <Search
                        className="input-search"
                        id="search-video"
                        prefix={<Icon type="search" style={{color: '#057797'}}/>}
                        placeholder="搜索"
                        onChange={(e) => {
                            this.searchList(e.target.value);
                        }}
                    />
                    <Menu>
                        {!isEmpty(this.props.playRecordList) && this.props.playRecordList.map((item) => {
                            return (
                                <Menu.Item key={`plan-${item.id}`} onMouseEnter={() => {
                                    this.enter(item.id, item.recordKey);
                                }} onMouseLeave={() => {
                                    this.leave(item.id);
                                }}>
                                    <span className="monitor-name">{item.recordName}</span>
                                    <span
                                        className={`hide btn-${item.id} video-${item.video_id} plays-${item.recordKey}  play-list-icon`}
                                        id={`video-${item.id}`} onClick={() => {
                                        this.play(item.recordKey, item.recordName, item.recordDepartment);
                                    }}/>
                                    {console.log("collectedDic",collectedDic)}
                                    <span className={`${collectedDic.hasOwnProperty(item.recordKey)?'all-collect':'collect-icon'}  hide collect-${item.id} colvide-${item.recordKey}`}
                                          id={`collect-${item.id}`} onClick={() => {
                                        this.collect(item.recordKey, item.recordName);
                                    }}/>
                                    <span className="department left"
                                          title={item.recordDepartment}>{item.recordDepartment}</span>
                                    <span className="time right">{item.recoredTime}</span>

                                </Menu.Item>

                            );
                        })}
                    </Menu>
                    <Modal
                        title="选择收藏夹"
                        okText="确定"
                        cancelText="取消"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        className="add-config add-collect"
                    >
                        {getFieldDecorator('collect', {
                            initialValue: ''
                        })(
                            <Select
                                showSearch
                                style={{width: 200}}
                                placeholder="请选择收藏夹"
                                optionFilterProp="children"
                                onChange={this.onChange}
                                onSearch={this.onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.InitOptions().map((item) => (
                                    <Option key={item.id} value={item.id}>{item.bookmarkName}</Option>))}
                            </Select>
                        )}
                        <Frame style={StyleIframe.wrap}/>
                    </Modal>

                </div>

                {/* <Popconfirm
                    visible={this.state.confirmVisible}
                    title="确定要取消收藏吗？"
                    onConfirm={this.confirm}
                    onCancel={this.cancel}
                    okText="确定"
                    cancelText="取消"
                > */}
                <Modal
                    title="确定要取消收藏吗?"
                    visible={this.state.confirmVisible}
                    okText="确定"
                    cancelText="取消"
                    onOk={this.confirm}
                    onCancel={this.cancel}
                    className="add-config delConfirm"
                >
                    <Frame style={StyleIframe.wrap}/>
                </Modal>
            </div>
        );
    }

    searchList = async (value) => {
        let search;
        if (value == '') {
            search = '';
        } else {
            search = value;
        }
        this.props.setSearchAction({search});
        let params = {
            pageNumber: 1,
            pageSize: 500,
            search: ''
        };
        let data = await commonPost(videoPart.queryPlayHistoryList, params);
        let recordList = data.data.data.list;

        //播放历史记录中增加全拼和首字母的字段
        recordList && recordList.length > 0 && recordList.map((item) => {
            var getPinYin = Pinyin(item.recordName, {
                style: Pinyin.STYLE_NORMAL
            });

            //获取全拼
            var result1 = getPinYin.join('');
            item['fullPinyin'] = result1;
            //获取首字母
            var list = [];
            getPinYin && getPinYin.length > 0 && getPinYin.map(item => {
                list.push(item[0].substr(0, 1));
            });
            var result2 = list.join('');
            item['initialPinyin'] = result2;
        });
        //匹配搜索字段
        let resultList = [];
        recordList && recordList.length > 0 && recordList.map((val) => {
            if (val.fullPinyin.indexOf(value) > -1 || val.initialPinyin.indexOf(value) > -1 || val.recordName.indexOf(value) > -1) {
                resultList.push(val);
            }
        });

        this.props.setPlayRecordListAction({playRecordList: resultList});
        this.setState({
            total: data.data.data.total
        });
    };
    /**
     * 鼠标进入
     */
    enter = async (id, propKey) => {
        $('.btn-' + id).removeClass('hide');
        $('.collect-' + id).removeClass('hide');
        /*let param = {
            propKey: propKey,
            catalogType: 1
        };
        let data = await commonGet(videoPart.loadBookmarkCatalogInfo, param);
        if (data.data.data !== null) {
            //已收藏
            $('.colvide-' + propKey).css('background-image', `url(${collectImg})`);
        }
        if (this.props.videoController.isPlaying(propKey) != -1) {
            $('.plays-' + propKey).css('background-image', `url(${yuyuan1})`);
        }*/
    };
    /**
     * 鼠标移除
     */
    leave = (id) => {
        $('.btn-' + id).addClass('hide');
        $('.collect-' + id).addClass('hide');
    };
    /**
     * 播放
     */
    play = (id, name, department) => {
        const _this = this;
        const {isFlow} = _this.props;
        var isClick = true;
        if (isClick) {
            isClick = false;
            if (_this.props.videoController.isPlaying(id) != -1) {
                $('.plays-' + id).css('background-image', `url(${yuyuan1})`);
                // 已播放
                message.error('该视频正在播放');
            } else {
                // 调云眼接口
                var param = {
                    sipTel: id,
                    type: 'video'
                };

                if (isFlow) {
                    queryflow_api.queryDevFlowState(param).then(resp => {
                        if (!resp.data.obj) {
                            message.error(resp.message || '设备流量达上限！');
                            return;
                        } else {
                            if (resp.message) {
                                message.warning(resp.message);
                            }
                            _this.openVideo(id, name, department);
                        }
                    });
                } else {
                    _this.openVideo(id, name, department);
                }


            }
            setTimeout(function () {
                isClick = true;
            }, 500);
            $('.video-' + id).css('background-image', `url(${playImg})`);
        }


        //  _this.props.videoController.playByOrderExpandWindow('2002', 5, { name: "前台", parentName: "监控平台" });
    };


    /**
     * 调度打开云眼
     */
    openVideo = (id, name, department) => {
        const self = this;
        scooper.poc.groupPoc.openCloudEye(id, function (data) {
            if (data.data.result !== 'fail' && data.code == 0) {
                self.props.videoController.playByOrderExpandWindow(id, id, {name: name, parentName: department});
            } else {
                var reason;
                if (data.data.reason) {
                    reason = data.data.reason;
                    if (reason.indexOf('非预呼叫对讲成员') > -1) {
                        reason = '设备离线!';
                    } else if (reason.indexOf('无此对讲成员') > -1) {
                        reason = '该成员未绑定设备！';
                    }

                } else {
                    reason = '';
                }
                message.error(reason);
            }
        });
    };
    /**
     * 收藏
     */
    collect = async (recordKey, name) => {
        var _this = this;
        _this.setState({
            recordKey: recordKey
        });
        let param = {
            propKey: recordKey,
            catalogType: 1
        };
        if(_this.props.collectedDic.hasOwnProperty(recordKey)){
            // 已收藏
            // let collectItem = {
            //     propKey: data.data.data.prop_key,
            //     catalogType: data.data.data.catalog_type,
            //     name: data.data.data.name,
            //     bookmarkId: data.data.data.bookmark_id
            // };
            // _this.props.setCollectItemAction({collectItem});
            _this.setState({
                cancelId: recordKey,
                confirmVisible: true
            });
        }else{
            // 未收藏
            _this.setState({
                visible: true
            });
            let collectItem = {
                propKey: this.state.recordKey,
                catalogType: 1,
                name: name
            };
            _this.props.setCollectItemAction({collectItem});
        }
        // let data = await commonPost(videoPart.loadBookmarkCatalogInfo, param);
        // if (data.data.data) {
        //     //已收藏
        //     let collectItem = {
        //         propKey: data.data.data.prop_key,
        //         catalogType: data.data.data.catalog_type,
        //         name: data.data.data.name,
        //         bookmarkId: data.data.data.bookmark_id
        //     };
        //     _this.props.setCollectItemAction({collectItem});
        //     _this.setState({
        //         cancelId: data.data.data.prop_key,
        //         confirmVisible: true
        //     });
        //     // _this.showDeleteConfirm();


        // } else {
        //     _this.setState({
        //         visible: true
        //     });
        //     //未收藏
        //     let collectItem = {
        //         propKey: this.state.recordKey,
        //         catalogType: 1,
        //         name: name
        //     };
        //     _this.props.setCollectItemAction({collectItem});
        // }
    };

    // showDeleteConfirm = () => {
    //     var _this = this
    //     confirm({
    //         title: '确定要取消收藏吗?',
    //         okText: '确定',
    //         okType: 'danger',
    //         className: 'delConfirm',
    //         cancelText: '取消',
    //         onOk() {
    //             _this.confirm()
    //         },
    //         onCancel() {
    //             _this.cancel();
    //         },
    //     });
    // }
    /**
     * 确认收藏
     */
    handleOk = async () => {
        // console.log(this.state.recordKey)
        const paramItem = this.props.collectItem;
        let param = [{
            propKey: this.state.recordKey,
            catalogType: 1,
            bookmarkId: this.state.bookmark_id,
            catalogId: this.state.recordKey,
            name: paramItem.name
        }];
        console.log("收藏收藏收藏",param);
        if (param[0].bookmarkId === -1) {
            return message.warn('请选择收藏夹');
        }
        commonPostJson(videoPart.saveBookmarkCatalog, param)
        .then(({data}) => {
            if (data.code == 0) {
                message.success('收藏成功！');
                this.loadBookmarkCatalog();
                let collet = {
                    bookmarkId: this.state.bookmark_id,
                    propKeys: paramItem.propKey,
                    catalogType: 1,
                    name: paramItem.name
    
                };
                this.props.form.setFieldsValue({
                    collect: ''
                });
                this.props.setCollectItemAction({collectItem: collet});
                console.log(this.state.recordKey);
                $('.colvide-' + this.state.recordKey).css('background-image', `url(${collectImg})`);
    
                this.setState({
                    visible: false
                });
            }
        })
       
    };

    loadBookmarkCatalog = async () => {
        let param = {};
        let data = await commonPost(videoPart.loadCollectList, param);
        if (data.data.code === 0) {
            this.props.setCollectAction({collectTree: data.data.list});
            this.loadCollectParent()
        }
    };
     /**
     * 获取收藏夹的数据，并放入redux
     * @return {Promise<void>}
     */
      loadCollectParent = async () => {
        let param = {};
        let response = await commonPost(videoPart.loadCollectList, param);
        if (response.data.code === 0) {
            const {list} = response.data.data;
            const collectedDic = {};
            list.forEach(item => {
                item.isParent = true;
                const {bookmarkCatalogs} = item;
                const collectList = [];
                bookmarkCatalogs.length && bookmarkCatalogs.forEach(devItem => {
                    devItem.isCollected = true;
                    collectedDic[devItem.catalogId] = devItem;
                    collectList.push(devItem);
                    this.setState({collectList});
                    this.props.setCollectedDicAction({collectedDic});
                });
            });
            this.props.setCollectParentAction({collectTreeParent: response.data.data.list});
        }
    };
    /**
     * 取消
     */
    handleCancel = () => {
        this.setState({
            visible: false
        });
        this.props.form.setFieldsValue({
            collect: ''
        });
    };
    onSearch = (val) => {
        // console.log('search:', val);
    };
    onChange = (value) => {
        this.setState({
            bookmark_id: `${value}`
        });
        // console.log(this.state.bookmark_id);


    };
    /**
     * 得到收藏夹中的根节点
     */
    InitOptions = () => {
        let treeParent = this.props.collectTreeParent;
        const options = [];
        treeParent.forEach((item, index) => {
            options[index] = item;

        });
        return options;
    };

    /**
     * 取消收藏的确定
     */
    confirm = (e) => {
        let {collectedDic} = this.props;
        let {cancelId} = this.state;
        this.setState({
            confirmVisible: false
        });

        let paramCancel = {
            ids:collectedDic[cancelId].id
        };
        console.log("collectedDic1",collectedDic)

        commonPost(videoPart.deleteBookmarkCatalog, paramCancel)
            .then(({data}) => {
                if (data.code === 0) {
                    // 保存成功重新加载收藏夹树
                    this.loadBookmarkCatalog();
                    // // '[id=\'member-more-operate' + this.state.cancelId + '\']'
                    // const idValue = `[id="member-more-operate${this.state.cancelId}"]`;
                    // $(idValue).removeClass('allCollect');
                    // $(idValue).css('background-image', `url(${collecty})`);
                    // $(idValue).attr('title', '收藏');
                    delete collectedDic[cancelId];
                    console.log("collectedDic2",collectedDic)
                    this.props.setCollectedDicAction({collectedDic});
                    $('.colvide-' + this.state.cancelId).css('background-image', `url(${collectNoImg})`);
                    message.success('取消收藏成功');
                }
            });
    };
    // getCatlogId = () => {}

    /**
     * 取消收藏的取消
     */
    cancel = (e) => {
        console.log(e);
        this.setState({
            confirmVisible: false
        });

    };

});

export default Form.create()(PlayHistory);

