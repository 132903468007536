import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/extension/bmap/BMapCoordSys';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import {reduce} from 'zrender/lib/core/util';
// import ReactEcharts from 'echarts-for-react'

import {connect} from 'react-redux';

export default connect(
    state => {
        return {
            gatherDetail: state.gatherDetail
        };
    },
    {}
)
(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * 创建环形饼图(磁盘使用率)
     */
    pieCreate = () => {
        var myChart = echarts.init(document.getElementById('disk-use'));
        myChart.setOption({
            tooltip: {},
            coordinateSystem: 'cartesian2d',
            xAxis: {
                show: false
            },
            yAxis: {
                show: false
            },
            series: [{
                type: 'pie',
                radius: ['60%', '80%'],
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                        textStyle: {
                            fontSize: 12

                        },
                        formatter: '{b}\n{c}%'
                    }
                },
                data: [{
                    value: `${this.props.gatherDetail.diskUsage}`,
                    name: '磁盘使用率',
                    label: {
                        normal: {
                            show: true,
                            color: '#EDF8FF'
                        }
                    },
                    itemStyle: {
                        color: {
                            type: 'linear',
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(0,252,255,1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1, color: 'rgba(0,162,237,1)' // 100% 处的颜色
                                }]
                        }
                    }
                },
                    {
                        value: 100 - `${this.props.gatherDetail.diskUsage}`,
                        name: '',
                        itemStyle: {
                            color: '#003273'
                        }
                    }
                ],
                x: 'center',
                y: 'center',
                labelLine: {
                    normal: {
                        show: false
                    }
                }
            }]
        });
    };

    componentDidMount() {
        this.pieCreate();
    }

    componentDidUpdate() {
        this.pieCreate();
    }


    render() {
        return (
            <div id="disk-use" style={{width: 150, height: 150}}></div>
        );
    }
});