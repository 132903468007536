/**
 * Created by zhujj on 2018/7/29.
 */
/**
 * 事件基类：提供事件注册及派发
 */
export default class EventDispatcher {
    eventMap = {}

    /**
     * @description 注册事件
     * @author zhujj
     * @param {string} event 事件名
     * @param {function} handler 事情函数
     * @memberof EventDispatcher
     */
    register(event, handler) {
        if (!this.eventMap[event]) {
            this.eventMap[event] = [];
        }
        this.eventMap[event].push(handler)
    }

    /**
     * @description 取消注册事件
     * @author zhujj
     * @param {string} event 事件名
     * @param {function} handler 事情函数，注意：该与注册时的函数为同一指向
     * @returns 
     * @memberof EventDispatcher
     */
    unregister(event, handler) {
        let handlers = this.eventMap[event]
        if (!handlers) {
            return
        }

        if (!handler) {
            this.eventMap[event] = null;
            return;
        }

        let index = handlers.indexOf(handler)
        if (index === -1) {     // -1 is meaningful as index.
            return
        }
        handlers.splice(index, 1)
    }

    /**
     * @description 派发事件
     * @author zhujj
     * @param {*} event 事件名
     * @param {*} [data] 参数
     * @returns 
     * @memberof EventDispatcher
     */
    fire(event, data) {
        let handlers = this.eventMap[event]
        console.debug(this.eventMap[event]);

        if (!handlers) {
            return
        }

        handlers.forEach(handler => {
            try {
                handler.call(this, data);
            } catch(e) {
                throw e;
            }
        })
    }
}
