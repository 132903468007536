import React from 'react';
import echarts from 'echarts/dist/echarts.common';
import 'echarts/extension/bmap/BMapCoordSys';


import { connect } from 'react-redux';

export default connect (
  
)
( class extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentWillReceiveProps(nextProps){
        var myChart = echarts.init(document.getElementById("pitPie1"));
        myChart.setOption({
            tooltip: {},
            coordinateSystem: 'cartesian2d',
            // getCoordSysModel: '',
            formatter:'{b}\n{c}%',
            xAxis: {
                show: false
            },
            yAxis: {
                show:false
            },
            series: [{
                type: 'pie',
                radius: ['70%', '90%'],
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                        textStyle: {
                            fontSize: 12,
                           
                        },
                        formatter:'{b}\n{c}%'
                    }
                },
                data: [{
                    value: nextProps.gatherCount.dasCount !== 0 ? ((nextProps.gatherCount.dasOnlineCount/nextProps.gatherCount.dasCount)*100).toFixed(2) : 0,
                    name: `采集站在线${nextProps.gatherCount.dasOnlineCount}台`,
                    label:{  
                        normal:{
                            show: true,
                            color: '#EDF8FF',
                        }
                    },
                    itemStyle: {
                        color: {
                            type: 'linear',
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(0,252,255,1)' // 0% 处的颜色
                                }, 
                                {
                                    offset: 1, color: 'rgba(0,162,237,1)' // 100% 处的颜色
                                }],
                        }
                    }    
                },
                {
                    value: 100 - (nextProps.gatherCount.dasCount !== 0 ? ((nextProps.gatherCount.dasOnlineCount/nextProps.gatherCount.dasCount)*100).toFixed(0) : 0),
                    name: '',
                    itemStyle: {
                        color: '#003273'
                    }
                },
               
            ],
                x:'center',
                y:'center',
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                
            }]
        });
    
    }

    render(){
        return(
            <div id="pitPie1" style={{width:180,height: 180}}></div>
        )
    }
})