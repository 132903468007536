/*
 * @Description: 数据时长统计
 * @Author: liulian
 * @Date: 2019-10-08 15:27:16
 * @LastEditTime: 2019-10-25 09:10:59
 * @LastEditors: Please set LastEditors
 */
import React, {Component} from 'react';
import echarts from 'echarts';
import {connect} from 'react-redux';
import {debounce, isEqual} from 'lodash';
import {setFileLengthAction} from '@/reducer/video_action';
import {statistics} from '@/rest-api/restapi-video';
import {commonGet, commonPost} from '@/request/common-axios';
import resizeFootSize from './ResizeFontSize';

export default connect(
    state => {
        return {
            fileLength: state.fileLength
        };
    },
    {
        setFileLengthAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.setCatOption = this.setCatOption.bind(this);
        this.initChars = this.initChars.bind(this);
    }

    componentDidMount() {
        this.initChars();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.fileLength, nextProps.fileLength)) {
            this.initChars(nextProps, 1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFn);
        this.myChart = null;
    }

    initChars = (nextProps, type) => {
        if (!this.catChart) return;

        const {data} = nextProps || this.props; //外部传入的data数据
        let time = [];
        let img = [];
        let audo = [];
        let video = [];
        if (data) {
            data.forEach((item, index) => {
                time.push(item.minutes);
                img.push(item.image);
                audo.push(item.audio);
                video.push(item.video);

            });
        }


        this.myChart = echarts.init(this.catChart, 'customed'); //初始化echarts

        let options = null;
        options = this.setCatOption(time, img, audo, video);

        this.myChart.clear();
        //设置options
        this.myChart.setOption(options);

        this.myChart.resize();

        window.addEventListener('resize', this.resizeFn = debounce(() => {
            this.myChart && this.myChart.resize();
        }, 200));
    };

    setCatOption = (time, img, audo, video) => {
        const fontSize = resizeFootSize();
        return {
            title: {},
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            legend: {
                data: ['音频', '视频'],
                right: '1%',
                top: '10',
                textStyle: {
                    color: '#8ED2F6',
                    fontSize: 0.14 * fontSize
                }

            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },

            xAxis: [{
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                type: 'category',
                data: time
            }],
            yAxis: [{
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                splitLine: {
                    show: true,
                    lineStyle: {color: ['#0F2145']}
                },
                type: 'value'
            }],
            series: [
                // {
                //     name: '图片',
                //     type: 'bar',
                //     barWidth: 14,
                //     itemStyle: { color: '#00B2FC' },
                //     data: img
                // },
                {
                    name: '音频',
                    type: 'bar',
                    barWidth: 0.14 * fontSize,
                    itemStyle: {color: '#00E762'},
                    data: audo
                },
                {
                    name: '视频',
                    type: 'bar',
                    barWidth: 0.14 * fontSize,
                    itemStyle: {color: '#FFB533'},
                    data: video
                }
            ]
        };
    };


    render() {
        return (

            <div id="dataTime" style={{width: '100%', height: '100%'}} className="data-time" ref={catChart => {
                this.catChart = catChart;
            }}></div>


        );
    }
});