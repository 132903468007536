import {CometD} from 'cometd/cometd';
import EventDispatcher from './event';

class EventSubscribe extends EventDispatcher {
    constructor() {
        super();
        this._cometd = new CometD();
        this._connected = false;
        this._subscribs = [];
        this._preSubscribs = [];
        /** @type 曾经连接成功过 */
        this._onceConnectedSuccessfully = false;
    }

    setCometdUrl(url) {
        this._cometdUrl = url;
    }

    init() {
        if (!this._cometdUrl) {
            console.error('请检查消息服务器地址信息');
            return;
        }

        this._cometd.websocketEnabled = true;

        this._cometd.addListener('/meta/handshake', (message) => {//断开连接后，重新握手，将连接断开
            // console.log(message)
            // debugger
            if (this._onceConnectedSuccessfully) {
                setTimeout(() => {
                    this._cometd.disconnect();
                }, 1000);
            }
        });

        this._cometd.addListener('/meta/disconnect', (message) => {//连接断开后，重新初始化
            // console.log(message)
            this._onceConnectedSuccessfully = false;
            this.init();
        });

        this._cometd.addListener('/meta/connect', (message) => {
            // console.log(message)
            if (message) {
                // {channel:"/meta/connect",advice:{},id:"10",successful:true}
                if (message.advice && message.successful) {
                    // advice:{interval:0,reconnect:"retry",timeout:30000}
                    if (!this._connected) {
                        this._reSubscribes();//只有中间断开，重新连上后才会有效，因为初始注册是this.fire('sse_connect_finish')之后才会注册
                    }
                    //
                    this._connected = true;
                    //
                    this._prepareSubscribes();
                    //
                    if (!this._onceConnectedSuccessfully) {//断开后重连，不会再去注册
                        this.fire('sse_connect_finish');//初次通知已连接，才去注册
                        this._onceConnectedSuccessfully = true;
                    }
                }
                // {channel:"/meta/connect",advice:{interval:0,reconnect:"handshake"},id:"10",successful:false,error:"402::Unknown client"}
                // {channel:"/meta/connect",failure:{},id:"13",successful:false}
                if (!message.successful && message.advice && message.advice.reconnect === 'handshake') {
                    // this._cometd.disconnect()
                }
                if (message.failure) {
                    // failure:{connectionType:"websocket",websocketCode:1006,reason:"",message:{channel,clientId,...},transport:{instanceOf WebSocket}}
                    // failure:{connectionType:"long-polling",httpCode:408,reason:"error",exception:"Request Timeout",message:{channel,clientId,...},transport:{instanceOf LongPollingTransport}}
                    // ... RequestTransport, LongPollingTransport, CallbackPollingTransport, WebSocketTransport
                    this._connected = false;
                }
                // this._connectCallback instanceof Function && this._connectCallback(this._connected);
            }
        });

        this._cometd.init({
            'url': this._cometdUrl,
            'logLevel': 'debug'
        }, {
            ext: {
                'sc-auth': {'token': window.auth && window.auth.token}
            }
        });
    }

    // setConnectionCallBack(callback) {
    //     callback instanceof Function && (this._connectCallback = callback);
    // }

    subscribe(channel, callback) {
        if (this._connected) {//已连接则注册
            try {
                this._subscribe(channel, callback);
            } catch (error) {
                console.error(error);
                if (error === 'Illegal state: disconnected') {//防止连接状态还未及时更新，加了异常捕获
                    this._preSubscribs.push({'channel': channel, 'callback': callback});
                }
            }
        } else {//未连接，则存入缓存中等待注册
            this._preSubscribs.push({'channel': channel, 'callback': callback});
        }
    }

    // isConnected() {
    //     return this._connected;
    // }

    _subscribe(channel, callback) {
        // const isDuplicate = this._subscribs.some(item => {
        //     return item.channel === channel;
        // });

        // if (isDuplicate) {
        //     return;
        // }

        const isDuplicated = this._subscribs.some(item => item.channel === channel);

        if (isDuplicated) return;

        const o = {
            'channel': channel,
            'callback': callback,
            'subscription': this._cometd.subscribe(channel, callback)
        };
        this._subscribs.push(o);

        return o;
    }

    _prepareSubscribes() {
        if (this._preSubscribs.length) {
            this._preSubscribs.forEach(sub => {
                this._subscribe(sub.channel, sub.callback);
            });
            this._preSubscribs.splice(0, this._preSubscribs.length);
        }
    }

    _reSubscribes() {
        this._subscribs.forEach(sub => {
            sub.subscription = this._cometd.resubscribe(sub.subscription);
        });
    }
}

export default EventSubscribe;
