/*
 * @Descripttion: 
 * @version: V1.0
 * @Author: yanghui
 * @Date: 2020-11-11 15:19:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-17 09:57:51
 */
import React from 'react';
import {Route} from 'react-router-dom';
import {message} from 'antd';
import Video from './pages/minitor-manager/minitor-manager';
import DocManager from './pages/document/doc-manager';
import Iframe from './pages/document/doc-iframe';
import Count from './pages/count/count';
import Dept from './pages/dept/dept';
import Gis from './pages/gis/Gis';
import Gather from './pages/gather/gather';
import Log from './pages/operate-log/operate-log';
import Police from './pages/police/police';
import DeptAnalysis from './pages/dept-analysis/dept-analysis';
import {connect} from 'react-redux';
import {setPoliceListAction} from '@/reducer/video_action';
import {initDispatch, destroy} from './components/dispatch-manage';
import Subscribe from '../subscribe';
import LockScreen from '@/containers/LockScreen/index.jsx';


@connect(
    state => {
        return {
            policeList: state.policeList
        };
    },
    {
        setPoliceListAction
    }
)
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        initDispatch(this.props)
            .finally(() => {
                this.setState({loading: false});
            });
        Subscribe.register('LIMIT_LOGIN', this.registerFn = ({data}) => {
            if (typeof (data) === 'string') {
                data = JSON.parse(data);
            }
            if (data.loginTime !== sessionStorage.getItem('loginTime') && data.username === sessionStorage.getItem('username')) {
                message.error('该用户已在别处登录！');
                setTimeout(() => {
                    this.props.history.push(`/`);
                    localStorage.removeItem('flag');
                }, 1000);
            }
        });
    }

    componentWillUnmount() {
        Subscribe.unregister('LIMIT_LOGIN', this.registerFn);
    }

    render() {
        return (
            !this.state.loading
            &&
            <React.Fragment>
                <LockScreen/>
                <Route path={'/main/minitor'} component={Video}/>
                <Route path={'/main/document'} component={DocManager}/>
                <Route path={'/main/iframe'} component={Iframe}/>
                <Route path={'/main/police'} component={Police}/>
                <Route path={'/main/statistics'} component={Count}/>
                <Route path={'/main/menu-dept'} component={Dept}/>
                <Route path={'/main/menu-group'} component={Dept}/>
                <Route path={'/main/menu-dev'} component={Dept}/>
                <Route path={'/main/gis'} component={Gis}/>
                <Route path={'/main/menu-das'} component={Gather}/>
                <Route path={'/main/menu-oplog'} component={Log}/>
                <Route path={'/main/data_statistics'} component={DeptAnalysis}/>
            </React.Fragment>
        );
    }
}