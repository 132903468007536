import qs from 'qs';

//获取地址参数 token
function GetQueryString(name) {
  const queryString = qs.parse(window.location.href.split("?")[1] || "");
  return queryString.token;
}

export const getToken = () => {
  let token = sessionStorage.getItem("token") || GetQueryString("token");
  if (sessionStorage.getItem('sc-auth')) {
    const {auth} = JSON.parse(sessionStorage.getItem('sc-auth'));
    return auth.token;
  }
  return GetQueryString('token');

  // if (token) {
  //   return token
  // } else if (window.location.hash !== "#/" && !token) {
  //   window.location.hash = "#/";
  // }
};

export const getCorpId = () => {
  return sessionStorage.getItem("corpId")
};

export const getCenterId = () => {
  return sessionStorage.getItem("centerId")
};



// export const getToken = () => {
//   sessionStorage.getItem("token");
// };
