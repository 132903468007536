import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import {Route, NavLink, Redirect} from 'react-router-dom';
import {dispatch} from '@/rest-api/restapi-video';
import {commonGet} from '@/request/common-axios';
import {setVideoCorAction, setPlayListAction, setIsFlowAction} from '@/reducer/video_action';
import Header from '../header/header';
import Terminal from '../terminal/terminal';
import Collect from '../collect/collect';
import PlayHistory from '../playHistory/playHistory';
import PlayList from '../playList/playList';
import VideoPart from '../video/video';
import PatrolList from '../patrolList/patrolList';
import MonitorList from '../monitor-list/monitor-list'
import ManageHeader from '../../components/manage-header';
import {initDispatch} from '../../components/dispatch-manage';
import {debounce, isEqual} from 'lodash';
import {sys_api} from '@/rest-api/restapi';

const Editor = () => {
};
export default connect(
    state => {
        return {
            videoController: state.videoController,
            playList: state.playList,
            isFlow: state.isFlow
        };
    },
    {
        setVideoCorAction,
        setPlayListAction,
        setIsFlowAction
    }
)
(class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            path: 'main/minitor',
            isFlow: false
        };
    }

    /**
     * 获取页面标题
     */
    getTitle = async () => {
        var param = {
            cfgKey: 'scooper.poc.video.title'
        };
        const data = await sys_api.getSysConfig(param);
        if (!data.code) {
            this.setState({
                title: data.data.cfgValue
            });
        }
    };

    componentWillMount() {
        var globalNativeBridge = window.nativeBridge || window.top.nativeBridge;
        if (globalNativeBridge) {
            var arr = document.location.href.split('?');
            var _arr = arr[1].split('&');
            var firstDeptId = '';
            var token = '';
            _arr.map(item => {
                if (item.indexOf('firstDeptId') > -1) {
                    firstDeptId = item.split('=')[1];
                    sessionStorage.setItem('firstDeptId', firstDeptId);
                    // localStorage.setItem('token', token);
                }
                if (item.indexOf('token') > -1) {
                    token = item.split('=')[1];
                    sessionStorage.setItem('token', token);
                    localStorage.setItem('token', token);
                }
            });
        }
        initDispatch(this.props);
    }

    componentDidMount() {
        this.getTitle();
        this.getConfig();
        var globalNativeBridge = window.nativeBridge || window.top.nativeBridge;
        if (this.state.path == `main/minitor`) {
            if (globalNativeBridge) {
                this.props.history.push(`/${this.state.path}/terminal?token=${sessionStorage.getItem('token')}&firstDeptId=${sessionStorage.getItem('firstDeptId')}`);
            } else {
                this.props.history.push(`/${this.state.path}/terminal`);
            }
        }
    }


    /**
     * 返回首页
     */
    goHome = () => {
        const _this = this;
        _this.props.history.push('/home');

        if (_this.props.videoController && _this.props.videoController.playing !== undefined) {
            _this.props.videoController.closeAll();
            _this.props.setPlayListAction({playList: []});
        }


        // var janusSrc = '/scooper-video/js/lib/janus.js';
        // var src = '/scooper-video/js/scooper.video.js'
        // _this.removeFile(janusSrc,'janus')
        // _this.removeFile(src,'scooper-video')
        // _this.props.videoController.closeAll();
    };

    // removeFile = (file,id) =>{
    //     $('#' + id).remove();

    //     $("<scri"+"pt>"+"</scr"+"ipt>").attr({ 
    //     id:id,src:file,type:'text/javascript'}).appendTo($("head"));
    // }

    componentWillUnmount() {
        this.props.setPlayListAction({playList: []});
        this.props.videoController && this.props.videoController.destoryJanus();
    }

    /**
     * 获取是否开启流量控制功能
     */
    getConfig = async () => {
        const _this = this;
        const res = await sys_api.getSysConfig({cfgKey: 'scooper.poc.flow'});
        if (!res.code && res.data.cfgValue === '1') {
            _this.props.setIsFlowAction({isFlow: true});
        }
    };

    render() {
        const {isFlow} = this.state;
        return (
            <div className="wrap">
                <ManageHeader goHome={this.goHome} title={this.state.title} this={this.props}/>
                <VideoPart/>
                <ul className="left-nav">
                    <li>
                        <NavLink to={`/${this.state.path}/terminal`} activeClassName="sel">终端</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/${this.state.path}/collect`} activeClassName="sel">收藏夹</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/${this.state.path}/playhistory`} activeClassName="sel">播放历史</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/${this.state.path}/playlist`} activeClassName="sel">播放列表</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/${this.state.path}/patrolList`} activeClassName="sel">轮巡列表</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/${this.state.path}/monitorlist`} activeClassName='sel'>监控列表</NavLink>
                    </li>
                </ul>
                <Route path={`/${this.state.path}/terminal`} component={Terminal}/>
                <Route path={`/${this.state.path}/collect`} component={Collect}/>
                <Route path={`/${this.state.path}/playhistory`} component={PlayHistory}/>
                <Route path={`/${this.state.path}/playlist`} component={PlayList}/>
                <Route path={`/${this.state.path}/patrolList`} component={PatrolList}/>
                <Route path={`/${this.state.path}/monitorlist`} component={MonitorList} />
            </div>
        );
    }
});
