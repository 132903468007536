import React from 'react';
import {Table, Divider, Icon, Popconfirm, message, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {doc_api} from '@/rest-api/restapi';
import {setPoliceListAction, setPoliceListAllAction, setSelectKeyAction, setSelectAction} from '@/reducer/video_action';

export default connect(
    state => {
        return {
            policeList: state.policeList,
            policeListAll: state.policeListAll
        };
    },
    {
        setPoliceListAction,
        setPoliceListAllAction,
        setSelectKeyAction,
        setSelectAction
    }
)
(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            previewSrc: '',
            visible: false
        };
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys: selectedRowKeys});
        this.props.setSelectKeyAction({selectedRowKeys: selectedRowKeys});
    };

    /**
     * @description 分页请求告警记录
     */
    onChangePage = (page, pageSize) => {
        const that = this;
        const param = {
            token: sessionStorage.getItem('token'),
            corpId: sessionStorage.getItem('corpId'),
            timeMin: this.props.timeMin,
            timeMax: this.props.timeMax,
            searchKey: this.props.searchKey,
            sosType: this.props.sosType,
            pageIndex: page,
            pageSize: pageSize
        };
        $.ajax({
            url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/queryAlarmRecord`,
            data: param,
            success: (data) => {
                if (!data.code) {
                    that.props.setPoliceListAction({policeList: data.data.list});
                    that.props.setPoliceListAllAction({policeListAll: data.data});
                }
            }
        });

    };

    /**
     * 更新告警记录状态
     */
    updateAlarmSosRecord = (id, record) => {
        var param = {
            token: sessionStorage.getItem('token'),
            ids: id,
            sosType: '10'
        };
        let _this = this;
        $.ajax({
            url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/ignoreAlarmRecord`,
            data: param,
            success: (data) => {
                if (!data.code) {
                    //_this.addOplogRecord('op-sos-deal', sessionStorage.getItem('memName') + '处理了' + record.memName +'【'+ record.memTel+'】'+ '发起的sos请求', '', sessionStorage.getItem('accId'), sessionStorage.getItem('corpId'));
                    //_this.addSosRecord(0, record.memTel, sessionStorage.getItem('memName') + '处理了' + record.memName +'【'+record.memTel+'】' + '发起的sos请求',record.sosKeyid, "");
                }
            }
        });
    };

    /**
     * @description 跳到GIS页面
     */
    jumpToGis = (record, memTel, sosType) => {
        this.updateAlarmSosRecord(record.id, record);
        this.props.history.push(`gis?token=${sessionStorage.getItem('token')}&memTel=${memTel}&jumpType=${true}`);
        sessionStorage.setItem('memTel', memTel);
        sessionStorage.setItem('sosType', sosType);
    };

    render() {
        const {eventType} = this.props;
        const columns = [{
            title: eventType == 0 ? '告警时间' : '上呼时间',
            dataIndex: 'gmtCreate',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 100,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                };
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        }, {
            title: '人员',
            dataIndex: 'memName',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 50,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                };
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
            {
                title: '位置',
                dataIndex: 'gisName',
                ellipsis: true,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 250,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    };
                },
                render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
            }, {
                title: eventType == 0 ? '告警类型' : '上呼类型',
                dataIndex: 'sosType',
                render: (text, record) => {
                    <Tooltip placement="topLeft" title={text}>{text}</Tooltip>;
                    switch (record.sosType) {
                        case 0:
                            return <span>{eventType == 0 ? '处理sos告警' : '处理上呼请求'}</span>;
                        case 1:
                            return <span>超时未处理</span>;
                        case 2:
                            return <span>{eventType == 0 ? '撤销sos告警' : '撤销上呼请求'}</span>;
                        case 3:
                            return <span>{eventType == 0 ? '发起sos告警' : '发起上呼请求'}</span>;
                        case 4:
                            return <span>{eventType == 0 ? '忽略sos告警' : '忽略上呼请求'}</span>;
                        case 20:
                            return <span>{eventType == 0 ? '电子围栏告警' : '忽略上呼请求'}</span>;
                    }
                },
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 100,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    };
                }
            },
            {
                title: eventType == 0 ? '告警内容' : '上呼内容',
                dataIndex: 'content',
                ellipsis: true,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 250,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    };
                },
                render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
            },
            {
                title: '操作',
                dataIndex: 'operate',
                render: (text, record) => {
                    <Tooltip placement="topLeft" title={text}>{text}</Tooltip>;
                    if (record.sosType == 1) {
                        return (
                            <span>
                            <a className="recall"
                               onClick={() => this.jumpToGis(record, record.memTel, record.sosType, record.id, record.memName)}><i></i></a>
                        </span>
                        );
                    }
                },
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    };
                }
            }];


        const {selectedRowKeys} = this.state;

        const rowSelection = {
            onChange: this.onSelectChange,
            onSelect: (record, selected, selectedRows) => {
                this.props.setSelectAction({selectedRows: selectedRows});

            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.props.setSelectAction({selectedRows: selectedRows});
            }
        };

        let pagination = {
            current: this.props.policeListAll.pageNum,
            total: this.props.policeListAll.total,
            pageSize: this.props.pageSize,
            defaultCurrent: this.props.current,
            showSizeChanger: true,
            itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            },

            onChange: this.onChangePage
        };

        return (
            <div>
                <Table rowSelection={rowSelection} columns={columns} rowKey={record => record.id}
                       pagination={pagination} dataSource={this.props.policeList}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   this.setState({
                                       rowId: record.id
                                   });
                               }
                           };
                       }}
                       rowClassName={(record, index) => {
                           let className = 'even-bg';
                           if (index % 2 === 0) {
                               className = 'odd-bg';
                           }
                           if (index === selectedRowKeys) {
                               className = 'light';
                           }
                           return className;
                       }}
                />

            </div>
        );
    }
});