import getServices from '../request/axios-request';

export const scooper_poc_server_login = getServices('/scooper-poc-server/api/login', null, {
    doLogin: '/doLogin',     //登录（因为密码过期原因已经弃用）
    login: '/login',    // 登录
    getAccountInfoByToken: {url: '/getAccountInfoByToken', type: 'get'} // 获取账号详情
});

export const scooper_poc_server = getServices('/scooper-poc-server/data', () => window.sessionStorage.getItem('token') || null, {
    getMenuConfig: '/homeMenuConfig/listHomeMenuConfig',    //获取配置管理数据
    deleteConfig: '/homeMenuConfig/removeHomeMenuConfig',   //删除配置快
    addConfig: '/homeMenuConfig/saveHomeMenuConfig',        //添加配置快
    getGisConfig: '/gisConfig/getGisConfigByAccId',        //获取地图配置项
    getAccountConfig: '/pageConfig/getPageConfigByAccId',    //获取操作员配置项
    listFileLawType: {url: '/manaFile/listFileLawType', type: 'get'},  //获取执法仪类型列表
    updateFileInfo: {url: '/manaFile/updateFileInfo', type: 'post'},  //修改文件信息
    listHomeMenuType:{url:'/homeMenuType/listHomeMenuType',type:'get'} //配置块管理信息
});

export const scooper_poc_log = getServices('/scooper-poc-server/api', () => window.sessionStorage.getItem('token') || null, {
    getCaptcha: '/login/getCaptcha', //获取验证码
    doLogout: '/login/doLogout',   //退出登录
    doLoginByToken: '/login/doLoginByToken',   //通过token登录
    resetPwd: '/login/resetPwd',   //修改密码
    getPageConfig: 'pageConfig/getPageConfigByAccId',   //获取页面配置信息
    getAccountInfoByToken: '/login/getAccountInfoByToken'    // 根据token获取信息
});

export const help_api = getServices('/scooper-poc-server/api', () => null, {
    helpDown: '/sysConfig/downloadFile',
    getOperList: '/login/listDispOper'   //获取操作员列表
});

export const scooper_core_api = getServices('/scooper-core-rest/data/contacts', () => window.sessionStorage.getItem('token') || null, {
    getAvatar: '/orgMemberManage/getImage',  //获取头像
    queryOrgDept: '/orgDeptManage/queryOrgDept',  //查询部门列表
    queryOrgMember: '/orgMemberManage/queryOrgMember'   //查询成员列表
});

export const scooper_core_rest = getServices('/scooper-core-rest/data/', () => sessionStorage.getItem('token'), {
    resetSelfPwd: {url: 'system/accountManage/resetSelfPwd', type: 'post'}   // 账号自身密码重置
});

/* 文件管理 */
export const doc_api = getServices('/scooper-poc-server/data/manaFile/', () => window.sessionStorage.getItem('token') || null, {
    queryFile: 'pageManaFile',    //查询文件
    fileDownload: 'downloadFile',   //文件下载
    fileDelete: 'removeManaFile', //文件删除
    fileDeleteBatch: 'removeBatchManaFile',   //批量删除文件
    getPocFile: 'pageManaPocFile',   //查询对讲录音文件列表
    deletePocFile: '/removeManaPocFile', //对讲录音文件删除
    deleteBatchPocFile: '/removeBatchManaPocFile' //批量删除对讲文件
});

export const dept_api = getServices('/scooper-core-rest/data/contacts/orgDeptManage', () => window.sessionStorage.getItem('token') || null, {
    getDept: '/listOrgDept'   //查询部门数据
});

/**
 * 采集站管理
 */
export const gather_api = getServices('/scooper-poc-server/data/manaDAS/', () => window.sessionStorage.getItem('token') || null, {
    getDataGather: 'pageManaDAS',  //查询采集站列表
    getManDetail: 'getManaDAS',    //查询采集站详情
    deleteGather: 'removeManaDAS',   //删除采集站
    deleteBatchGather: 'removeBatchManaDAS', //批量删除采集站
    addGather: 'saveManaDAS',   //新增采集站
    updateGather: 'updateManaDAS'  //更新采集站信息
});

/**
 * 统计(采集站数量)
 */
export const gathCount_api = getServices('/scooper-poc-server/data/statisticsFile/', () => window.sessionStorage.getItem('token') || null, {
    getGatherCount: 'getDASStatistics'
});

/**
 * 统计(执法记录仪数量)
 */
export const record_api = getServices('/scooper-poc-manager/data/dev/', () => window.sessionStorage.getItem('token') || null, {
    getRecordCount:{url:'queryDevNumByCorpId',type:"get"}
});


/**
 * 操作日志类型
 */
export const log_api = getServices('/scooper-poc-server/data/', () => window.sessionStorage.getItem('token') || null, {
    getOplogSort: 'oplogType/getOplogCategoryList',   //获取操作类别列表
    getOplogType: 'oplogType/getOplogTypeByCategoryId',   //获取操作类型列表
    getOplogList: 'manaOplog/pageManaOplog',    //分页查询操作日志列表
    deleteLog: 'manaOplog/removeBatchManaOplog', //批量删除操作日志
    getPageConfig: 'pageConfig/getPageConfigByAccId'   //获取页面配置信息
});

/**
 * 告警记录
 */
export const police_api = getServices('/poc-web/data/', () => window.sessionStorage.getItem('token') || null, {
    getPoliceList: 'alarmRecord/queryAlarmRecord',  //查询告警记录
    deleteBactchPolice: 'alarmRecord/delAlarmRecord' //删除告警记录
});

/**
 * 标题可配置
 */
export const sys_api = getServices('/scooper-poc-server/api/', () => window.sessionStorage.getItem('token') || null, {
    getSysConfig: 'sysConfig/getSysConfig', //配置名称
    getSysConfigList: {url: 'sysConfig/getListSysConfig', type: 'get'} // 配置项列表
});

/**
 * 二维码信息
 */
export const or_info_api = getServices('/scooper-poc-server/api/', () => null, {
    getQrInfo: {url: 'sysConfig/getQrInfo', type: 'get'} //二维码信息
});

/**
 * 修改记录仪名称
 */
export const edit_name_api = getServices('/scooper-poc-manager/data/', () => window.sessionStorage.getItem('token') || null, {
    getDetail: 'dev/getInfoByToken' //配置名称
});

/**
 * 统计分析模块
 */
export const statistics_api = getServices('/scooper-poc-manager/data/', () => window.sessionStorage.getItem('token') || null, {
    getUsedFlow: {url:'stat/getDevFlow',type:"get"},  //物联网卡流量使用
    getDevInfoStatistical: {url: 'stat/getDevInfoStatistical', type: 'get'}, // 设备统计数据
    getDeptStatInfoSorted: {url: 'stat/getDeptStatInfoSorted', type: 'get'} // 部门统计数据
});

/**
 * 流量状态
 */
export const queryflow_api = getServices('/scooper-poc-manager/data/', () => window.sessionStorage.getItem('token') || null, {
    queryDevFlowState: {url:'dev/queryDevFlowState',type:"get"},  //查询设备流量状态
    queryCorpFlowState: {url:'contacts/queryCorpFlowState',type:"get"}  //查询机构云存储流量状态
});
