/*
 * @Descripttion: 
 * @version: V1.0
 * @Author: yanghui
 * @Date: 2020-11-11 15:19:25
 * @LastEditors: yanghui
 * @LastEditTime: 2021-01-26 16:22:36
 */
import {commonPost,commonGet} from '../../request/common-axios';
import {dispatch} from '../../rest-api/restapi-video'

/**
 * 调度登陆
 */
export function initDispatch(){
    let param = {}
    commonGet(dispatch.login,param).then((data)=> {
        if(data.data.code == 0){
            setInterval(function(){
                keepHeart();
            },30000)
            
        }
    })
}
/**
 * 心跳保活
 */
function keepHeart ()  {
    let param = {}
    commonGet (dispatch.heartKeep,param).then((data)=> {
        if(data.data.code){
            console.error("scooper.poc登录失败");
            return ;
        }
    })
    
}

/**
 * 打开云眼
 */
export function openCloud(pocNo,fn) {
    let params = {
        pocNo: pocNo
    }
    commonGet(dispatch.openCloudEye, params)
        .then(({ data }) => {
            // console.log(data);
            if (data.code == 0 && fn && fn instanceof Function ) {
               fn(data.data);
            } 
        })
}

/**
 * 关闭云眼
 */
export function closeCloudEye(pocNo,fn) {
    let params = {
        pocNo:pocNo
    }
    commonGet(dispatch.closeCloudEye,params)
    .then(({data})=> {
        if (data.code == 0 && fn && fn instanceof Function ) {
            fn(data.data);
        } 
    })
} 

/**
 * 对讲组人员状态
 */
export function pocMemStatus(fn) {
    let param = {}
    commonGet(dispatch.getListStatus, param)
    .then(({data})=> {
        if (data.code == 0 && fn && fn instanceof Function ) {
            fn(data.list);
        }
    })
}