import React from 'react';
import {Divider, message, Modal, Popconfirm, Table} from 'antd';
import {connect} from 'react-redux';
import {doc_api} from '@/rest-api/restapi';
import {
    setPocFileAction,
    setPocFileAllAction,
    setSelectPocAction,
    setSelectPocKeyAction
} from '@/reducer/video_action';

export default connect(
    state => {
        return {
            pocFile: state.pocFile,
            pocFileAll: state.pocFileAll,
            selectedRowKeys: state.selectedRowKeys,
            selectedPocRows: state.selectedPocRows,
            selectedPocRowKeys: state.selectedPocRowKeys
        };
    },
    {
        setPocFileAction,
        setPocFileAllAction,
        setSelectPocAction,
        setSelectPocKeyAction
    }
)
(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            previewSrc: '',
            visible: false,
            previewEnv: '',
            pageNum: 1,
            modalWidth: 500,
            isShowReduce: 'none',
            isShowEnlarge: 'block'
        };
    }

    /**
     *checkbox状态
     */
    onSelectChangePoc = (selectedRowKeys) => {
        console.log('选中的行为: ', selectedRowKeys);
        this.props.setSelectPocKeyAction({selectedPocRowKeys: selectedRowKeys});
    };

    /**
     * 分页请求
     */
    onChangePage = async (page, pageSize) => {
        this.setState({
            pageNum: page
        });
        const {pocParam} = this.props;
        const param = {
            token: sessionStorage.getItem('token'),
            corpId: sessionStorage.getItem('corpId'),
            pageNum: page,
            pageSize
        };
        var params = {};
        if (pocParam) {
            params = Object.assign(pocParam, param);
        } else {
            params = param;
        }
        const pocData = await doc_api.getPocFile(params);
        if (!pocData.code) {
            this.props.setPocFileAction({pocFile: pocData.data.list});
            this.props.setPocFileAllAction({pocFileAll: pocData.data});
        }
    };

    /**
     * 文件删除
     */
    onDelete = async (id, fileName) => {
        var param = {
            token: sessionStorage.getItem('token'),
            id: id,
            fileName: fileName
        };
        const data = await doc_api.deletePocFile(param);
        if (!data.code) {
            message.success(data.message);
            const {pocParam} = this.props;
            const param2 = {
                pageNum: this.state.pageNum,
                pageSize: 10
            };
            var params = Object.assign(param2, pocParam);
            const pocData = await doc_api.getPocFile(params);
            if (!pocData.code) {
                this.props.setPocFileAction({pocFile: pocData.data.list});
                this.props.setPocFileAllAction({pocFileAll: pocData.data});
            }
        } else {
            message.error(data.message);
        }

    };

    handleCancel = () => {
        this.setState({
            previewVisible: false
        });
        var audio = document.getElementsByClassName('prev-audio')[0];
        var audio2 = document.getElementsByClassName('prev-audio2')[0];
        var videoDiv = document.getElementsByClassName('prev-video-div')[0];
        var imgDiv = document.getElementsByClassName('prev-img-div')[0];
        var videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
        var audioDiv = document.getElementsByClassName('pit-music')[0];
        var audioDiv2 = document.getElementsByClassName('pit-music-ie')[0];

        audio && audio.pause();
        audio2 && audio2.pause();

        if (videoDiv && videoDiv.children) {
            var video = videoDiv.children[0];
            if (video) {
                videoDiv.removeChild(video);
            }
        }

        if (imgDiv && imgDiv.children) {
            var img = imgDiv.children[0];
            if (img) {
                imgDiv.removeChild(img);
            }
        }
        if (videoDivY && videoDivY.children) {
            var video = videoDivY.children[0];
            if (video) {
                videoDivY.removeChild(video);
            }
        }
        if (audioDiv && audioDiv.children) {
            var audio = audioDiv.children[0];
            if (audio) {
                audioDiv.removeChild(audio);
            }
        }

        if (audioDiv2 && audioDiv2.children) {
            var audio = audioDiv2.children[0];
            if (audio) {
                audioDiv2.removeChild(audio);
            }
        }
    };


    /**
     * 文件预览
     */
    preView = (fileType, filePath, fileName, fileSize, memName, record) => {
        this.setState({
            previewVisible: true,
            fileName: fileName,
            filePath: filePath,
            fileType: fileType,
            fileSize: fileSize,
            memName: memName
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            this.setState({
                previewEnv: 'ie'
            });
        } else {
            this.setState({
                previewEnv: 'noie'
            });
        }

        window.setTimeout(() => {
            let url;
            if (fileType === 1) {
                const videoDiv = document.getElementsByClassName('prev-video-div')[0];
                const videoElement = document.createElement('video');
                videoElement.className = 'prev-video';
                url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}`;
                videoElement.src = url;
                videoElement.controls = true;
                videoElement.controlsList = 'nodownload';
                videoElement['disablePictureInPicture'] = true;
                videoDiv.appendChild(videoElement);
            }
            if (fileType === 0) {
                const imgDiv = document.getElementsByClassName('prev-img-div')[0];
                const imgElement = document.createElement('img');
                imgElement.className = 'prev-img';

                url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}`;
                imgElement.src = url;
                imgDiv.appendChild(imgElement);
            }
            if (fileType === -2) {
                const videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
                const videoElementY = document.createElement('video');
                videoElementY.className = 'prev-video';

                url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}`;
                videoElementY.src = url;
                videoElementY.controls = true;
                videoElementY.controlsList = 'nodownload';
                videoElementY['disablePictureInPicture'] = true;
                videoDivY.appendChild(videoElementY);
            }
            if (fileType === 2) {
                const audioElement = document.createElement('audio');
                audioElement.className = 'prev-audio2';
                url = `/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}`;
                audioElement.src = url;
                audioElement.type = 'application/json';
                audioElement.controlsList = 'nodownload';
                audioElement.controls = true;
                audioElement['disablePictureInPicture'] = true;
                this.pitMusicElement.appendChild(audioElement);
                // audioElement.load();

                /*audioElement.oncanplay = async (e) => {
                    while (audioElement.duration === Infinity) {
                        await new Promise(r => setTimeout(r, 200));
                        audioElement.currentTime = 10000000 * Math.random();
                    }
                };*/
                /*audioElement.addEventListener('canplay ', (e) => {
                    console.log(audioElement.duration, '==============');
                    // audioElement.controls = true;
                    // audioDiv.appendChild(audioElement);
                });*/
            }
        }, 200);

    };

    noFile = (record) => {
        window.setTimeout(function () {
            if (record.fileMoveStatus == 2 && record.cloudFilePath === '') {
                message.error('该文件不存在！');
                return;
            }
            if (record.fileMoveStatus !== 2 && record.filePath === '') {
                message.error('该文件不存在！');
                return;
            }
        }, 300);
    };


    /**
     * 图片放大功能
     */
    enlargePit = () => {
        this.setState({
            modalWidth: 1200,
            isShowEnlarge: 'none',
            isShowReduce: 'block'
        });
    };

    /**
     * 图片缩小功能
     */
    reducePit = () => {
        this.setState({
            modalWidth: 500,
            isShowEnlarge: 'block',
            isShowReduce: 'none'
        });
    };

    render() {
        const columns = [{
            title: '通话组',
            dataIndex: 'groupName'
        }, {
            title: '话权人',
            dataIndex: 'memName'
        },
            {
                title: '时长',
                dataIndex: 'fileTime',
                render: (text, record) => {
                    if (record.fileTime === 0) {
                        record.fileTime = 1;
                    }
                    return <span>{record.fileTime}</span>;
                }
            },
            {
                title: '开始时间',
                dataIndex: 'startTime'
            },
            {
                title: '结束时间',
                dataIndex: 'endTime'
            },
            {
                title: '工号',
                dataIndex: 'memCode'
            },
            {
                title: '部门',
                dataIndex: 'deptName'
            },
            {
                title: '操作',
                dataIndex: 'operate',
                render: (text, record) => {
                    return (
                        <span>
                        <a className='preview'
                           onClick={() => this.preView(record.fileType, record.filePath, record.fileName, record.fileSize, record.memName, record)}><i></i></a>
                        <Divider type='vertical'/>
                            {/* <a  className="edit" target="_blank" onClick={(e)=>{this.noFile(record, e)}} href={record.fileMoveStatus === 2 ? `${record.cloudFilePath}?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}` : `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${record.fileName}&filePath=${record.filePath}`} ><i></i></a> */}
                            <a className='edit' target='_blank'
                               href={`${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${record.fileName}&filePath=${record.filePath}`}><i></i></a>
                        <Divider type='vertical'/>
                        <Popconfirm title='确认要删除吗？' okText='确认' cancelText='取消' className='popConfirm'
                                    onConfirm={() => this.onDelete(record.id, record.fileName)}>
                            <a href='#' className='delete'><i></i></a>
                        </Popconfirm>
                    </span>
                    );

                }
            }];

        const {selectedRowKeys} = this.state;

        const rowSelection = {
            selectedRowKeys: this.props.selectedPocRowKeys,
            onChange: this.onSelectChangePoc,
            onSelect: (record, selected, selectedRows) => {
                this.props.setSelectPocAction({selectedPocRows: selectedRows});

            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.props.setSelectPocAction({selectedPocRows: selectedRows});
            }
        };

        let pagination = {
            current: this.props.pocFileAll.pageNum,
            total: this.props.pocFileAll.total,
            pageSize: 10,
            defaultCurrent: this.props.current,
            hideOnSinglePage: true,
            showSizeChanger: true,
            itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            },

            onChange: this.onChangePage,
            showTotal: this.showTotal
        };
        const {modalWidth, isShowReduce, isShowEnlarge, fileType, previewEnv} = this.state;
        return (
            <div className='content'>
                <Modal title={`${this.state.fileName}`} visible={this.state.previewVisible} onCancel={this.handleCancel}
                       className='prev-modal'
                       width={modalWidth}
                       footer={
                           <div>
                               <span className='msg-size'>文件大小：{this.state.fileSize}</span>
                               <span className='msg-mem'>上传人员： {this.state.memName}</span>
                           </div>
                       }
                >
                    {fileType === 0 && <div className='prev-img-div'/>}
                    {fileType === 2 && previewEnv === 'noie' &&
                    <div ref={el => this.pitMusicElement = el} className='pit-music'/>}
                    {fileType === 2 && previewEnv === 'ie' &&
                    <div ref={el => this.pitMusicElement = el} className='pit-music'/>}
                    {fileType === 1 && <div className='prev-video-div'/>}
                    {fileType === -2 && <div className='prev-video-div-yy'/>}

                </Modal>
                <Table rowSelection={rowSelection} columns={columns} rowKey={record => record.id}
                       dataSource={this.props.pocFile} pagination={pagination}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   this.setState({
                                       rowId: record.id
                                   });
                               }
                           };
                       }}
                       rowClassName={(record, index) => {
                           let className = 'even-bg';
                           if (index % 2 === 0) {
                               className = 'odd-bg';
                           }
                           if (index === selectedRowKeys) {
                               className = 'light';
                           }
                           return className;
                       }}
                />
            </div>
        );
    }
});