import {sys_api} from '@/rest-api/restapi';

/**
 * 使用文档
 */
export const getFile = async () => {
    const data = await sys_api.getSysConfig({cfgKey: 'scooper.poc.file.document.address'});
    if (!data.code) {
        const {cfgValue, defaultValue} = data.data;
        if (cfgValue || defaultValue) {
            return true;
        } else if (cfgValue === '' && defaultValue === '') {
            return false;
        }
    }
};

/**
 * 使用视频
 * @return {Promise<boolean>}
 */
export const getVideo = async () => {
    const data = await sys_api.getSysConfig({cfgKey: 'scooper.poc.file.video.address'});
    if (!data.code) {
        const {cfgValue, defaultValue} = data.data;
        if (cfgValue || defaultValue) {
            return true;
        } else if (cfgValue === '' && defaultValue === '') {
            return false;
        }
    }
};