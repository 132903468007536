/*
 * @Author: your name
 * @Date: 2021-12-28 14:13:13
 * @LastEditTime: 2022-03-17 18:09:54
 * @LastEditors: Please set LastEditors
 * @Description: 跳转的头部
 * @FilePath: \scooper-poc-web_qsjd\src\view\pages\gis\GisHeader.js
 */
import React, { useEffect, useState } from "react";
import { message } from 'antd';
import NowDateTime from "../../components/date";
import "../../../less/gisheader.less";
import { scooper_poc_server } from "../../../rest-api/restapi";
import { getBanList } from "../../../home/index"
export let menuPath = [
  { path: "/main/gis", name: "GIS实时指挥", key: 'gis-conduct', _key: "gis" },
  { path: "/main/minitor", name: "监控管理", key: 'minitor-manager', _key: "video" },
  { path: "/main/document", name: "文件管理", key: 'doc-manager', _key: "file" },
  { path: "/main/police", name: "告警记录", key: 'police-record', _key: "alarm" },
  { path: "/main/statistics", name: "统计分析", key: 'statistics-analyze', _key: "statistics" },
  // { path: "/main/menu-dept", name: "部门管理" },
  // { path: "/main/menu-group", name: "通话组管理" },
  // { path: "/main/menu-dev", name: "记录仪管理" },
  // { path: "/main/menu-das", name: "采集站管理" },
  // { path: "/main/menu-oplog", name: "操作日志" },
];
let banList = [];
const GisHeader = ({ props }) => {
  useEffect(() => {
    initNavAuthority();
    if (selectValue === "") {
      judgeLink();
    }
    // if (menuPath.length === 0) {
    //   getMenu((e) => { setPath(e) })
    // }
  })
  const [selectValue, setSelectValue] = useState("")
  const [title, settitle] = useState("")
  const [visible, setvisible] = useState(false);
  const initNavAuthority=async()=>{
    banList =await getBanList();
    console.log("slslsls",banList);
  }
  // useEffect(() => {
  //   setPath(menuPath);
  // }, [menuPath])
  // let [path, setPath] = useState([]);
  let path = menuPath;
  const redirectLink = (target, value) => {
    const {contentWindow} = document.getElementById('calculation');
    contentWindow.backToPlatformCallBack();
    props.history.push(target.path, value);
  };
  const selectChange = (value) => {
    if (banList.indexOf(value.split(",")[1]) > -1) {
      props.history.push({ pathname: value.split(",")[0], state: value.split(",")[0] });
      setSelectValue(value);
    } else {
      return message.info('该模块正在开发中...');
    }
  };
  // const selectChange = (e) => {
  //   if (banList.indexOf(e.target.value.split(",")[1]) > -1) {
  //     props.history.push({ pathname: e.target.value.split(",")[0], state: e.target.value.split(",")[0] });
  //     setSelectValue(e.target.value);
  //   } else {
  //     return message.info('该模块正在开发中...');
  //   }
  // };
  const judgeLink = (item) => {
    menuPath.map(it => {
      if (window.location.hash.indexOf(it.path) > -1) {
        setSelectValue(`${it.path},${it._key}`);
        settitle(it.name);
      }
    })
    // let res = window.location.hash.indexOf(item.path);
    // console.log("item", item)
    // if (res > -1) {
    //   return true;
    // } else return false;
  };
  const isVisible = () => {
    setvisible(!visible);
  }
  return (
    <div className="gisheader">
      <div className="header">
        <div className="top-head">
          <div className="head-title">
            <div className="select-value" onClick={isVisible}>{title} <span className="select-tri"></span>
              {visible && <div className="select-box">
                {/* <select name="pathSelect" onChange={selectChange} value={selectValue}> */}
                {/* {console.log("eeepath,", path)}
              {path.map((item, index) => {
                return (
                  <option
                    // selected={judgeLink(item)}
                    key={item.key}
                    value={`${item.path},${item._key}`}
                  >
                    {item.name}
                  </option>
                );
              })} */}
                {/* </select> */}
                {path.map((item, index) => {
                  return (
                    <div className="select-item"
                      // selected={judgeLink(item)}
                      // key={item.key}
                      onClick={selectChange.bind(this, `${item.path},${item._key}`)}
                      value={`${item.path},${item._key}`}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div
        className="back"
        id="back"
        onClick={redirectLink.bind(this, { path: "/home", name: "首页" })}
      >
        <span className="title">首页</span>
      </div>
      <div className="count-down">
        <span id="current-time">
          <NowDateTime />
        </span>
      </div>
    </div>
  );
};
export function setMenu(menuList, fn) {
  return;
  menuPath = [];
  menuList.map((menu) => {
    menuPath.push({ path: menu.menuServer, name: menu.menuName })
  })
  fn && fn(menuPath);
}
const initMenu = async (fn) => {
  return;
  const param = {
    accId: sessionStorage.getItem('accId') || localStorage.getItem('accId')
  };
  let data = await scooper_poc_server.getMenuConfig(param);
  setMenu(data.data.list, fn);
}
export const getMenu = (fn) => {
  return menuPath;
  if (menuPath.length === 0) {
    initMenu(fn);
  }
  return menuPath || [];
}
export default GisHeader;
