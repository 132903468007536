/*
 * @Author: yanghui
 * @Date: 2019-09-30 10:33
 * @Last Modified by: yanghui
 * @Last Modified time: 2019-10-22 10:35:29
 */

import React from 'react';
import {Input, Form, Select, Button, Modal, message} from 'antd';
import {connect} from 'react-redux';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import ManageHeader from '../../components/manage-header';
import AddModal from '../../components/add-modal';
import GatherTable from '../../components/gather-table';
import '@/less/gather.less';
import {gather_api, sys_api} from '@/rest-api/restapi';
import {
    setDeptMemAction,
    setGatherListAction,
    setGatherListAllAction,
    setShowSelectAction,
    setRowsSelectedAction,
    setKeySelectedAction
} from '@/reducer/video_action';
import $ from '../../../lib/jquery-vendor';

const {confirm} = Modal;

const GatherManager = connect(
    state => {
        return {
            rowsSelected: state.rowsSelected,
            keySelected: state.keySelected,
            deptMem: state.deptMem,
            gatherListAll: state.gatherListAll
        };
    },
    {
        setRowsSelectedAction,
        setKeySelectedAction,
        setDeptMemAction,
        setGatherListAction,
        setGatherListAllAction,
        setShowSelectAction
    }
)(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                gatherList: [],
                gatherListAll: [],
                visible: false,
                deptList: []
            };
        }

        showModal = () => {
            var param = {
                deptType: 3,
                token: sessionStorage.getItem('token')
            };
            $.ajax({
                url: `${window.config.modulesUrl}/scooper-core-rest/data/contacts/orgDeptManage/listOrgDept`,
                data: param,
                success: function (data) {
                }
            });
            this.setState({
                visible: true
            });
            this.props.setShowSelectAction({showSelect: 0});
        };

        handleOk = () => {
            this.setState({
                visible: false
            });
        };

        handleCancel = e => {
            this.setState({
                visible: false
            });
            this.props.form.resetFields();
        };

        /**
         * 查询采集站列表
         */
        getTable = async () => {
            var param = {
                token: sessionStorage.getItem('token'),
                corpId: sessionStorage.getItem('corpId'),
                // orgCode: sessionStorage.getItem('orgCode'),
                pageNum: 1,
                pageSize: 10
            };
            const data = await gather_api.getDataGather(param);
            console.log("datasss",this,data);
            if (!data.code) {
                this.props.setGatherListAction({gatherList: data.data.list});
                this.props.setGatherListAllAction({gatherListAll: data.data});
            }
        };

        /**
         * 采集站删除(批量)
         */
        onDeleteBatch = async () => {
            const rowsSelected = this.props.rowsSelected;
            if (rowsSelected.length == 0) {
                message.error('请勾选要删除的文件！');
                return;
            }
            confirm({
                title: '确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: async () => {
                    var id = [];
                    rowsSelected &&
                    rowsSelected.map(item => {
                        id.push(item.id);
                    });
                    const _id = id.join(',');
                    const data = await gather_api.deleteBatchGather({ids: _id});
                    if (!data.code) {
                        message.success(data.message);
                        this.props.setRowsSelectedAction({rowsSelected: []});
                        this.updateSelectedKeys(this.props.rowsSelected);
                        var param = {
                            token: sessionStorage.getItem('token'),
                            corpId: sessionStorage.getItem('corpId'),
                            // orgCode: sessionStorage.getItem('orgCode'),
                            pageNum: 1,
                            pageSize: 10,
                            deptId: this.state.deptId,
                            gzzXh: this.state.gzzXh,
                            isOnline: this.state.isOnline
                        };
                        param.pageNum = this.props.gatherListAll.pageNum;
                        const res = await gather_api.getDataGather(param);
                        if (!res.code) {
                            if (res.data.list.length == 0) {
                                param.pageNum = res.data.lastPage;
                                const resp = await gather_api.getDataGather(param);
                                this.props.setGatherListAction({gatherList: resp.data.list});
                                this.props.setGatherListAllAction({gatherListAll: resp.data});
                            } else {
                                this.props.setGatherListAction({gatherList: res.data.list});
                                this.props.setGatherListAllAction({gatherListAll: res.data});
                            }

                        }
                    }
                }
            });

        };

        /**
         * 清空残留复选框
         */
        updateSelectedKeys = rows => {
            var keySelected = this.props.keySelected;
            console.log(this.props.keySelected);
            for (let i = 0; i < rows.length; i++) {
                keySelected.splice(keySelected.indexOf(rows.sequence), 1);
            }
        };

        /**
         * 获取页面标题
         */
        getTitle = async () => {
            var param = {
                cfgKey: 'scooper.poc.das.title'
            };
            const data = await sys_api.getSysConfig(param);
            if (!data.code) {
                this.setState({
                    title: data.data.cfgValue
                });
            }
        };

        componentDidMount() {
            this.getTable();
            this.getDeptData();
            this.getTitle();
            if (!sessionStorage.getItem('token')) {
                message.error('请先登录！');
                setTimeout(() => this.props.history.push(`/`), 1000);
            }
            // document.addEventListener("keydown",this.keypressGather);
        }

        /**
         * 加载部门数据
         */
        getDeptData = async () => {
            const param = {
                // deptType: 3,
                token: sessionStorage.getItem('token')
                // corpId: sessionStorage.getItem("corpId"),
                // orgCode: sessionStorage.getItem('orgCode')
            };
            const _this = this;
            $.ajax({
                url: `${window.config.modulesUrl}/scooper-poc-server/api/login/listDeptByOrgCode`,
                data: param,
                success: function (data) {
                    _this.setState({
                        deptList: data.data.list
                    });
                    _this.props.setDeptMemAction({deptMem: data.data.list});
                }
            });
        };

        componentWillMount() {
            if (document.location.href.indexOf('token') > -1) {
                var arr = document.location.href.split('?');
                var _arr = arr[1].split('&');
                var token = '';
                _arr.map(item => {
                    if (item.indexOf('token') > -1) {
                        token = item.split('=')[1];
                        sessionStorage.setItem('token', token);
                        localStorage.setItem('token', token);
                    }
                });
            }

        }

        /**
         * 搜索采集站
         */
        seachGather = async () => {
            const {
                form: {validateFields}
            } = this.props;
            validateFields(async (err, values) => {
                var param = {};
                $.each(values, (key, value) => {
                    if (key == 'deptId') {
                        if (
                            value != undefined &&
                            value != '' &&
                            value != null &&
                            value != NaN
                        ) {
                            param[key] = value;
                        }
                    }
                    if (key == 'gzzXh') {
                        if (
                            value != undefined &&
                            value != '' &&
                            value != null &&
                            value != NaN
                        ) {
                            param[key] = value;
                        }
                    }
                    if (key == 'isOnline') {
                        value = parseInt(value);
                        param[key] = value;
                    }
                });
                param.orgCode = sessionStorage.getItem('orgCode');
                const data = await gather_api.getDataGather(param);
                if (!data.code) {
                    this.props.setGatherListAction({gatherList: data.data.list});
                    this.props.setGatherListAllAction({gatherListAll: data.data});
                    this.setState({
                        deptId: values.deptId,
                        gzzXh: values.gzzXh,
                        isOnline: values.isOnline
                    });
                    $('.ant-empty-description').text('暂无数据');
                }
            });
        };

        /**
         * 重置搜索条件
         */
        reset = () => {
            this.props.form.resetFields();
            this.getTable();
        };

        /**
         * 回车事件
         */
        keypressGather = e => {
            if (e.keyCode == 13) {
                this.seachGather();
            }
        };

        /**
         * 返回首页
         */
        goHome = () => {
            this.props.history.push('/home');
        };

        render() {
            const {getFieldDecorator} = this.props.form;
            return (
                <div className="box">
                    <ManageHeader goHome={this.goHome} title={this.state.title} this={this.props}></ManageHeader>
                    <div className="content-main">
                        <div className="content-top">
                            <Form.Item>
                                <span className="key-words first-word"> 所属单位 </span>
                                {getFieldDecorator('deptId', {
                                    initialValue: ''
                                })(
                                    <Select
                                        dropdownClassName="add-xiala"
                                    >
                                        {this.state.deptList &&
                                        this.state.deptList.map(item => {
                                            return (
                                                <Select.Option value={item.deptId} key={item.deptId}>
                                                    {item.deptName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <span className="key-words"> 设备编号 </span>
                                {getFieldDecorator('gzzXh', {
                                    initialValue: ''
                                })(<Input onPressEnter={this.seachGather}/>)}
                            </Form.Item>
                            <Form.Item>
                                <span className="key-words"> 状态 </span>
                                {getFieldDecorator('isOnline', {
                                    initialValue: '10'
                                })(
                                    <Select
                                        onChange={this.handleChange}
                                        dropdownClassName="xiala"
                                    >
                                        <Select.Option value="10">全部</Select.Option>
                                        <Select.Option value="1">在线</Select.Option>
                                        <Select.Option value="0">离线</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <div className="btn-group">
                                <button className="search-btn ant-btn" onClick={this.seachGather}>
                                    搜索
                                </button>
                                <button className="search-btn ant-btn" onClick={this.reset}>
                                    重置
                                </button>
                                <button className="search-btn ant-btn" onClick={this.showModal}>
                                    增加
                                </button>
                                <button className="search-btn delete-btn ant-btn" onClick={this.onDeleteBatch}>
                                    删除
                                </button>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <ConfigProvider locale={zhCN}>
                            <div className="content-table">
                                <GatherTable
                                    getTable={this.getTable}
                                    deptId={this.state.deptId}
                                    gzzXh={this.state.gzzXh}
                                    isOnline={this.state.isOnline}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                    <AddModal
                        visible={this.state.visible}
                        handleOk={this.handleOk}
                        handleCancel={this.handleCancel}
                        getTable={this.getTable}
                    />
                </div>
            );
        }
    }
);
export default Form.create()(GatherManager);
