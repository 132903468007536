/*
 * @Author: liulian
 * @Date: 2019/9/18 10:50:06 
 * @Last Modified by: 
 * @Last Modified time: 
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from '../../../lib/jquery-vendor';
import {videoPart, dept, dispatch} from '@/rest-api/restapi-video';
import zTree from 'ztree';
import 'ztree/js/jquery.ztree.exhide';
import 'ztree/css/zTreeStyle/zTreeStyle.css';
import {commonGet, commonPost, commonPostJson} from '@/request/common-axios';
import {help_api, queryflow_api, scooper_core_api} from '@/rest-api/restapi';
import {
    setDeptAction,
    setCollectParentAction,
    setCollectedDicAction,
    setCollectAction,
    setCollectItemAction,
    setCloudEyeCloseAction,
    setPlayListAction,
    setPatrolAction
} from '@/reducer/video_action';
import {Input, Icon, Menu, Dropdown, Modal, Button, Select, Popconfirm, message, Form} from 'antd';
import '../../../less/video/ztree-cover.less';
import collecta from '../../../image/video-img/collect.png';
import collecty from '../../../image/video-img/collecta.png';
import playImg from '../../../image/video-img/yunyan1.png';
import yuyuan1 from '../../../image/video-img/yunyan.png';
import Frame from 'react-frame-component';
import {openCloud} from '../../components/dispatch-cloud';
import {pocMemStatus} from '../../components/dispatch-cloud';
import {closeCloudEye} from '../../components/dispatch-cloud';
import Subscribe from '../../../subscribe';
import Pinyin from 'pinyin';
import {debounce} from 'lodash';


const {Search} = Input;
const {Option} = Select;
const {confirm} = Modal;
let list_status = [];
let list_statusPoc = [];
let operList = [];   //存放操作员信息
let isClick;

@connect(
    state => {
        return {
            deptTree: state.deptTree,
            videoController: state.videoController,
            collectTreeParent: state.collectTreeParent,
            collectItem: state.collectItem,
            playList: state.playList,
            cloudEyeId: state.cloudEyeId,
            listStatus: state.listStatus,
            collectTree: state.collectTree,
            isFlow: state.isFlow,
            collectedDic: state.collectedDic,
            patrolList: state.patrolList
        };
    },
    {
        setDeptAction,
        setCollectParentAction,
        setCollectItemAction,
        setCloudEyeCloseAction,
        setCollectAction,
        setCollectedDicAction,
        setPlayListAction,
        setPatrolAction
    }
)
class Terminal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deptName: '',
            visible: false,
            propKey: -1,
            collectId: -1,
            bookmarkId: -1,
            confirmVisible: false,
            cancelId: -1000,
            realCancelId: -1000,
            searchVal: '',
            openCloseMenuVisible: false,
            key: '',
            type: '',
            windowId: -100,
            searchList: [],
            showView: 'tree',
            collectList: []  //已收藏的设备列表
        };
    }

    componentDidMount() {
        let self = this;
        console.log('===========_this.props.videoController', this.props.videoController);
        this.token = sessionStorage.getItem('token');
        this.loadCollectParent();
        //监听消息改变树节点成员状态
        scooper.poc.listen(scooper.poc.event_const.POC_MEM_CHN, data => {
            console.log('gengxin', data);
            const {msg} = data;
            let changeNode = [];
            self.props.deptTree && self.props.deptTree.length && self.props.deptTree.map(item => {
                if (!item.isParent) {
                    if (item.devId === msg.pocNo) {
                        changeNode.push(item);
                    }
                }
            });

            changeNode && changeNode.map(val => {
                console.log('gengxzhuyaocaozuo-----val', val);
                const className = `[title='${val.name}']`;
                $(className).removeClass('idle').removeClass('offline');
                $(className).addClass(msg.status);
                if (msg.status === 'working') {
                    $(className).addClass('idle');
                }
            });
        });
        // scooper.dispatch.calls.listStatus(function(data){
        //     data && data.list.map(item => {
        //         list_status[item.tel] = item
        //     });
        //     self.loadTree();
        // });
        scooper.poc.groupPoc.listMemStatus(function (data) {
            console.log(data, '=============listMemStatus1111');
            data.data && data.data.map(item => {
                list_statusPoc[item.pocNo] = item;
            });
            // self.loadTree();
        });

        this.loadTree();

        this.getOperList();

        /**
         * 壳程序
         */
        var globalNativeBridge = window.nativeBridge || window.top.nativeBridge;

        if (globalNativeBridge) {
            this.chromeDemo(globalNativeBridge);
        } else {
            console.log('不是壳');
        }
        if (!sessionStorage.getItem('token')) {
            setTimeout(() => this.props.history.push(`/`), 1000);
        }
    }

    componentWillUnmount() {
        //监听云眼关闭动作
        Subscribe.register('CLOSE_CLOUD_EYE', function (data) {
            let _data = JSON.parse(data.data);
            if (this.props.cloudEyeId) {
                if (_data.pocNo === this.props.cloudEyeId) {
                    this.props.videoController.close(0);
                }
            }
        });
        window.changeStatus = () => {

        };
    }

    loadTree = async () => {
        const _this = this;
        let param = {};
        let response = await commonPost(videoPart.queryFacilityList, param);
        const {data} = response;
        const {list} = data.data.list;
        if (data.code === 0 && data.data.list.length) {
            data.data.list[0].open = true;  // 第一层级展开
        }

        const setting = {
            view: {
                showIcon: false,
                showLine: false,
                fontCss: {color: '#666'},
                addHoverDom: $.proxy(addHoverDomHandler),
                removeHoverDom: $.proxy(removeHoverDomHandler)
            },
            data: {
                parent: true,
                key: {
                    name: 'name',
                    title: 'name'
                },
                simpleData: {
                    enable: true,
                    idKey: 'id',
                    pIdKey: 'parentId'
                }
            },
            // async: {
            //     enable: true,
            //     type: 'post',
            //     url: getDeptAsyncUrl,
            //     dataFilter: dataReturn
            // },
            callback: {
                // onAsyncSuccess: onAsyncSuccess,
                beforeCheck: beforeCheck,
                onClick: clickNode,
                onNodeCreated: getStatus
            }
        };
        let lists = data.data.list;
        if (lists && lists.length > 0) {
            lists.map((item) => {
                if (item.parental == 0) {
                    item.isParent = false;
                } else {
                    item.isParent = true;
                }
            });
        }
        // 初始化树
        $.fn.zTree.init($('#tree'), setting, [].concat(lists));
        this.props.setDeptAction({deptTree: lists});
        // store.dispatch();

        // $.fn.zTree.init($('#tree'), setting, this.props.deptTree);

        function getStatus(event, treeId, treeNode) {
            // console.log(treeNode, '===============terminal treeNode');
            setTimeout(function () {
                if (!treeNode.isParent) {
                    if (treeNode.status) {
                        const tIdNode = `#${treeNode.tId}_a`;
                        switch (treeNode.status) {
                            case 0: // 离线
                            case 'offline': // 离线
                                $(tIdNode).addClass('offline');
                                break;
                            case 1: // 在线
                            case 'idle': // 在线
                            case 'working': // 在线
                                $(tIdNode).addClass('idle');
                                //初始化树时检测到播放列表，给正在播放的视频添加图标常亮按钮
                                _this.props.playList && _this.props.playList.length > 0 && _this.props.playList.map(val => {
                                    if (val && val.video === treeNode.devId) {
                                        const aObj = $('.level' + treeNode.level).find(tIdNode);
                                        const _nodeView = '<i class="bofang" id="bofang"><i/>';
                                        aObj.append(_nodeView);
                                    }
                                });
                                break;
                            case 2: // 未知
                                break;
                            default:
                                break;
                        }
                    }
                    /*if (treeNode.status && treeNode.status === 0) { // 离线
                        $('#' + treeNode.tId + '_a').addClass('offline');
                    } else {
                        let memTel = treeNode.devId;
                        console.log(list_statusPoc, '========getStatus');
                        if (list_statusPoc[memTel] && (list_statusPoc[memTel].status == 'idle' || list_statusPoc[memTel].status == 'working')) {
                            $('#' + treeNode.tId + '_a').addClass('idle');
                        }
                        if (list_statusPoc[memTel] && list_statusPoc[memTel].status == 'offline') {
                            $('#' + treeNode.tId + '_a').addClass('offline');
                        }
                        //初始化树时检测到播放列表，给正在播放的视频添加图标常亮按钮
                        _this.props.playList && _this.props.playList.length > 0 && _this.props.playList.map(val => {
                            if (val.video == memTel) {
                                var aObj = $('.level' + treeNode.level).find('#' + treeNode.tId + '_a');
                                var _nodeView = '<i class="bofang" id="bofang"><i/>';
                                aObj.append(_nodeView);
                            }
                        });
                    }*/
                } else {
                    $('#' + treeNode.tId + '_a').addClass('dept-name');
                }
            }, 1);
        }

        function setCheckStatus(node, res) {
            console.log('itemres', node);
            node.forEach(item => {

                console.log('item', item);
                if (item.children) {
                    setCheckStatus(item.children, res);
                } else {
                    if (item.status !== 1) {
                        res.status = false;
                    }
                }
            });
        }

        function beforeCheck(treeId, treeNode) {
            console.log('treeId', treeId, treeNode);
            if (treeNode.children) {
                const arraytree = treeNode.children;
                let res = {status: true};
                setCheckStatus(treeNode.children, res);
                return res.status;
            } else {
                return treeNode.status === 1;   // 离线谧无法选择过滤
            }
        }

        function clickNode(event, treeId, treeNode, clickFlag) {
            if (!treeNode.isParent) {
                // let realId = treeNode.devId;
                // let params = {
                //     propKey: realId,
                //     catalogType: 1
                // };
                // if (!document.getElementById('play-monitor' + treeNode.id) && !document.getElementById('member-more-operate' + treeNode.id)) {
                //     var aObj = $('.level' + treeNode.level).find('#' + treeNode.tId + '_a');
                //     var _nodeView = '<button class="play-test call-video plays-' + realId + '" title="视频播放"  id="play-monitor' + treeNode.id + '" video="' + treeNode.id + '"></button><button class="more-operate" id="member-more-operate' + treeNode.id + '" title="收藏" propKey="' + realId + '"></button>';
                //     aObj.append(_nodeView);
                // }

                /*commonGet(videoPart.loadBookmarkCatalogInfo, params)
                    .then(({data}) => {
                        if ($('[id=\'member-more-operate' + treeNode.id + '\']').hasClass('allCollect')) {
                            // 换背景+删除
                            $('[id=\'member-more-operate' + treeNode.id + '\']').css('background-image', `url(${collecta})`);
                            $('[id=\'member-more-operate' + treeNode.id + '\']').attr('title', '已收藏');

                        }
                        if (_this.props.videoController.isPlaying(realId) != -1) {
                            console.log('正在播放');
                            $('.plays-' + realId).css('background-image', `url(${yuyuan1})`);
                        }

                    });*/
                // 主要操作
                operateNode(treeNode);
            }
        }

        /*//  异步加载数据
        function dataReturn(treeId, parentNode, data) {
            var treeArr = [];
            if (data.data) {
                if (!parentNode) {
                    data.data.forEach(item => {
                        // if(item.id == sessionStorage.getItem('firstDeptId')) {
                        treeArr.push(item);
                        if (item.dataType == 'orgDept') {
                            item.id = 'dept_' + item.id;
                            item.pid = 'dept_' + item.pid;
                        }
                        if (item.dataType == 'orgMember') {
                            item.id = 'mem_' + item.id;
                            item.pid = 'dept_' + item.pid;
                        }
                        // }

                    });
                } else {
                    data.data.forEach(item => {
                        treeArr.push(item);
                        item.status = '';
                        if (item.dataType == 'orgDept') {
                            item.id = 'dept_' + item.id;
                            item.pid = 'dept_' + item.pid;
                        }
                        if (item.dataType == 'orgMember') {
                            item.id = 'mem_' + item.id;
                            item.pid = 'dept_' + item.pid;
                        }
                    });
                }

                //操作员树节点
                // operList && operList.map(val => {
                //     for(let len = treeArr.length, i=len-1; i>=0; i--) {
                //         if(treeArr[i].dataType == 'orgMember' && treeArr[i].id.indexOf(val.memId) > -1 ) {
                //             treeArr.splice(i, 1);
                //         }
                //     }
                // })

                var operString = ',';
                for (var i = 0; i < operList.length; i++) {
                    operString += ('mem_' + operList[i].memId);
                }
                operString += ',';

                for (let len = treeArr.length, i = len - 1; i >= 0; i--) {
                    if (treeArr[i].dataType == 'orgMember' && operString.indexOf(',' + treeArr[i].id + ',') > -1) {
                        treeArr.splice(i, 1);
                    }
                }
                store.dispatch(setDeptAction({deptTree: treeArr}));
                return treeArr;
            }
        }

        // 异步加载url
        function getDeptAsyncUrl(treeId, treeNode) {
            var url = '';
            if (treeNode) {
                let id = treeNode.id.split('_')[1];
                // url = window.config.sysUrl + "/scooper-video/data/video/load/route/scooper-core-rest/data/contacts/orgDeptManage/listDeptByParent?expandMember=true&orgCorpId=0"
                url = window.config.sysUrl + '/scooper-core-rest/data/contacts/orgDeptManage/listDeptByParent?expandMember=true&orgCorpId=0'
                    + '&token=' + sessionStorage.getItem('token') + '&id=' + id;

            } else {
                //首次加载url
                //  url = window.config.sysUrl + "/scooper-video/data/video/load/route/scooper-core-rest/data/contacts/orgDeptManage/listDeptByParent?expandMember=true&orgCorpId=0"
                url = window.config.sysUrl + '/scooper-core-rest/data/contacts/orgDeptManage/listDeptByParent?expandMember=true&orgCorpId=0'
                    + '&token=' + sessionStorage.getItem('token');
            }
            return url;
        }*/

        let html;
        let selectId = -1;

        // 移到叶子结点的事件
        function addHoverDomHandler(treeId, treeNode) {
            // 条件根据接口更改 得到是没有子节点的结点
            let self = this;
            if (!treeNode.isParent) {
                if (selectId === treeNode.id) return;
                const {id, devId, tId, level} = treeNode;
                selectId = id;
                // 查找部门名称
                // _this.loadDeptName(treeNode.pid.split("_")[1]);
                if (!document.getElementById(`play-monitor${id}`) && !document.getElementById(`member-more-operate${id}`)) {
                    const aObj = $(`.level${level}`).find(`#${tId}_a`);
                    const _nodeView = '<button class="play-test call-video plays-' + devId + '" title="视频播放"  id="play-monitor' + id + '" video="' + id + '"></button><button class="more-operate" id="member-more-operate' + id + '" title="收藏" propKey="' + devId + '"></button>';
                    aObj.append(_nodeView);
                }
                // 收藏夹数据
                if (_this.props.collectedDic.hasOwnProperty(devId)) {
                    const collectFilter = `[id='member-more-operate${id}']`;
                    // 换背景+删除
                    $(collectFilter).css('background-image', `url(${collecta})`);
                    $(collectFilter).attr('title', '已收藏');
                }

                /*
                let params = {
                    propKey: devId,
                    catalogType: 1
                };
                commonGet(videoPart.loadBookmarkCatalogInfo, params)
                    .then(({data}) => {
                        if (data.data) {
                            //html = '<button class="play-test call-video plays-'+realId+' " title="视频播放" id="play-monitor' + treeNode.id + '" video="' + treeNode.id + '"></button><button class="more-operate allCollect" id="member-more-operate' + treeNode.id + '" title="已收藏" propKey="' + realId + '"></button>';
                            $('[id=\'member-more-operate' + treeNode.id + '\']').css('background-image', `url(${collecta})`);
                            $('[id=\'member-more-operate' + treeNode.id + '\']').attr('title', '已收藏');
                            $('[id=\'member-more-operate' + treeNode.id + '\']').addClass('allCollect');
                        }
                        // 已收藏
                        if ($('[id=\'member-more-operate' + treeNode.id + '\']').hasClass('allCollect')) {
                            // 换背景+删除
                            $('[id=\'member-more-operate' + treeNode.id + '\']').css('background-image', `url(${collecta})`);
                            $('[id=\'member-more-operate' + treeNode.id + '\']').attr('title', '已收藏');
                        }
                        if (_this.props.videoController.isPlaying(realId) != -1) {
                            // $("[title='" + treeNode.extraDataMap.memName + "']").addClass('bofang');
                            // var aObj = $(".level" + treeNode.level).find("#" + treeNode.tId + "_a")
                            // var _nodeView = '<i class="bofang" id="bofang"><i/>';
                            // aObj.append(_nodeView);
                            $('.plays-' + realId).css('background-image', `url(${yuyuan1})`);
                        }
                    });*/
                // 主要操作
                operateNode(treeNode);
                // 关闭事件
                if (_this.props.videoController.isPlaying(id) > -1) {
                    if ($('#' + tId + '_a .stop-monitor').length > 0) return;
                    const html = '<button class="stop-monitor" title="关闭"  id="stop-monitor' + id + '"></button>';
                    $('#' + tId + '_a').append(html);
                    operateNode(treeNode);
                } else {

                }
            }
        }

        //鼠标移除焦点事件
        function removeHoverDomHandler(treeId, treeNode) {
            if (!treeNode.isParent) {
                selectId = -1;
                $('.level' + treeNode.level + ' button').off().remove();
            }
        }

        let self = this;

        // 主要操作
        async function operateNode(treeNode) {
            console.log('gengxzhuyaocaozuo', treeNode);
            // 点击播放按钮
            isClick = true;
            const realId = treeNode.memTel;
            $('[id=\'play-monitor' + treeNode.id + '\']').click(function (e) {
                e.stopPropagation();
                if (isClick) {
                    isClick = false;
                    let deptName = $('#' + treeNode.parentTId + '_a').attr('title');
                    let pocNo = treeNode.devId;
                    if ($('#' + treeNode.tId + '_a')[0].className.indexOf('idle') === -1) {
                        message.error('当前人员离线');
                    } else {
                        self.play(pocNo, treeNode.name, deptName, treeNode);
                        setTimeout(function () {
                            isClick = true;
                        }, 1000);
                    }
                } else {
                    // message.info("请勿频繁操作！")
                }
            });
            // 点击收藏按钮的操作
            $('[id=\'member-more-operate' + treeNode.id + '\']').click(function () {
                self.getCollect(treeNode.id, treeNode.devId, treeNode.name);
            });
        }

        const isFirst = {
            videoTerminal: true,
            monitorList: true,
            getIsFirst: function (treeId) {
                return treeId === 'tree' ? this.videoTerminal : this.monitorList;
            },
            setIsFirst: function (treeId, isFirst) {
                if (treeId === 'tree') {
                    this.videoTerminal = isFirst;
                } else {
                    this.monitorList = isFirst;
                }
            }
        };

        //展开第一个树节点, 一直展开到第一个节点的最内层
        function onAsyncSuccess(event, treeId, treeNode, msg) {
            if (!isFirst.getIsFirst(treeId)) {
                return;
            }
            var treeObj = $.fn.zTree.getZTreeObj(treeId);
            if (!treeNode) {
                // var nodes = treeObj.getNodes();
                var nodes = treeObj.getNodesByParam('level', 0);
                if (nodes && nodes.length > 0 && nodes[0].isParent) {
                    treeObj.expandNode(nodes[0], true, false, false);//默认展开第一级节点
                } else {
                    isFirst.setIsFirst(treeId, false);
                }
                return;
            }
            if (!treeNode.isParent || treeNode.children.length == 0 || !treeNode.children[0].isParent) {
                isFirst.setIsFirst(treeId, false);
                return;
            }
            // treeObj.expandNode(treeNode.children[0], true, false, false);//默认展开第一级节点
        }


        /*var treeObj = $.fn.zTree.getZTreeObj('tree');
        if (treeObj) {
            var node = treeObj.getNodeByParam('id', 0, null);
            treeObj.reAsyncChildNodes(node, 'refresh');
        }*/


    };

    /**
     * 播放视频
     */
    play = (pocNo, memName, deptName, treeNode) => {
        const self = this;
        const {isFlow} = self.props;
        if (pocNo === '') {
            // message.error('该成员未绑定设备！');
        }
        this.props.setCloudEyeCloseAction({cloudEyeId: pocNo});
        if (self.props.videoController.isPlaying(pocNo) !== -1) {
            message.error('该视频正在播放');
        } else {
            const param = {
                sipTel: pocNo,
                type: 'video'
            };
            if (isFlow) {
                queryflow_api.queryDevFlowState(param).then(resp => {
                    if (!resp.data.obj) {
                        message.error(resp.message || '设备流量达上限！');
                    } else {
                        if (resp.message) {
                            message.warning(resp.message);
                        }
                        self.openVideo(pocNo, memName, deptName, treeNode);
                    }
                });
            } else {
                self.openVideo(pocNo, memName, deptName, treeNode);
            }
        }
    };

    /**
     * 调度打开云眼
     */
    openVideo = (pocNo, memName, deptName, treeNode) => {
        const self = this;
        scooper.poc.groupPoc.openCloudEye(pocNo, function (data) {
            let _nodeView;
            let aObj;
            //打开云眼前先判断该云眼是否已经打开
            let videoIndex = self.props.videoController && self.props.videoController.isPlaying(pocNo);
            if (data.data.result !== 'fail' && data.code === 0) {
                if (videoIndex === -1) {
                    self.props.videoController.playByOrderExpandWindow(pocNo, pocNo, {
                        name: memName,
                        parentName: deptName
                    });
                    if (treeNode) {
                        aObj = $('.level' + treeNode.level).find('#' + treeNode.tId + '_a');
                        _nodeView = '<i class="bofang" id="bofang"><i/>';
                        aObj.append(_nodeView);
                    } else {
                        aObj = $('li[class=' + pocNo + ']').find('.bofang-btn');
                        _nodeView = '<i class="bofang" id="bofang"><i/>';
                        aObj.append(_nodeView);
                    }
                }
            } else {
                let reason;
                if (data.data.reason) {
                    reason = data.data.reason;
                    if (reason.indexOf('非预呼叫对讲成员') > -1) {
                        reason = '设备离线!';
                    } else if (reason.indexOf('无此对讲成员') > -1) {
                        reason = '该成员未绑定设备！';
                    }
                } else {
                    reason = '';
                }
                message.error(reason);
            }
        });
    };

    /**
     * 点击收藏夹按钮
     */
    getCollect = (id, devId, memName) => {
        // 已收藏
        if ($('[id=\'member-more-operate' + id + '\']').hasClass('allCollect')) {
            this.setState({
                cancelId: id,
                realCancelId: devId,
                confirmVisible: true
            });
            this.loadInfo(id);
        } else {
            // 未收藏
            this.setState({
                visible: true,
                propKey: devId
            });
            const collectItem = {
                collectId: id,
                propKey: devId,
                catalogType: 0,
                name: memName
            };
            this.props.setCollectItemAction({collectItem});
        }
    };

    /**
     * 获取收藏夹的数据，并放入redux
     * @return {Promise<void>}
     */
    loadCollectParent = async () => {
        let param = {};
        let response = await commonPost(videoPart.loadCollectList, param);
        if (response.data.code === 0) {
            const {list} = response.data.data;
            const collectedDic = {};
            list.forEach(item => {
                item.isParent = true;
                const {bookmarkCatalogs} = item;
                const collectList = [];
                if (bookmarkCatalogs.length === 0) {
                    this.props.setCollectedDicAction({collectedDic: {}});
                } else {
                    bookmarkCatalogs.forEach(devItem => {
                        devItem.isCollected = true;
                        collectedDic[devItem.catalogId] = devItem;
                        collectList.push(devItem);
                        this.setState({collectList});
                        this.props.setCollectedDicAction({collectedDic});
                    });
                }
            });
            this.props.setCollectParentAction({collectTreeParent: response.data.data.list});
        }
    };

    loadInfo = (id) => {
        return;
        let params = {};
        commonPost(videoPart.loadCollectList, params)
            .then(({data}) => {
                if (data.code === 0) {
                    const {list} = data.data;
                    list.forEach(item => {
                        item.isParent = true;
                    });
                    // store.dispatch(setCollectItemAction({collectItem: list}));
                }
                /*if (data.data) {
                    let collet = {
                        collectId: id,
                        propKey: data.data.prop_key,
                        catalogType: data.data.catalog_type,
                        name: data.data.name,
                        bookmarkId: data.data.bookmarkId,
                        flag: 0
                    };
                    store.dispatch(setCollectItemAction({collectItem: collet}));
                }*/
            });
    };


    // 根据部门id查找部门详情,这里只需要部门名称
    loadDeptName = (id) => {
        let params = {
            id: id
        };
        commonGet(dept.getOrgDeptDetail, params)
            .then(({data}) => {
                if (data.code === 0) {
                    this.setState({
                        deptName: data.data.deptName
                    });
                }
            });
    };

    handleChange = e => {
        const self = this;
        const searchVal = e.target.value;
        if (searchVal === '') {
            this.setState({
                showView: 'tree'
            }, () => {
                self.loadTree();
            });
        } else {
            this.setState({
                searchVal,
                showView: 'search'
            });
        }
        this.getSearchList(searchVal);
    };

    //获取部门列表和成员列表
    getSearchList = debounce(searchVal => {
        let tempList = [];
        let _this = this;
        let parms = {
            isPerm: 1,
            searchKey: searchVal
        };
        commonPost(videoPart.queryFacilityList, parms).then(({data}) => {
            if (data.code === 0 && data.data.list.length > 0) {
                tempList = data.data.list;
                _this.setState({
                    searchList: tempList
                });

            } else {
                _this.setState({
                    searchList: []
                });
            }
        });

    }, 800);

    /**
     * 获取操作员列表
     */
    getOperList = async () => {
        let param = {
            centerId: sessionStorage.getItem('centerId')
        };
        const data = await help_api.getOperList(param);
        if (!data.code && data.data.list) {
            operList = data.data.list;
        }
    };


    chromeDemo = (globalNativeBridge) => {
        var _this = this;
        var swindowId = 0;
        globalNativeBridge.windowOp.onMessage('video', function (evt, args) {
            swindowId = globalNativeBridge.windowOp.parentWindowId;
            let openString = args[0];
            let paramArray = openString.split(',');
            let finalParam = [];
            paramArray.forEach((item, index) => {
                finalParam.push(item.split(':'));

            });
            if (finalParam[0][1] == 'open') {
                let videoIndex = _this.props.videoController && _this.props.videoController.isPlaying(finalParam[1][1] || finalParam[2][1]);
                if (finalParam[1][1] || finalParam[2][1] || finalParam[3][1] || finalParam[4][1]) {
                    if (_this.props.videoController) {
                        if (videoIndex == -1) {
                            _this.props.videoController.playByOrderExpandWindow(finalParam[1][1], finalParam[2][1], {
                                name: finalParam[3][1],
                                parentName: finalParam[4][1],
                                isSos: finalParam[5][1],
                                isVideoPoc: finalParam[6][1]
                            });
                            _this.setState({
                                key: finalParam[1][1],
                                type: finalParam[0][1],
                                windowId: evt.senderId
                            });
                        } else {
                            message.info('该云眼视频已经打开');
                        }
                    }
                } else {
                    return;
                }
            } else if (finalParam[0][1] == 'close') {
                _this.props.videoController.close(_this.props.videoController.isPlaying(finalParam[1][1]));
            }
        });
        var intervalplay = window.setInterval(function () {
            if (_this.props.videoController) {
                clearInterval(intervalplay);
                _this.props.videoController.addListener('playsuccess', function (e) {
                    var topic = 'videoBack';
                    var sendMsgText = {type: 'open', id: e.id, isVideoPoc: e.opts.isVideoPoc};
                    globalNativeBridge.windowOp.sendMessageTo(swindowId, topic, JSON.stringify(sendMsgText));
                });
            }
        }, 1000);
        var intervalclose = window.setInterval(function () {
            if (_this.props.videoController) {
                clearInterval(intervalclose);
                _this.props.videoController.addListener('afterclose', function (e) {
                    var topic = 'videoBack';
                    var sendMsgText = {type: 'close', id: e.id, isVideoPoc: e.opts.isVideoPoc};
                    globalNativeBridge.windowOp.sendMessageTo(swindowId, topic, JSON.stringify(sendMsgText));
                });
            }
        }, 1000);
    };


    /**
     * 确认收藏
     */
    handleOk = e => {
        const paramItem = this.props.collectItem;
        let bookmarkId = this.state.bookmarkId;
        let params = [{
            bookmarkId: bookmarkId,
            propKey: paramItem.propKey,
            catalogType: paramItem.catalogType,
            catalogId: paramItem.propKey,
            name: paramItem.name
        }];
        if (bookmarkId === -1) {
            message.error('请选择收藏夹');
            return;
        }

        commonPostJson(videoPart.saveBookmarkCatalog, params)
            .then(({data}) => {
                if (data.code === 0) {
                    message.success('收藏成功！');
                    this.setState({
                        visible: false,
                        bookmarkId: -1
                    });
                    // 保存成功重新加载收藏夹树
                    this.loadBookmarkCatalog();
                    this.props.form.setFieldsValue({
                        bookmarkId: ''
                    });
                    // 将collect.flag置为1
                    let collet = {
                        bookmarkId: bookmarkId,
                        propKeys: paramItem.propKey,
                        catalogType: paramItem.catalogType,
                        collectId: paramItem.collectId,
                        name: paramItem.name,
                        flag: 1
                    };
                    $('[id=\'member-more-operate' + paramItem.collectId + '\']').addClass('allCollect');
                    $('[id=\'member-more-operate' + paramItem.collectId + '\']').css('background-image', `url(${collecta})`);
                    this.props.setCollectItemAction({collectItem: collet});
                }
            });
    };

    loadBookmarkCatalog = async () => {
        let param = {};
        let data = await commonPost(videoPart.loadCollectList, param);
        if (data.data.code === 0) {
            this.props.setCollectAction({collectTree: data.data.list});
        }
        await this.loadCollectParent();
    };

    /**
     * 取消收藏
     */
    handleCancel = e => {
        this.setState({
            visible: false
        });
        this.props.form.resetFields();
    };

    onChange = (value) => {
        this.setState({
            bookmarkId: `${value}`
        });
    };

    onSearch = (val) => {
        console.log('search:', val);
    };

    searchList = (value) => { //输入框的值
        this.setState({
            searchVal: value
        }, () => {
            var treeObj = $.fn.zTree.getZTreeObj('tree');
            if (this.state.value === '') {
                var nodes = treeObj.getNodesByParam('isHidden', true);
                treeObj.showNodes(nodes);
            } else {
                var nodes = treeObj.transformToArray(treeObj.getNodes());

                treeObj.hideNodes(nodes);
                var nodeArray;
                nodeArray = treeObj.getNodesByFilter(this.treeFilter);
                console.log('filter node array length=' + nodeArray.length);
                for (var i = 0; i < nodeArray.length; i++) {
                    var node = nodeArray[i];
                    var pathNodes = node.getPath(node);
                    treeObj.showNodes(pathNodes);
                    var childrenNodes = node.children;
                    if (childrenNodes) {
                        for (var j = 0; j < childrenNodes.length; j++) {
                            var pathNodes = childrenNodes[j].getPath(childrenNodes[j]);
                            treeObj.showNodes(pathNodes);
                        }
                    }
                }
                treeObj.expandAll(true);
            }
        });
    };

    treeFilter = (node) => {
        const getPinYin = Pinyin(node.name, {
            style: Pinyin.STYLE_NORMAL
        });

        //获取全拼
        var result1 = getPinYin.join('');
        node['fullPinyin'] = result1;
        //获取首字母
        var list = [];
        getPinYin && getPinYin.length > 0 && getPinYin.map(item => {
            list.push(item[0].substr(0, 1));
        });
        var result2 = list.join('');
        node['initialPinyin'] = result2;
        // // var flag = node.level == 1 || node.level == 2 || node.level == 3;
        // return node.deptName.indexOf(this.state.searchVal) >= 0 || 
        //             node.fullPinyin.indexOf(this.state.searchVal) >= 0 || 
        //             node.initialPinyin.indexOf(this.state.searchVal) >= 0;
        console.log(node);
    };

    /**
     * 得到收藏夹中的根节点
     */
    InitOptions = () => {
        let treeParent = this.props.collectTreeParent;
        const options = [];
        treeParent.forEach((item, index) => {
            options[index] = item;
        });
        return options;
    };

    /**
     * 取消收藏的确定
     */
    confirm = (e) => {
        this.setState({
            confirmVisible: false
        });
        // console.log(this.state.cancelId)
        let paramCancel = {
            propKeys: this.state.realCancelId,
            catalogType: 1
        };
        commonPost(videoPart.updateBookmarkCatalog, paramCancel)
            .then(({data}) => {
                if (data.code === 0) {
                    // 保存成功重新加载收藏夹树
                    this.loadBookmarkCatalog();
                    // '[id=\'member-more-operate' + this.state.cancelId + '\']'
                    const idValue = `[id="member-more-operate${this.state.cancelId}"]`;
                    $(idValue).removeClass('allCollect');
                    $(idValue).css('background-image', `url(${collecty})`);
                    $(idValue).attr('title', '收藏');
                    message.success('取消收藏成功');
                }
            });
    };

    /**
     * 取消收藏的取消
     */
    cancel = (e) => {
        this.setState({
            confirmVisible: false
        });
    };

    /**
     * 设备是否在播放
     */
    isPlaying = (devId) => {
        const {playList} = this.props;
        if (playList.length > 0) {
            let isFind = false;
            playList.map((item) => {
                if (item && item.video == devId) {
                    isFind = true;
                }
            });
            if (isFind) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    onOpenCloseMenuHandler = () => {
        this.setState({
            openCloseMenuVisible: !this.state.openCloseMenuVisible
        });
        const zTree = $.fn.zTree.getZTreeObj('tree');
        zTree.setting.check.enable = !this.state.openCloseMenuVisible;
        zTree.refresh();
    };
    /**
     * 轮巡按钮触发
     */
    onPatrolHandler = () => {
        const {patrolList} = this.props;
        const zTree = $.fn.zTree.getZTreeObj('tree');
        const nodes = zTree.getChangeCheckedNodes();
        const currentPatrolList = nodes.filter(item => {
            return item.isParent === false;
        });
        if (currentPatrolList.length === 0) {
            message.warning('当前未选择视频,请选择要播放的视频');
            return;
        }
        if (patrolList.length > 0) {
            confirm({
                title: '当前视频正在轮巡，是否要中断？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    this.updatePatrol();
                }
            });
        } else {
            this.updatePatrol();
        }
    };

    updatePatrol = () => {
        this.props.videoController.stopPoll();
        const zTree = $.fn.zTree.getZTreeObj('tree');
        const nodes = zTree.getChangeCheckedNodes();
        const currentPatrolList = nodes.filter(item => {
            return item.isParent === false;
        });
        console.log('--------轮询点击', currentPatrolList);
        if (currentPatrolList.length === 0) {
            message.warning('当前未选择视频,请选择要播放的视频');
            return;
        }
        this.props.setPatrolAction({patrolList: currentPatrolList});
        const nodeList = currentPatrolList.map(item => {
            const devId = item.devId;
            const parentNode = item.getParentNode();
            item.parentName = parentNode.name;
            return {
                video: devId,
                id: devId,
                opts: {name: item.name, parentName: item.parentName}
            };
        });
        this.props.videoController.startPoll(nodeList);
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {showView, searchList, openCloseMenuVisible} = this.state;
        const {collectedDic, playList} = this.props;
        const StyleIframe = {
            wrap: {
                position: 'absolute',
                visibility: 'inherit',
                top: '-6px',
                left: '-5px',
                width: '372px',
                height: '210px',
                zIndex: '-1',
                border: 'medium',
                filter: 'alpha(opacity=0)'
            }
        };

        return (
            <div className='terminal-wrap'>
                <div className={'top-menu-wrap'}>
                    <span className='open-close-menu' onClick={this.onOpenCloseMenuHandler}/>
                    {openCloseMenuVisible ?
                        <button className='patrol' onClick={this.onPatrolHandler}>轮巡</button> : null}
                </div>
                <Search
                    className='input-search'
                    id='search-video'
                    prefix={<Icon type='search' style={{color: '#057797'}}/>}
                    placeholder='搜索人员'
                    onChange={this.handleChange}
                />

                {showView === 'tree' && <div className='zTreeVideo'>
                    <ul id='tree' className='ztree'/>
                </div>}
                {showView === 'search' && <div className='search-box'>
                    {searchList.length && searchList.map(list => {
                        return (
                            <li className={list.memTel} key={list.id}>
                                <span className='search-res'>
                                    {/* ==0 :设备 1：部门 */}
                                    <span className='mem-name' title={list.name}><Icon type='user'/>{list.name}</span>
                                    <span title={list.parentName}>({list.parentName})</span>
                                </span>
                                <span className='bofang-btn'>
                                    {this.isPlaying(list.devId) && <i class='bofang' id='bofang'/>}
                                </span>
                                {list.parental == 0 && list.devId && <span className='oper-btn-group'>
                                    <button className="play-test call-video plays-'+list.memTel+'" title='视频播放'
                                            id="play-monitor' + list.id + '" video="' + list.id + '"
                                            onClick={() => this.play(list.devId, list.name, list.parentName)}/>
                                    <button
                                        className={`more-operate ${collectedDic.hasOwnProperty(list.devId) ? 'allCollect' : ''} `}
                                        id="member-more-operate' + list.id + '"
                                        propKey="' + list.devId  + '"
                                        onClick={() => this.getCollect(list.id, list.devId, list.name)}
                                    />
                                    {/* {!list.isCollected &&
                                    <button className="more-operate" id="member-more-operate' + list.id + '" title="收藏"
                                            propKey="' + list.memTel  + '"
                                            onClick={() => this.getCollect(list.id, list.memTel, list.memName)}/>}
                                    {list.isCollected &&
                                    <button className="more-operate allCollect" id="member-more-operate' + list.id + '"
                                            title="已收藏" propKey="' + list.memTel  + '"
                                            onClick={() => this.getCollect(list.id, list.memTel, list.memName)}/>} */}
                                </span>}
                            </li>
                        );
                    })}
                </div>}

                {/* <div className='zTreeVideoSearch hide'>
                    <ul id='zTreeVideoSearch' className='ztree'></ul>
                </div> */}
                <div>
                    <Modal
                        title='选择收藏夹'
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okText='确定'
                        cancelText='取消'
                        className='add-config add-collect'
                    >
                        {getFieldDecorator('bookmarkId', {
                            initialValue: ''
                        })(
                            <Select
                                showSearch
                                style={{width: 270}}
                                placeholder='请选择收藏夹'
                                optionFilterProp='children'
                                onChange={this.onChange}
                                // onFocus={this.onFocus}
                                // onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.InitOptions().map((item) => {
                                    return <Option key={item.id} title={item.bookmarkName}
                                                   value={item.id}>{item.bookmarkName}</Option>;
                                })}
                            </Select>
                        )}
                        <Frame style={StyleIframe.wrap}/>
                    </Modal>


                    {/* <Popconfirm
                        visible={this.state.confirmVisible}
                        title="确定要取消收藏吗？"
                        onConfirm={this.confirm}
                        onCancel={this.cancel}
                        okText="确定"
                        cancelText="取消"
                    >

                    </Popconfirm> */}

                    {/* <Modal
                        visible={this.state.confirmVisible}
                        title='确定要取消收藏吗?'
                        okText='确定'
                        okType='danger'
                        className='add-config delConfirm'
                        cancelText='取消'
                        onOk={
                            this.confirm
                        }
                        onCancel={
                            this.cancel
                        }
                    >
                        <Frame style={StyleIframe.wrap}></Frame>
                    </Modal> */}
                </div>
                <div>
                    <Modal
                        visible={this.state.confirmVisible}
                        title='确定要取消收藏吗?'
                        className='add-config delConfirm'
                        okText='确定'
                        cancelText='取消'
                        onOk={this.confirm}
                        onCancel={this.cancel}
                    >
                        <Frame style={StyleIframe.wrap}/>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default Form.create()(Terminal);