//============================Action===================================
import {
    SET_ADDUSER_ACTION,
    SET_BOOKNAME_ACTION,
    SET_CHECKED_ACTION,
    SET_CLOUDEYECLOSE_ACTION,
    SET_COLLECT_ACTION, SET_COLLECTED_DIC_ACTION,
    SET_COLLECTITEM_ACTION,
    SET_COLLECTNAME_ACTION,
    SET_COLLECTPARENT_ACTION,
    SET_CONFIGDATA_ACTION,
    SET_CORPMEMBER_ACTION,
    SET_CORPMEMBERALL_ACTION,
    SET_CORPNODE_ACTION,
    SET_CORPNODEID_ACTION,
    SET_DASDATA_ACTION,
    SET_DATATYPE_ACTION,
    SET_DATESTORE_ACTION,
    SET_DEPT_ACTION,
    SET_DEPT_MEMBER_ACTION,
    SET_DEPT_TREEDATA,
    SET_DEVINFO_ACTION,
    SET_DEVMEMBER_ACTION,
    SET_DEVMEMBERALL_ACTION,
    SET_DEVNUM_ACTION,
    SET_DISPGROUP_ACTION,
    SET_FILEDATA_ACTION,
    SET_FILEDATALL_ACTION,
    SET_FILELENGTH_ACTION,
    SET_FILELEVEL_ACTION,
    SET_FILERECORD_ACTION,
    SET_FILETIME_ACTION,
    SET_GATHERCOUNT_ACTION,
    SET_GATHERDETAIL_ACTION,
    SET_GATHERLIST_ACTION,
    SET_GATHERLISTALL_ACTION,
    SET_GROUPMEMBER_ACTION,
    SET_GROUPMEMBERALL_ACTION,
    SET_ISDOWNLOAD_ACTION,
    SET_ISFLOW_ACTION,
    SET_KEYSELECTED_ACTION,
    SET_LISTSTATUS_ACTION, SET_LOCK_SCREEN_ACTION,
    SET_ORGDEPT_ACTION, SET_PATROL_ACTION,
    SET_PLAYLIST_ACTION,
    SET_PLAYRECORDLIST_ACTION,
    SET_POCFILE_ACTION,
    SET_POCFILEALL_ACTION,
    SET_POLICELIST_ACTION,
    SET_POLICELISTALL_ACTION, SET_RECORD_DATA_ACTION, SET_RECORDCOUNT_ACTION,
    SET_ROWSELECTED_ACTION,
    SET_SEARCH_ACTION,
    SET_SELECT_ACTION, SET_SELECTEDKEY_ACTION, SET_SELECTEDlLOG_ACTION,
    SET_SELECTEDVALUE_ACTION,
    SET_SELECTPOC_ACTION,
    SET_SELECTPOCKEY_ACTION,
    SET_SHOWSELECT_ACTION,
    SET_STORERANGE_ACTION,
    SET_SYSDATA_ACTION,
    SET_TIMERANGE_ACTION,
    SET_VIDEOCOR_ACTION,
    SETSELECTEDLOG_ACTION,
    SETSORTLIST_ACTION,
    SETSORTLISTALL_ACTION
} from '@/reducer/constant';

export const setIsFlowAction = payload => {   //存储使用情况
    return {
        type: SET_ISFLOW_ACTION,
        payload: payload
    };
};

export const setSelectPocAction = payload => {   //存储使用情况
    return {
        type: SET_SELECTPOC_ACTION,
        payload: payload
    };
};

export const setSelectPocKeyAction = payload => {   //存储使用情况
    return {
        type: SET_SELECTPOCKEY_ACTION,
        payload: payload
    };
};

export const setStoreRangeAction = payload => {   //存储使用情况
    return {
        type: SET_STORERANGE_ACTION,
        payload: payload
    };
};

export const setListStatusAction = payload => {   //人员状态数组
    return {
        type: SET_LISTSTATUS_ACTION,
        payload: payload
    };
};

export const setPocFileAction = payload => {
    return {
        type: SET_POCFILE_ACTION,
        payload: payload
    };
};

export const setPocFileAllAction = payload => {
    return {
        type: SET_POCFILEALL_ACTION,
        payload: payload
    };
};

export const setIsDownloadAction = payload => {
    return {
        type: SET_ISDOWNLOAD_ACTION,
        payload: payload
    };
};

export const setCollectNameAction = payload => {
    return {
        type: SET_COLLECTNAME_ACTION,
        payload: payload
    };
};

export const setCloudEyeCloseAction = payload => {
    return {
        type: SET_CLOUDEYECLOSE_ACTION,
        payload: payload
    };
};


export const setSysDataAction = payload => {
    return {
        type: SET_SYSDATA_ACTION,
        payload: payload
    };
};


export const setSearchAction = payload => {
    return {
        type: SET_SEARCH_ACTION,
        payload: payload
    };
};

//首页
export const setConfigDataAction = payload => {
    return {
        type: SET_CONFIGDATA_ACTION,
        payload: payload
    };
};

export const setDevInfoAction = payload => {
    return {
        type: SET_DEVINFO_ACTION,
        payload: payload
    };
};

export const setCheckedAction = payload => {
    return {
        type: SET_CHECKED_ACTION,
        payload: payload
    };
};

//============================deptStart=================================
export const setDeptTreeData = payload => {
    return {
        type: SET_DEPT_TREEDATA,
        payload: payload
    };
};

export const setDevMemberAction = payload => {
    return {
        type: SET_DEVMEMBER_ACTION,
        payload: payload
    };
};

export const setDevMemberAllAction = payload => {
    return {
        type: SET_DEVMEMBERALL_ACTION,
        payload: payload
    };
};

export const setGroupMemberAction = payload => {
    return {
        type: SET_GROUPMEMBER_ACTION,
        payload: payload
    };
};

export const setGroupMemberAllAction = payload => {
    return {
        type: SET_GROUPMEMBERALL_ACTION,
        payload: payload
    };
};

export const setDasDataAction = payload => {
    return {
        type: SET_DASDATA_ACTION,
        payload: payload
    };
};

export const setDispGroupAction = payload => {
    return {
        type: SET_DISPGROUP_ACTION,
        payload: payload
    };
};

export const setOrgDeptAction = payload => {
    return {
        type: SET_ORGDEPT_ACTION,
        payload: payload
    };
};

export const setCorpMemberAction = payload => {
    return {
        type: SET_CORPMEMBER_ACTION,
        payload: payload
    };
};

export const setCorpMemberAllAction = payload => {
    return {
        type: SET_CORPMEMBERALL_ACTION,
        payload: payload
    };
};

export const setCorpNodeAction = payload => {
    return {
        type: SET_CORPNODE_ACTION,
        payload: payload
    };
};
export const setCorpNodeIdAction = payload => {
    return {
        type: SET_CORPNODEID_ACTION,
        payload: payload
    };
};
//============================deptEnd===================================

//============================countStart=================================

export const setDevNumAction = payload => {
    return {
        type: SET_DEVNUM_ACTION,
        payload: payload
    };
};

export const setFileLevelAction = payload => {
    return {
        type: SET_FILELEVEL_ACTION,
        payload: payload
    };
};

export const setFileLengthAction = payload => {
    return {
        type: SET_FILELENGTH_ACTION,
        payload: payload
    };
};

export const setFileTimeAction = payload => {
    return {
        type: SET_FILETIME_ACTION,
        payload: payload
    };
};

export const setFileRecordAction = payload => {
    return {
        type: SET_FILERECORD_ACTION,
        payload: payload
    };
};

export const setDateStoreAction = payload => {
    return {
        type: SET_DATESTORE_ACTION,
        payload: payload
    };
};

export const setDataTypeAction = payload => {
    return {
        type: SET_DATATYPE_ACTION,
        payload: payload
    };
};

//============================countEnd===================================

/*export const setConfigAction = payload => {
    return {
        type: SET_CONFIG_ACTION,
        payload: payload
    };
};*/

//============================videoStart===================================
export const setDeptAction = payload => {
    return {
        type: SET_DEPT_ACTION,
        payload: payload
    };
};
export const setBookNameAction = payload => {
    return {
        type: SET_BOOKNAME_ACTION,
        payload: payload
    };
};

export const setVideoCorAction = payload => {
    return {
        type: SET_VIDEOCOR_ACTION,
        payload: payload
    };
};

export const setPlayRecordListAction = payload => {
    return {
        type: SET_PLAYRECORDLIST_ACTION,
        payload: payload
    };
};
export const setCollectAction = payload => {
    return {
        type: SET_COLLECT_ACTION,
        payload: payload
    };
};
export const setCollectParentAction = payload => {
    return {
        type: SET_COLLECTPARENT_ACTION,
        payload: payload
    };
};

export const setCollectedDicAction = payload => {
    return {
        type: SET_COLLECTED_DIC_ACTION,
        payload
    };
};

export const setCollectItemAction = payload => {
    return {
        type: SET_COLLECTITEM_ACTION,
        payload: payload
    };
};
export const setPlayListAction = payload => {
    return {
        type: SET_PLAYLIST_ACTION,
        payload: payload
    };
};

export const setRecordDataAction = payload => {
    return {
        type: SET_RECORD_DATA_ACTION,
        payload
    };
};
//============================videoEnd===================================

//文件管理模块
export const setFileDataAction = payload => {
    return {
        type: SET_FILEDATA_ACTION,
        payload: payload
    };
};

export const setFileDataAllAction = payload => {
    return {
        type: SET_FILEDATALL_ACTION,
        payload: payload
    };
};
export const setPageAction = payload => {
    return {
        type: SET_PAGE_ACTION,
        payload: payload
    };
};

export const setTimeRangeAction = payload => {
    return {
        type: SET_TIMERANGE_ACTION,
        payload: payload
    };
};

export const setSelectAction = payload => {
    return {
        type: SET_SELECT_ACTION,
        payload: payload
    };
};
export const setSelectKeyAction = payload => {
    return {
        type: SET_SELECTEDKEY_ACTION,
        payload
    };
};
export var setDeptMemAction = payload => {
    return {
        type: SET_DEPT_MEMBER_ACTION,
        payload
    };
};
export const setSelectedValueAction = payload => {
    return {
        type: SET_SELECTEDVALUE_ACTION,
        payload
    };
};

//采集站管理
export const setRowsSelectedAction = payload => {
    return {
        type: SET_ROWSELECTED_ACTION,
        payload
    };
};
export const setKeySelectedAction = payload => {
    return {
        type: SET_KEYSELECTED_ACTION,
        payload
    };
};
export const setGatherDetailAction = payload => {
    return {
        type: SET_GATHERDETAIL_ACTION,
        payload
    };
};
export const setGatherListAction = payload => {
    return {
        type: SET_GATHERLIST_ACTION,
        payload
    };
};
export const setGatherListAllAction = payload => {
    return {
        type: SET_GATHERLISTALL_ACTION,
        payload
    };
};
export const setShowSelectAction = payload => {
    return {
        type: SET_SHOWSELECT_ACTION,
        payload
    };
};
//操作日志
export const setSortListAction = payload => {
    return {
        type: SETSORTLIST_ACTION,
        payload
    };
};
export const setSortListAllAction = payload => {
    return {
        type: SETSORTLISTALL_ACTION,
        payload
    };
};
/*export const setSortAction = payload => {
    return {
        type: SET_SORT_ACTION,
        payload
    };
};
export const setTypeAction = payload => {
    return {
        type: SET_TYPE_ACTION,
        payload
    };
};*/
export const setSelectedLogAction = payload => {
    return {
        type: SETSELECTEDLOG_ACTION,
        payload
    };
};

export const setSelectedKeyLogAction = payload => {
    return {
        type: SET_SELECTEDlLOG_ACTION,
        payload
    };
};
//告警记录
export const setPoliceListAction = payload => {
    return {
        type: SET_POLICELIST_ACTION,
        payload
    };
};

export const setPoliceListAllAction = payload => {
    return {
        type: SET_POLICELISTALL_ACTION,
        payload
    };
};

//人员选择器
export const setAddUserAction = payload => {
    return {
        type: SET_ADDUSER_ACTION,
        payload
    };
};

export const SetGatherCountAction = payload => {
    return {
        type: SET_GATHERCOUNT_ACTION,
        payload
    };
};
export const setRecordCountAction = payload => {
    return {
        type: SET_RECORDCOUNT_ACTION,
        payload
    };
};

export const setLockScreenAction = payload => {
    return {
        type: SET_LOCK_SCREEN_ACTION,
        payload
    };
};

export const setPatrolAction = payload => {
    return {
        type: SET_PATROL_ACTION,
        payload
    };
};