import React, {Component} from 'react';
import {Button, Form, Input, message} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import {sha256} from 'js-sha256';
import {scooper_core_rest} from '@/rest-api/restapi';

class ResetPassWord extends Component {
    state = {
        disabled: false,
        imageSrc: '',
        checked: true
    };

    /**
     * 清空输入框的值(当前密码)
     */
    clearCurrentPasswordValue = () => {
        this.props.form.setFieldsValue({
            currentPassword: ''
        });
    };

    /**
     * 清空输入框的值(新密码)
     */
    clearNewPasswordValue = () => {
        this.props.form.setFieldsValue({
            newPassword: ''
        });
    };

    /**
     * 清空输入框的值(确认密码)
     */
    clearConfirmPasswordValue = () => {
        this.props.form.setFieldsValue({
            confirmPassword: ''
        });
    };

    handleSubmit = () => {
        const {getFieldValue} = this.props.form;
        const currentPasswordValue = getFieldValue('currentPassword');
        if (typeof currentPasswordValue === 'undefined') {
            message.warning('当前密码不能为空');
            return;
        }
        const newPasswordValue = getFieldValue('newPassword');
        if (typeof newPasswordValue === 'undefined') {
            message.warning('请输入新的密码');
            return;
        }
        const confirmPasswordValue = getFieldValue('confirmPassword');
        if (typeof confirmPasswordValue === 'undefined') {
            message.warning('请确认新的密码');
            return;
        }
        if (newPasswordValue && confirmPasswordValue && newPasswordValue !== confirmPasswordValue) {
            message.warning('两次新密码设置不一样');
            return;
        }
        const params = {
            token: sessionStorage.getItem('token'),
            origPwd: sha256(currentPasswordValue),
            newPwd: sha256(newPasswordValue)
        };
        scooper_core_rest.resetSelfPwd(params).then(data => {
            if (data.code === 0) {
                message.success('密码重置成功，请重新登录！');
                this.goBack();
            } else {
                message.error(data.message);
            }
        }).catch(error => {
            message.error(error);
            this.goBack();
        });
    };

    goBack = () => {
        this.props.goBack();
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {disabled, imageSrc, checked} = this.state;
        return (
            <div className="login-form">
                <div className="user-message">
                    <div className="user-avatar">
                        <i/>
                    </div>
                    <div className="login-title">
                        修改密码
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            <i className="pass-left icon-left"/>
                            {getFieldDecorator('currentPassword', {
                                rules: [{required: true, message: '请输入当前密码!'}]
                            })(
                                <Input type="password" placeholder="当前密码" className="password" autoComplete="off"
                                       disabled={disabled}/>
                            )}
                            <i className="user-right icon-right" onClick={this.clearCurrentPasswordValue}/>
                        </Form.Item>
                        <FormItem>
                            <i className="pass-left icon-left"/>
                            {getFieldDecorator('newPassword', {
                                rules: [{required: true, message: '请输入新密码!'}]
                            })(
                                <Input type="password" placeholder="新密码" className="password"
                                       disabled={disabled}/>
                            )}
                            <i className="pass-right icon-right" onClick={this.clearNewPasswordValue}/>
                        </FormItem>
                        <FormItem>
                            <i className="pass-left icon-left"/>
                            {getFieldDecorator('confirmPassword', {
                                rules: [{required: true, message: '请输入新密码!'}]
                            })(
                                <Input type="password" placeholder="确认新密码" className="password"
                                       disabled={disabled}/>
                            )}
                            <i className="pass-right icon-right" onClick={this.clearConfirmPasswordValue}/>
                        </FormItem>
                        <FormItem>
                            <Button htmlType="submit" className="submit-btn">
                                确 认 修 改
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button htmlType="submit" className="submit-btn" onClick={this.goBack}>
                                返 回
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Form.create()(ResetPassWord);