import React, { PureComponent } from 'react';

import Progress from './Progress';
import { Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { usedFlow } = this.props;
        //排序数组
        usedFlow && usedFlow.sort(function(a, b) {
            return b.monthFlow - a.monthFlow
        });
        const totalNum = usedFlow && usedFlow.length > 0 &&  usedFlow[0].monthFlow;

        let _usedFlow = null;
        if(usedFlow) {
            _usedFlow = usedFlow
        }
        //截取排行榜前十位
        if(_usedFlow && _usedFlow.length > 10) {
            _usedFlow = _usedFlow.slice(0, 10);
        } else {
            _usedFlow = _usedFlow;
        }
        return (
            <div className="interTop">
                {_usedFlow && <span className='name'>物联网卡流量使用TOP{_usedFlow.length>10 ? 10 : _usedFlow.length}</span>}
                {/* {this.renderLeftContent()} */}
                    <div className="top-ten-content">
                   
                    {
                        _usedFlow && usedFlow.length > 0 && _usedFlow.map((topTen, index) => {
                            return (
                                <Progress
                                    title={topTen.memName}
                                    num={topTen.monthFlow}
                                    ratio={topTen.monthFlow / totalNum}
                                    key={topTen.id}
                                />
                            )
                        })
                    }
                    {
                        usedFlow && usedFlow.length == 0 &&
                        <ConfigProvider locale={zhCN}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </ConfigProvider>
                    }
                </div>
            </div>
        )
    }
}
