/*
 * @Author: yanghui 
 * @Date: 2019-09-30 10:33
 */

import React from 'react';
import {Input, Form, Select, Button, Modal, message, Layout} from 'antd';
import {connect} from 'react-redux';
import {gather_api} from '@/rest-api/restapi';
import UserSelect from './select-user/UserPicker';
import {
    setAddUserAction,
    setShowSelectAction,
    setDeptMemAction
} from '@/reducer/video_action';
import $ from '../../lib/jquery-vendor';

const {Content} = Layout;
const FormItem = Form.Item;
const {TextArea} = Input;
// const coreIp = sessionStorage.getItem('corpId');
const token = sessionStorage.getItem('token');

const AddModal = connect(
    state => {
        return {
            deptMem: state.deptMem,
            addUser: state.addUser,
            showSelect: state.showSelect
        };
    },
    {
        setDeptMemAction,
        setAddUserAction,
        setShowSelectAction
    }
)(
    class extends React.Component {
        constructor(props) {
            super(props);
            // console.log("deptMem",deptMem);
            this.state = {
                modalVisible: false,
                mode: 1,        //选择器模式
                singleData: [],
                multiData: [],
                deleteAllBtnDis: []
            };
        }

        onCancel = () => {
            this.props.handleCancel();
            this.props.form.resetFields();
            this.props.setAddUserAction({addUser: []});
        };

        componentDidMount() {

        }

        //判断token可用
        // tokenJudge(){
        //     let params = {
        //      token: sessionStorage.getItem('token')
        //     };

        //     let postData = new URLSearchParams();
        //     for (let key in params) {
        //      postData.append(key, params[key]);
        //     }
        //     console.log(postData)
        //     axios.post(`${window.config.sysUrl}+/system/authManage/tokenVerify`,postData)
        //     .then((response) => {
        //         if(response.data.code === 0){
        //         //console.log(response.data);
        //         }else{
        //         message.error(response.data.message);
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //         }
        //     );
        // }


        /**
         * 添加采集站
         */
        addGather = async () => {
            const {
                form: {validateFields}
            } = this.props;
            validateFields(async (err, values) => {
                var param = {};
                $.each(values, (key, value) => {
                    if (key !== 'memId') {
                        param[key] = value;
                    }
                    if (this.props.showSelect == 0) {
                        param.memId = values.memId;
                    } else {
                        param.memId = this.props.addUser.id;
                    }
                });
                if (param.memId == '' || param.memId == undefined || param.memId == null) {
                    return message.error('请输入管理员！');
                }
                const data = await gather_api.addGather(param);
                if (!data.code) {
                    message.success(data.message);
                    this.onCancel();
                    // var param1 = {
                    //     token: sessionStorage.getItem('token'),
                    //     corpId: sessionStorage.getItem('corpId'),
                    //     pageNum: 1,
                    //     pageSize: 10
                    //     // orgCode: sessionStorage.getItem('orgCode')
                    // };
                    // const res = await gather_api.getDataGather(param1);
                    // if (!res.code) {
                    //     this.props.setGatherListAction({gatherList: res.data.list});
                    //     this.props.setGatherListAllAction({gatherListAll: res.data});
                    //     this.onCancel();
                    //     this.props.form.resetFields();
                    //     this.props.setShowSelectAction({showSelect: 2});
                    // }
                    this.props.getTable();

                }
                if (data.code === 1121) {
                    message.error('该采集站已存在！请勿重复添加！');
                }
            });

        };

        /**
         * 人员选择
         */
        showSelector = () => {
            let {singleData} = this.state;
            this.setState({
                modalVisible: true,
                mode: 0,
                defaultMemData: []
            });
        };

        //多人添加按钮
        addMultiPerson() {
            let {multiData} = this.state;
            this.setState({
                modalVisible: true,
                mode: 1,
                defaultMemData: multiData
            });
        }

        //获取人员选择器 人员
        getMemData = (memData) => {
            let {mode} = this.state;
            if (mode === 1) {
                this.setState({
                    multiData: memData
                });
            } else {
                this.setState({
                    singleData: memData
                });
            }
            this.setState({
                modalVisible: false
            });
        };


        render() {
            let {modalVisible, mode, singleData, multiData, defaultMemData} = this.state;
            const {getFieldDecorator} = this.props.form;

            let multiMem = [];
            if (multiData) {
                multiData.map((item) => {
                    multiMem.push(item.memName);
                });
            }

            //向选择器组件传参
            let userPickerParams = {
                visible: modalVisible,
                mode: mode,
                ip: `${window.config.sysUrl}`,
                token: sessionStorage.getItem('token'),
                deptType: 'all',
                limit: 5,
                defaultMemData: defaultMemData
            };

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 6}
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16}
                }
            };

            const userPickerForm = () => {
                return (
                    <React.Fragment>
                        <Form.Item label="采集站编码" className="code">
                            {getFieldDecorator('gzzXh', {
                                initialValue: '',
                                rules: [{required: true, message: '请输入采集站编码!'}]
                            })(
                                <Input style={{'marginLeft': '110px'}}/>
                            )}
                        </Form.Item>
                        {this.props.showSelect == 0 && <Form.Item label="部门" className="left">
                            {getFieldDecorator('deptId', {
                                initialValue: ''
                            })(
                                <Select style={{width: '17.5rem', 'margin-left': '110px'}}
                                        dropdownClassName="add-xiala">
                                    {
                                        this.props.deptMem && this.props.deptMem.map(item => {
                                            return (
                                                <Select.Option value={item.deptId} key={item.deptId}>
                                                    {item.deptName}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>}
                        {this.props.showSelect == 1 && <Form.Item label="部门" className="left">
                            {getFieldDecorator('deptId', {
                                initialValue: this.props.addUser.deptId
                            })(
                                <Select style={{width: '17.5rem', 'margin-left': '110px'}}
                                        dropdownClassName="add-xiala">
                                    {
                                        this.props.deptMem && this.props.deptMem.map(item => {
                                            return (
                                                <Select.Option value={item.deptId} key={item.deptId}>
                                                    {item.deptName}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>}
                        {/* {this.props.showSelect == 2 && <Form.Item label="部门" className="left">
                            {getFieldDecorator('deptId',{
                                initialValue: this.props.deptId
                            })(
                                <Select style={{ width: '17.5rem' }} dropdownClassName="add-xiala">
                                    {
                                        this.props.deptMem && this.props.deptMem.map(item => {
                                            return (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.deptName}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                
                            )}
                        </Form.Item>} */}
                        {/* <FormItem
                      {...formItemLayout}
                      label="单人选择器">
                      {getFieldDecorator('single',{initialValue:singleData[0]?singleData[0].memName:''})
                      ( <TextArea placeholder="请输入人员..." autosize readOnly/>)}
                    </FormItem>
                    <Button type="primary" shape="circle" icon="plus"
                            style={{position:'absolute',left:'56%',top:'22px'}}
                            onClick={this.showSelector}
                    />*/}

                    </React.Fragment>
                );
            };

            return (
                <div>
                    <Modal
                        title="添加采集站"
                        visible={this.props.visible}
                        onOk={this.props.handleOk}
                        onCancel={this.onCancel}
                        className="add-gather"
                        okText="确认"
                        cancelText="取消"
                        footer={[
                            <Button key="back" onClick={this.onCancel} className="cancel">
                                取消
                            </Button>,
                            <Button key="submit" className="confirm" onClick={this.addGather}>
                                添加
                            </Button>
                        ]
                        }
                    >
                        <Form className="form">
                            <Layout style={{padding: '0 24px 24px'}}>
                                <Content style={{
                                    background: '#fff',
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                    position: 'relative'
                                }}>
                                    {userPickerForm()}
                                </Content>
                                <UserSelect {...userPickerParams} memData={memData => this.getMemData(memData)}/>
                            </Layout>
                            {this.props.addUser && this.props.showSelect == 1 &&
                            <Form.Item label="管理员" className="user-select">
                                {getFieldDecorator('memId', {
                                    initialValue: this.props.addUser.memName,
                                    rules: [{required: true, message: '请输入管理员!'}]
                                })(
                                    <React.Fragment>
                                        <Input maxLength={32} value={this.props.addUser.memName} className=" bb"/>
                                        <i className="select-btn" onClick={this.showSelector}></i>
                                    </React.Fragment>
                                )}
                            </Form.Item>}
                            {this.props.showSelect == 0 && <Form.Item label="管理员" className="user-select">
                                {getFieldDecorator('memId1', {
                                    initialValue: '',
                                    rules: [{required: true, message: '请输入管理员!'}]
                                })(
                                    <React.Fragment>
                                        <Input maxLength={32} value={this.props.addUser.memName} className="aa"/>
                                        {this.props.showSelect == 0 &&
                                        <i className="select-btn" onClick={this.showSelector}></i>}
                                    </React.Fragment>
                                )}
                            </Form.Item>}
                        </Form>
                    </Modal>
                </div>
            );

        }
    }
);

export default Form.create()(AddModal);