/*
 * @Description: 数据上传趋势
 * @Author: liulian
 * @Date: 2019-10-08 15:27:16
 * @LastEditTime: 2019-10-25 10:22:31
 * @LastEditors: Please set LastEditors
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import echarts from 'echarts';
import {debounce, isEqual} from 'lodash';
import resizeFootSize from './ResizeFontSize';
import {setFileRecordAction} from '@/reducer/video_action';
import {statistics} from '@/rest-api/restapi-video';
import {commonGet, commonPost} from '@/request/common-axios';

export default connect(
    state => {
        return {
            fileRecord: state.fileRecord
        };
    },
    {
        setFileRecordAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.setPieOption = this.setPieOption.bind(this);
        this.initPie = this.initPie.bind(this);
    }


    componentDidMount() {
        this.initPie();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.fileRecord, nextProps.fileRecord)) {

            this.initPie(nextProps, 1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFn);
        this.myChart = null;
    }


    initPie = (nextProps, type) => {
        if (!this.pieChart) return;

        const {data} = nextProps || this.props;
        let date = [];
        let img = [];
        let audo = [];
        let video = [];
        if (data) {
            data.forEach((item, index) => {
                date.push(item.date);
                img.push(item.image);
                audo.push(item.audio);
                video.push(item.video);

            });
        }


        this.myChart = echarts.init(this.pieChart, 'customed'); //初始化echarts

        let options = null;
        options = this.setPieOption(date, img, audo, video);

        this.myChart.clear();
        //设置options
        this.myChart.setOption(options);

        this.myChart.resize();

        window.addEventListener('resize', this.resizeFn = debounce(() => {
            this.myChart && this.myChart.resize();
        }, 200));
    };

    setPieOption = (date, img, audo, video) => {

        const fontSize = resizeFootSize();
        return {
            title: {},
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['图片', '音频', '视频'],
                right: '1%',
                top: '10',
                borderColor: '#fff',
                textStyle: {
                    color: '#8ED2F6',
                    fontSize: 0.14 * fontSize

                }

            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },

            xAxis: {
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                type: 'category',
                data: date
            },
            yAxis: {
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                splitLine: {
                    show: true,
                    lineStyle: {color: ['#0F2145']}
                },
                type: 'value'
            },
            series: [
                {
                    name: '图片',
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 0.05 * fontSize,
                    itemStyle: {color: '#13C4EC', borderColor: '#fff'},
                    data: img
                },
                {
                    name: '音频',
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 0.05 * fontSize,
                    itemStyle: {color: '#01CC5D', borderColor: '#fff'},
                    data: audo
                },
                {
                    name: '视频',
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 0.05 * fontSize,
                    itemStyle: {color: '#F6B033', borderColor: '#fff'},
                    data: video
                }
            ]
        };

    };

    render() {
        return (

            <div
                id="record"
                style={{width: '100%', height: '100%'}}
                className="recordLine-wrap"
                ref={pieChart => {
                    this.pieChart = pieChart;
                }}
            >
            </div>


        );
    }
});