/*
 * @Author: your name
 * @Date: 2021-12-27 11:25:00
 * @LastEditTime: 2022-03-17 18:10:07
 * @LastEditors: Please set LastEditors
 * @Description:监控列表组件
 * @FilePath: \scooper-poc-web_qsjd\src\view\pages\monitorList\monitorList.js
 */

import { Input, Icon, message, Tree } from "antd";
import { connect } from "react-redux";
import React, { Component } from "react";
import { commonGet, commonPost } from "../../../request/common-axios";
import {
  help_api,
  queryflow_api,
  scooper_core_api,
} from "../../../rest-api/restapi";
import "../../../less/video/monitortree.less";
import {
  store
} from "../../../reducer/store";
import {
  setDeptAction,
  setVideoCorAction,
  setCollectParentAction,
  setPlayListAction,
  setCollectAction,
  setCollectItemAction,
  setCloudEyeCloseAction,
  setListStatusAction,
  setIsFlowAction,
} from "../../../reducer/video_action";
import { debounce } from "lodash";
import Pinyin from 'pinyin';
const { DirectoryTree } = Tree;
const { Search } = Input;
let list_statusPoc = [];

class MonitorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookInfo: -1,
      deptName: "",
      visible: false,
      propKey: -1,
      collectId: -1,
      bookmark_id: -1,
      confirmVisible: false,
      cancelId: -1000,
      realCancelId: -1000,
      searchVal: "",
      openCloseMenuVisible: false,
      key: "",
      type: "",
      windowId: -100,
      searchList: [],
      showView: "tree",
      collectList: [], //已收藏的设备列表
      treeData: [], //树的数据
      isplaying: [], //正在播放列表
      selectList: [], //已选择列表
      AllLoading: true, //是否需要一次性加载（此选项如果为true，loadingEnd需为false，loadingtime为0，若为false，loadingEnd需为true）
      loadingEnd: false, //是否已加载完毕
      loadingtime: 0,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    //props改变后修改bofang图标的状态
    this.ctrlPlayIcon(prevState);
  }
 /** 
  *搜索栏内容改变触发 
  */
  handleChange = (e) => {
    const self = this;
    const searchVal = e.target.value;
    if (searchVal == "") {
      this.setState(
        {
          showView: "tree",
        }
      );
    } else {
      this.setState({
        searchVal,
        showView: "search",
      });
    }
    this.getSearchList(searchVal);
  };

  /**
  *获取部门列表和成员列表
  */
  getSearchList = debounce((searchVal) => {
    const { collectList } = this.state;
    var tempList = [];
    let _this = this;
    scooper_core_api.queryOrgDept({ deptName: searchVal }).then((res) => {
      console.log("res===",res);
      tempList = [...res.data.list];
      scooper_core_api.queryOrgMember({ keyword: searchVal }).then((resp) => {
        tempList = [...tempList, ...resp.data.list];

        let _tempList = [];
        tempList.length &&
          tempList.forEach((item) => {
            item.name = item.memName ? item.memName : item.deptName;
            var getPinYin = Pinyin(item.name, {
              style: Pinyin.STYLE_NORMAL,
            });
            _this.props.playList &&
              _this.props.playList.length > 0 &&
              _this.props.playList.map((val) => {
                if (val.video == item.memTel) {
                  item.isPlaying = true;
                }
              });

            collectList.length &&
              collectList.forEach((val) => {
                if (val.idKey == item.memTel) {
                  item.isCollected = true;
                }
              });

            //获取全拼
            var result1 = getPinYin.join("");
            item["fullPinyin"] = result1;
            //获取首字母
            var list = [];
            getPinYin &&
              getPinYin.length > 0 &&
              getPinYin.map((val) => {
                list.push(val[0].substr(0, 1));
              });
            var result2 = list.join("");
            item["initialPinyin"] = result2;
            if (
              (item.memName && item.memName.indexOf(searchVal) > -1) ||
              item.fullPinyin.indexOf(searchVal) > -1 ||
              item.initialPinyin.indexOf(searchVal) > -1 ||
              item.deptName.indexOf(searchVal) > -1
            ) {
              _tempList.push(item);
            }
          });
        this.setState({
          searchList: _tempList,
        });
      });
    });
  }, 800);

  /**
  *修改正在播放窗口信息的数组
  */
  ctrlPlayIcon = (prevState) => {
    let _this = this;
    if (this.props.PlayList.length === 0) {
      this.state.isplaying.map((item, index) => {
        this.playingicon("pause", null, item.target);
      });
      _this.state.isplaying = [];
    } else {
      this.state.isplaying.map((item, index) => {
        let isShow = false;
        for (let its of this.props.PlayList) {
          //its有可能为undefined，防止报错
          if (!its) {
            continue;
          }
          if (its.id === item.id) {
            isShow = true;
            return;
          }
        }
        if (!isShow) {
          this.playingicon("pause", null, item.target);
          this.state.isplaying.slice(index, 1);
        }
      });
    }
  };

   /**
  *修改bofang图标的状态的方法
  */
  playingicon = (status, id, target) => {
    if (status === "play") {
      let res = false;
      this.state.isplaying.map((item) => {
        if (item.id === id) {
          res = true;
          return;
        }
      });
      if (target && !res) {
        this.state.isplaying.push({ id, target });
        let dom = document.querySelector(target);
        if (dom) {
          dom.style.visibility = "visible";
        }
      }
    } else if (status === "pause") {
      if (target) {
        let dom = document.querySelector(target);
        if (dom) {
          dom.style.visibility = "hidden";
        }
        return;
      }
      if (id) {
        this.state.isplaying.map((item) => {
          if (item.id === id) {
            let dom = document.querySelector(target);
            if (dom) {
              dom.style.visibility = "hidden";
            }
          }
        });
      }
    }
  };
   /**
  *播放视频
  */
  playVideo = (treeNode, e) => {
    let deptName = treeNode.parentObj.name;
    let pocNo = treeNode.dev_id;
    this.play(pocNo, treeNode.name, deptName, treeNode, e.target);
  };

  /**
   * 播放视频
   */
  play = (pocNo, memName, deptName, treeNode, target) => {
    const self = this;
    const { isFlow } = self.props;
    if (pocNo === "") {
      // message.error('该成员未绑定设备！');
    }
    store.dispatch(setCloudEyeCloseAction({ cloudEyeId: pocNo }));
    if (self.props.videoController.isPlaying(pocNo) != -1) {
      message.error("该视频正在播放");
    } else {
      var param = {
        sipTel: pocNo,
        type: "video",
      };
      if (isFlow) {
        queryflow_api.queryDevFlowState(param).then((resp) => {
          if (!resp.data.obj) {
            message.error(resp.message || "设备流量达上限！");
          } else {
            if (resp.message) {
              message.warning(resp.message);
            }
            self.openVideo(pocNo, memName, deptName, treeNode, target);
          }
        });
      } else {
        self.openVideo(pocNo, memName, deptName, treeNode, target);
      }
    }
  };

  /**
   * 调度打开云眼
   */
  openVideo = (pocNo, memName, deptName, treeNode, target) => {
    const self = this;
    scooper.poc.groupPoc.openCloudEye(pocNo, function (data) {
      //打开云眼前先判断该云眼是否已经打开
      let videoIndex =
        self.props.videoController &&
        self.props.videoController.isPlaying(pocNo);
      if (data.data.result !== "fail" && data.code == 0) {
        if (videoIndex == -1) {
          self.props.videoController.playByOrderExpandWindow(pocNo, pocNo, {
            name: memName,
            parentName: deptName,
          });
          if (treeNode)
            self.playingicon(
              "play",
              treeNode.dev_id,
              ".bofang" + treeNode.dev_id
            );
          if (treeNode) {
            var aObj = $(".level" + treeNode.level).find(
              "#" + treeNode.tId + "_a"
            );
            var _nodeView = '<i class="bofang" id="bofang"><i/>';
            aObj.append(_nodeView);
          } else {
            var aObj = $("li[class=" + pocNo + "]").find(".bofang-btn");
            var _nodeView = '<i class="bofang" id="bofang"><i/>';
            aObj.append(_nodeView);
          }
        }
      } else {
        var reason;
        if (data.data.reason) {
          reason = data.data.reason;
          if (reason.indexOf("非预呼叫对讲成员") > -1) {
            reason = "设备离线!";
          } else if (reason.indexOf("无此对讲成员") > -1) {
            reason = "该成员未绑定设备！";
          }
        } else {
          reason = "";
        }
        message.error(reason);
      }
    });
  };
  
   /**
  *构成树结构目录
  */
  dataMap = (list, pid, res, pos) => {
    let newdata = [];
    let posList = pos.split("-");
    posList.reduce((prev, item, index) => {
      if (index === 0) {
        return this.state.treeData;
      } else {
        if (index === posList.length - 1) {
          prev[parseInt(item)].children = res;
          prev[parseInt(item)].children.map((itemchild) => {
            itemchild.parentObj = prev[parseInt(item)];
          });
          prev[parseInt(item)].loadend = true;
          return;
        } else return prev[parseInt(item)].children;
      }
    }, this.state.treeData);
    this.state.treeData.map((item) => {
      newdata.push(item);
    });
    this.setState({ treeData: newdata }, () => {
      if (this.state.loadingtime === 0) {
        this.setState({ loadingEnd: true });
        console.log("sssss=", this.state.treeData);
      }
    });
  };
   /**
  *展开
  */
  onExpand = (keys, expand) => {
    console.log("展开所有节点");
    if (expand.node.props.loadend) {
    } else this.initTreeData(expand);
  };
  initTreeData = async (expand, level = "0") => {
    this.state.loadingtime++;
    let url = "/scooper-video/data/video/load/getNodes";
    let param = null;
    let treedata = {};
    if (expand && expand.node.props.nodeId) {
      param = { parentId: expand.node.props.nodeId };
    } else {
      param = {};
    }
    let data = await commonPost(url, param);
    console.log("pexndo", expand, data);
    if (!expand) {
      data.data.list.map((item) => {
        console.log("item", item.dev_id == -1, item, expand);
        if (item.dev_id == -1) {
          this.initTreeData({
            node: { props: { nodeId: item.nodeId } },
          });
        }
      });
      return;
    }
    try {
      let treedata = this.state.treeData;
      let res = data.data.list
        .filter((data) => {
          if (data.nodeId === 2) return false;
          else return true;
        })
        .map((item, index) => {
          let playshow = false;
          if (item.ipc_type === 2) {
            this.props.PlayList.map((items) => {
              if (items.id === item.dev_id) {
                playshow = true;
              }
              return;
            });
          }

          item.title =
            item.parental === 1 ? (
              <span>
                <span
                  className="leafname"
                  title={item.name}
                  style={{ color: "#a9f8ff" }}
                >
                  {item.name}
                </span>
              </span>
            ) : item.parental === 0 ? (
              <span
                className="iconlist"
                // onMouseEnter={this.onMouseOverCollect.bind(this, item)}
              >
                <span
                  className="leafname"
                  style={{
                    color: `${item.status === "1" ? "#a9f8ff" : ""}`,
                  }}
                  title={item.name}
                >
                  {item.name}
                </span>
                <span
                  className={`bofang bofang${item.dev_id}`}
                  data-memtel={item.dev_id}
                  style={{
                    visibility: `${playshow ? "visible" : "hidden"}`,
                  }}
                ></span>
                {/* <span
                className={`${
                  item.extraDataMap.memTel === this.state.collectShow
                    ? "iconcancelcollect"
                    : ""
                } more-operate`}
                onClick={this.onCollect.bind(this, item)}
              ></span> */}
                <span
                  onClick={this.playVideo.bind(this, item)}
                  className="play-test"
                ></span>
              </span>
            ) : (
              ""
            );

          item.key = item.name;
          // item.isLeaf = !item.isParent;
          // item.disableCheckbox = item.isParent
          //   ? false
          //   : list_statusPoc[item.extraDataMap.memTel]
          //   ? list_statusPoc[item.extraDataMap.memTel].status === "idle" ||
          //     list_statusPoc[item.extraDataMap.memTel].status === "working"
          //     ? false
          //     : true
          //   : true;
          // if (this.state.AllLoading && item.isParent) {
          //   let pos = level + "-" + index.toString();
          //   this.initTreeData(
          //     {
          //       node: { props: { ...item, pos: pos } },
          //     },
          //     pos
          //   );
          // }
          return item;
        });
      this.state.loadingtime--;
      if (treedata && treedata.length !== 0) {
        this.dataMap(
          treedata,
          expand.node.props.id,
          res,
          expand.node.props.pos
        );
      } else {
        this.setState({ treeData: res }, () => {
          console.log("XXXX=", res);
          res.map((item, index) => {
            this.initTreeData({
              node: { props: { nodeId: item.nodeId, pos: "0-" + index } },
            });
          });
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  componentDidMount() {
    let self = this;
    let token = sessionStorage.getItem("token");
    scooper.poc.groupPoc.listMemStatus(function (data) {
      data.data &&
        data.data.map((item) => {
          list_statusPoc[item.pocNo] = item;
        });
      // self.loadTree();
    });
    //初始就正在播放的视频窗口
    console.log("self====",self)
    if(self.props.PlayList){
    self.props.PlayList.map((item) => {
      self.state.isplaying.push({
        id: item.id,
        target: ".bofang" + item.id,
      });
    });
    self.initTreeData();
  }
 }
  render() {
    const { showView, searchList } = this.state;
    return (
      <div className="terminal-wrap">
        <Search
          className="input-search"
          id="search-video"
          prefix={<Icon type="search" style={{ color: "#057797" }} />}
          placeholder="搜索人员"
          onChange={this.handleChange}
        />
        {showView == "search" && (
          <div className="search-box">
            {searchList.length>0 &&
              searchList.map((list) => {
                return (
                  <li className={list.memTel}>
                    <span className="search-res">
                      {list.memName && (
                        <span className="mem-name" title={list.memName}>
                          <Icon type="user" />
                          {list.memName}
                        </span>
                      )}
                      {list.deptName && !list.memName && (
                        <span title={list.deptName}>{list.deptName}</span>
                      )}
                      {list.deptName && list.memName && (
                        <span title={list.deptName}>({list.deptName})</span>
                      )}
                    </span>
                    <span className="bofang-btn">
                      {list.isPlaying && <i class="bofang" id="bofang"></i>}
                    </span>
                    {list.memName && (
                      <span className="oper-btn-group">
                        <button
                          className="play-test call-video plays-'+list.memTel+'"
                          title="视频播放"
                          id="play-monitor' + list.id + '"
                          video="' + list.id + '"
                          onClick={() =>
                            this.play(list.memTel, list.memName, list.deptName)
                          }
                        ></button>
                        {/* {!list.isCollected && (
                          <button
                            className="more-operate"
                            id="member-more-operate' + list.id + '"
                            title="收藏"
                            propKey="' + list.memTel  + '"
                            onClick={() =>
                              this.getCollect(
                                list.id,
                                list.memTel,
                                list.memName
                              )
                            }
                          ></button>
                        )}
                        {list.isCollected && (
                          <button
                            className="more-operate allCollect"
                            id="member-more-operate' + list.id + '"
                            title="已收藏"
                            propKey="' + list.memTel  + '"
                            onClick={() =>
                              this.getCollect(
                                list.id,
                                list.memTel,
                                list.memName
                              )
                            }
                          ></button>
                        )} */}
                      </span>
                    )}
                  </li>
                );
              })}
          </div>
        )}

        <div className={"top-menu-wrap"}>
          <span
            className="open-close-menu"
            onClick={this.onOpenCloseMenuHandler}
          />
        </div>
        {showView == "tree" && (
          <div className="monitor-video zTreeVideo">
            <ul id="monitor-tree" className="monitor-tree">
              {/* {this.state.loadingEnd ? ( */}
              <DirectoryTree
                checkable={this.state.openCloseMenuVisible}
                showIcon={false}
                multiple
                blockNode
                defaultExpandAll={true}
                onCheck={this.checkVideo}
                treeData={this.state.treeData}
                onExpand={this.onExpand}
                onSelect={this.onSelectfn}
              ></DirectoryTree>
              {/* ) : (
              <Spin
                size="large"
                style={{ transform: "translateX(50%)" }}
              ></Spin>
            )} */}
            </ul>
            <ul id="trees" className="ztree"></ul>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      deptTree: state.deptTree,
      videoController: state.videoController,
      collectTreeParent: state.collectTreeParent,
      collectItem: state.collectItem,
      PlayList: state.PlayList,
      cloudEyeId: state.cloudEyeId,
      listStatus: state.listStatus,
      collectTree: state.collectTree,
      isFlow: state.isFlow,
    };
  },
  (dispatch) => {
    return {
      setDeptAction: (deptTree) => {
        dispatch(setDeptAction({ deptTree }));
      },
      setVideoCorAction: (videoController) => {
        dispatch(setVideoCorAction({ videoController }));
      },
      setCollectParentAction: (collectParent) => {
        dispatch(setCollectParentAction({ collectParent }));
      },
      setCollectItemAction: (collectItem) => {
        dispatch(setCollectItemAction({ collectItem }));
      },
      setPlayListAction: (playList) => {
        dispatch(setPlayListAction({ playList }));
      },
      setCloudEyeCloseAction: (cloudEyeId) => {
        dispatch(setCloudEyeCloseAction({ cloudEyeId }));
      },
      setListStatusAction: (listStatus) => {
        dispatch(setListStatusAction({ listStatus }));
      },
      setCollectAction: (collectTree) => {
        dispatch(setCollectAction({ collectTree }));
      },
      setIsFlowAction: (isFlow) => {
        dispatch(setIsFlowAction({ isFlow }));
      },
    };
  }
)(MonitorList);
