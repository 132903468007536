/**
 * @author wangyan
 * @date 2018/12/17.
 * @version v1.0.1
 */
import React from "react";
import axios from "axios";
// import { formatMessage } from 'umi/locale';
// import moment from 'moment';
// import 'moment/locale/zh-cn';
// import zhCN from '../../../node_modules/antd/lib/locale-provider/zh_CN';
import {
    Icon,
    Button,
    message,
    // Form,
    Input,
    Modal,
    Tabs,
    Tree,
    Menu,
    AutoComplete
} from "antd";
import "@/less/user-select/UserPick.less";

const TabPane = Tabs.TabPane;
const { TreeNode } = Tree;
// const Search = Input.Search;
const DirectoryTree = Tree.DirectoryTree;
// const platIp = formatMessage({ id: 'platIp' });

export default class UserPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            mode: 1, //选择器模式 0-单人 1-多人  默认多人选择器
            defaultMemData: [], //初始已添加人员列表
            restrict: false,
            token: "",
            api: {
                listOrgDept: "/scooper-core-rest/data/contacts/orgDeptManage/listOrgDept",
                listDeptByParent: "/scooper-poc-manager/data/dept/listOrgDept",
                listOrgMember: "/scooper-poc-manager/data/dept/listOrgMember",
                findOrgMemberByTel: "/scooper-core-rest/data/contacts/orgMemberManage/findOrgMemberByTel",
                listDispGroup: "/scooper-core-rest/data/dispatch/dispGroupManage/listDispGroup",
                listDispMember: "/scooper-core-rest/data/dispatch/dispMemberManage/listDispMember"
            },
            record: [], //部门信息
            allMemData: [], //加载的成员列表
            memData: [], //筛选过的成员列表
            DeptData: [], //部门树列表

            rightBtnDis: true, //添加按钮是否禁用
            leftBtnDis: true, //删除按钮是否禁用
            addAllBtnDis: true, //添加全部按钮是否禁用
            deleteAllBtnDis: true, //删除全部按钮是否禁用

            addMemData: [], //准备添加的成员
            chosenMem: [], //已添加成员列表 mode为1时有用
            deleteMem: [], //删除后剩下的成员 准备阶段

            selectedKeys: [], //成员列表已选择的key
            memSelectedKeys: [] //已添加成员列表 已选择的key
        };
    }

    componentWillReceiveProps(nextProps) {
        let { visible, mode, defaultMemData, ip, deptType, token, record, restrict } = nextProps;
        this.setState({
            visible: visible,
            mode: mode,
            ip: ip,
            token: token,
            deptType: deptType,
            record,
            restrict,
            addMemData: defaultMemData ? [...defaultMemData] : [],
            chosenMem: defaultMemData ? [...defaultMemData] : [],
            defaultMemData: defaultMemData ? [...defaultMemData] : []
        });

        visible && this.loadDept(ip, token, record);
    }

    //加载部门信息
    loadDept(ip, token, record) {
        let { api } = this.state;
        let deptUrl = ip + api.listDeptByParent;
        let postData = new URLSearchParams();
        let deptParams = {
            id: record.id,
            parentId: 0,
            token: token
        };
        for (let key in deptParams) {
            postData.append(key, deptParams[key]);
        }
        axios
            .get(deptUrl, {params:postData})
            .then(response => {
                if (response.data.code === 0) {
                    this.setState({
                        DeptData: response.data.data.list
                    });
                } else {
                    message.error(response.data.message);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    //异步加载
    onLoadData = treeNode =>
        new Promise(resolve => {
            let { ip, api, DeptData, token } = this.state;
            if (treeNode.props.children) {
                resolve();
                return;
            }
            let deptParams = {
                token: token,
                parentId: treeNode.props.dataRef.id
            };

            let postData = new URLSearchParams();
            for (let key in deptParams) {
                postData.append(key, deptParams[key]);
            }

            axios
                .get(ip + api.listDeptByParent,{params:postData})
                .then(response => {
                    if (response.data.code === 0) {
                        treeNode.props.dataRef.children = response.data.data.list;
                        this.setState({
                            DeptData: [...DeptData]
                        });
                        resolve();
                    } else {
                        message.error(response.data.message);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        });

    //渲染树节点
    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.deptName} key={item.id} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.deptName} key={item.id} dataRef={item} />;
        });

    //点击树节点 加载成员
    onTreeSelect = (selectedKeys, info) => {
        let parentCode = info.node.props.dataRef.id;
        this.loadMem(parentCode);
    };

    //搜索成员
    onSearch = value => {
        let { allMemData } = this.state;
        let filterData = [];
        let searchVal = trim(value);
        if (searchVal !== "") {
            allMemData.map(item => {
                if (
                    isMatch(item.memName, searchVal) ||
                    isMatch(item.pinyinLess, searchVal) ||
                    isMatch(item.pinyinFull, searchVal)
                ) {
                    filterData.push(item);
                }
            });
        } else {
            filterData = allMemData;
        }
        this.setState({
            memData: filterData
        });
    };

    //加载成员
    loadMem(parentCode) {
        let { chosenMem, ip, api, token } = this.state;
        let params = {
            token: token,
            deptId: parentCode,
            devType: 2
        };
        let postData = new URLSearchParams();
        let stringparams="";
        let addIndex=0;
        for (let key in params) {
            postData.append(key, params[key]);
            if(addIndex===0){
                stringparams+=`?${key}=${params[key]}`
            }else{
                stringparams+=`&${key}=${params[key]}`
            }
            addIndex++;
        }
        axios
            .get(ip + api.listOrgMember+stringparams)
            .then(response => {
                if (response.data.code === 0) {
                    var memData = response.data.data.list;
                    // console.log("////////", memData);
                    memData.map(item => {
                        item.pinyinLess = window.scooper.pinyin.convert(item.memName, true);
                        item.pinyinFull = window.scooper.pinyin.convert(item.memName);
                    });
                    this.setState({
                        allMemData: memData,
                        memData: memData,
                        selectedKeys: [],
                        addMemData: [...chosenMem],
                        rightBtnDis: true
                    });
                    if (response.data.data.list.length !== 0) {
                        this.setState({
                            addAllBtnDis: false
                        });
                    } else {
                        this.setState({
                            addAllBtnDis: true
                        });
                    }
                } else {
                    message.error(response.data.message);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    //选择人员
    onMemSelect = e => {
        let { addMemData, selectedKeys, mode, deleteMem, chosenMem } = this.state;
        let value = e.item.props.value;
        if (mode === 1) {
            selectedKeys.push(e.key);
            if (addMemData.length === 0) {
                addMemData.push(value);
            } else {
                let hasItem = false;
                addMemData.map(item => {
                    if (item.id === value.id) {
                        hasItem = true;
                    }
                });
                if (!hasItem) {
                    addMemData.push(value);
                }
            }
            if (chosenMem.length === 0) {
                deleteMem.push(value);
            } else {
                let hasItem = false;
                chosenMem.map(item => {
                    if (item.id === value.id) {
                        hasItem = true;
                    }
                });
                if (!hasItem) {
                    deleteMem.push(value);
                }
            }
        } else {
            selectedKeys[0] = e.key;
            addMemData[0] = value;
        }
        this.setState({
            rightBtnDis: false,
            addMemData: addMemData,
            selectedKeys: selectedKeys,
            deleteMem: deleteMem
        });
    };

    //取消选择人员
    memDeselect = e => {
        let { addMemData, selectedKeys, chosenMem, deleteMem } = this.state;
        let value = e.item.props.value;
        let hasItem = false;
        for (let i in chosenMem) {
            if (chosenMem[i].id === value.id) {
                hasItem = true;
            }
        }
        addMemData.map((item, index) => {
            if (item.id === value.id) {
                if (!hasItem) {
                    addMemData.splice(index, 1);
                }
                for (let i in selectedKeys) {
                    if (selectedKeys[i] === e.key) {
                        selectedKeys.splice(i, 1);
                    }
                }
            }
        });
        deleteMem.map((item, index) => {
            if (item.id === value.id) {
                if (!hasItem) {
                    deleteMem.splice(index, 1);
                }
            }
        });
        this.setState({
            addMemData: addMemData,
            deleteMem: deleteMem,
            selectedKeys: selectedKeys
        });
    };

    //双击添加人员
    onAddDoubleClick(value) {
        let { chosenMem, mode } = this.state;
        let hasItem = false;
        if (chosenMem.length != 0) {
            chosenMem.map(item => {
                if (item.id === value.id) {
                    hasItem = true;
                }
            });
        }

        if (!hasItem) {
            let arr = [];
            arr[0] = value;
            chosenMem.push(value);
        }

        //当mode为0时，单人选择器,双击时选择完毕
        if (mode === 0) {
            let arr = [];
            arr[0] = value;
            this.props.memData(arr);
            this.setState({
                addMemData: [],
                chosenMem: [],
                selectedKeys: []
            });
        }
        this.setState({
            addMemData: [...chosenMem],
            chosenMem: chosenMem,
            selectedKeys: [],
            memSelectedKeys: [],
            rightBtnDis: true,
            leftBtnDis: true,
            deleteAllBtnDis: false
        });
    }

    //添加、向右 按钮
    onAddMem() {
        let { addMemData } = this.state;
        this.setState({
            chosenMem: [...addMemData],
            selectedKeys: [],
            rightBtnDis: true,
            deleteAllBtnDis: false
        });
    }

    //添加当前所有成员 按钮
    addAllMem() {
        let { memData, chosenMem } = this.state;
        for (let i in memData) {
            let flag = true;
            for (let j in chosenMem) {
                if (chosenMem[j].id === memData[i].id) {
                    flag = false;
                }
            }
            if (flag) {
                chosenMem.push(memData[i]);
            }
        }
        this.setState({
            chosenMem: [...chosenMem],
            addMemData: [...chosenMem],
            selectedKeys: [],
            rightBtnDis: true,
            deleteAllBtnDis: false
        });
    }

    //选择已添加人员列 预备删除
    onChosenMemSelect = e => {
        let { memSelectedKeys, chosenMem, deleteMem } = this.state;
        let value = e.item.props.value;
        let arr = [];
        if (memSelectedKeys.length === 0) {
            arr = [...chosenMem];
        } else {
            arr = deleteMem;
        }
        arr.map((item, index) => {
            if (item.id === value.id) {
                arr.splice(index, 1);
            }
        });
        memSelectedKeys.push(e.key);
        this.setState({
            leftBtnDis: false,
            deleteMem: arr,
            memSelectedKeys: memSelectedKeys
        });
    };

    //取消选择已添加人员 取消删除
    chosenMemDeSelect = e => {
        let { memSelectedKeys, chosenMem, deleteMem } = this.state;
        let value = e.item.props.value;
        chosenMem.map((item, index) => {
            if (item.id === value.id) {
                for (let i in memSelectedKeys) {
                    if (memSelectedKeys[i] === e.key) {
                        memSelectedKeys.splice(i, 1);
                    }
                }
                deleteMem.splice(index, 0, item);
            }
        });
        this.setState({
            memSelectedKeys: memSelectedKeys,
            deleteMem: deleteMem
        });
    };

    //双击删除人员
    onDeleteDbClick(value) {
        let { chosenMem } = this.state;
        let hasData = true;

        chosenMem.map((item, index) => {
            if (item.id === value.id) {
                chosenMem.splice(index, 1);
            }
        });
        if (chosenMem.length === 0) {
            hasData = false;
        }

        this.setState({
            chosenMem: chosenMem,
            deleteMem: [...chosenMem],
            deleteAllBtnDis: !hasData,
            addMemData: [...chosenMem],
            leftBtnDis: true,
            rightBtnDis: true,
            selectedKeys: [],
            memSelectedKeys: []
        });
    }

    //删除、向左按钮
    onDeleteMem() {
        let { deleteMem } = this.state;
        this.setState({
            chosenMem: [...deleteMem],
            leftBtnDis: true,
            memSelectedKeys: []
        });
        if (deleteMem.length === 0) {
            this.setState({
                deleteAllBtnDis: true
            });
        } else {
            this.setState({
                deleteAllBtnDis: false
            });
        }
    }

    //删除所有成员 按钮
    deleteAllMem() {
        this.setState({
            chosenMem: [],
            addMemData: []
        });
    }

    //确定
    onPickOk() {
        let { chosenMem, addMemData, mode } = this.state;
        console.log(chosenMem, addMemData, mode);
        if (mode === 1 && chosenMem.length === 0) {
            message.warning("请选择人员");
        }
        // console.log(addMemData, chosenMem);
        if (mode === 0) {
            this.props.memData(addMemData);
        } else {
            this.props.memData(chosenMem);
        }
        this.setState({
            addMemData: [],
            chosenMem: [],
            memSelectedKeys: [],
            selectedKeys: [],
            memData: []
        });
    }

    //取消
    onCancel() {
        let { defaultMemData } = this.state;
        this.props.memData(defaultMemData);
        this.setState({
            visible: false,
            addMemData: [],
            memData: [],
            chosenMem: [],
            memSelectedKeys: [],
            selectedKeys: []
        });
        this.props.cancel();
    }

    unBind() {
        this.props.memData('unBind');
    }

    render() {
        const {
            visible,
            mode,
            DeptData,
            memData,
            rightBtnDis,
            leftBtnDis,
            chosenMem,
            selectedKeys,
            memSelectedKeys,
            addAllBtnDis,
            deleteAllBtnDis,
            restrict
        } = this.state;
        // console.log(88888, memData);
        return (
            <Modal
                maskClosable={false}
                title="选择成员"
                width={865}
                visible={visible}
                onOk={this.onPickOk.bind(this)}
                onCancel={this.onCancel.bind(this)}
                footer={[
                    <Button key="submit" type="primary" onClick={this.onPickOk.bind(this)}>
                        确定
                    </Button>,
                    <Button key="unbind" type="primary" onClick={this.unBind.bind(this)}>
                        解绑
                    </Button>,
                    <Button key="cancel" onClick={this.onCancel.bind(this)}>
                        取消
                    </Button>
                ]}
                destroyOnClose={true}
                closable={false}
                className="selectModal"
            >
                <div className="selectBox">
                    <div className={mode === 1 ? "selectLeft" : "selectLeftSingle"}>
                        <AutoComplete
                            style={{
                                width: "46%",
                                position: "absolute",
                                right: "4px",
                                top: "4px",
                                zIndex: "2"
                            }}
                            placeholder="请输入搜索内容"
                            onChange={this.onSearch}
                        >
                            <Input maxLength={12} suffix={<Icon type="search" className="certain-category-icon" />} />
                        </AutoComplete>

                        <Tabs type="card" className="deptContent">
                            <TabPane tab="部门" key="1">
                                <DirectoryTree loadData={this.onLoadData} onSelect={this.onTreeSelect}>
                                    {this.renderTreeNodes(DeptData)}
                                </DirectoryTree>
                            </TabPane>
                        </Tabs>

                        <Menu
                            className="memBox"
                            onSelect={this.onMemSelect}
                            onDeselect={this.memDeselect}
                            multiple
                            selectedKeys={selectedKeys}
                        >
                            {memData.map((item, index) => {
                                if (this.props.memBindList && this.props.memBindList.includes(item.memName) && restrict) {
                                    return (
                                        <Menu.Item disabled key={index} value={item}>
                                            {item.memName}
                                            <span className="deptName">{item.deptName}</span>
                                        </Menu.Item>
                                    );
                                } else {
                                    return (
                                        <Menu.Item
                                            key={index}
                                            value={item}
                                            onDoubleClick={this.onAddDoubleClick.bind(this, item)}
                                        >
                                            {item.memName}
                                            <span className="deptName">{item.deptName}</span>
                                        </Menu.Item>
                                    );
                                }
                            })}
                        </Menu>
                    </div>

                    {mode === 1 ? (
                        <div className="selectBtn">
                            <Button
                                shape="circle"
                                icon="double-right"
                                disabled={addAllBtnDis ? true : false}
                                onClick={this.addAllMem.bind(this)}
                            />
                            <Button
                                shape="circle"
                                icon="arrow-right"
                                style={{ marginTop: "15px" }}
                                disabled={rightBtnDis ? true : false}
                                onClick={this.onAddMem.bind(this)}
                            />
                            <Button
                                shape="circle"
                                icon="arrow-left"
                                style={{ marginTop: "15px" }}
                                disabled={leftBtnDis ? true : false}
                                onClick={this.onDeleteMem.bind(this)}
                            />
                            <Button
                                shape="circle"
                                icon="double-left"
                                style={{ marginTop: "15px" }}
                                disabled={deleteAllBtnDis ? true : false}
                                onClick={this.deleteAllMem.bind(this)}
                            />
                        </div>
                    ) : null}

                    {mode === 1 ? (
                        <Menu
                            className="selectRight"
                            onSelect={this.onChosenMemSelect}
                            onDeselect={this.chosenMemDeSelect}
                            multiple
                            selectedKeys={memSelectedKeys}
                        >
                            {chosenMem.map((item, index) => {
                                return (
                                    <Menu.Item
                                        key={index}
                                        value={item}
                                        onDoubleClick={this.onDeleteDbClick.bind(this, item)}
                                    >
                                        {item.memName}
                                        <span className="deptName">{item.deptName}</span>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    ) : null}
                </div>
            </Modal>
        );
    }
}

/**
 * 检测str是否包含matchStr，忽略大小写
 * @param str
 * @param matchStr
 * @returns {boolean}
 */
function isMatch(str, matchStr) {
    if (str == null || str == "") {
        return matchStr == null || matchStr == "";
    } else {
        return (
            str.indexOf(matchStr) >= 0 ||
            str.toLowerCase().indexOf(matchStr.toLowerCase()) >= 0 ||
            str.toUpperCase().indexOf(matchStr.toUpperCase()) >= 0
        );
    }
}

/*
 * 去除字符串前后的空格
 * 返回值：去除空格后的字符串
 * */
function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}
