import React, {Component} from 'react';
import {connect} from 'react-redux';
import {List, message, Modal} from 'antd';
import {setPatrolAction, setPlayListAction} from '@/reducer/video_action';

const {confirm} = Modal;

export default connect(state => {
        return {
            patrolList: state.patrolList,
            videoController: state.videoController
        };
    },
    {
        setPatrolAction,
        setPlayListAction
    })(
    class extends Component {

        onStopPatrolHandler = () => {
            const {patrolList} = this.props;
            if (patrolList.length === 0) {
                message.warning('当前没有轮巡视频在播放');
                return;
            }
            confirm({
                title: '确定要结束轮巡吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    this.props.videoController.stopPoll();
                    this.props.setPatrolAction({patrolList: []});
                    this.props.setPlayListAction({playList: []});
                }
            });
        };

        render() {
            return (
                <div className="patrol-wrap">
                    <div className="top">
                        <button className="stop-patrol right" onClick={this.onStopPatrolHandler}>结束轮巡</button>
                    </div>
                    <div className={'patrol-wrap_list-warp'}>
                        <List dataSource={this.props.patrolList} renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<span>{item.name}</span>}
                                    description={`部门：${item.parentName}`}
                                />
                            </List.Item>
                        )}/>
                    </div>
                </div>
            );
        }
    }
);