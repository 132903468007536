import React from 'react';
import {Pagination, Checkbox, Button, Popconfirm, message, Modal, Form, Select, Input} from 'antd';
import '../../../less/pitView.less';
import {connect} from 'react-redux';
import {doc_api, queryflow_api} from '@/rest-api/restapi';
import {
    setFileDataAction,
    setFileDataAllAction,
    setSelectedValueAction,
    setCheckedAction,
    setIsDownloadAction
} from '@/reducer/video_action';
import FileDescription from './file-description';
import Detail from '@/view/components/document/Detail/index.jsx';

const {Option} = Select;

// let arr = [];
export default connect(
    state => {
        return {
            fileData: state.fileData,
            fileDataAll: state.fileDataAll,
            checked: state.checked,
            isDownload: state.isDownload,
            // selectedValue: state.selectedValue,
            previewEnv: ''
        };
    },
    {
        setFileDataAction,
        setFileDataAllAction,
        setCheckedAction,
        setIsDownloadAction,
        setSelectedValueAction
    }
)
(class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                selList: [],
                selList1: [],
                modalWidth: 700,
                isShowReduce: 'none',
                isShowEnlarge: 'block',
                flowStatus: true,
                bodyHeight: '270px'
            };
            // this.valueChecked = this.valueChecked.bind(this)
        }


        onPage = async (page, pageSize) => {
            const param = {
                token: sessionStorage.token,
                pageNum: page,
                pageSize,
                fileType: this.props.fileType,
                startTime: this.props.startTime,
                endTime: this.props.endTime,
                memName: this.props.memName,
                deptId: this.props.deptId,
                devNo: this.props.devNo,
                gzzXh: this.props.gzzXh,
                fileLevel: this.props.fileLevel,
                corpId: sessionStorage.getItem('corpId')
                // orgCode: sessionStorage.getItem('orgCode')
            };
            const data = await doc_api.queryFile(param);
            this.props.setFileDataAction({fileData: data.data.list});
            this.props.setFileDataAllAction({fileDataAll: data.data});
        };

        /**
         * 选中的value
         */
        onChange = e => {
            debugger
            /*if (this.props.selectedValue.length === 0) {
                arr = [];
            }
            if (e.target.checked) {
                arr.push(e.target.value);
                const _arr = [];    //数组去重
                for (let i = 0; i < arr.length; i++) {
                    if (_arr.indexOf(arr[i]) === -1) {
                        _arr.push(arr[i]);
                    }
                }
                this.props.setSelectedValueAction({selectedValue: _arr});
            } else {
                //取消选中
                for (let len = arr.length, i = len - 1; i >= 0; i--) {
                    if (arr[i].id.indexOf(e.target.value.id) !== -1) {
                        arr.splice(i, 1);
                    }
                }
                this.props.setSelectedValueAction({selectedValue: arr});
            }*/
        };

        /**
         * 加载图片列表
         */
        loadPitList = async () => {
            const param = {
                token: sessionStorage.token,
                fileType: 10,
                pageNum: 1,
                pageSize: 15
            };
            const data = await doc_api.queryFile(param);
            this.props.setFileDataAction({fileData: data.data.list});
            this.props.setFileDataAllAction({fileDataAll: data.data});
        };

        /**
         * 单个删除文件
         */
        onDelete = async (id, fileName) => {
            const data = await doc_api.fileDelete({id: id, fileName: fileName});
            if (!data.code) {
                message.success('删除成功');
                const param1 = {
                    token: sessionStorage.token,
                    pageNum: 1,
                    pageSize: 15,
                    fileType: this.props.fileType,
                    startTime: this.props.startTime,
                    endTime: this.props.endTime,
                    memName: this.props.memName,
                    deptId: this.props.deptId,
                    devNo: this.props.devNo,
                    fileLevel: this.props.fileLevel,
                    // orgCode: sessionStorage.getItem('orgCode'),
                    corpId: sessionStorage.getItem('corpId')
                };
                param1.pageNum = this.props.fileDataAll.pageNum;
                const data = await doc_api.queryFile(param1);
                if (!data.code) {
                    if (data.data.list.length === 0) {
                        param1.pageNum = data.data.lastPage;
                        const resp = await doc_api.queryFile(param1);
                        this.props.setFileDataAction({fileData: resp.data.list});
                        this.props.setFileDataAllAction({fileDataAll: resp.data});
                    } else {
                        this.props.setFileDataAction({fileData: data.data.list});
                        this.props.setFileDataAllAction({fileDataAll: data.data});
                    }
                }
            }else{
                return message.info(data.message);
            }
        };

        handleCancel = () => {
            let video;
            this.setState({
                previewVisible: false,
                modalWidth: 700,
                bodyHeight: '270px'
            });
            this.child.restoreInitVal();
            let audio = document.getElementsByClassName('prev-audio')[0];
            const audio2 = document.getElementsByClassName('prev-audio2')[0];
            const videoDiv = document.getElementsByClassName('prev-video-div')[0];
            const imgDiv = document.getElementsByClassName('prev-img-div')[0];
            const videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
            const audioDiv = document.getElementsByClassName('pit-music')[0];
            const audioDiv2 = document.getElementsByClassName('pit-music-ie')[0];
            audio && audio.pause();
            audio2 && audio2.pause();
            if (videoDiv && videoDiv.children) {
                video = videoDiv.children[0];
                if (video) {
                    videoDiv.removeChild(video);
                }
            }

            if (imgDiv && imgDiv.children) {
                const img = imgDiv.children[0];
                if (img) {
                    imgDiv.removeChild(img);
                }
            }

            if (videoDivY && videoDivY.children) {
                video = videoDivY.children[0];
                if (video) {
                    videoDivY.removeChild(video);
                }
            }

            if (audioDiv && audioDiv.children) {
                audio = audioDiv.children[0];
                if (audio) {
                    audioDiv.removeChild(audio);
                }
            }

            if (audioDiv2 && audioDiv2.children) {
                audio = audioDiv2.children[0];
                if (audio) {
                    audioDiv2.removeChild(audio);
                }
            }

        };

        /**
         * 切换当前访问协议
         */
        changeProto = (target) => {
            const targetProto = target.split(':')[0];
            return target.replace(targetProto, window.location.protocol.split(':')[0]);
        };

        /**
         * 文件预览
         */
        preView = async (fileType, filePath, fileName, fileSize, memName, record) => {
            const {isFlow} = this.props;
            if (isFlow) {
                const resp = await queryflow_api.queryCorpFlowState();
                if (!resp.data.obj) {
                    message.error(resp.message || '流量已达上限');
                    return;
                } else {
                    if (resp.message) {
                        message.warning(resp.message || '流量即将达到上限');
                    }
                }
            }
            this.setState({
                previewVisible: true,
                fileName: fileName,
                filePath: filePath,
                fileType: fileType,
                fileSize: fileSize,
                memName: memName,
                fileDetail: record
            });
            if (!!window.ActiveXObject || 'ActiveXObject' in window) {
                this.setState({
                    previewEnv: 'ie'
                });
            } else {
                this.setState({
                    previewEnv: 'noie'
                });
            }

            const self = this;
            window.setTimeout(function () {
                let url;
                if (fileType == 1) {
                    const videoDiv = document.getElementsByClassName('prev-video-div')[0];
                    const videoElement = document.createElement('video');
                    videoElement.className = 'prev-video';
                    url = '';
                    if (record.fileMoveStatus == 2 && record.cloudFilePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus != 2 && filePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus == 2) {
                        url = self.changeProto(record.cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${record.id}`;
                    } else {
                        url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&fileId=${record.id}`;
                    }
                    videoElement.src = url;
                    videoElement.controls = true;
                    videoElement.controlsList = 'nodownload';
                    videoElement['disablePictureInPicture'] = true;
                    videoDiv.appendChild(videoElement);
                }
                if (fileType == 0) {
                    var imgDiv = document.getElementsByClassName('prev-img-div')[0];
                    var imgElement = document.createElement('img');
                    imgElement.className = 'prev-img enlarge';
                    url = '';
                    if (record.fileMoveStatus == 2 && record.cloudFilePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus != 2 && filePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus == 2) {
                        url = self.changeProto(record.cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${record.id}`;
                    } else {
                        url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${record.id}`;
                    }
                    imgElement.src = url;
                    imgDiv.appendChild(imgElement);
                }
                if (fileType == -2) {
                    var videoDivY = document.getElementsByClassName('prev-video-div-yy')[0];
                    var videoElementY = document.createElement('video');
                    videoElementY.className = 'prev-video';
                    url = '';
                    if (record.fileMoveStatus == 2 && record.cloudFilePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus != 2 && filePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus == 2) {
                        url = self.changeProto(record.cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${record.id}`;
                    } else {
                        url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${record.id}`;
                    }
                    videoElementY.src = url;
                    videoElementY.controls = true;
                    videoElementY.controlsList = 'nodownload';
                    videoElementY['disablePictureInPicture'] = true;
                    videoDivY.appendChild(videoElementY);
                }
                if (fileType == 2) {
                    var audioDiv = document.getElementsByClassName('pit-music')[0];
                    var audioElement = document.createElement('audio');
                    audioElement.className = 'prev-audio2';
                    url = '';
                    if (record.fileMoveStatus == 2 && record.cloudFilePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus != 2 && filePath == '') {
                        message.error('该文件不存在！');
                        return;
                    }
                    if (record.fileMoveStatus == 2) {
                        url = self.changeProto(record.cloudFilePath) + `?token=${sessionStorage.getItem('token')}&deviceType=web&deviceId=${sessionStorage.getItem('loginTime')}&fileId=${record.id}`;
                    } else {
                        url = `${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${fileName}&filePath=${filePath}&id=${record.id}`;
                    }
                    audioElement.src = url;
                    audioElement.controls = true;
                    audioElement.type = 'application/json';
                    audioElement.controlsList = 'nodownload';
                    audioElement['disablePictureInPicture'] = true;
                    audioDiv.appendChild(audioElement);
                }

            }, 100);
        };

        componentDidMount() {
            this.getInitStatus();
        }

        getInitStatus = () => {
            // 查询机构的云存储下行流量，就是下载文件，预览文件消耗的流量，会有个限制，超出限制就无法下载。
            queryflow_api.queryCorpFlowState().then(resp => {
                if (!resp.data.obj) {
                    this.setState({
                        flowStatus: false
                    });
                } else {
                    if (resp.message) {
                        this.setState({
                            flowStatus: true
                        });
                    } else {
                        this.setState({
                            flowStatus: true
                        });
                    }
                }
            });
        };

        noFile = (record, e) => {
            const {isFlow} = this.props;
            if (isFlow) {
                queryflow_api.queryCorpFlowState().then(resp => {
                    if (!resp.data.obj) {
                        message.error(resp.message || '流量已达上限');
                        this.setState({
                            flowStatus: false
                        });
                    } else {
                        if (resp.message) {
                            message.warning(resp.message || '流量即将达到上限');
                            this.setState({
                                flowStatus: true
                            });
                        } else {
                            this.setState({
                                flowStatus: true
                            });
                        }
                        if (record.fileMoveStatus == 2 && record.cloudFilePath === '') {
                            message.error('该文件不存在！');
                            return false;
                        }
                        if (record.fileMoveStatus !== 2 && record.filePath === '') {
                            message.error('该文件不存在！');
                            return false;
                        }
                    }
                });
            } else {
                if (record.fileMoveStatus == 2 && record.cloudFilePath === '') {
                    message.error('该文件不存在！');
                    return false;
                }
                if (record.fileMoveStatus !== 2 && record.filePath === '') {
                    message.error('该文件不存在！');
                    return false;
                }
            }
        };

        /**
         * 图片放大功能
         */
        enlargePit = () => {
            this.setState({
                modalWidth: 1200,
                isShowEnlarge: 'none',
                isShowReduce: 'block'
            });
        };

        /**
         * 图片缩小功能
         */
        reducePit = () => {
            this.setState({
                modalWidth: 700,
                isShowEnlarge: 'block',
                isShowReduce: 'none'
            });
        };

        /**
         * 改变图片大小
         */
        changePit = () => {
            if ($('.prev-img').hasClass('enlarge')) {
                $('.prev-img').removeClass('enlarge').addClass('reduce');
                this.setState({
                    modalWidth: 1200,
                    bodyHeight: '650px'
                });
            } else if ($('.prev-img').hasClass('reduce')) {
                $('.prev-img').removeClass('reduce').addClass('enlarge');
                this.setState({
                    modalWidth: 700,
                    bodyHeight: '270px'
                });
            }
        };

        onRef = ref => {
            this.child = ref;
        };

        render() {
            function itemRender(current, type, originalElement) {
                if (type === 'prev') {
                    return <a>上一页</a>;
                }
                if (type === 'next') {
                    return <a>下一页</a>;
                }
                return originalElement;
            }

            let pagination = {
                current: this.props.fileDataAll.pageNum,
                total: this.props.fileDataAll.total,
                pageSize: 10,
                defaultCurrent: this.props.current,
                hideOnSinglePage: true,
                showSizeChanger: true
            };
            const {modalWidth, isShowReduce, isShowEnlarge, bodyHeight, flowStatus, fileDetail} = this.state;
            const {showView} = this.props;
            return (
                <div>
                    <ul className="pitViewList">
                        {this.props.fileData.map((item) => {
                            return (
                                <li key={item.id} className="pit-list" pagination={pagination}>
                                    <Detail {...item} flowStatus preView={this.preView} noFile={this.noFile}
                                            onDelete={this.onDelete} onChange={this.onChange}/>
                                </li>
                            );
                        })}
                    </ul>
                    <Pagination total={this.props.fileDataAll.total} itemRender={itemRender} pageSize={10}
                                onChange={this.onPage} hideOnSinglePage={true} current={this.props.fileDataAll.pageNum}
                                showTotal={total => `共 ${total} 条`}/>
                    <Modal visible={this.state.previewVisible} onCancel={this.handleCancel} className="prev-modal2"
                           title={`${this.state.fileName}`}
                           maskClosable={false} width={modalWidth} bodyStyle={{height: bodyHeight}}
                           footer={
                               // <div className="footer-box">
                               <>
                                   <div className="msg-size footer-msg"
                                        title={`文件大小：${this.state.fileSize}`}>文件大小：{this.state.fileSize}</div>
                                   <div className="msg-mem footer-msg"
                                        title={`上传人员： ${this.state.memName}`}>上传人员： {this.state.memName}</div>
                               </>
                               // </div>
                           }
                    >
                        {this.state.fileType == '0' && <div className="prev-img-div" onClick={this.changePit}></div>}
                        {/* { this.state.fileType == '0'&&<img className="prev-img" src={`${window.config.modulesUrl}/scooper-poc-server/data/manaFile/downloadFile?token=${sessionStorage.getItem('token')}&fileName=${this.state.fileName}&filePath=${this.state.filePath}`} />} */}
                        {this.state.fileType == '2' && this.state.previewEnv == 'noie' && <div className="pit-music"></div>}
                        {this.state.fileType == '2' && this.state.previewEnv == 'ie' && <div className="pit-music"></div>}
                        {/* { this.state.fileType == '1' &&  <video className="prev-video" src={`${window.config.modulesUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${sessionStorage.getItem('token')}&fileName=${this.state.fileName}&filePath=${this.state.filePath}`} controls="controls"></video> } */}
                        {this.state.fileType == '1' && <div className="prev-video-div"></div>}
                        {this.state.fileType == '-2' && <div className="prev-video-div-yy"></div>}
                        <FileDescription {...{fileDetail, showView}} onRef={this.onRef} loadTable={this.props.loadTable}
                                         listFileLawType={this.props.listFileLawType}/>
                    </Modal>
                </div>
            );
        }
    }
);
