/*
 * @Description: 文件创建时间统计
 * @Author: liulian
 * @Date: 2019-10-08 15:27:16
 * @LastEditTime: 2021-02-24 10:29:01
 * @LastEditors: yanghui
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import echarts from 'echarts';
import {debounce, isEqual} from 'lodash';
import resizeFootSize from './ResizeFontSize';

import {setFileTimeAction} from '@/reducer/video_action';
import {statistics} from '@/rest-api/restapi-video';
import {commonGet, commonPost} from '@/request/common-axios';

export default connect(
    state => {
        return {
            fileTime: state.fileTime
        };
    },
    {
        setFileTimeAction
    }
)(class extends Component {
    constructor(props) {
        super(props);
        this.setFileOption = this.setFileOption.bind(this);
        this.initFile = this.initFile.bind(this);
    }

    // loadFileTime = async() => {
    //     let params = {
    //         startTime : ' ' || this.props.dateStore[0],
    //         endTime:' ' || this.props.dateStore[0]
    //     }
    //     let data = await commonGet(statistics.getFileCreate,params) ;
    //     store.dispatch(setFileTimeAction({fileTime:data.data.data}))
    //     console.log(this.props);
    //     this.initFile();
    // }

    componentDidMount() {
        // this.loadFileTime();
        this.initFile();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.fileTime, nextProps.fileTime)) {
            this.initFile(nextProps, 1);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFn);
        this.myChart = null;
    }

    initFile = (nextProps, type) => {
        if (!this.fileChart) return;

        const {data} = nextProps || this.props;


        let date = [];
        let img = [];
        let audo = [];
        let video = [];
        if (data) {
            data.forEach((item, index) => {
                date.push(item.hour);
                img.push(item.image);
                audo.push(item.audio);
                video.push(item.video);

            });
        }


        this.myChart = echarts.init(this.fileChart, 'customed'); //初始化echarts

        let options = null;
        options = this.setFileOption(date, img, audo, video);

        this.myChart.clear();
        //设置options
        this.myChart.setOption(options);

        this.myChart.resize();

        window.addEventListener('resize', this.resizeFn = debounce(() => {
            this.myChart && this.myChart.resize();
        }, 200));
    };

    setFileOption = (date, img, audo, video) => {
        const fontSize = resizeFootSize();
        return {
            title: {},
            tooltip: {
                trigger: 'axis',
                width: 0.15 * fontSize,
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            legend: {
                data: ['图片', '音频', '视频'],
                right: '1%',
                top: '10',
                textStyle: {
                    color: '#8ED2F6',
                    fontSize: 0.14 * fontSize
                }

            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },

            xAxis: [{
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                type: 'category',
                data: date
            }],
            yAxis: [{
                axisTick: {show: false},
                axisLine: {show: false, lineStyle: {color: '#8ED2F6'}},
                // max: 1000,
                // min: 0,
                // splitNumber: 5,
                splitLine: {
                    show: true,
                    lineStyle: {color: ['#0F2145']}
                },
                type: 'value'
            }],
            series: [
                {
                    name: '图片',
                    type: 'bar',
                    stack: 'time',
                    barWidth: 0.14 * fontSize,
                    itemStyle: {color: '#00B2FC'},
                    data: img
                },
                {
                    name: '音频',
                    type: 'bar',
                    stack: 'time',
                    barWidth: 0.14 * fontSize,
                    itemStyle: {color: '#00E762'},
                    data: audo
                },
                {
                    name: '视频',
                    type: 'bar',
                    stack: 'time',
                    barWidth: 0.14 * fontSize,
                    itemStyle: {color: '#FFB533'},
                    data: video
                }
            ]
        };
    };


    render() {
        return (
            <div id="fileCreate" style={{width: '100%', height: '100%'}} className="file-create" ref={fileChart => {
                this.fileChart = fileChart;
            }}></div>


        );
    }
});