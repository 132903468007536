/**
 * @Author : JiGuangJie
 * @Date : 2021/11/22
 * @Time : 16:57
 * @Version : 1.0.0
 * @descr :
 */

import React, { Component } from "react";
import { Table, ConfigProvider } from "antd";
import zh_CN from "antd/es/locale-provider/zh_CN";

class StatisticList extends Component {
  render() {
    const { data, rowClickFn, isDepartment } = this.props;
    const total = data.length;
    const deptColumns = [
      {
        title: "部门名称",
        dataIndex: "deptName",
        width: 250,
        align: "center",
      },
      {
        title: "用户总数",
        dataIndex: "memNum",
        width: 270,
        align: "center",
      },
      {
        title: "在线用户",
        dataIndex: "onlineMemNum",
        width: 150,
        align: "center",
      },
      {
        title: "在线率",
        dataIndex: "onlineRate",
        width: 120,
        align: "center",
      }, {
        title: "平均在线时长",
        dataIndex: "averageOnlineTime",
        width: 120,
        align: "center",
      },
    ];
    const personColumns = [
      {
        title: "人员名称",
        dataIndex: "memName",
        width: 250,
        align: "center",
      },
      {
        title: "所属部门",
        dataIndex: "deptName",
        width: 270,
        align: "center",
      },
      {
        title: "在/离线状态",
        dataIndex: "devStatus",
        width: 150,
        align: "center",
        render:(value, text) => {
          return value === 1 ? "在线" : "离线"
        }
      },
      {
        title: "在线时长",
        dataIndex: "devTotalOnlineTime2",
        width: 120,
        align: "center",
      }, {
        title: "云眼",
        dataIndex: "cntChoudEye",
        width: 120,
        align: "center",
      }, {
        title: "对讲",
        dataIndex: "cntTalkBack",
        width: 120,
        align: "center",
      }, {
        title: "音频",
        dataIndex: "soundNum",
        width: 120,
        align: "center",
      }, {
        title: "视频",
        dataIndex: "videoNum",
        width: 120,
        align: "center",
      }, {
        title: "图片",
        dataIndex: "imgNum",
        width: 120,
        align: "center",
      }, {
        title: <div>已用流量 <span class="hovertap" title="流量按天统计">i</span></div>,
        dataIndex: "flowSum",
        width: 120,
        align: "center",
        render:(value)=>{
          return value+"兆"
        }
      }, {
        title: "轨迹长度",
        dataIndex: "gisTraceNum",
        width: 120,
        align: "center",
        render:(value)=>{
          return value+"米"
        }
      },
    ]
    return (
      <>
        <ConfigProvider locale={zh_CN}>
          <Table
            onRowClick={rowClickFn}
            rowKey={"id"}
            columns={isDepartment ? deptColumns : personColumns}
            dataSource={data}
            pagination={{
              // 分页
              total,
              pageSize: 10,
              itemRender: (page, type, originalElement) => {
                if (type === "prev") {
                  return <a>上一页</a>;
                }
                if (type === "next") {
                  return <a>下一页</a>;
                }
                return originalElement;
              },
            }}
            rowClassName={(record, index) => {
              let className = "even-bg";
              if (index % 2 === 0) {
                className = "odd-bg";
              }
              return className;
            }}
          />
        </ConfigProvider>
      </>
    );
  }
}

export default StatisticList;
