/*
 * @Description: fontsize
 * @Author: liulian
 * @Date: 2019-10-10 18:22:03
 * @LastEditTime: 2021-02-25 20:09:53
 * @LastEditors: yanghui
 */
function resizeFootSize(){
    const viewPort = document.documentElement.clientWidth;
    // let rootFontSize = 100;
    // if (viewPort <= 1400) rootFontSize = 12;
    // if (viewPort >= 1400 && viewPort <= 1900) rootFontSize = 12;
    // if (viewPort >= 1900) rootFontSize = 14;
    // if (viewPort >= 3201 && viewPort <= 3840) rootFontSize = 200;
    // if (viewPort > 3840 && viewPort <= 4500) rootFontSize = 230;
    // if (viewPort > 5000 && viewPort <= 5760) rootFontSize = 270;

    let rootFontSize = 100;
    if (viewPort <= 1480) rootFontSize = 71;
    if (viewPort >= 1481 && viewPort <= 1760) rootFontSize = 83;
    if (viewPort >= 2241 && viewPort <= 3200) rootFontSize = 130;
    if (viewPort >= 3201 && viewPort <= 3840) rootFontSize = 200;
    if (viewPort > 3840 && viewPort <= 4500) rootFontSize = 230;
    if (viewPort > 5000 && viewPort <= 5760) rootFontSize = 270;
    return rootFontSize;
}

export default resizeFootSize;