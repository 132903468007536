/*
 * @Descripttion: 
 * @version: V1.0
 * @Author: yanghui
 * @Date: 2020-11-11 15:19:25
 * @LastEditors: yanghui
 * @LastEditTime: 2021-01-22 18:12:41
 */
/*
 * File Created: 2019-09-24
 * Author:Chenhuan
 * Desc: 接口配置
 * -----
 */
import getServices from './axios-request';
import {getToken} from '../config/constans';

const manager = '/scooper-app-manager';
// const core = "/scooper-core-rest";

export let apis = {
    //项目
    auth: {
        login: manager + '/data/auth/login'
    }
};

export let scooper_app_api = getServices(
    '/scooper-poc-manager' /* 该参数作用为将url中的相同前缀提取，减少代码量 */,
    () => getToken(),
    {
        //数据配置
        queryOrgCorpInfo: {url: '/data/contacts/queryOrgCorpInfo', type: 'get'}, //分页查询单位信息
        saveOrgCorp: {url: '/data/contacts/saveOrgCorp', type: 'post'}, //分页查询单位信息
        deleteOrgCorp: {url: '/data/contacts/deleteOrgCorp', type: 'post'}, //删除单位
        updateOrgCorp: {url: '/data/contacts/updateOrgCorp', type: 'post'}, //更新单位

        saveOrgDispatchGroup: {url: '/data/contacts/saveOrgDispatchGroup', type: 'post'}, //生成通话组
        deleteOrgDispatchGroup: {url: '/data/contacts/deleteOrgDispatchGroup', type: 'post'}, //删除通话组
        updateOrgDispatchGroup: {url: '/data/contacts/updateOrgDispatchGroup', type: 'post'}, //编辑通话组
        memberPocImportCloude: {url: '/data/dev/memberPocImportCloude', type: 'post'}, //通讯录导入excel

        listOrgDept: {url: '/data/dept/listOrgDept', type: 'get'}, //获取部门树
        listOrgMember: {url: '/data/dept/listOrgMember', type: 'get'}, //获取部门下的成员
        queryMemberInfoInFirstDept: {url: '/data/contacts/queryMemberInfoInFirstDept', type: 'get'}, //通讯录表格-搜索成员名称
        queryCorpMemberInfo: {url: '/data/contacts/queryCorpMemberInfo', type: 'get'}, //通讯录表格-分页查询公司成员列表
        saveOrgDept: {url: '/data/dept/saveOrgDept', type: 'post'}, //新增部门
        updateOrgDept: {url: '/data/dept/updateOrgDept', type: 'post'}, //编辑部门
        deleteOrgDept: {url: '/data/dept/deleteOrgDept', type: 'post'}, //删除部门
        saveOrgMember: {url: '/data/contacts/saveOrgMember', type: 'post'}, //添加成员

        queryGroupMemberInfo: {url: '/data/contacts/queryGroupMemberInfo', type: 'get'}, //分页查询通话组成员列表
        queryCorpDevMemberInfo: {url: '/data/contacts/queryCorpDevMemberInfo', type: 'get'}, //查询单位下执法仪列表
        deleteOrgMemberList: {url: '/data/contacts/deleteOrgMemberList', type: 'post'}, //批量删除通讯录成员
        deleteDispMemberList: {url: '/data/contacts/deleteDispMemberList', type: 'post'}, //批量删除通话组组成员
        saveDispMemberList: {url: '/data/contacts/saveDispMemberList', type: 'post'}, //批量新增通话组成员
        updateOrgMemberInfo: {url: '/data/contacts/updateOrgMemberInfo', type: 'post'}, //编辑通话组组成员

        setUserCloude: {url: '/data/dev/setUserCloude', type: 'post'}, //执法仪绑定成员
        unBindUserCloude: {url: '/data/dev/unBindUserCloude', type: 'post'}, //记录仪解绑成员
        saveDevInfo: {url: '/data/dev/saveDevInfo', type: 'post'}, //添加单个APP设备

        queryMemNameListBind: {url: '/data/dev/queryMemNameListBind', type: 'get'}  //查询绑定设备的人员列表
    }
);

export let scooper_core_api = getServices('/scooper-core-rest', () => getToken(),
    {
        listDispGroup: {url: '/data/dispatch/dispGroupManage/listDispGroup', type: 'get'}, //查询通话组树
        updateDispMember: {url: '/data/dispatch/dispMemberManage/updateDispMember', type: 'get'}, //编辑通话组成员
        listAdCode: {url: '/data/contacts/orgCorpManage/listAdCode', type: 'get'}, //查询行政区域列表信息
        saveDispMember: {url: '/data/dispatch/dispMemberManage/saveDispMember', type: 'post'} //新增通话组组成员
    }
);
