/*
 * @Description: count for qinzhiyitihua
 * @Author: liulian
 * @Date: 2019-09-30 16:43:58
 * @LastEditTime: 2021-02-25 19:39:52
 * @LastEditors: yanghui
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from '../../../lib/jquery-vendor';

import ManageHeader from '../../components/manage-header';
import DataType from '../../components/countCom/DataType';
import SysResource from '../../components/countCom/SysResource';
import InterTop from '../../components/countCom/InterTop';
import ImportFile from '../../components/countCom/ImportFile';
import RecordLine from '../../components/countCom/RecordLine';
import FileCreate from '../../components/countCom/FileCreate';
import DataTime from '../../components/countCom/DataTime';

import {Form, Select, DatePicker, message, ConfigProvider} from 'antd';
import moment from 'moment';
import {statistics_api} from '@/rest-api/restapi';
import locale from 'antd/lib/locale-provider/zh_CN';
import {
    setDeptMemAction,
    setDataTypeAction,
    setDevNumAction,
    setDasDataAction,
    setFileLengthAction,
    setFileTimeAction,
    setFileLevelAction,
    setFileRecordAction,
    setStoreRangeAction
} from '@/reducer/video_action';
import {statistics} from '@/rest-api/restapi-video';
import {commonGet} from '@/request/common-axios';

import '../../../less/count/count.less';
import {getStatisticsTitle} from '@/api/SysConfig';

const {Option} = Select;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm';

export default connect(
    state => {
        const {
            dispGroup,
            dataType,
            dateStore,
            fileLevel,
            fileRecord,
            fileTime,
            fileLength,
            dasData,
            devNum,
            deptMem
        } = state;
        return {
            dispGroup,
            dataType,
            dateStore,
            fileLevel,
            fileRecord,
            fileTime,
            fileLength,
            dasData,
            devNum,
            deptMem
        };
    },
    {
        setDeptMemAction,
        setDataTypeAction,
        setFileLevelAction,
        setStoreRangeAction,
        setFileRecordAction,
        setFileTimeAction,
        setFileLengthAction,
        setDasDataAction,
        setDevNumAction
    }
)(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                startTime: '',
                endTime: '',
                deptInfo: [],
                deptId: '',
                defaultValue: [
                    moment(
                        moment(
                            new Date(
                                new Date() -
                                7 * 24 * 3600 * 1000 -
                                new Date().getHours() * 3600 * 1000 -
                                new Date().getMinutes() * 60 * 1000
                            ).getTime()
                        ).format('YYYY/MM/DD HH:mm'),
                        dateFormat
                    ),
                    moment(
                        moment(new Date().getTime()).format('YYYY/MM/DD HH:mm'),
                        dateFormat
                    )
                ]
            };
        }

        loadDataType = async deptId => {
            let startTime = this.state.defaultValue[0]._i;
            let endTime = this.state.defaultValue[1]._i;
            let params = {
                startTime: startTime,
                endTime: endTime,
                corpId: sessionStorage.getItem('corpId'),
                orgCode: sessionStorage.getItem('orgCode'),
                deptId: deptId
                // firstDeptId: sessionStorage.getItem('firstDeptId')
            };

            let param = {
                corpId: sessionStorage.getItem('corpId'),
                orgCode: sessionStorage.getItem('orgCode')
            };


            // 数据类型统计
            let data = await commonGet(statistics.getFileType, params);
            this.props.setDataTypeAction({dataType: data.data.data});

            // 重要文件占比
            let levelData = await commonGet(statistics.getFileLevel, params);
            this.props.setFileLevelAction({fileLevel: levelData.data.data});

            //系统资源展示
            // let sysData = await commonGet(statistics.getFileLevel, params);
            // store.dispatch(setFileLevelAction({ sysData: sysData.data.data }));

            //存储使用情况
            let storeData = await commonGet(statistics.getStoreRange, param);
            this.props.setStoreRangeAction({storeData: storeData.data.data});

            // 数据上传趋势
            let dataQuen = await commonGet(statistics.getFileUpload, params);
            this.props.setFileRecordAction({fileRecord: dataQuen.data.data});

            // 文件创建时间
            let fileTimeData = await commonGet(statistics.getFileCreate, params);
            this.props.setFileTimeAction({fileTime: fileTimeData.data.data});

            // 数据时长
            let dataLength = await commonGet(statistics.getFileLength, params);
            this.props.setFileLengthAction({fileLength: dataLength.data.data});

            // // 采集站
            // let datData = await commonGet(statistics.getDASData, params);
            // store.dispatch(setDasDataAction({ dasData: datData.data.data }));

            // 记录仪
            let devNumData = await commonGet(statistics.GetDevNum, params);
            this.props.setDevNumAction({devNum: devNumData.data.data});
        };

        /**
         * 获取页面标题
         */
        getTitle = async () => {
            const response = await getStatisticsTitle();
            const {cfgValue: title} = response.data;
            if (!response.code) {
                this.setState({
                    title
                });
            }
        };

        /**
         * 获取物联网卡流量使用
         */
        getUsedFlow = async (deptId) => {
            let _this = this;
            let startTime = this.state.defaultValue[0]._i.replace(/\//g, '-');
            let endTime = this.state.defaultValue[1]._i.replace(/\//g, '-');
            let params = {
                beginTime: startTime,
                endTime: endTime
                // firstDeptId: sessionStorage.getItem('firstDeptId'),
            };
            if (deptId) {
                params.deptId = this.state.deptId;
            }
            statistics_api.getUsedFlow(params).then(res => {
                if (!res.code) {
                    _this.setState({
                        usedFlow: res.data
                    });
                }
            });

        };

        componentDidMount() {
            this.loadDataType('');
            this.loadDept();
            this.getTitle();
            this.getUsedFlow('');
            if (!sessionStorage.getItem('token')) {
                message.error('请先登录！');
                setTimeout(() => this.props.history.push(`/`), 1000);
            }
        }

        /**
         * 查找部门信息
         */
        loadDept = async () => {
            // let param = {
            //   deptType: 3,
            //   corpId: sessionStorage.corpId,
            //   orgCode: sessionStorage.getItem('orgCode')
            // };
            // let coreData = await commonPost(dept.listOrgDept, param);
            // // console.log()
            // let nodes = {
            //   id: -1,
            //   deptName: "全部"
            // };
            // coreData.data.data.unshift(nodes);
            // store.dispatch(setDeptMemAction({ deptMem: coreData.data.data }));
            // console.log(this.props.deptMem);
            const orgCode = sessionStorage.getItem('orgCode');
            const param = {
                // deptType: 3,
                token: sessionStorage.getItem('token')
                // corpId: sessionStorage.getItem("corpId"),
                // orgCode: orgCode
            };
            const _this = this;
            $.ajax({
                url: `${window.config.modulesUrl}/scooper-poc-server/api/login/listDeptByOrgCode`,
                data: param,
                success: function (data) {
                    var item_all = {deptName: '全部', orgCode: orgCode, deptId: ''};
                    data.data.list.unshift(item_all);
                    _this.setState({
                        deptList: data.data.list
                    });
                    _this.props.setDeptMemAction({deptMem: data.data.list});
                }
            });
        };
        /**
         * 返回首页
         */
        goHome = () => {
            this.props.history.push('/home');
        };

        render() {
            return (
                <div className="count-wrap">
                    <ManageHeader goHome={this.goHome} title={this.state.title} this={this.props}/>

                    <div className="select-wrap">
                        <Form className="form">
                            <Form.Item>
                                <Select
                                    defaultValue="全部"
                                    style={{width: '12.4rem'}}
                                    onChange={this.onChange}
                                    dropdownClassName="dept-xiala"
                                >
                                    {this.props.deptMem &&
                                    this.props.deptMem.map(item => {
                                        return (
                                            <Option value={item.deptId} key={item.deptId}>
                                                {item.deptName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <ConfigProvider locale={locale}>
                                    <RangePicker
                                        showTime={{
                                            format: 'HH:mm',
                                            defaultValue: [
                                                moment('01:00', 'HH:mm'),
                                                moment('23:59', 'HH:mm')
                                            ]
                                        }}
                                        format="YYYY/MM/DD HH:mm"
                                        defaultValue={this.state.defaultValue}
                                        onOk={this.onOk}
                                        placeholder={['开始时间', '结束时间']}
                                    />
                                </ConfigProvider>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="content-wrap">
                        <div className="data-wrap">
                            <DataType data={this.props.dataType}/>
                            <span className="name">数据类型统计</span>
                        </div>
                        <div className="sys-wrap">
                            <SysResource/>
                            <span className="name">系统资源展示</span>
                        </div>
                        <div className="inter-wrap">
                            <InterTop usedFlow={this.state.usedFlow}/>
                        </div>
                        <div className="file-wrap">
                            <ImportFile data={this.props.fileLevel}/>
                            <span className="name">重要文件占比</span>
                        </div>
                        <div className="record-wrap">
                            <RecordLine data={this.props.fileRecord}/>
                            <span className="name">数据上传趋势</span>
                        </div>
                        <div className="fileCreate-wrap">
                            <FileCreate data={this.props.fileTime}/>
                            <span className="name">文件创建时间统计</span>
                        </div>
                        <div className="dataTime-wrap">
                            <DataTime data={this.props.fileLength}/>
                            <span className="name">数据时长统计</span>
                        </div>
                    </div>
                </div>
            );
        }

        /**
         * 下拉框改变
         */
        onChange = async value => {
            this.setState({
                deptId: `${value}`
            });
            var deptIdParam;
            if (value == -1) {
                deptIdParam = '';
            } else {
                deptIdParam = value;
            }
            let startTime = this.state.startTime || this.state.defaultValue[0]._i;
            let endTime = this.state.endTime || this.state.defaultValue[1]._i;
            let params = {
                startTime: startTime,
                endTime: endTime,
                corpId: sessionStorage.corpId,
                orgCode: sessionStorage.getItem('orgCode'),
                deptId: deptIdParam
            };
            // 数据类型统计
            let data = await commonGet(statistics.getFileType, params);
            this.props.setDataTypeAction({dataType: data.data.data});

            // 重要文件占比
            let levelData = await commonGet(statistics.getFileLevel, params);
            this.props.setFileLevelAction({fileLevel: levelData.data.data});

            // 数据上传趋势
            let dataQuen = await commonGet(statistics.getFileUpload, params);
            this.props.setFileRecordAction({fileRecord: dataQuen.data.data});

            // 文件创建时间
            let fileTimeData = await commonGet(statistics.getFileCreate, params);
            this.props.setFileTimeAction({fileTime: fileTimeData.data.data});

            // 数据时长
            let dataLength = await commonGet(statistics.getFileLength, params);
            this.props.setFileLengthAction({fileLength: dataLength.data.data});

            // 采集站
            let datData = await commonGet(statistics.getDASData, params);
            this.props.setDasDataAction({dasData: datData.data.data});

            // 记录仪
            let devNumData = await commonGet(statistics.GetDevNum, params);
            this.props.setDevNumAction({devNum: devNumData.data.data});

            //物联网卡流量使用
            this.getUsedFlow(value);
        };

        /**
         * 时间改变
         */
        onOk = async value => {
            let _this = this;
            this.setState({
                startTime: value[0].format('YYYY/MM/DD HH:mm'),
                endTime: value[1].format('YYYY/MM/DD HH:mm')
            });
            let startTime = value[0].format('YYYY/MM/DD HH:mm');
            let endTime = value[1].format('YYYY/MM/DD HH:mm');

            var deptIdParam;
            if (this.state.deptId == -1) {
                deptIdParam = '';
            } else {
                deptIdParam = this.state.deptId;
            }
            let params = {
                deptId: deptIdParam,
                startTime: startTime,
                endTime: endTime,
                corpId: sessionStorage.corpId,
                orgCode: sessionStorage.getItem('orgCode')
            };

            // 数据类型统计
            let data = await commonGet(statistics.getFileType, params);
            this.props.setDataTypeAction({dataType: data.data.data});

            // 重要文件占比
            let levelData = await commonGet(statistics.getFileLevel, params);
            this.props.setFileLevelAction({fileLevel: levelData.data.data});

            // 数据上传趋势
            let dataQuen = await commonGet(statistics.getFileUpload, params);
            this.props.setFileRecordAction({fileRecord: dataQuen.data.data});

            // 文件创建时间
            let fileTimeData = await commonGet(statistics.getFileCreate, params);
            this.props.setFileTimeAction({fileTime: fileTimeData.data.data});

            // 数据时长
            let dataLength = await commonGet(statistics.getFileLength, params);
            this.props.setFileLengthAction({fileLength: dataLength.data.data});

            // 采集站
            let datData = await commonGet(statistics.getDASData, params);
            this.props.setDasDataAction({dasData: datData.data.data});

            // 记录仪
            let devNumData = await commonGet(statistics.GetDevNum, params);
            this.props.setDevNumAction({devNum: devNumData.data.data});

            //物联网卡流量显示
            let _startTime = startTime.replace(/\//g, '-');
            let _endTime = endTime.replace(/\//g, '-');
            let params2 = {
                beginTime: _startTime,
                endTime: _endTime
                // firstDeptId: sessionStorage.getItem('firstDeptId'),
            };
            if (deptIdParam) {
                params2.deptId = deptIdParam;
            }
            statistics_api.getUsedFlow(params2).then(res => {
                if (!res.code) {
                    _this.setState({
                        usedFlow: res.data
                    });
                }
            });
        };
    }
);
