/*
 * File Created: 2019-10-21
 * Author:Chenhuan
 * Desc: 编辑部门/通话组
 * -----
 */
import React from "react";
import { Input, Modal, Form, message, Select, TreeSelect } from "antd";
import { scooper_app_api } from "@/util/api";
import { connect } from "react-redux";
const Option = Select.Option;
const { TreeNode } = TreeSelect;
const { confirm } = Modal;

const EditModal = connect(state => {
    return {
        deptTreeData: state.deptTreeData
    };
})(
    class EditModal extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                visible: false,
                isAdd: false,
                item: [],
                treeValue: "",
                //树节点
                treeData: [],
                // 加载过的树节点
                loadedKeys: [],
                //默认选中的树节点
                treeSelectedKeys: [],
                //加载
                confirmLoading: false,
                record: ""
            };
        }

        componentDidMount() {
            this.props.showModal(this.showModal);
        }

        //暴露接口给父组件来打开弹窗
        showModal = async data => {
            this.onInitTree(data.record.id, data.item.parentId);
            if (data.type === "deptedit") {
                this.setState({
                    isAdd: true
                });
            }
            this.setState({
                visible: true,
                item: data.item,
                record: data.record.corpId,
                confirmLoading: false
            });
        };

        // 初始化树节点
        onInitTree(myId, parentId) {
            this.setState({
                treeSelectedKeys: parentId === 0 ? myId : parentId,
                loadedKeys: [],
                treeData: this.props.deptTreeData
            });
        }
        /**
         * @desc 加载树节点
         * */
        onLoadTree = treeNode =>
            new Promise(resolve => {
                let { treeData } = this.state;
                if (treeNode.props.children && treeNode.props.children.length) {
                    resolve();
                    return;
                }
                const id = String(treeNode.props.eventKey);
                scooper_app_api
                    .listOrgDept({
                        parentId: id
                    })
                    .then(res => {
                        treeNode.props.dataRef.children = res.list;
                        this.setState({
                            treeData: [...treeData]
                        });
                        resolve();
                    });
            });

        //加载树节点
        renderTreeNodes = data =>
            data.map(item => {
                if (
                    item.children &&
                    item.id !== this.state.item.id
                    // &&(this.state.item.parentId === 0 || item.id !== this.state.item.id)
                ) {
                    return (
                        <TreeNode value={item.id} title={item.deptName} key={item.id} dataRef={item}>
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                }
                return <TreeNode value={item.id} title={item.deptName} key={item.id} dataRef={item} />;
            });

        // 取消添加
        handleCancel = () => {
            this.props.form.resetFields();
            this.setState({
                visible: false,
                isAdd: false,
                confirmLoading: false
            });
        };

        // 编辑部门提交
        editDeptSubmit() {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                } else {
                    confirm({
                        title: "确定对部门进行编辑?",
                        content: "",
                        okText: "确定",
                        cancelText: "取消",
                        onOk: () => {
                            if (values.parentId == this.state.item.id) {
                                if (this.state.item.parentId === 0) {
                                    let params = {
                                        deptName: values.deptName,
                                        parentId: 0,
                                        id: this.state.item.id,
                                        corpId: this.state.record,
                                        isCreateLog: 1
                                    };

                                    scooper_app_api
                                        .updateOrgDept(params)
                                        .then(() => this.addSuccess())
                                        .then(() => {
                                            this.props.form.resetFields();
                                            this.props.onInitTree();
                                            this.setState({ visible: false, isAdd: false });
                                        });
                                } else {
                                    message.warning("上级部门不可为自身，请重新选择上级部门");
                                }
                            } else {
                                let params = {
                                    deptName: values.deptName,
                                    parentId: values.parentId,
                                    id: this.state.item.id,
                                    corpId: this.state.record,
                                    isCreateLog: 1
                                };

                                scooper_app_api
                                    .updateOrgDept(params)
                                    .then(() => this.addSuccess())
                                    .then(() => {
                                        this.props.form.resetFields();
                                        this.props.onInitTree();
                                        this.setState({ visible: false, isAdd: false });
                                    });
                            }
                        }
                    });
                }
            });
        }
        //提醒用户是否添加或编辑成功
        addSuccess() {
            message.success("编辑成功!");
        }

        // 设备导入提交
        Submit() {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                } else {
                    let params = {
                        groupName: values.groupName,
                        groupNo: values.groupNo,
                        groupLevel: values.groupLevel,
                        id: this.state.item.id,
                        corpId: this.state.record,
                        isCreateLog: 1
                    };
                    confirm({
                        title: "确定对通话组进行编辑?",
                        content: "",
                        okText: "确定",
                        cancelText: "取消",
                        onOk: () => {
                            scooper_app_api
                                .updateOrgDispatchGroup(params)
                                .then(() => {
                                    message.success("编辑成功!");
                                })
                                .then(res => {
                                    this.props.form.resetFields();
                                    this.setState({ visible: false, isAdd: false });
                                    this.props.loadMemberListTree();
                                })
                                .catch(error => {
                                    this.setState({
                                        confirmLoading: false
                                    });
                                });
                        }
                    });
                }
            });
        }

        render() {
            const { visible, item, isAdd, treeData, treeSelectedKeys, confirmLoading, treeValue } = this.state;
            const { getFieldDecorator } = this.props.form;
            const formItemLayout = {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 }
            };
            return (
                <React.Fragment>
                    <Modal
                        maskClosable={false}
                        title={isAdd ? "编辑部门" : "编辑通话组"}
                        visible={visible}
                        onOk={isAdd ? () => this.editDeptSubmit() : () => this.Submit()}
                        onCancel={this.handleCancel}
                        okText="提交"
                        cancelText="取消"
                        confirmLoading={confirmLoading}
                        className="edit-gather"
                        width="460px"
                    >
                        {isAdd ? (
                            <Form {...formItemLayout}>
                                <Form.Item label="部门名称">
                                    {getFieldDecorator("deptName", {
                                        initialValue: item.deptName,
                                        rules: [
                                            {
                                                required: true,
                                                message: `请输入部门名称`
                                            }
                                        ]
                                    })(<Input maxLength="12" />)}
                                </Form.Item>
                                <Form.Item label="上级部门">
                                    {getFieldDecorator("parentId", {
                                        initialValue: treeSelectedKeys ? treeSelectedKeys : "0",
                                        rules: [
                                            {
                                                required: true,
                                                message: `请选择上级部门`
                                            }
                                        ]
                                    })(
                                        <TreeSelect
                                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                            // value={treeValue}
                                            allowClear
                                            onChange={this.onChange}
                                            loadData={this.onLoadTree}
                                        >
                                            {this.renderTreeNodes(treeData)}
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                            </Form>
                        ) : (
                            <Form {...formItemLayout}>
                                <Form.Item label="通话组名称">
                                    {getFieldDecorator("groupName", {
                                        initialValue: item.groupName,
                                        rules: [
                                            {
                                                required: true,
                                                message: `请输入通话组名称`
                                            }
                                        ]
                                    })(<Input maxLength={12} />)}
                                </Form.Item>

                                <Form.Item label="通话组等级">
                                    {getFieldDecorator("groupLevel", {
                                        initialValue: item.groupLevel,
                                        rules: [
                                            {
                                                required: true,
                                                message: `请选择通话组等级`
                                            }
                                        ]
                                    })(
                                        <Select>
                                            <Option value="0">0(最低)</Option>
                                            <Option value="1">1(较低)</Option>
                                            <Option value="2">2(较低)</Option>
                                            <Option value="3">3(中等)</Option>
                                            <Option value="4">4(中等)</Option>
                                            <Option value="5">5(中等)</Option>
                                            <Option value="6">6(中等)</Option>
                                            <Option value="7">7(较高)</Option>
                                            <Option value="8">8(较高)</Option>
                                            <Option value="9">9(较高)</Option>
                                            <Option value="10">10(最高)</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Form>
                        )}
                    </Modal>
                </React.Fragment>
            );
        }
    }
);

export default Form.create({ mapProps: props => props })(EditModal);
