/*
 * @Description: 部门管理
 * @Author: chenhuan
 * @Date: 2019-12-14
 */

import React from 'react';
import {getToken} from '@/config/constans';
import {connect} from 'react-redux';
import {scooper_app_api, scooper_core_api} from '@/util/api';
import {Modal, Icon, Tree, message} from 'antd';
import EditModal from './modal/edit-modal';
import BulkImportModal from './modal/bulk-modal';
import ManageHeader from '../../components/manage-header';
import UnitTable from './table-contain';
import axios from 'axios';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import {setCorpNodeIdAction, setDeptTreeData} from '@/reducer/video_action';
import {sys_api} from '@/rest-api/restapi';
import '@/less/dept/dept.less';

const {confirm} = Modal;
const DirectoryTree = Tree.DirectoryTree;
const {TreeNode} = Tree;


export default connect(
    state => {
        return {
            deptTreeData: state.deptTreeData,
            corpNodeId: state.corpNodeId
        };
    },
    {
        setCorpNodeIdAction,
        setDeptTreeData
    }
)(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                openSub1: true,
                openSub2: false,
                //通话组列表
                memberList: [],
                checkedSubKey: '',
                firstLevelKey: 'sub1',
                pitchOn: '',
                //树节点
                treeData: [],
                //默认选中的树节点
                selectedKeys: [],
                // 加载过的树节点
                loadedKeys: [],
                selectedName: '',
                title: '',
                //用户信息
                recordInfo: {
                    centerId: sessionStorage.getItem('centerId'),
                    corpId: sessionStorage.getItem('corpId'),
                    id: sessionStorage.getItem('firstDeptId')
                }
            };
        }

        componentDidMount() {
            if (window.location.hash.indexOf('/main/menu-group') > -1) {
                this.loadMemberListTree(true);
            } else {
                this.loadMemberListTree();
            }
            this.changeFirstState();
            this.onInitTree(true);
            if (!sessionStorage.getItem('token')) {
                message.error('请先登录！');
            }
        }

        /**
         * @desc 判断标题显示
         * @param {String} firstLevelKey 一级选择项
         * @param {String} selectedKeys 选中部门key值
         * @param {String} openSub1 通讯录是否展开
         * @param {String} openSub2 通话组是否展开
         */
        changeFirstState() {
            let params = {}, defaultTitle = '', firstLevelKey = '';
            if (window.location.hash.indexOf('/main/menu-dev') > -1) {
                params = {
                    cfgKey: 'scooper.poc.dev.title'
                };
                defaultTitle = '记录仪管理';
                firstLevelKey = 'sub3';
                this.setState({
                    openSub1: true,
                    openSub2: false
                });
            } else if (window.location.hash.indexOf('/main/menu-group') > -1) {
                params = {
                    cfgKey: 'scooper.poc.group.title'
                };
                defaultTitle = '通话组管理';
                firstLevelKey = 'sub2';
                this.setState({
                    openSub1: false,
                    openSub2: true
                });
            } else if (window.location.hash.indexOf('/main/menu-dept') > -1) {
                params = {
                    cfgKey: 'scooper.poc.dept.title'
                };
                defaultTitle = '部门管理';
                firstLevelKey = 'sub1';
                this.setState({
                    openSub1: false,
                    openSub2: false
                });
            }
            sys_api.getSysConfig(params).then(data => {
                this.setState({
                    title: data.data.cfgValue || defaultTitle,
                    // checkedSubKey: "",
                    firstLevelKey,
                    selectedKeys: []
                });
            });
        }

        /**
         * @desc 加载通话组树列表
         * @param {String} centerId 中心账号
         * @param {String} openSub1 通讯录是否展开
         * @param {String} openSub2 通话组是否展开
         */
        loadMemberListTree = trulyDelete => {
            let params = {
                // groupType: 1,
                // centerId: sessionStorage.getItem("centerId")
            };
            scooper_core_api.listDispGroup(params).then(res => {
                if (window.location.hash.indexOf('/main/menu-group') > -1) {
                    this.setState({
                        openSub1: false,
                        openSub2: true,
                        selectedKeys: [],
                        firstLevelKey: 'sub2'
                    });
                }
                //加载成员列表
                this.child.loadTable();
                if (trulyDelete) {
                    this.setState({
                        checkedSubKey: res[0] ? res[0].id : ''
                    });
                }
                this.setState({
                    memberList: res,
                    pitchOn: res[0],
                    checkedSubKey: res[0] ? res[0].id : ''
                });
            });
        };

        /**
         * @desc 初始化部门树树节点
         * @param {Object} treeData 部门树数据
         * @param {Object} selectedKeys 选中部门key值
         * @param {Object} loadedKeys 加载过的部门树
         */
        onInitTree = checked => {
            let _this = this;
            let params = {
                parentId: 0
            };
            let param = {
                cfgKey: 'scooper.poc.dept.title'
            };
            scooper_app_api.listOrgDept(params).then(res => {
                if (window.location.hash.indexOf('main/menu-dept') > -1) {
                    checked &&
                    _this.setState({
                        checkedSubKey: res.list[0] ? res.list[0].id : '',
                        selectedKeys: res.list[0] ? [res.list[0].id + ''] : []
                    });
                }

                _this.setState({
                    treeData: res.list.map(item => ({...item, children: []})),
                    selectedName: res.list[0] ? res.list[0].deptName : '',
                    loadedKeys: []
                });
                // console.log(res.list.map(item => ({ ...item, children: [] })));
                _this.props.setDeptTreeData({deptTreeData: res.list.map(item => ({...item, children: []}))});
            });
        };

        /**
         * @desc 加载树节点
         * */
        onLoadTree = treeNode => {
            const that = this;
            return new Promise(resolve => {
                const treeData = this.state.treeData;
                if (treeNode.props.children && treeNode.props.children.length) {
                    resolve();
                    return;
                }
                const id = treeNode.props.eventKey.toString();
                scooper_app_api
                    .listOrgDept({
                        parentId: id
                    })
                    .then(res => {
                        treeNode.props.dataRef.children = res.list;
                        // console.log([...treeData]);
                        that.props.setDeptTreeData({deptTreeData: [...treeData]});
                        // this.props.setDeptTreeData([...treeData]);
                        resolve();
                    });
            });
        };


        //加载树节点
        renderTreeNodes = data =>
            data.map(item => {
                if (item.children && item.children.length > 0) {
                    return (
                        <TreeNode
                            title={
                                <span className="dept-title">
                                    {item.deptName}
                                    <Icon
                                        type="edit"
                                        onClick={() =>
                                            this.showAddModal('openModal', {
                                                item,
                                                record: this.state.recordInfo,
                                                type: 'deptedit'
                                            })
                                        }
                                    />
                                    <Icon type="delete" onClick={event => this.deleteTalklist(item, event)}/>
                                </span>
                            }
                            isLeaf={false}
                            key={item.id}
                            dataRef={item}
                        >
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                }
                return (
                    <TreeNode
                        title={
                            <span className="dept-title">
                                {item.deptName}
                                <Icon
                                    type="edit"
                                    onClick={() =>
                                        this.showAddModal('openModal', {
                                            item,
                                            record: this.state.recordInfo,
                                            type: 'deptedit'
                                        })
                                    }
                                />
                                <Icon type="delete" onClick={event => this.deleteTalklist(item, event)}/>
                            </span>
                        }
                        key={item.id}
                        dataRef={item}
                    />
                );
            });

        // 返回首页
        goHome = () => {
            this.props.history.push('/home');
        };

        //菜单切换
        onOpenChange(openKeys) {
            if (openKeys === 'sub3') {
                this.props.history.push('/main/menu-dev');
                let param3 = {
                    cfgKey: 'scooper.poc.dev.title'
                };
                sys_api.getSysConfig(param3).then(data => {
                    this.setState({
                        title: data.data.cfgValue || '终端管理',
                        checkedSubKey: '',
                        firstLevelKey: 'sub3',
                        openSub1: false,
                        openSub2: false,
                        selectedKeys: []
                    });
                });
            } else if (openKeys === 'sub1') {
                this.props.history.push('/main/menu-dept');
                let param1 = {
                    cfgKey: 'scooper.poc.dept.title'
                };
                if (!this.state.openSub1 && this.state.firstLevelKey !== 'sub1') {
                    this.onInitTree(true);
                }
                sys_api.getSysConfig(param1).then(data => {
                    this.setState({
                        firstLevelKey: 'sub1',
                        title: data.data.cfgValue || '部门管理',
                        openSub1: !this.state.openSub1,
                        openSub2: false
                    });
                });
            } else {
                this.props.history.push('/main/menu-group');
                if (!this.state.openSub2 && this.state.firstLevelKey !== 'sub2') {
                    this.loadMemberListTree(true);
                }
                let param2 = {
                    cfgKey: 'scooper.poc.group.title'
                };
                sys_api.getSysConfig(param2).then(data => {
                    this.setState({
                        title: data.data.cfgValue || '通话组管理',
                        firstLevelKey: 'sub2',
                        openSub2: !this.state.openSub2,
                        openSub1: false
                    });
                });
            }
            this.child.loadTable(openKeys);
        }

        //menu点击
        menuChecked(item) {
            this.setState({
                checkedSubKey: item.id,
                firstLevelKey: 'sub2'
            });
        }

        //弹窗类型加载
        showAddModal(type, record) {
            this[type](record);
        }

        //选中树节点
        selectTreeNode(selectedKeys, value) {
            // console.log(selectedKeys, value.selectedNodes[0].props.dataRef.deptName);
            let selectedName = value.selectedNodes[0].props.dataRef.deptName;
            this.setState({
                checkedSubKey: selectedKeys[0],
                firstLevelKey: 'sub1',
                selectedKeys,
                selectedName
            });
        }

        //导出
        excelExport(e) {
            e.stopPropagation();
            const token = getToken();
            const {recordInfo, selectedName} = this.state;
            const corpId = recordInfo.corpId;
            confirm({
                title: '确定导出？',
                okText: '确认',
                cancelText: '取消',
                content: '',
                onOk: () => {
                    axios
                        .get(
                            `/scooper-poc-manager/data/dev/exportOrgFormwork?token=${token}&corpId=${corpId}`, {
                                responseType: 'blob'
                            }
                        )
                        .then(function (res) {
                            if (res && res.status.toString() === '200') {
                                const blob = new Blob([res.data]);
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.style.display = 'none';
                                link.href = url;
                                link.download = `${selectedName}.xls`;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                message.success('导出成功');
                            } else {
                                message.warning(res.data.message);
                            }
                        });
                }
            });
        }

        /**列表點擊回調
         * @param { object } item
         * @param { Event } event
         */
        //删除部门
        deleteTalklist(item, event) {
            event.stopPropagation();
            // console.log(id);
            confirm({
                title: '确定删除数据?',
                okText: '确认',
                cancelText: '取消',
                content: '',
                onOk: () => {
                    scooper_app_api.deleteOrgDept({id: item.id, deptName: item.deptName, isCreateLog: 1}).then(res => {
                        message.success('删除成功');
                        this.onInitTree(true);
                    });
                }
            });
        }

        //删除通话组
        deleteTalkGroup(item) {
            this.menuChecked(item);
            confirm({
                title: '确定删除数据?',
                okText: '确认',
                cancelText: '取消',
                content: '',
                onOk: () => {
                    scooper_app_api.deleteOrgDispatchGroup({
                        id: item.id,
                        groupName: item.groupName,
                        isCreateLog: 1
                    }).then(res => {
                        message.success('删除成功');
                        this.loadMemberListTree(true);
                    });
                }
            });
        }

        loadData = loadedKeys => {
            this.setState({loadedKeys});
        };

        onRef = ref => {
            this.child = ref;
        };

        render() {
            const {
                memberList,
                checkedSubKey,
                firstLevelKey,
                openSub1,
                openSub2,
                selectedKeys,
                loadedKeys,
                treeData,
                pitchOn,
                selectedName,
                title,
                recordInfo
            } = this.state;
            return (
                <ConfigProvider locale={zh_CN} autoInsertSpaceInButton={false}>
                    <div className="dept-wrap">
                        <ManageHeader goHome={this.goHome} title={title} this={this.props}/>

                        <div className="content-wrap">
                            <div className="deptList-wrap">
                                <ul className="menu-tree">
                                    <li key="sub1" onClick={() => this.onOpenChange('sub1')}>
                                        <div
                                            className={`menu-title ${
                                                firstLevelKey === 'sub1' ? 'menu-title-checked' : ''
                                            }`}
                                        >
                                            <Icon type="book"/>
                                            <span>通讯录</span>
                                            <Icon className="updown" type={openSub1 ? 'caret-up' : 'caret-down'}/>
                                            <a className="port" onClick={e => this.excelExport(e)}>
                                                导出
                                            </a>
                                            <a
                                                // href=""
                                                className="port"
                                                onClick={() =>
                                                    this.showAddModal('groupImport', {
                                                        recordInfo: this.state.recordInfo,
                                                        type: 'groupImport'
                                                    })
                                                }
                                            >
                                                导入
                                            </a>
                                        </div>
                                    </li>
                                    {openSub1 ? (
                                        <DirectoryTree
                                            className="dept-tree"
                                            loadData={this.onLoadTree}
                                            selectedKeys={selectedKeys}
                                            onSelect={(selectedKeys, value) => this.selectTreeNode(selectedKeys, value)}
                                            loadedKeys={loadedKeys}
                                            onLoad={this.loadData}
                                        >
                                            {this.renderTreeNodes(treeData)}
                                        </DirectoryTree>
                                    ) : (
                                        ''
                                    )}
                                    <li key="sub2" onClick={() => this.onOpenChange('sub2')}>
                                        <div
                                            className={`menu-title ${
                                                firstLevelKey === 'sub2' ? 'menu-title-checked' : ''
                                            }`}
                                        >
                                            <Icon type="phone"/>
                                            <span>通话组</span>
                                            <Icon className="updown" type={openSub2 ? 'caret-up' : 'caret-down'}/>
                                        </div>
                                    </li>
                                    {openSub2 ? (
                                        <ul className="submenu-group">
                                            {memberList.map(item => {
                                                return (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => this.menuChecked(item)}
                                                        className={
                                                            checkedSubKey === item.id
                                                                ? 'checked-group-title'
                                                                : 'group-title'
                                                        }
                                                    >
                                                        <span className="limit-menu" title={item.groupName}>
                                                            {item.groupName}
                                                        </span>
                                                        <Icon
                                                            type="delete"
                                                            onClick={() => this.deleteTalkGroup(item)}
                                                        />
                                                        <Icon
                                                            type="edit"
                                                            onClick={() =>
                                                                this.showAddModal('openModal', {
                                                                    item,
                                                                    record: recordInfo,
                                                                    type: 'gropedit'
                                                                })
                                                            }
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                    <li key="sub3" onClick={() => this.onOpenChange('sub3')}>
                                        <div
                                            className={`menu-title ${
                                                firstLevelKey === 'sub3' ? 'menu-title-checked' : ''
                                            }`}
                                        >
                                            <Icon type="setting"/>
                                            <span>终端</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="data-wrap">
                                <UnitTable
                                    onRef={this.onRef}
                                    checkedSubKey={checkedSubKey}
                                    pitchOn={pitchOn}
                                    recordInfo={recordInfo}
                                    firstLevelKey={firstLevelKey}
                                    onInitTree={(...rest) => this.onInitTree(...rest)}
                                    loadMemberListTree={this.loadMemberListTree}
                                    selectedName={selectedName}
                                />

                                <BulkImportModal
                                    showModal={showModal => (this.groupImport = showModal)}
                                    onInitTree={(...rest) => this.onInitTree(...rest)}
                                    loadTable={() => this.child.loadTable()}
                                />

                                <EditModal
                                    showModal={showModal => (this.openModal = showModal)}
                                    onInitTree={(...rest) => this.onInitTree(...rest)}
                                    loadMemberListTree={this.loadMemberListTree}
                                />
                            </div>
                        </div>
                    </div>
                </ConfigProvider>
            );
        }
    }
);
