/*
 * @Author: yanghui 
 * @Date: 2019-09-23 14:56:49 
 */

import React from 'react';
import {Form, Input, Button, message, Modal} from 'antd';
import NowDateTime from '@/view/components/date';
import {scooper_poc_log, log_api, scooper_poc_server_login} from '@/rest-api/restapi';
import {getFile, getVideo} from '@/component/HelpDocument';
import QrCodeLogin from '@/component/qr-code-login';
import {getGisTitle, getLoginTitle, getTitleByCfgKey, getWebTitle} from '@/api/SysConfig';
import {createScAuth} from 'scooper-core-auth';
import {functionModuleList, settingManagementList} from '@/pages/login/config';
import LoginPanel from '@/pages/login/LoginPanel';
import ResetPassWord from '@/pages/login/ResetPassWord';

import '../../less/login/login.less';

const FormItem = Form.Item;

// const host = window.config.sysUrl;
window.auth = createScAuth();

class DoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            tile: '',
            visible: false,
            checked: false,
            video: false,
            file: false,
            needChangePwd: false    // 是否修改密码，false - 不修改， true - 修改
        };
    }

    componentDidMount() {
        const agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf('chrome') === -1) {
            message.error('不允许登录和操作！');
            this.setState({
                visible: true
            });
        }

        const token = sessionStorage.getItem('token') || localStorage.getItem('token');
        const accId = sessionStorage.getItem('accId') || localStorage.getItem('accId');
        // 通过token和accId获取对应的配置信息
        if (token && accId) {
            this.getPageConfig();
        } else {
            this.getDefaultSysConfig();
        }


        this.getSettingConfig();
        const video = getVideo();
        const file = getFile();
        this.setState({
            video,
            file
        });
        /*
        this.getPageTitle();*/
    }

    reminder = () => {
        message.error('请先登录！');
    };

    /**
     * 获取指定账号的页面配置信息
     */
    getPageConfig = async () => {
        const param = {
            token: localStorage.getItem('token') || sessionStorage.getItem('token'),
            accId: localStorage.getItem('accId') || sessionStorage.getItem('accId')
        };
        const webTitleData = await log_api.getPageConfig(param);
        const {pageName, pageTitle, pageGisName} = webTitleData.data;
        sessionStorage.setItem('pageTitle', pageTitle);
        sessionStorage.setItem('pageName', pageName);
        sessionStorage.setItem('pageGisName', pageGisName);
        document.title = pageTitle;

        await this.getDefaultSysConfig();
        //修改favicon
        /*var oLink = document.getElementById('linkId');
        if (oLink) {
            oLink.parentNode.removeChild(oLink);
        }
        var link = document.createElement('link');
        link.rel = 'shortcut icon';
        link.href = `${window.config.sysUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${token}&filePath=${res.data.pageImg}`;
        link.id = 'linkId';
        document.getElementsByTagName('head')[0].appendChild(link);*/
    };

    /**
     * 获取默认页面配置信息
     * @return {Promise<void>}
     */
    getDefaultSysConfig = async () => {
        const pageTitle = sessionStorage.getItem('pageTitle') || '';
        const pageName = sessionStorage.getItem('pageName') || '';
        const pageGisName = sessionStorage.getItem('pageGisName') || '';
        // 如果未获得相应的配置信息则获取默认的配置信息
        if (pageTitle === 'undefined' || pageTitle.length === 0) {
            const webTitleData = await getWebTitle();   // 获取默认网页标签数据
            const {cfgValue: pageTitle} = webTitleData.data;
            sessionStorage.setItem('pageTitle', pageTitle);
            document.title = pageTitle;
        }
        if (pageName === 'undefined' || pageName.length === 0) {
            const loginTitleData = await getLoginTitle();   // 获取默认登录面标题数据
            const {cfgValue: pageName} = loginTitleData.data;
            sessionStorage.setItem('pageName', pageName);
            this.setState({
                pageName
            });
        }
        if (pageGisName === 'undefined' || pageGisName.length === 0) {
            const gisTitleData = await getGisTitle();   // 获取默认登录gis标题数据
            const {cfgValue: pageGisName} = gisTitleData.data;
            sessionStorage.setItem('pageGisName', pageGisName);
            this.setState({
                pageGisName
            });
        }
    };

    /**
     * 获取模块名称的配置
     * @return {Promise<void>}
     */
    getSettingConfig = async () => {
        functionModuleList.map(async item => {
            const response = await getTitleByCfgKey(item.cfgKey);
            const {cfgValue} = response.data;
            item.name = cfgValue;
        });
        settingManagementList.map(async item => {
            if (item.cfgKey.length > 0) {
                const response = await getTitleByCfgKey(item.cfgKey);
                const {cfgValue} = response.data;
                item.name = cfgValue;
            }
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    /**
     * 修改页面标签&Logo
     */

    /*getPageTitle = () => {
        const token = sessionStorage.getItem('token') || localStorage.getItem('token');
        const accId = sessionStorage.getItem('accId') || sessionStorage.getItem('accId');
        const params = {
            token: token,
            accId: accId
        };
        if (token && accId) {
            const {sysUrl} = window.config;
            $.ajax({
                url: `${sysUrl}/scooper-poc-server/data/pageConfig/getPageConfigByAccId`,
                data: params,
                success: function (res) {
                    let oLink;
                    const arr = Object.getOwnPropertyNames(res.data);
                    if (!res.code && arr.length > 0) {
                        //修改项目title值
                        var title = document.getElementsByTagName('title')[0];
                        if (res.data.pageTitle && res.data.pageTitle !== '') {
                            document.title = res.data.pageTitle;
                            sessionStorage.setItem('loginTitle', res.data.pageTitle);
                        } else {
                            // document.title = '勤指一体化平台';
                        }
                        //修改favicon
                        oLink = document.getElementById('linkId');
                        if (oLink) {
                            oLink.parentNode.removeChild(oLink);
                        }
                        var link = document.createElement('link');
                        link.rel = 'shortcut icon';
                        link.href = `${window.config.sysUrl}/scooper-poc-server/data/manaFile/loadFileStream?token=${token}&filePath=${res.data.pageImg}`;
                        link.id = 'linkId';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    } else {
                        var title = document.getElementsByTagName('title')[0];
                        // document.title = '勤指一体化平台';
                        oLink = document.getElementById('linkId');
                        if (oLink) {
                            oLink.parentNode.removeChild(oLink);
                        }
                        var link = document.createElement('link');
                        link.rel = 'shortcut icon';
                        link.href = './favicon.ico';
                        link.id = 'linkId';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                }
            });
        }
    };*/

    /**
     * 登录成功回调函数
     */
    loginSuccessHandler = () => {
        sessionStorage.setItem('isLockScreen', 'false');
        this.props.history.push(`/home`);
    };

    /**
     * 重置密码
     */
    resetPassWordHandler = (type) => {
        const {confirm} = Modal;
        switch (type) {
            case 1: // 首次登录，修改密码
                confirm({
                    title: '首次登录，请修改密码',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        this.setState({
                            needChangePwd: true
                        });
                    }
                });
                break;
            case 2: // 密码过期，修改密码
                confirm({
                    title: '密码过期，请修改密码',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        this.setState({
                            needChangePwd: true
                        });
                    }
                });
                break;
            default:
                break;
        }
    };
    /**
     * 修改密码返回回调
     */
    goBackHandler = () => {
        this.setState({
            needChangePwd: false
        });
    };

    render() {
        const {file, video, needChangePwd} = this.state;
        const pageName = sessionStorage.getItem('pageName') || this.state.pageName;

        return (
            <div className='login-page'>
                <QrCodeLogin/>
                <div className='login-box'>
                    <div className='top-header'>
                        <div className='top-left'>
                            <span className='top-title'>{pageName}</span>
                        </div>
                        <div className='top-right'>
                            {(file || video) && <div className='help'>
                                <i className='help-icon'/>
                                <span className='help-text'>帮助</span>
                                {file && <a className='doc-icon' title='使用文档' target='_blank'
                                            href={`/scooper-poc-server/api/sysConfig/downloadFile?fileType=document`}/>}
                                {video && <a className='video-icon' title='使用视频' target='_blank'
                                             href={`/scooper-poc-server/api/sysConfig/downloadFile?fileType=video`}/>}
                            </div>}
                            <NowDateTime/>
                        </div>
                    </div>
                    <div className='login-content'>
                        {
                            needChangePwd ? <ResetPassWord goBack={this.goBackHandler}/> :
                                <LoginPanel loginSuccess={this.loginSuccessHandler}
                                            resetPassWord={this.resetPassWordHandler}/>
                        }
                        <div className='nav-list'>
                            <div className='left-nav-content'>
                                <div className='content-title'>功能模块</div>
                                <div className='main-nav-content list1'>
                                    {
                                        functionModuleList.map((item) => {
                                            return (
                                                <div className={`${item.class} modules`} key={item.key}
                                                     onClick={this.reminder}>
                                                    <i/>
                                                    <div className='title'>{item.name}</div>
                                                    <div className='cover'/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='right-nav-content'>
                                <div className='content-title'>
                                    <div className='module-manager'>
                                        <p className='list2-title'>配置管理</p>
                                        <i className='module-icon'/>
                                        <span className='module-text'>模块管理</span>
                                    </div>
                                </div>
                                <div className='extra-nav-content list2'>
                                    {
                                        settingManagementList.map((item) => {
                                            return (
                                                <div className={`${item.class} modules`} key={item.key}
                                                     onClick={this.reminder}>
                                                    <i/>
                                                    <div className='title'>{item.name}</div>
                                                    <div className='cover'/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.visible}
                    title='弹窗提示'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className='show-prompt'
                    footer={
                        <button className='cancel'>取消</button>
                    }
                >
                    <p className='prompt-text'>本系统暂不支持IE下操作！</p>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(DoLogin);

