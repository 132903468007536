import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import Login from '../pages/login/index';
import Home from '../home/index';
import Main from '../view/index';

const ROUTER_CFG = Object.freeze({
    ROOT: '/',
    LOGIN: '/login',
    HOME: '/home',
    MAIN: '/main'
});

const {ROOT, LOGIN, HOME, MAIN} = ROUTER_CFG;

export default class AppRouter extends Component {
    render() {
        return (
            <React.Fragment>
                <Route exact path={ROOT} component={Login}/>
                <Route path={LOGIN} component={Login}/>
                <Route path={HOME} component={Home}/>
                <Route path={MAIN} component={Main}/>
            </React.Fragment>
        );
    }
}