/*
 * @Author: yanghui 
 * @Date: 2019-09-19 16:51:09
 * @Last Modified by: yanghui
 * @Last Modified time: 2019-10-22 10:35:29
 */

import React from 'react';
import {DatePicker, Input, Button, Select, Menu, Form, message, Row, LocaleProvider, Modal} from 'antd';
import ManageHeader from '../../components/manage-header';
import $ from 'jquery';
import PoliceTable from '../../components/police-table';
import '@/less/police.less';
import {connect} from 'react-redux';
import {police_api, sys_api, scooper_poc_server} from '@/rest-api/restapi';
import {
    setPoliceListAction,
    setPoliceListAllAction,
    setSelectAction
} from '@/reducer/video_action';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const {RangePicker} = DatePicker;
const Option = Select.Option;
const {confirm} = Modal;

const Police = connect(
    state => {
        return {
            selectedRows: state.selectedRows,
            selectedRowKeys: state.selectedRowKeys,
            policeListAll: state.policeListAll
        };
    },
    {
        setSelectAction,
        setPoliceListAction,
        setPoliceListAllAction
    }
)(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeRange: ['', ''],
            eventType: 0  //默认事件类型
        };
    }

    /**
     * 重置时间选择器
     */
    CascaderClear = () => {
        let closeBtn = document.getElementsByClassName('ant-calendar-picker-clear')[0];
        closeBtn && closeBtn.click();
    };

    /**
     * 重置搜索框
     */
    reset = () => {
        this.props.form.resetFields();
        this.setState({
            timeRange: ['', '']
        }, () => {
            this.CascaderClear();
        });
        this.getPoliceList(1);
        this.setState({
            timeMax: '',
            timeMin: '',
            searchKey: '',
            sosType: ''
        });

    };

    /**
     * 返回首页
     */
    goHome = () => {
        this.props.history.push('/home');
    };

    componentWillMount() {
        if (document.location.href.indexOf('token') > -1) {
            var arr = document.location.href.split('?');
            var _arr = arr[1].split('&');
            var token = '';
            _arr.map(item => {
                if (item.indexOf('token') > -1) {
                    token = item.split('=')[1];
                    sessionStorage.setItem('token', token);
                    localStorage.setItem('token', token);
                }
            });
        }

    }

    /**
     * 获取页面标题
     */
    getTitle = async () => {
        var param = {
            cfgKey: 'scooper.poc.alarm.title'
        };
        const data = await sys_api.getSysConfig(param);
        if (!data.code) {
            this.setState({
                title: data.data.cfgValue,
                alarm: true
            });
        }
    };


    componentDidMount() {
        this.getPoliceList(1);
        this.getTitle();
        if (!sessionStorage.getItem('token')) {
            message.error('请先登录！');
            setTimeout(() => this.props.history.push(`/`), 1000);
        }
        this.getEventType();
        // document.addEventListener("keydown",this.keypressPolice);
    }


    /**
     * @获取告警记录数据
     * @param {pageIndex}
     */
    getPoliceList = async (pageIndex) => {
        var param = {
            token: sessionStorage.getItem('token'),
            corpId: sessionStorage.getItem('corpId'),
            // orgCode: sessionStorage.getItem('orgCode'),
            pageIndex,
            pageSize: 10
        };
        const _this = this;
        $.ajax({
            url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/queryAlarmRecord`,
            data: param,
            success: (data) => {
                if (!data.code) {
                    _this.props.setPoliceListAction({policeList: data.data.list});
                    _this.props.setPoliceListAllAction({policeListAll: data.data});
                }
            }
        });
    };

    dateOnChange = (date, dateString) => {
        this.setState({
            timeRange: dateString
        });

    };

    /**
     * 根据条件搜索告警记录
     */
    searchPolice = () => {
        const _this = this;
        const {
            form: {validateFields}
        } = this.props;
        var param = {};
        validateFields(async (err, values) => {
            $.each(values, (key, value) => {
                if (key !== 'time' && value !== undefined && value !== null && value !== NaN && value !== '') {
                    param[key] = value;
                }
                param.timeMin = this.state.timeRange[0];
                param.timeMax = this.state.timeRange[1];
                param.pageIndex = 1;
                param.pageSize = 10;
                param.token = sessionStorage.getItem('token');
                param.corpId = sessionStorage.getItem('corpId');
                // param.orgCode = sessionStorage.getItem('orgCode')
            });
            $.ajax({
                url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/queryAlarmRecord`,
                data: param,
                success: (data) => {
                    if (!data.code) {
                        _this.props.setPoliceListAction({policeList: data.data.list});
                        _this.props.setPoliceListAllAction({policeListAll: data.data});
                        this.setState({
                            timeMax: param.timeMax,
                            timeMin: param.timeMin,
                            searchKey: values.searchKey,
                            sosType: values.sosType
                        });
                    }
                }
            });
        });
    };

    /**
     * 获取事件类型配置
     */
    getEventType = async () => {
        var param = {
            token: sessionStorage.getItem('token'),
            accId: sessionStorage.getItem('accId')
        };
        const data = await scooper_poc_server.getGisConfig(param);
        if (!data.code && data.data.mapType && data.data.mapZoom && data.data.center) {
            this.setState({
                eventType: data.data.eventType
            });
        }
    };

    /**
     * 批量删除告警记录
     */
    deleteBatchPolice = async () => {
        const _this = this;
        const selectedRows = this.props.selectedRows;
        if (selectedRows.length == 0) {
            message.error('请勾选要删除的记录！');
            return;
        }
        confirm({
            title: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                var id = [];
                selectedRows && selectedRows.map((item) => {
                    id.push(item.id);
                });
                const _id = id.join(',');
                const param = {
                    ids: _id
                };
                $.ajax({
                    url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/delAlarmRecord`,
                    data: param,
                    contentType: '',
                    success: (data) => {
                        if (!data.code) {
                            message.success('删除成功');
                            _this.props.setSelectAction({selectedRows: []});
                            this.updateSelectedKeys(selectedRows);
                            var param_1 = {
                                timeMax: this.state.timeMax,
                                timeMin: this.state.timeMin,
                                searchKey: this.state.searchKey,
                                sosType: this.state.sosType,
                                token: sessionStorage.getItem('token'),
                                corpId: sessionStorage.getItem('corpId')
                                // orgCode: sessionStorage.getItem('orgCode')
                            };
                            param_1.pageIndex = this.props.policeListAll.pageNumber;
                            $.ajax({
                                url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/queryAlarmRecord`,
                                data: param_1,
                                success: (data) => {
                                    if (!data.code) {
                                        if (data.data.list.length == 0) {
                                            param_1.pageIndex = data.data.pageNumber - 1;
                                            $.ajax({
                                                url: `${window.config.modulesUrl}/poc-web/data/alarmRecord/queryAlarmRecord`,
                                                data: param_1,
                                                success: function (_data) {
                                                    _this.props.setPoliceListAction({policeList: _data.data.list});
                                                    _this.props.setPoliceListAllAction({policeListAll: _data.data});
                                                }
                                            });
                                        } else {
                                            _this.props.setPoliceListAction({policeList: data.data.list});
                                            _this.props.setPoliceListAllAction({policeListAll: data.data});
                                        }
                                    }
                                }
                            });
                        }
                    },
                    error: (err) => {
                        console.log(err);
                    }
                });
            }
        });

    };

    /**
     * @description 清空残留复选框
     */
    updateSelectedKeys = (rows) => {
        var selectKeys = this.props.selectedRowKeys;
        for (let i = 0; i < rows.length; i++) {
            selectKeys.splice(selectKeys.indexOf(rows.sequence), 1);
        }
    };

    /**
     * 回车事件
     */
    keypressPolice = e => {
        if (e.keyCode == 13) {
            this.searchPolice();
        }
    };


    /**
     * 设置未来时间不可选
     */
    disabledDate = current => current && current > moment().endOf('day');

    render() {
        const {getFieldDecorator} = this.props.form;
        const {eventType, alarm} = this.state;
        return (
            <div className="box">
                <ManageHeader goHome={this.goHome} alarm={alarm} title={this.state.title} this={this.props}
                              eventType={eventType}/>

                <div className="police-main-content">
                    <div className="content-top">
                        <Row>
                            <Form onSubmit={this.handleSubmit} className="form">
                                <Form.Item>
                                    <span className="time-ranger  key-words">时间</span>
                                    {getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <LocaleProvider locale={locale}>
                                            <RangePicker
                                                locale={locale}
                                                showTime={{format: 'HH:mm:ss'}}
                                                format="YYYY-MM-DD HH:mm:ss"
                                                placeholder={['开始时间', '结束时间']}
                                                onChange={this.dateOnChange}
                                                disabledDate={this.disabledDate}
                                            />
                                        </LocaleProvider>
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    <span className="key-words name"> 关键字 </span>
                                    {getFieldDecorator('searchKey', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder="请输入姓名" style={{width: '8rem'}}
                                               onPressEnter={this.searchPolice}/>
                                    )}

                                </Form.Item>

                                <Form.Item>
                                    <span className="key-words last-word"> 告警类型 </span>
                                    {getFieldDecorator('type', {
                                        initialValue: ''
                                    })(
                                        <Select style={{width: '12.5rem'}} onChange={this.handleChange}
                                                dropdownClassName="xiala">
                                            <Option value="1">电子围栏告警</Option>
                                            {/*<Option value="2">巡逻路线告警</Option>*/}
                                            <Option value="3">SOS告警</Option>
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    <span className="key-words last-word"> {eventType == 0 ? 'SOS告警' : '上呼类型'} </span>
                                    {getFieldDecorator('sosType', {
                                        initialValue: ''
                                    })(
                                        <Select style={{width: '12.5rem'}} onChange={this.handleChange}
                                                dropdownClassName="xiala">
                                            <Option value="0">{eventType == 0 ? '处理sos告警' : '处理上呼请求'}</Option>
                                            <Option value="1">超时未处理</Option>
                                            <Option value="2">{eventType == 0 ? '撤销sos告警' : '撤销上呼请求'}</Option>
                                            <Option value="3">{eventType == 0 ? '发起sos告警' : '发起上呼请求'}</Option>
                                            <Option value="4">{eventType == 0 ? '忽略sos告警' : '忽略上呼请求'}</Option>
                                        </Select>
                                    )}

                                </Form.Item>
                            </Form>
                            <div className="btn-group">
                                <button className="search-btn ant-btn" onClick={this.searchPolice}>搜索</button>
                                <button className="reset-btn ant-btn" onClick={this.reset}>重置</button>
                                <button className="delete-btn ant-btn" onClick={this.deleteBatchPolice}>删除</button>
                            </div>
                        </Row>
                    </div>

                    <div className="divider"/>
                    <ConfigProvider locale={zhCN}>
                        <div className="content">
                            <PoliceTable eventType={eventType} timeMax={this.state.timeMax} timeMin={this.state.timeMin}
                                         searchKey={this.state.searchKey} sosType={this.state.sosType}
                                         history={this.props.history}/>
                        </div>
                    </ConfigProvider>
                </div>
            </div>
        );

    }
});

export default Form.create()(Police);