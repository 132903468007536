/*
 * File Created: 2019-10-21
 * Author:Chenhuan
 * Desc: 生成通话组
 * -----
 */
import React from "react";
import { Input, Modal, Form, message, Select, Button } from "antd";
import { scooper_app_api } from "@/util/api";
import UserPicker from "@/view/components/UserPick/UserPicker";
import { getToken } from "@/config/constans";
const Option = Select.Option;

class CreateGroupModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: undefined,
      centerList: [],
      rowKey: undefined,
      parentUnitId: undefined,
      deptId: undefined,
      //判断通讯录、通话组
      isDept: false,
      //是否显示人员选择器
      memVisible: false,
      //加载
      confirmLoading: false
    };
  }

  componentDidMount() {
    this.props.showModal(this.showModal);
  }

  /**
   * 暴露接口给父组件来打开弹窗
   */
  showModal = async data => {
  
    if (data.type === "dept") {
      this.setState({
        isDept: true
      });
    }
    this.setState({
      visible: true,
      recordInfo: data.recordInfo,
      rowKey: data.rowKey,
      deptId: data.deptId,
      confirmLoading: false
    });
  };

  // 取消添加
  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      isDept: false,
      memVisible: false,
      confirmLoading: false
    });
  };

  onChange = value => {
    this.setState({ value });
  };

  //部门通话组提交
  deptSubmit() {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        const { recordInfo, deptId } = this.state;
        let memIds = this.state.rowKey ? String(this.state.rowKey) : undefined;
        let params = {
          groupName: values.groupName,
          groupLevel: values.groupLevel,
          memIds: memIds,
          deptId: deptId,
          corpId: recordInfo.corpId,
          centerId: recordInfo.centerId,
          isCreateLog: 1
        };
        scooper_app_api
          .saveOrgDispatchGroup(params)
          .then(() => {
            this.addSuccess();
            this.props.groupCancel();
            this.props.form.resetFields();
            this.setState({ visible: false, isDept: false });
          })
          .catch(error => {
            this.setState({
              confirmLoading: false
            });
          });
      }
    });
  }

  //通话组提交
  groupSubmit() {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (this.state.rowKey && this.state.rowKey.length > 0) {
          const { recordInfo, rowKey } = this.state;
          let params = {
            groupName: values.groupName,
            groupLevel: values.groupLevel,
            memIds: String(rowKey),
            centerId: recordInfo.centerId,
            corpId: recordInfo.corpId,
            isCreateLog: 1
          };
          scooper_app_api
            .saveOrgDispatchGroup(params)
            .then(() => {
              this.addSuccess();
              this.props.form.resetFields();
              this.setState({ visible: false, isDept: false });
            })
            .catch(error => {
              this.setState({
                confirmLoading: false
              });
            });
        } else {
          message.warning("请选择人员");
        }
      }
    });
  }
  //提醒用户是否添加或编辑成功
  addSuccess() {
    message.success("添加成功!");
    this.props.loadMemberListTree();
  }

  //打开人员选择器
  openUserPicker() {
    this.setState({ memVisible: true });
  }
  //人员选择回调
  updateMember = (...rest) => {
    console.log("-------------",rest);
    const memberList = rest[0];
    //批量新增通话组成员
    let corgMemList = [];
    memberList.forEach(item => {
      corgMemList.push(item.id);
    });
    if (corgMemList.length !== 0) {
      this.setState(
        {
          rowKey: corgMemList
        },
        () => {
          this.cancel();
        }
      );
    }
  };
  cancel = () => {
    this.setState({ memVisible: false });
  };
  render() {
    const {
      visible,
      confirmLoading,
      isDept,
      recordInfo,
      memVisible
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };
    return (
      <React.Fragment>
        <Modal
          maskClosable={false}
          title="生成通话组"
          visible={visible}
          onOk={isDept ? () => this.deptSubmit() : () => this.groupSubmit()}
          onCancel={this.handleCancel}
          confirmLoading={confirmLoading}
          okText="提交"
          className="edit-gather"
          width="480px"
        >
          <Form {...formItemLayout}>
            <Form.Item label="通话组名称">
              {getFieldDecorator("groupName", {
                initialValue: isDept ? this.props.selectedName : "",
                rules: [
                  {
                    required: true,
                    message: `请输入通话组名称`
                  }
                ]
              })(<Input maxLength={12} />)}
            </Form.Item>
            <Form.Item label="通话组等级">
              {getFieldDecorator("groupLevel", {
                initialValue: isDept ? "0" : "",
                rules: [
                  {
                    required: true,
                    message: `请选择通话组等级`
                  }
                ]
              })(
                <Select>
                  <Option value="0">0(最低)</Option>
                  <Option value="1">1(较低)</Option>
                  <Option value="2">2(较低)</Option>
                  <Option value="3">3(中等)</Option>
                  <Option value="4">4(中等)</Option>
                  <Option value="5">5(中等)</Option>
                  <Option value="6">6(中等)</Option>
                  <Option value="7">7(较高)</Option>
                  <Option value="8">8(较高)</Option>
                  <Option value="9">9(较高)</Option>
                  <Option value="10">10(最高)</Option>
                </Select>
              )}
            </Form.Item>
          </Form>
          {isDept ? (
            <span className="notice">
              * 若未进行人员选择，则默认全组成员快捷生成通话组
            </span>
          ) : (
            <Button
              type="primary"
              onClick={() => this.openUserPicker()}
              className="choice-member"
            >
              请选择人员
            </Button>
          )}

          <UserPicker
            visible={memVisible}
            mode={1}
            ip=""
            restrict={false}
            token={getToken()}
            record={recordInfo}
            memData={this.updateMember}
            cancel={this.cancel}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form.create({ mapProps: props => props })(CreateGroupModal);
